import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import '../../css/zamery.css';

const ZameryBlockDisplay = ({ item }) => {
    return (
        <Container className='d-flex flex-column justify-content-between border rounded p-3 zamery-container'>
            <Row className='d-flex'>
                <p className='p-0 mb-1 zamery-id'>{item.projectId}</p>
                <p className='p-0 zamery-name'>{item.projectName}</p>
            </Row>
            <Row className='d-flex align-items-center pt-4 pb-1'>
                <Col>
                    {/* <button type='button' className='mb-2 zamery-button'>Nová žádost</button>
                    <button type='button' className='zamery-button zamery-button--filled'>Zobrazit detail</button> */}
                </Col>
            </Row>
        </Container>
    );
};

ZameryBlockDisplay.propTypes = {
    item: PropTypes.shape({
        number: PropTypes.string,
        projectId: PropTypes.string,
        projectName: PropTypes.string,
        state: PropTypes.string,
        status: PropTypes.string,
        date: PropTypes.string,
    })
};

export default ZameryBlockDisplay;