import React, { useContext, useEffect, useState, useImperativeHandle } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/prijemci.css';
import useFirstRender from '../CustomHooks/useFirstRender.jsx';
import { AuthContext } from 'react-oauth2-code-pkce';
import axios from 'axios';
import PropTypes from 'prop-types';
import usePrevious from '../CustomHooks/usePrevious';
import FormContext from '../../formContexts/FormContext';
import { canFulFillPrijemceList, canInitiateSaveForPrijemce, getBuildApplicationFormForPrijemce, getPrijemceList } from '../../helperFunctions/helpers';
import { formSave } from '../../apiCalls/formApiCalls';
import { SearchWithDataList } from '../index.js';
import { AUTHORITY_MODEL, BUILDING } from '../../constants/sharedConstants';
import { useLocation, useNavigate } from 'react-router-dom';
import useDebounce from '../CustomHooks/useDebounce.jsx';

const PrijemceComponent06 = ({ urlPath }) => {
    const {intention, stepValue, validationRef, setStagesArr,
        saveFormRef, id} = useContext(FormContext);
    const [request, setRequest] = useState({
        'applicationId': id,
        buildIntention: {
            recipientAuthority: AUTHORITY_MODEL
        }
    });
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    
    // const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const [prijemceList, setPrijemceList] = useState([]);
    const [searchRequest, setSearchRequest] = useState(
        {
            title: '',
            typeTitle: null
        }
    );
    const { token } = useContext(AuthContext);
    const prevRequest = usePrevious(request);
    const firstRender = useFirstRender();

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const debouncedSearchTitle = useDebounce(searchRequest.title);

    useEffect(() => {
        if(!id) {
            return;
        }
        getBuildApplicationFormForPrijemce(
            id,
            token,
            intention,
            setRequest,
            setSearchRequest,
            null,
            'recipientAuthority',
            pathname,
            navigate
        );
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (!canFulFillPrijemceList(request, firstRender, searchRequest, setPrijemceList, 'recipientAuthority')) {
            return;
        }

        setIsSearchLoading(true);
        getPrijemceList(searchRequest, token, setPrijemceList, setIsSearchLoading, signal);

        return () => {
            controller.abort();
        };
    }, [debouncedSearchTitle]);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        if(!canInitiateSaveForPrijemce(firstRender, request, prevRequest, 'recipientAuthority')) {
            return;
        }

        saveChanges();
        
    }, [request.buildIntention]);

    React.useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;

            if (!request.buildIntention?.recipientAuthority || request.buildIntention?.recipientAuthority?.authorityName?.trim() === '')
            {
                err = true;
            }
            
            

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, };
                }
                return stg;
            }));

            return !err;
        }
    }));

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    return (
        <Container className='py-3' fluid>
            <Row className='pb-5'>
                <p className='p-0 text primary'>{`${stepValue}. Příjemce`}</p>
            </Row>
            <Row className='d-flex align-items-start'>
                <Col md={6} className='pe-md-4 mb-2 mb-md-0'>
                    <Container fluid>
                        <SearchWithDataList
                            request={request}
                            authorityType={'recipientAuthority'}
                            authorityObj={request.buildIntention.recipientAuthority}
                            setRequest={setRequest}
                            paramFirst={'title'}
                            paramSecond={'typeTitle'}
                            setSearchRequest={setSearchRequest}
                            searchRequest={searchRequest} // obj
                            listData={prijemceList}
                            setListData={setPrijemceList} // arr
                            dynamicVal={BUILDING} // str
                            stavebniUradText={true}
                            isSearchLoading={isSearchLoading}
                            setIsSearchLoading={setIsSearchLoading}
                        />
                    </Container>
                </Col>
                <Col md={6} className='mt-md-2' >
                    <p className='input-help-text-push-down' >Vyhledejte stavební úřad, pro který je žádost určena</p>
                </Col>
            </Row>
        </Container>
    );
};

PrijemceComponent06.propTypes = {
    urlPath: PropTypes.string.isRequired,
};


export default PrijemceComponent06;
