import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { alarm } from '../../assets';
import { NewestEventCard } from '../index';

const TMP_EVENT_LIST = [
    {
        date: '29.1.2024',
        text: 'Aktualizace "Žádosti o vydání povolení stavby" 45DV456DG4Q',
    },
    {
        date: '16.1.2024',
        text: 'Stal jste se účastníkem řízení 456521231',
    },
    {
        date: '16.1.2024',
        text: 'Aktualizace "Žádosti o vyjádření nebo závazné stanovisko" 1AF3D3S5VSC',
    },
    {
        date: '14.1.2024',
        text: 'Aktualizace řízení 786245486',
    },
    {
        date: '2.1.2024',
        text: 'Aktualizace "Žádost o povolení stavby" 7DSFV65SDF4F',
    },
];

const NewestEventsSection = () => {
    const [eventList, setEventList] = React.useState(null);

    React.useEffect(() => {
        if (!eventList) {
            const countOfEventCards = Number.parseInt((new URLSearchParams(window.location.search)).get('udalosti')) || 0;
            const timeoutId = setTimeout(() => {
                setEventList(TMP_EVENT_LIST.slice(0, Math.min(countOfEventCards, TMP_EVENT_LIST.length)));
            }, 2000 + Math.round(Math.random() * 5) * 1000);

            return () => {
                // Clear (or kill) the previous call. (E.g.: Cancel an Axios request, same way as setTimeout call was cancelled.)
                clearTimeout(timeoutId);
            };
        }
    }, [eventList]);

    return (
        // !eventList ? ( // If there is null, show a loading spinner.
        //     <Container fluid className='after-login-main-section-row d-flex justify-content-center flex-column'>
        //         <span>Načítám žádosti a řízení...</span>
        //     </Container>
        // ) : 
        eventList?.length === 0 || !eventList ? ( // If there is no data, show nothing.
            <></>
        ) : ( // If there is data, show the data.
            <Container fluid className='after-login-main-section-row mt-5 mb-5 d-flex justify-content-center flex-column'>
                <Row className='d-flex align-items-end mb-4'>
                    <Col className='d-flex align-items-end' sm={4} md={3} xl={3}>
                        <div className='d-flex align-items-end'>
                            <img className='logo' src={alarm} alt='zamery' />
                            <p className='fs-4 fw-medium mb-0 pt-2'>Nejnovější</p>
                        </div>
                    </Col>
                    <Col className='align-bottom' sm={4} md={4} xl={3}>
                        <p className='mb-0'>změny v mých žádostech a řízeních</p>
                    </Col>
                    <Col sm={4} md={5} xl={6}>
                        <div className='d-flex align-items-center justify-content-end'>
                            <p className='mb-0 me-2 pt-2'>Zobrazit</p>
                            <div className='d-flex justify-content-center border rounded'>
                                <button type='button' className='event-filter-button active'>Vše</button>
                                <button type='button' className='event-filter-button'>Žádosti</button>
                                <button type='button' className='event-filter-button'>Žádosti</button>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {eventList.map((item, index) => {
                        return (
                            <NewestEventCard item={item} key={index}></NewestEventCard>
                        );
                    })}
                </Row>
                <Row className='mt-5'>
                    <p className='d-flex justify-content-center fs-4'>Více najdete na stránkách</p>
                </Row>
                <Row >
                    <Col className='d-flex justify-content-center'>
                        <button type='button' className='event-section-button me-3'>Moje žádosti</button>
                        <button type='button' className='event-section-button'>Moje řízení</button>
                    </Col>
                </Row>
            </Container>
        )
    );
};

export default NewestEventsSection;