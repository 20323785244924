import React from 'react';
import '../css/homePage.css';
import '../css/reusable.css';
import { Container } from 'react-bootstrap';
import { NewsOnPortal } from '../components';
// import NewsOnPortal from "../components/Documentation/NewsOnPortal.jsx";

const PortalNewsStatement = () => {

    return (
        <Container className='d-flex flex-column align-items-center' fluid>
            <NewsOnPortal/>
        </Container>
    );
};

export default PortalNewsStatement;
