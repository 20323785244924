import { signal, effect } from '@preact/signals-react';

export const saved = signal(true);
export const counter = signal(0);

effect(() => {
    if (saved.value)
    {
        const intervalId = setInterval(() => {
            counter.value++;
            if (counter.value === 30)
            {
                saved.value = false;
            }
        }, 1000);
        return () => clearInterval(intervalId);
    }

    if (!saved.value)
    {
        const timeOutId = setTimeout(() => {
            saved.value = true;
            counter.value = 1;
        }, 2000);
        return () => clearTimeout(timeOutId);
    }
});





