import React from 'react';
import './App.css';
import { AuthProvider } from 'react-oauth2-code-pkce';
import authConfig from './config/authorization-config.js';
import { Router } from './components/index.js';

const App = () => {

    return (
        <div className='App'>
            <AuthProvider authConfig={authConfig}>
                <Router />
            </AuthProvider>
        </div>
    );
};

export default App;