import React, { useState, useContext, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
// import apiConfig from '../../config/api-config';
// import urlJoin from 'url-join';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { GovButton } from '@gov-design-system-ce/react';
import { Container, Row, Col } from 'react-bootstrap';
import { StavbyBlockDisplay, StavbyBlockDisplayDeveloped, StavbyTableDisplay, Filter, RecordNotFound } from '../index.js';
import { AuthContext } from 'react-oauth2-code-pkce';
import { iconExport } from '../../assets';
import { fetchStavby } from '../../apiCalls/componentsApiCalls.js';
import { getFilterBodyBySections } from '../../helperFunctions/helpers.js';
import { STAVBY } from '../../constants/sharedConstants.js';

const STAVBY_HEADERS = ['IČS', 'Katastrální území', 'Parcelní číslo', 'Č.p.', 'Č. bytové jednotky', 'registrationNumber', 'constructionPurpose', 'Číslo záměru', 'Záměr'];

const StavbyMainSection = ({ isActive, setIsActive }) => {
    const [list, setList] = useState([]);
    const { token } = useContext(AuthContext);
    const [ fetching, setFetching ] = useState(true);
    const [postBody, setPostBody] = useState(getFilterBodyBySections(STAVBY));

    const getData = useCallback(async () => {
        const source = axios.CancelToken.source();

        try {
            const response = await fetchStavby(postBody, token, source);
            if ((200 <= response?.status) && (response?.status < 300)) {
                setList([...response.data.data]);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setFetching(false);
        }
            
        return () => {
            source.cancel('Operation canceled by the user.');
        };
        
    }, [postBody]);
    
    useEffect(() => {
        getData();
    }, [getData]);

    const exportToCSV = (headers, csvData) => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.csv';
        const ws = XLSX.utils.json_to_sheet(csvData);

        /* HEADERS */
        XLSX.utils.sheet_add_aoa(ws, [headers], { origin: 'A1' });
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, STAVBY + fileExtension);
    };

    return (
        <Container className='px-2 py-4 px-md-5 px-xl-0 stavby-main-container' fluid>
            <Row>
                <Filter
                    isWithFolding={true}
                    setPostBody={setPostBody}
                    postBody={postBody}
                    isActive={isActive}
                    setIsActive={setIsActive}
                    page={STAVBY}
                />
            </Row>
            {fetching ? (
                <Container fluid className='px-2 py-4 px-md-5 px-xl-0 rizeni-main-container'>
                    <span>Načítám stavby...</span>
                </Container>
            ) : !fetching && (list.length === 0 ? (
                <RecordNotFound />
            ) :
                isActive === 'block' ?
                    <>
                        <Row className='d-flex gx-5'>
                            {list.map((stavba, idx) => {
                                return (
                                    <Col key={stavba.id || idx} xs={12} md={6} xl={4} className='d-flex justify-content-center justify-content-md-start py-2 px-md-2 px-lg-0'>
                                        {stavba.dirty === true ? <StavbyBlockDisplayDeveloped item={stavba} key={stavba.id} /> : <StavbyBlockDisplay item={stavba} key={stavba.id} />}
                                    </Col>
                                );
                            })}
                        </Row>
                        <Row className='pt-3'>
                            <GovButton className='p-0' type='outlined' variant={'primary'} size='s' onGov-click={() => exportToCSV(STAVBY_HEADERS, list)}>
                                <Container className='d-flex justify-content-center align-items-center' style={{ width: '160px', fontWeight: '500', fontSize: '.909rem', lineHeight: '1.464rem', letterSpacing: '0.018rem' }}>
                                    <img src={iconExport} alt='export icon' style={{ marginRight: '.75rem' }} />
                                    Exportovat .csv
                                </Container>
                            </GovButton>
                        </Row>
                    </>
                    :
                    <>
                        <Row className='overflow-x-scroll overflow-x-md-hidden' style={{ border: 'solid #DDDDDD .5px' }}>
                            <StavbyTableDisplay setPostBody={setPostBody} postBody={postBody} items={list} />
                        </Row>
                        <Row className='pt-3'>
                            <GovButton className='my-2 p-0' type='outlined' variant={'primary'} size='s' onGov-click={() => exportToCSV(STAVBY_HEADERS, list)}>
                                <Container className='d-flex justify-content-center align-items-center' style={{ width: '160px', fontWeight: '500', fontSize: '.909rem', lineHeight: '1.464rem', letterSpacing: '0.018rem' }}>
                                    <img src={iconExport} alt='export icon' style={{ marginRight: '.75rem' }} />
                                    Exportovat .csv
                                </Container>
                            </GovButton>
                        </Row>
                    </>)
            }
        </Container>
    );
};
StavbyMainSection.propTypes = {
    isActive: PropTypes.string.isRequired,
    setIsActive: PropTypes.func.isRequired,
};

export default StavbyMainSection;