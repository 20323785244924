import React, { useCallback, useContext, useEffect, useState } from 'react';
import '../../css/homePage.css';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { RizeniTableDisplay, RizeniBlockDisplay, Filter, RecordNotFound } from '../index.js';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce';
import { fetchRizeni } from '../../apiCalls/componentsApiCalls.js';
import { getFilterBodyBySections } from '../../helperFunctions/helpers.js';
import { RIZENI } from '../../constants/sharedConstants.js';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import useFirstRender from '../CustomHooks/useFirstRender.jsx';

const RizeniMainSection = ({ isActive, setIsActive }) => {
    const [list, setList] = useState([]);
    const [fetching, setFetching] = useState(false);
    const { token } = useContext(AuthContext);
    const [postBody, setPostBody] = useState(getFilterBodyBySections(RIZENI));
    const prevPostBody = usePrevious(postBody);
    const firstRender = useFirstRender();

    const getData = useCallback(async () => {
        if (!firstRender && (JSON.stringify(prevPostBody) === JSON.stringify(postBody))) {
            return;
        }
        
        const source = axios.CancelToken.source();

        try {
            const response = await fetchRizeni(postBody, token, source);
            if ((200 <= response?.status) && (response?.status < 300)) {
                setList([...response.data.data]);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setFetching(false);
        }
            
        return () => {
            source.cancel('Operation canceled by the user.');
        };
        
    }, [postBody]);

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <Container className='base-width px-2 py-4 px-md-5 px-xl-0 rizeni-main-container' fluid>
            <Filter
                isWithFolding={true}
                setPostBody={setPostBody}
                postBody={postBody} 
                isActive={isActive}
                setIsActive={setIsActive}  
                page={RIZENI}
            />
            {fetching ? ( // If there is null, show a loading spinner.
                <Container fluid className='px-2 py-4 px-md-5 px-xl-0 rizeni-main-container'>
                    <span>Načítám řízení...</span>
                </Container>
            ) : !fetching && (list.length === 0 ? (
                <RecordNotFound/>
            ) :
                isActive === 'block' ?
                    <Row className='d-flex'>
                        {list.map((rizeni, idx) => {
                            return (
                                <Col key={rizeni.id ?? idx} xs={12} md={6} xl={4} className='d-flex justify-content-center justify-content-md-start py-2 px-md-2 px-lg-0'>
                                    {<RizeniBlockDisplay item={rizeni} key={rizeni.id} />}
                                </Col>
                            );
                        })}
                    </Row>
                    :
                    <Row className='overflow-x-scroll overflow-x-md-hidden' style={{ border: 'solid #DDDDDD .5px' }}>
                        <RizeniTableDisplay items={list} setPostBody={setPostBody} postBody={postBody} />
                    </Row>)}
        </Container>
    );
};

RizeniMainSection.propTypes = {
    isActive: PropTypes.string.isRequired,
    setIsActive: PropTypes.func.isRequired,
};

export default RizeniMainSection;