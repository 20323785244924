import React, { useContext, useEffect, useState } from 'react';
import { GovButton } from '@gov-design-system-ce/react';
import { Row, Container } from 'react-bootstrap';
import { iconImport, iconPridat } from '../../../assets';
import PropTypes from 'prop-types';
import { ImportModal, PaginateResults, AddPozemekModal, AddStavbaModal, AddProjektantModal  } from '../../.';
import { AFFECTED_POZEMKY_TABLE, AFFECTED_STAVBY_TABLE, APPROVED_STAVBY_TABLE, FORM_10_INTENTION, FORM_11_INTENTION, FORM_12_INTENTION, FORM_13_INTENTION, FORM_14_INTENTION, POZEMKY_TABLE, PROJEKTANT_DOCUMENTATION_TABLE, STAVBY_TABLE } from '../../../constants/sharedConstants';
import FormContext from '../../../formContexts/FormContext';

const ButtonsAndPaginate = ({ 
    showStavbaModal,
    setShowStavbaModal,
    itemToUpdate,
    setItemToUpdate,
    showPozemekModal,
    setShowPozemekModal,
    currentlyShowing, 
    resultPerPage, 
    setTableRequest, 
    setProjektantDocumentationRequest,
    category, 
    listData, 
    setListData, 
    startIndex, 
    setStartIndex, 
    progressElements, 
    setProgressElements, 
    handleCsv,
    isAffected,
    handleAddItem,
    handleUpdateItem,
}) => {
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 872 || (window.innerWidth >= 992 && window.innerWidth <= 1276));
    const [showImportModal, setShowImportModal] = useState(false);
    const [ showProjektantModal, setShowProjektantModal ] = useState(false);
    const isForPozemky = Boolean(category === POZEMKY_TABLE || category === AFFECTED_POZEMKY_TABLE);
    const isForStavby = Boolean(category === STAVBY_TABLE || category === AFFECTED_STAVBY_TABLE || category === APPROVED_STAVBY_TABLE);
    const isForProjektant = category === PROJEKTANT_DOCUMENTATION_TABLE;
    const {intention} = useContext(FormContext);
    const isForm10 = intention === FORM_10_INTENTION.title;
    const isForm14 = intention === FORM_14_INTENTION.title;
    const isForm11 = intention === FORM_11_INTENTION.title;
    const isForm12 = intention === FORM_12_INTENTION.title;
    const isForm13 = intention === FORM_13_INTENTION.title;
    const isDisabled = (isForStavby && ((isForm10 || isForm14) && listData.length >= 7) || ((isForm11 || isForm12 || isForm13) && listData.length >= 5));
    // exception for 11,12,13
    const isException = isForm13 || isForm12 || isForm11;

    useEffect(() => {
        function handleResize() {
            if(window.innerWidth <= 872 || (window.innerWidth >= 992 && window.innerWidth <= 1276)) {
                setIsMobileView(true);
                return;
            } 

            setIsMobileView(false); 
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    return (
        <Container className='mt-1 d-flex flex-column' fluid>
            {listData.length > 0 && <Row>
                <span className='text-left m-0 p-0 pb-3 text-info-amount'>{`Zobrazuji ${currentlyShowing} z celkem ${listData.length}`}</span>  
            </Row>}
 
            {listData.length > resultPerPage && isMobileView &&
            <Row sm={12} className='px-2'>
                <PaginateResults
                    resultPerPage={resultPerPage}
                    listData={listData}
                    startIndex={startIndex}
                    setStartIndex={setStartIndex}
                    isMobileView={isMobileView}
                />
            </Row>}
            
            <Container className='d-flex justify-content-between  align-items-center btn-container w-100' fluid>
                <Row lg={listData.length > resultPerPage ? 4 : 6} sm={6} className={`${listData.length === 0 || isMobileView ? 'full' : ''} d-flex me-2`}>
                    <GovButton
                        disabled={isDisabled}
                        className='add-remove-btn'
                        type='outlined'
                        variant={'primary'}
                        onGov-click={() => {
                            ((isForPozemky || isException) && setShowPozemekModal) ? setShowPozemekModal(true) 
                                : (isForStavby && setShowStavbaModal) ? setShowStavbaModal(true)
                                    : (isForProjektant && setShowProjektantModal) ? setShowProjektantModal(true)
                                        : null;
                        }}
                    >
                        <Container
                            className='d-flex justify-content-center align-items-center button-create-req fluid'>
                            <img
                                src={iconPridat}
                                alt={'přidat další'}
                            />
                            <p className='button-create-req-text'>{`Přidat ${isForPozemky ? 'pozemek' : 
                                isForStavby ? 'stavbu' : 
                                    isForProjektant ? 'projektanta' : ''}`}</p>
                        </Container>
                    </GovButton>
                </Row>

                {listData.length > resultPerPage && !isMobileView && 
                <Row md={4}>
                    <PaginateResults
                        resultPerPage={resultPerPage}
                        listData={listData}
                        startIndex={startIndex}
                        setStartIndex={setStartIndex}
                        isMobileView={isMobileView}
                    />
                </Row>}
                
                <Row lg={listData.length > resultPerPage ? 4 : 6} sm={6} className={`${listData.length === 0 || isMobileView ? 'full' : ''} d-flex ms-2`}>
                    <GovButton   
                        className='add-remove-btn'
                        type='outlined'
                        variant={'primary'}
                        onGov-click={() => setShowImportModal(true)}
                    >
                        <Container className='d-flex justify-content-center align-items-center button-create-req' fluid>
                            <img
                                src={iconImport}
                                alt={'import'}
                            />
                            <p className='button-create-req-text'>Import</p>
                        </Container>
                    </GovButton>
                </Row>
            </Container>
            {showPozemekModal && <AddPozemekModal
                itemToUpdate={itemToUpdate}
                setItemToUpdate={setItemToUpdate}
                listData={listData}
                setTableRequest={setTableRequest}
                showPozemekModal={showPozemekModal}
                setShowPozemekModal={setShowPozemekModal}
                setListData={setListData}
                isAffected={isAffected}
                isException={isException}
                handleAddItem={handleAddItem}
                handleUpdateItem={handleUpdateItem}
            />}
            
            {showStavbaModal && <AddStavbaModal
                itemToUpdate={itemToUpdate}
                setItemToUpdate={setItemToUpdate}
                category={category}
                listData={listData}
                setTableRequest={setTableRequest}
                showStavbaModal={showStavbaModal}
                setShowStavbaModal={setShowStavbaModal}
                setListData={setListData}
                isAffected={isAffected}
            />}
            {showProjektantModal && <AddProjektantModal
                listData={listData}
                setProjektantDocumentationRequest={setProjektantDocumentationRequest}
                showProjektantModal={showProjektantModal}
                setShowProjektantModal={setShowProjektantModal}
                setListData={setListData}
            />}
            <ImportModal
                category={category} 
                showImportModal={showImportModal} 
                setShowImportModal={setShowImportModal}
                progressElements={progressElements} 
                setProgressElements={setProgressElements} 
                handleCsv={handleCsv}
                isForPozemky={isForPozemky}
                isForStavby={isForStavby}
                isForProjektant={isForProjektant}
            />
        </Container>
    );
};

ButtonsAndPaginate.propTypes = {
    showStavbaModal: PropTypes.bool,
    setShowStavbaModal: PropTypes.func,
    itemToUpdate: PropTypes.object,
    setItemToUpdate: PropTypes.func,
    showPozemekModal: PropTypes.bool,
    setShowPozemekModal: PropTypes.func,
    category: PropTypes.string,
    listData: PropTypes.arrayOf(Object),
    setListData: PropTypes.func,
    startIndex: PropTypes.number,
    setStartIndex: PropTypes.func,
    progressElements: PropTypes.arrayOf(Object),
    setProgressElements: PropTypes.func,
    handleCsv: PropTypes.func,
    setTableRequest: PropTypes.func,
    setProjektantDocumentationRequest: PropTypes.func,
    projektantDocumentationRequest: PropTypes.objectOf(String),
    resultPerPage: PropTypes.number,
    currentlyShowing: PropTypes.number,
    isAffected: PropTypes.bool,
    handleAddItem: PropTypes.func,
    handleUpdateItem: PropTypes.func,
};

export default ButtonsAndPaginate;