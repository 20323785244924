/* eslint-disable no-undef */
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import '../../css/rizeni.css';
// import { Link,/ useLocation } from 'react-router-dom';
import moment from 'moment';
import cs from 'moment/locale/cs';
import {Link, useLocation} from 'react-router-dom';

moment.locale('cs', cs);

const RizeniBlockDisplay = ({ item }) => {
    const location = useLocation();
    // const handleDate = (date) => {
    //     return  moment(date).format('LL');
    // };
    return (
        <Container className='d-flex flex-column justify-content-between border rounded p-3 rizeni-container' fluid>
            <Row className='d-flex align-items-center pt-1 pb-1'>
                <p className='p-0 rizeni-number'>{item.procedureNumber ?? ''}</p>
            </Row>
            <Row className='d-flex align-items-center pt-1 pb-1 border-bottom'>
                <Col sm={4}>
                    <p className='rizeni-row-name'>Číslo záměru</p>
                </Col>
                <Col sm={8}>
                    <p className='rizeni-row-info'>{item.projectNumber ?? ''}</p>
                </Col>
            </Row>
            <Row className='d-flex align-items-center pt-1 pb-1 border-bottom'>
                <Col sm={4}>
                    <p className='rizeni-row-name'>Záměr</p>
                </Col>
                <Col sm={8}>
                    <p className='rizeni-row-info'>{item.projectName ?? ''}</p>
                </Col>
            </Row>
            <Row className='d-flex align-items-center pt-1 pb-1 border-bottom'>
                <Col sm={4}>
                    <p className='rizeni-row-name'>Stav</p>
                </Col>
                <Col sm={8}>
                    <p className='rizeni-row-info'>
                        {item.status === 'ACTIVE' ? 'Běžící' : 
                            item.status === 'COMPLETED' ? 'Dokončené' : 
                                item.status === 'PAUSED' ? 'Přerušené' :  
                                    ''}
                    </p>
                </Col>
            </Row>
            {/* <Row className='d-flex align-items-center pt-1 pb-1 border-bottom'>
                <Col sm={4}>
                    <p className='rizeni-row-name'>Moje role</p>
                </Col>
                <Col sm={8}>
                    <p className='rizeni-row-info'>
                        {item.role === 'BUILDER_OR_REPRESENTATIVE' ? 'Žadatel nebo zástupce' :
                            item.role === 'LAND_OR_LAW_OWNER' ? 'Vlastník pozemku' : 
                                item.role === 'NEIGHBOUR' ? 'Soused' : 
                                    item.role === 'OTHER' ? 'Ostatní' : 
                                        ''}
                    </p>
                </Col>
            </Row> */}
            {/* <Row className='d-flex align-items-center pt-1 pb-1 border-bottom'>
                <Col sm={4}>
                    <p className='rizeni-row-name'>Dotčený orgán</p>
                </Col>
                <Col sm={8}>
                    <p className='rizeni-row-info'>{''}</p>
                </Col>
            </Row> */}
            {/* <Row className='d-flex align-items-center pt-1 pb-1'>
                <Col sm={4}>
                    <p className='rizeni-row-name'>Upraveno</p>
                </Col>
                <Col sm={8}>
                    <p className='rizeni-row-info'>{handleDate(item.date) ?? ''}</p>
                </Col>
            </Row> */}
            {<Row className='d-flex justify-content-center pt-1 pb-1'>
                <Link
                    to={`/rizeni/${encodeURIComponent(item.procedureId)}`}
                    state={{from: location}}
                    className='p-0'>
                    <button type='button' className='rizeni-button'>
                        Zobrazit detail
                    </button>
                </Link>
            </Row>}
        </Container>
    );
};

RizeniBlockDisplay.propTypes = {
    item: PropTypes.shape({
        procedureId: PropTypes.string,
        procedureNumber: PropTypes.string,
        procedurePID: PropTypes.string,
        projectId: PropTypes.string,
        projectNumber: PropTypes.string,
        projectPID: PropTypes.string,
        projectName: PropTypes.string,
        department: PropTypes.string,
        status: PropTypes.string,
        date: PropTypes.string,
        role: PropTypes.string,
    })
};

export default RizeniBlockDisplay;
