import React, { useEffect, useState, useContext, useImperativeHandle } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/rozsah.css';
import {  useOnlyRequiredKeys, shouldNotSave, spreadObject, checkLimitAndInsertText } from '../../helperFunctions/helpers';
import PropTypes from 'prop-types';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import FormContext from '../../formContexts/FormContext';
import { errorIcon } from '../../assets';
import usePrevious from '../CustomHooks/usePrevious';
import useFirstRender from '../CustomHooks/useFirstRender';
import { CharCount } from '../../components/index.js';
import { dateValidation } from '../../helperFunctions/validationHelpers.js';
import { useLocation, useNavigate } from 'react-router-dom';

const RozsahComponent14 = ({urlPath}) => {
    const {intention, stepValue, validationRef, setStagesArr, stagesArr, saveFormRef, id} = useContext(FormContext); 
    const { token } = useContext(AuthContext);
    
    
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const [isDateError, setIsDateError] = useState({
        tmpBuildingDate: false,
    });
    const [ focused, setFocused ] = React.useState({
        date: false,
        currentBuildingUse: false,
        changeBuildingUseDescription: false,
        intendedChangeJustification: false,
        newPublicTransportTechnicalInfrastructure: false,
    });
    const [count, setCount] = useState({
        [0]: {count: 0, limit:10000},
        [1]: {count: 0, limit:10000},
        [2]: {count: 0, limit:10000},
        [3]: {count: 0, limit:10000},
    });
    const firstRender = useFirstRender();
    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            structureUsageMethod: false,
            changeOperationFacility: false,
            changeProductionMethod: false,
            requestChangeActivity: false,
            temporaryBuildingDuration: false,
            currentBuildingUse: '',
            changeBuildingUseDescription: '',
            newPublicTransportTechnicalInfrastructure: '',
            intendedChangeJustification: '',
            changeNotRequired: true,
            changeRequired: false,
            numberOfNewFlats: '',
            numberOfDeletedFlats: '',
            totalAreaFlats: '',
            requestChangeTemporaryBuilding: false,
        },
        buildIntention: {
            buildingTemporaryDuration: '',
        }
    });
    const prevRequest= usePrevious(request);
    
    const form = request?.form;
    const buildIntention = request?.buildIntention;
    const [moreInfoState, setMoreInfoState] = useState(false);

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!id) {
            return;
        }
        const source = axios.CancelToken.source();
        (async () => {
            const response = await getFormById(id, token, source, intention, null, pathname, navigate);

            if ((200 <= response?.status) && (response?.status < 300)) {
                const loadedData = response?.data?.buildApplicationForms[0];

                if (loadedData) {
                    const formStructure = request.form;
                    const filteredDataToSave = useOnlyRequiredKeys(formStructure, loadedData);
                    setRequest(state => ({ ...state, form: {...state.form, ...filteredDataToSave, changeNotRequired: !loadedData.changeRequired } }));
                }

                const intention = response.data?.buildIntention;
                if (intention) {
                    setRequest(state => ({ ...state,
                        buildIntention: {...state.buildIntention, ...spreadObject(intention), buildingTemporaryDuration: intention.buildingTemporaryDuration } }));
                }
            } else {
                console.log('not yet in DB');
            }
        })();

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }

        saveChanges();
    }, [request.form, request.buildIntention.buildingTemporaryDuration]);

    const handleFocused = (elementToFocus) => {
        setFocused(prev => ({...prev, [elementToFocus]: true}));
    };

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    useImperativeHandle(validationRef, () => ({
        validate() {
            console.log(request);
            let err = false;
            
            if (form?.temporaryBuildingDuration && !buildIntention?.buildingTemporaryDuration?.trim())
            {
                err = true;
            }else if (isDateError.tmpBuildingDate) {
                err = true;
            }
            else if (!form?.currentBuildingUse?.trim())
            {
                err = true;
            }
            else if (!form?.changeBuildingUseDescription?.trim())
            {
                err = true;
            }
            else if (!form?.intendedChangeJustification?.trim())
            {
                err = true;
            }
            else if (form.changeRequired && !form?.newPublicTransportTechnicalInfrastructure?.trim())
            {
                err = true;
            }
    
            

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, };
                }
                return stg;
            }));
    
            return !err;
        }
    }));

    return (
        <Container className='py-0 d-flex row align-items-center rozsahContainer' fluid>
            <Row className='row-wrapper'>
                <h2 className='p-0 m-0 main-title'>{`${stepValue}. Rozsah`}</h2>
            </Row>
            <Row className='row-wrapper'>
                <Row className='py-2 pb-4'>
                    <p className='p-0 title-rozsah-bigger-secondary'>Žádám o změnu v užívání stavby spočívající ve změně</p>
                </Row>
                <Row className='row-wrapper'>
                    <Row className='d-flex row'>                    
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={form?.structureUsageMethod}
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, structureUsageMethod: e.target.checked }}));
                                }}
                            />
                        způsobu užívání stavby
                        </label>         

                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={form?.changeOperationFacility}
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, changeOperationFacility: e.target.checked }}));
                                }}
                            />
                        provozního zařízení stavby
                        </label>                  
                        
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={form?.changeProductionMethod}
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, changeProductionMethod: e.target.checked }}));
                                }}
                            />
                        způsobu výroby nebo podstatném rozšíření výroby
                        </label>                  
                        
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={form?.requestChangeActivity}
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, requestChangeActivity: e.target.checked }}));
                                }}
                            />
                        činnosti, jejíž účinky by mohly ohrozit život nebo veřejné zdraví, život a zdraví zvířat, bezpečnost nebo životní prostředí
                        </label>                  
                        
                        <label className='ls-1p25 p-0 pb-2 d-flex align-items-start'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={form?.temporaryBuildingDuration}
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, temporaryBuildingDuration: e.target.checked }}));
                                }}
                            />
                        doby trvání dočasné stavby
                        </label>                  
                        
                        {form?.temporaryBuildingDuration && <Row className='row-wrapper mt-2'>
                            <Col md={6}>
                                <label className='p-0 povoleni-label' style={{color: ((!buildIntention?.buildingTemporaryDuration?.trim() && (error || focused.date)) || isDateError.tmpBuildingDate) ? '#C52A3A' : ''}}>
                                Požadovaná doba trvání dočasné stavby do
                                    <input 
                                        type='date'
                                        required
                                        {...(focused.date && { focused: 'true' })}
                                        value={buildIntention?.buildingTemporaryDuration ?? ''}
                                        style={{border: ((!buildIntention?.buildingTemporaryDuration?.trim() && (error || focused.date)) || isDateError.tmpBuildingDate) ? '#C52A3A solid 1px' : ''}}
                                        onChange={(e) => {
                                            setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, buildingTemporaryDuration: e.target.value }})); 
                                        }}
                                        onBlur={(e) => {
                                            const validatedDate = dateValidation(e.target.value); 
                                            validatedDate && setRequest(state => ({ ...state, buildIntention: { ...state?.buildIntention, buildingTemporaryDuration: '' } }));
                                            setIsDateError(state => ({...state, tmpBuildingDate: validatedDate}));
                                            handleFocused('date');
                                        }}
                                        className='id-input2 bg-white p-2 mt-1'
                                    />
                                    {isDateError.tmpBuildingDate && <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                        <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                            Nesprávný formát vyplnění, zkuste to znovu.
                                    </Container>}
                                    {(!buildIntention?.buildingTemporaryDuration?.trim() && (error || focused.date)) && !isDateError.tmpBuildingDate &&
                                        <Container style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                            Bez vyplnění pole &quot;Požadovaná doba trvání dočasné stavby do&quot; není možné pokračovat.
                                        </Container>}
                                </label>
                            </Col>
                        </Row>}

                        <label className='ls-1p25 p-0 d-flex align-items-start row-wrapper'>
                            <input
                                className='me-3'
                                type="checkbox"
                                checked={form?.requestChangeTemporaryBuilding}
                                onChange={(e) => {
                                    setRequest(prev => ({...prev, form: {...prev.form, requestChangeTemporaryBuilding: e.target.checked }}));
                                }}
                            />
                        změna dočasné stavby na stavbu trvalou
                        </label> 

                        <Row className='row-wrapper'>
                            <Row className='py-2 pb-4'>
                                <p className='p-0 title-rozsah-bigger-tertiaty'>Charakteristika změny v užívání stavby </p>
                            </Row>
                            <Container className='py-2'>
                                <Row className='pt-2 pb-0 justification-container'>
                                    <Col>
                                        <span className='px-0 m-0 justification-text' style={{color: (!form?.currentBuildingUse?.trim() && (error || focused.currentBuildingUse)) ? '#C52A3A' : ''}}>Dosavadní užívání stavby</span>
                                    </Col>
                                </Row>
                                <Row className='py-2'>
                                    <Row className='position-relative textarea-with-count-container'>
                                        <textarea
                                            className='textarea-with-count p-2 text-area--validation'
                                            onChange={(e) => { checkLimitAndInsertText(e.target, 0, count, setCount); }}                
                                            required
                                            defaultValue={form?.currentBuildingUse}
                                            {...(focused.currentBuildingUse && { focused: 'true' })}
                                            style={{border: error && !form?.currentBuildingUse?.trim() ? '#C52A3A solid 1px' : ''}}
                                            onBlur={(e) => {
                                                setRequest(prev => ({ ...prev, form: {...prev.form, currentBuildingUse: e.target.value}}));
                                                handleFocused('currentBuildingUse');}}
                                        />
                                        <CharCount 
                                            value={form?.currentBuildingUse} setCount={setCount} propToUpdate={0} count={count[0].count} limit={count[0].limit}
                                        />
                                    </Row>
                                </Row>
                            </Container>
                            <Container className='py-2'>
                                <Row className='pt-2 pb-0 justification-container'>
                                    <Col>
                                        <span className='px-0 m-0 justification-text' style={{color: (!form?.changeBuildingUseDescription?.trim() && (error || focused.changeBuildingUseDescription)) ? '#C52A3A' : ''}}>Popis požadované změny v užívání stavby</span>
                                    </Col>
                                    <Col className='d-flex align-items-center justify-content-end'>
                                        <p className='p-0 pb-2 justification-link' onClick={() => setMoreInfoState(prev => !prev)}>{moreInfoState ? 'zabalit' : 'více informací'}</p>
                                    </Col>
                                </Row>
                                {moreInfoState && <Row className='py-2 justification-detail-text'>
                                    <span className='px-0'>
                                    tj. změny ve způsobu užívání stavby, změny provozního zařízení stavby, změny ve způsobu výroby nebo podstatné rozšíření výroby, změny v činnosti, jejíž účinky by mohly ohrozit život a veřejné zdraví, život a zdraví zvířat, bezpečnost nebo životní prostředí, prodloužení doby trvání stavby nebo změna stavby dočasné na stavbu trvalou</span>
                                </Row>}
                                <Row className='py-2'>
                                    <Row className='position-relative textarea-with-count-container'>
                                        <textarea
                                            className='textarea-with-count p-2 text-area--validation'
                                            onChange={(e) => { checkLimitAndInsertText(e.target, 1, count, setCount); }}                
                                            required
                                            defaultValue={form?.changeBuildingUseDescription}
                                            style={{border: error && !form?.changeBuildingUseDescription?.trim() ? '#C52A3A solid 1px' : ''}}
                                            {...(focused.changeBuildingUseDescription && { focused: 'true' })}
                                            onBlur={(e) =>{
                                                setRequest(prev => ({ ...prev, form: {...prev.form, changeBuildingUseDescription: e.target.value}})); 
                                                handleFocused('changeBuildingUseDescription');}}
                                        />
                                        <CharCount 
                                            value={form?.changeBuildingUseDescription} setCount={setCount} propToUpdate={1} count={count[1].count} limit={count[1].limit}
                                        />
                                    </Row>
                                </Row>
                            </Container>
                            <Container className='py-2'>
                                <Row className='pt-2 pb-0 justification-container'>
                                    <Col>
                                        <span className='px-0 m-0 justification-text' style={{color: (!form?.intendedChangeJustification?.trim() && (error || focused.intendedChangeJustification)) ? '#C52A3A' : ''}}>Odůvodnění zamýšlené změny</span>
                                    </Col>
                                </Row>
                                <Row className='py-2'>
                                    <Row className='position-relative textarea-with-count-container'>
                                        <textarea
                                            className='textarea-with-count p-2 text-area--validation'
                                            onChange={(e) => { checkLimitAndInsertText(e.target, 2, count, setCount); }}                
                                            defaultValue={form?.intendedChangeJustification}
                                            required
                                            style={{border: error && !form?.intendedChangeJustification?.trim() ? '#C52A3A solid 1px' : ''}}
                                            {...(focused.intendedChangeJustification && { focused: 'true' })}
                                            onBlur={(e) => {
                                                setRequest(prev => ({ ...prev, form: {...prev.form, intendedChangeJustification: e.target.value}}));
                                                handleFocused('intendedChangeJustification');}}
                                        />
                                        <CharCount 
                                            value={form?.intendedChangeJustification} setCount={setCount} propToUpdate={2} count={count[2].count} limit={count[2].limit}
                                        />
                                    </Row>
                                </Row>
                            </Container>
                        </Row>

                        <Row className='row-wrapper'>
                            <Row className='py-2 pb-4'>
                                <p className='p-0 title-rozsah-bigger-tertiaty'>Nové nároky na veřejnou dopravní nebo technickou infrastrukturu</p>
                            </Row>                      
                            <Row className='d-flex pb-2'>
                                <Col md={6} sm={12}>
                                    <label className='d-flex mb-2 label'>
                                        <input
                                            type="radio"
                                            className='radio'
                                            checked={form?.changeNotRequired}
                                            onChange={() => { setRequest(prev => ({ ...prev, form: { ...prev.form, changeNotRequired: true, changeRequired: false }})); }}
                                        />
                                    změna nevyžaduje
                                    </label>
                                </Col>                     
                            </Row>
                            <Row className='d-flex pb-2'>
                                <Col md={6} sm={12}>
                                    <label className='d-flex mb-2 label'>
                                        <input
                                            type="radio"
                                            className='radio'
                                            checked={form?.changeRequired}
                                            onChange={() => { setRequest(prev => ({ ...prev, form: { ...prev.form,  changeNotRequired: false, changeRequired: true } })); }}
                                        />
                                    změna vyžaduje 
                                    </label>
                                </Col>                    
                            </Row>
                            <Container className='py-2'>
                                <Row className='pt-2 pb-0 justification-container'>
                                    <Col>
                                        <span className='px-0 m-0 justification-text' style={{color: (form.changeRequired && (!form?.newPublicTransportTechnicalInfrastructure?.trim() && (error || focused.newPublicTransportTechnicalInfrastructure))) ? '#C52A3A' : ''}}>Popis nových nároků na veřejnou dopravní a technickou infrastrukturu</span>
                                    </Col>
                                </Row>
                                <Row className='py-2'>
                                    <Row className='position-relative textarea-with-count-container'>
                                        <textarea
                                            className='textarea-with-count p-2 text-area--validation'
                                            onChange={(e) => { checkLimitAndInsertText(e.target, 3, count, setCount); }}
                                            //required
                                            style={{border: (form.changeRequired && error && !form?.newPublicTransportTechnicalInfrastructure?.trim() )? '#C52A3A solid 1px' : ''}}
                                            {...(focused.newPublicTransportTechnicalInfrastructure && { focused: 'true' })}
                                            onBlur={(e) => { setRequest(prev => ({ ...prev, form: {...prev.form, newPublicTransportTechnicalInfrastructure: e.target.value}}));
                                                handleFocused('newPublicTransportTechnicalInfrastructure');
                                            }}
                                            defaultValue={form?.newPublicTransportTechnicalInfrastructure}
                                        />
                                        <CharCount 
                                            value={form?.newPublicTransportTechnicalInfrastructure} setCount={setCount} propToUpdate={3} count={count[3].count} limit={count[3].limit}
                                        />
                                    </Row>
                                </Row>
                            </Container>

                            <Container fluid>
                                <Row className='pt-4 pb-2'>
                                    <p className='p-0 title-rozsah-bigger-tertiaty'>Statistické údaje</p>
                                </Row>  
                                <Row className='pb-4'>
                                    <p className='p-0 title-rozsah-bigger-tertiaty-helptext'>pokud navrhovanou změnou vznikají nebo se ruší byty</p>
                                </Row>  
                                <Row className='d-flex pb-4'>
                                    <Col md={6}>
                                        <label className='d-flex flex-column'>
                                        Počet nových bytů (nepovinné)
                                            <input 
                                                type='text' 
                                                defaultValue={form?.numberOfNewFlats}
                                                onBlur={(e) => setRequest(prev => ({...prev, form: {...prev.form, numberOfNewFlats: e.target.value}}))}
                                                className='mt-1 id-input2'
                                            />
                                        </label>
                                    </Col>
                                </Row>
                                <Row className='d-flex pb-4'>
                                    <Col md={6}>
                                        <label className='d-flex flex-column'>
                                        Počet zrušených bytů (nepovinné)
                                            <input 
                                                type='text' 
                                                defaultValue={form?.numberOfDeletedFlats}
                                                onBlur={(e) => setRequest(prev => ({...prev, form: {...prev.form, numberOfDeletedFlats: e.target.value}}))}
                                                className='mt-1 id-input2'
                                            />
                                        </label>
                                    </Col>
                                </Row>
                                <Row className='d-flex pb-4'>
                                    <Col md={6}>
                                        <label className='d-flex flex-column'>
                                        Užitková plocha všech bytů v m² (nepovinné)
                                            <input 
                                                type='text' 
                                                defaultValue={form?.totalAreaFlats}
                                                onBlur={(e) => setRequest(prev => ({...prev, form: {...prev.form, totalAreaFlats: e.target.value}}))}
                                                className='mt-1 id-input2'
                                            />
                                        </label>
                                    </Col>
                                    <Col md={6} className='d-flex'>
                                        <p className='building-change ps-md-3 mb-md-3 mt-2 align-content-end'>
                                        Bez plochy nebytových prostor
                                        </p>
                                    </Col>
                                </Row>
                            </Container>
                        </Row>

                    </Row>
                </Row>
            </Row>

        



        </Container>
    );
};

RozsahComponent14.propTypes = {
    
    urlPath: PropTypes.string,
};

export default RozsahComponent14;
