import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import '../../css/stavby.css';
// import { Link } from 'react-router-dom';

const StavbyBlockDisplay = ({ item }) => {
    return (
        <Container className='d-flex flex-column justify-content-between border rounded p-3 stavby-container' fluid>
            <Row className='d-flex align-items-center pt-1 pb-1 mb-2'>
                <p className='p-0 stavby-title'>{item.costructionId !== '' ? item.costructionId : 'Bez IČS'}</p>
            </Row>
            <Row className='d-flex align-items-center pt-1 pb-1 border-bottom'>
                <Col sm={3}>
                    <p className='stavby-row-name'>Katastrální území</p>
                </Col>
                <Col sm={9}>
                    <p className='ps-3 stavby-row-info'>{item.cadastralTerritory}</p>
                </Col>
            </Row>
            <Row className='d-flex align-items-center pt-1 pb-1 border-bottom'>
                <Col sm={3}>
                    <p className='stavby-row-name'>Parcelní číslo</p>
                </Col>
                <Col sm={9}>
                    <p className='ps-3 stavby-row-info'>{item.parcelNumber}</p>
                </Col>
            </Row>
            <Row className='d-flex align-items-center pt-1 pb-1 border-bottom'>
                <Col sm={3}>
                    <p className='stavby-row-name'>Č.p.</p>
                </Col>
                <Col sm={9}>
                    <p className='ps-3 stavby-row-info'>{item.constructionNumber}</p>
                </Col>
            </Row>
            <Row className='d-flex align-items-center pt-1 pb-1 mb-2'>
                <Col sm={3}>
                    <p className='stavby-row-name'>Č. bytové jednotky</p>
                </Col>
                <Col sm={9}>
                    <p className='ps-3 stavby-row-info'>{item.flatNumber}</p>
                </Col>
            </Row>
            <Row className='d-flex align-items-center pt-1 pb-1'>
                <p className='p-0 stavby-row-number'>Záměr: {item.projectId}</p>
            </Row>
            <Row className='d-flex align-items-center pt-1 pb-1'>
                <p className='p-0 stavby-name'>{item.projectName}</p>
            </Row>
            {/* <Row className='d-flex justify-content-center pt-1 pb-1'>
                <Link className='d-flex justify-content-center p-0' to={`/rizeni/${encodeURIComponent()}`}><button type='button' className='stavby-button'>Zobrazit detail</button></Link>
            </Row> */}
        </Container>
    );
};

StavbyBlockDisplay.propTypes = {
    item: PropTypes.shape({
        costructionId: PropTypes.string,
        cadastralTerritory: PropTypes.string,
        parcelNumber: PropTypes.string,
        constructionNumber: PropTypes.string,
        flatNumber: PropTypes.string,
        projectId: PropTypes.string,
        projectName: PropTypes.string,
    })
};

export default StavbyBlockDisplay;