import React, { useState, useContext, useEffect, useRef, useImperativeHandle } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import axios from 'axios';
import { Container, Row } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/zamerComponent.css';
import PropTypes from 'prop-types';
import { formSave, getFormById } from '../../apiCalls/formApiCalls.js';
import { PozemkyTable, FormInput, ZamerSelectionControls } from '../index.js';
import { BUILDING_ADDRESS } from '../../constants/form06.js';
import FormContext from '../../formContexts/FormContext.js';
import { shouldNotSave, spreadParcelsAndConstructions, spreadObject, hasZamerError } from '../../helperFunctions/helpers.js';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import { useLocation, useNavigate } from 'react-router-dom';

const buildIntentionModel = {
    title: { value: null, dirty: false },
    address: {
        city: '',
        cityPart: '',
        street: '',
        descNum: '',
        orientNum: '',
        zip: '',
    },
};

const ZamerComponent06 = ({
    progressElements,
    setProgressElements,
    urlPath,
    setCheckbox,
}) => {
    const {intention, stepValue, validationRef, setStagesArr, saveFormRef, id} = useContext(FormContext);
    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            id: null,
        },
        buildIntention: buildIntentionModel
    });
    const buildIntention = request?.buildIntention;

    const [isIntentionCheckboxDisabled, setIsIntentionCheckboxDisabled] = useState(false);
    const prevRequest = usePrevious(request);
    const { token } = useContext(AuthContext);
    const initialRender = useRef(true);
    const [state, setState] = useState({
        inputValue: '',
        zamerType: 'new',
    });
    const [loading, setLoading] = useState(false);
    const [parcelAndConstructionData, setParcelAndConstructionData] = useState({
        buildParcels: [],
    });

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (id) {
            (async () => {
                const response = await getFormById(id, token, source, intention, null, pathname, navigate);

                if (response?.status && Math.floor(response.status / 100) === 2) {
                    const isCopied = response.data?.buildApplication?.isCopied || false;
                    setIsIntentionCheckboxDisabled(isCopied);
                    spreadParcelsAndConstructions(
                        response.data, //obj
                        setParcelAndConstructionData, //func
                        parcelAndConstructionData // obj                
                    );

                    // do not send id: null if a form is present
                    const formData = response.data?.buildApplicationForms?.[0];
                    if (formData && formData.id) {
                        setRequest(state => ({ ...state, form: {id: formData.id} }));
                        setCheckbox({
                            first: formData?.requestPublicUtility ?? false,
                        });
                    }

                    const intention = response.data?.buildIntention;
                    if (intention) {
                        // eslint-disable-next-line no-unused-vars
                        const { buildConstructions, buildParcels, affectedBuildConstructions, affectedBuildParcels, approvedConstructions, ...rest } = intention;
                        setRequest(state => ({ ...state, buildIntention: spreadObject(rest) }));
                        
                        setState(prevSate => ({ 
                            ...prevSate,
                            inputValue: intention.title.value, 
                            zamerType: intention.eszId ? 'existing' : 'new' 
                        }));
                    }
                    setLoading(false);
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        }
        else {
            console.log('NON EXISTENT ID');
            setLoading(false);
        }
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        if (buildIntention?.buildParcels) {
            setParcelAndConstructionData(prev => ({
                ...prev,
                buildParcels: [...(buildIntention?.buildParcels ?? [])],
            }));
            setRequest(prev => ({...prev, buildParcels: buildIntention?.buildParcels}));
        }

        if (initialRender.current) {
            initialRender.current = false;
        } else {
            // avoid multiple save for same object
            if (shouldNotSave(prevRequest.buildIntention, buildIntention)) {
                return;
            }

            if (buildIntention?.title?.value === null) {
                return;
            }

            saveChanges();
        }
    }, [buildIntention]);

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;
            let warning = false;

            if (hasZamerError(buildIntention)) {
                err = true;
            }

            if (buildIntention?.address?.zip)
            {
                const cleanedValue = buildIntention?.address?.zip?.replace(/\s+/g, '');
                warning = (isNaN(cleanedValue) || cleanedValue.length !== 5);
            }
            else if (buildIntention?.propertyDeveloperDetails?.address?.zip)
            {
                const cleanedValue = buildIntention?.propertyDeveloperDetails?.address?.zip?.replace(/\s+/g, '');
                warning = (isNaN(cleanedValue) || cleanedValue.length !== 5);
            }

            

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, warning};
                }
                return stg;
            }));

            return !err;
        }
    }));

    return (
        <>
            {loading ? <div className='loading'></div> :
                <Container fluid>
                    <Row className='row-wrapper'>
                        <h2 className='p-0 m-0 main-title'>{`${stepValue}. Záměr`}</h2>
                    </Row>

                    <ZamerSelectionControls
                        request={request}
                        setRequest={setRequest}
                        state={state}
                        setState={setState}
                        buildIntentionModel={buildIntentionModel}
                        isIntentionCheckboxDisabled={isIntentionCheckboxDisabled}
                    />

                    {/* Místo záměru */}
                    <Row className='row-wrapper'>
                        <p className='section-title p-0 mb-4'>Místo záměru</p>
                        <form className='p-0'>

                            {BUILDING_ADDRESS.map(item => (
                                <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                            ))}
                        </form>
                    </Row>

                    <PozemkyTable 
                        title={'Pozemky, na kterých bude změna využití provedena'} 
                        progressElements={progressElements}
                        setProgressElements={setProgressElements} 
                        urlPath={urlPath}
                        dataToAdd={parcelAndConstructionData.buildParcels}
                        setDataToAdd={setParcelAndConstructionData}
                    />
                </Container>}
        </>
    );
};

ZamerComponent06.propTypes = {
    
    progressElements: PropTypes.arrayOf(Object),
    setProgressElements: PropTypes.func,
    setCheckbox: PropTypes.func,
    urlPath: PropTypes.string.isRequired,
};

export default ZamerComponent06;