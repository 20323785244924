import React, { useState } from 'react';
import { HeaderSection, NovaZadostMainSection } from '../components';
import { Container } from 'react-bootstrap';

const NovaZadost = () => {
    const [isActive, setIsActive] = useState('table');

    return (
        <Container className='d-flex flex-column align-items-center' fluid>
            <HeaderSection headerText={'Podejte rychle žádost nebo návrh elektronicky!'} mainHeader={'Nová žádost'} page={'zadosti/new'} />
            <NovaZadostMainSection isActive={isActive} setIsActive={setIsActive} />
        </Container>
    );
};

export default NovaZadost;