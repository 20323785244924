let items = [
    {
        stage: 1,
        name: 'Záměr',
        error: false,
        warning: false,
        seen: false,  
    },
    {
        stage: 2,
        name: 'Žadatel',
        moreInfo: 'Osobní údaje o žadateli a případně o jeho zástupci',
        error: false,
        warning: false,
        seen: false,  
    },
    {
        stage: 3,
        name: 'Stavba',
        error: false,
        warning: false,
        seen: false,  
    },
    {
        stage: 4,
        name: 'Rozsah',
        moreInfo: 'Rozsah žádosti a případná rozšíření',
        error: false,
        warning: false,
        seen: false,  
    },
    {
        stage: 5,
        name: 'Dokumentace',
        moreInfo: 'Stavební dokumentace, ve výchozím stavu se přebírá ze Záměru',
        error: false,
        warning: false,
        seen: false,  
    },
    {
        stage: 6,
        name: 'Přilohy',
        moreInfo: 'Další přílohy formuláře',
        error: false,
        warning: false,
        seen: false,  
    },
    {
        stage: 7,
        name: 'Příjemce',
        error: false,
        warning: false,
        seen: false,  
    },
    {
        stage: 8,
        name: 'Kontrola a podání',
        error: false,
        warning: false,
        seen: false,  
    },
];

const CONTACTS_PO_FORM_14 = [
    {
        id: 1,
        label: 'Telefon (nepovinné)',
        type: 'text',
        refer: 'contacts-tel',
        required: false,
        state: 'phone',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 2,
        label: 'E-mail (nepovinné)',
        type: 'text',
        refer: 'contacts-email',
        required: false,
        state: 'email',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 3,
        label: 'ID datové schránky',
        type: 'text',
        refer: 'contacts-idDataBox',
        required: true,
        state: 'idDataBox',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
];

export {
    items,
    CONTACTS_PO_FORM_14
};
