import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../../css/zadatelComponent.css';
import { errorIcon, warningYellow } from '../../assets';
import { Container, Row, Col } from 'react-bootstrap';
import useDynamicRefs from '../CustomHooks/useDynamicRefs';
import { COUNTRIES } from '../../constants/sharedConstants.js';
import FormContext from '../../formContexts/FormContext.js';
import { canDisableExceptions } from '../../helperFunctions/helpers.js';
import { dateValidation } from '../../helperFunctions/validationHelpers.js';
import { AuthContext } from 'react-oauth2-code-pkce';
import { jwtDecode } from 'jwt-decode';

const FormInput = (props) => {
    const { 
        setRequest,
        request,
        object,
        propertyToUpdate,
        parentProperty,
        refer,
        type,
        state,
    } = props;
    const {stagesArr, stepValue} = useContext(FormContext);
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const { getRef } = useDynamicRefs();
    const inputRef = getRef(refer);
    const objectState = props.state;
    const [ focused, setFocused ] = React.useState(false);
    const [ hasMounted, setHasMounted ] = React.useState(false);
    const { token } = useContext(AuthContext);
    const { dateOfBirth: defaultDOB } = jwtDecode(token);
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    const telPattern = /^\+?(?:\(\d+\))?[\d ()-]{9,}$/;
    const icoPattern = /^[0-9]{8}$/;

    const [dateError, setDateError] = React.useState(false);
    
    const isWrongFormat = React.useCallback((current) => {
        let warning = false;
    
        if (objectState === 'zip') {
            const cleanedValue = current.replace(/\s+/g, '');
            warning = (isNaN(cleanedValue) || cleanedValue.length !== 5);
        } else if (objectState === 'email') {
            warning = !emailPattern.test(current);
        } else if (objectState === 'phone')
        {
            const cleanedValue = current.replace(/\s+/g, '');
            warning = (!telPattern.test(current) || cleanedValue.length < 9);
        } else if (objectState === 'ico') 
        {
            warning = !icoPattern.test(current?.toString()?.trim()?.replace(/\s+/g, ''));
        }
        
        return warning;
    }, [objectState]);

    const isUserWithToken = (request, propertyToUpdate) => {        
        return (
            (request?.form?.applicantMe && request?.form?.applicant?.isFO && propertyToUpdate === 'applicant') || 
            (request?.form?.applicantAttorney && request?.form?.attorney?.isFO && propertyToUpdate === 'attorney')
        );
    };

    const getInputValue = () => {
        if (parentProperty) {
            return request?.[object]?.[propertyToUpdate]?.[parentProperty]?.[objectState] || '';
        } else if (propertyToUpdate) {
            return request?.[object]?.[propertyToUpdate]?.[objectState] || '';
        } else {
            return request?.[object]?.[objectState] || '';
        }
    };

    const inputValue = getInputValue() ?? '';
    const isDate = type === 'date';
    const validatedDate = (isDate && dateValidation(inputValue));
    const hasError = ((error && inputValue?.trim() === '' && inputRef?.current?.required) || (focused && inputValue?.trim() === '' && inputRef?.current?.required) || (validatedDate));
    const hasWarning = React.useMemo(() => {
        return (inputValue && (objectState === 'email' || objectState === 'zip' || objectState === 'phone' || objectState === 'ico') && isWrongFormat(inputValue));
        // return (inputValue && inputRef?.current?.required && isWrongFormat(inputValue)) || 
    }, [inputValue, isWrongFormat, objectState, hasMounted]);

    const handleFocused = () => {
        setFocused(true);
    };

    useEffect(() => {
        setHasMounted(true);
    }, []);

    useEffect(() => {
        const inputElement = getRef(refer).current;
        if (inputElement) {
            const stateValue = parentProperty ? request?.[object]?.[propertyToUpdate]?.[parentProperty]?.[objectState] :
                objectState ? request?.[object]?.[propertyToUpdate]?.[objectState] : null;
            inputElement.value = stateValue === null || stateValue === undefined ? '' : stateValue;
        }
    }, [objectState, refer, request?.[object], getRef]);
    
    return (
        <>
            {
                props.info
                    ?
                    <label
                        htmlFor={refer}
                        className='d-flex flex-column label'
                        style={{marginTop: props.id > 1 && '1.5rem', color: hasError ? '#C52A3A' : hasWarning ? '#C78300' : ''}}
                    >
                        <Row className='d-flex' style={{ alignItems: (!props.required) || (!props.required && !focused) || (props.required && !focused) ? 'end' : 'center' }}>
                            <Col className='d-flex flex-column' xs={12} md={6}>
                                {props.label}
                                {type === 'select' ? 
                                    <select
                                        id={refer}
                                        ref={inputRef}
                                        style={{border: hasError ? 'solid #C52A3A 1px' : hasWarning ? 'solid #C78300 1px' : ''}}
                                        className='id-input2 dropdown-input'
                                        required={props.required}
                                        onBlur={parentProperty ?
                                            (e) => { 
                                                setRequest(state => ({ 
                                                    ...state, 
                                                    [object]: { 
                                                        ...state[object], 
                                                        [propertyToUpdate]: { 
                                                            ...state[object]?.[propertyToUpdate], 
                                                            [parentProperty]: { 
                                                                ...state[object]?.[propertyToUpdate]?.[parentProperty], 
                                                                [objectState]: e.target.value ?? ''
                                                            } 
                                                        } 
                                                    } 
                                                })); 
                                                handleFocused();
                                            }
                                            :
                                            propertyToUpdate ?
                                                (e) => {
                                                    setRequest(state => ({ 
                                                        ...state, 
                                                        [object]: { 
                                                            ...state[object], 
                                                            [propertyToUpdate]: { 
                                                                ...state[object]?.[propertyToUpdate], 
                                                                [objectState]: e.target.value ?? ''
                                                            } 
                                                        } 
                                                    }));
                                                    handleFocused();
                                                }
                                                :
                                                (e) => {
                                                    setRequest(state => ({ 
                                                        ...state, 
                                                        [object]: { 
                                                            ...state[object], 
                                                            [objectState]: e.target.value ?? ''
                                                        } 
                                                    }));
                                                    handleFocused();
                                                }
                                        }
                                        {...(focused && { focused: 'true' })}
                                    >    
                                        <option value={''}>Vyberte</option>
                                        {COUNTRIES.map((country, idx) => (
                                            <option key={country.value ?? idx} value={country.value ?? ''}>{country.label ?? ''}</option>
                                        ))}
                                    </select>
                                    :
                                    <input
                                        id={refer}
                                        ref={inputRef}
                                        type={type}
                                        style={{border: hasError ? 'solid #C52A3A 1px' : hasWarning ? 'solid #C78300 1px' : ''}}
                                        className='id-input2'
                                        required={props.required}
                                        disabled={isDate ? (defaultDOB && !dateValidation(inputValue) && isUserWithToken(request, propertyToUpdate)) : canDisableExceptions(request, state, propertyToUpdate)} // Conditional check to avoid input disabling when date is not valid
                                        onBlur={parentProperty ?
                                            (e) => {
                                                setDateError(dateValidation(e.target.value));
                                                setRequest(state => ({ 
                                                    ...state, 
                                                    [object]: { 
                                                        ...state[object], 
                                                        [propertyToUpdate]: { 
                                                            ...state[object]?.[propertyToUpdate], 
                                                            [parentProperty]: { 
                                                                ...state[object]?.[propertyToUpdate]?.[parentProperty], 
                                                                [objectState]: isDate && dateValidation(e.target.value) ? '' : e.target.value ?? ''
                                                            } 
                                                        } 
                                                    } 
                                                })); 
                                                handleFocused();
                                            }
                                            :
                                            propertyToUpdate ?
                                                (e) => {
                                                    setDateError(dateValidation(e.target.value));
                                                    setRequest(state => ({ 
                                                        ...state, 
                                                        [object]: { 
                                                            ...state[object], 
                                                            [propertyToUpdate]: { 
                                                                ...state[object]?.[propertyToUpdate], 
                                                                [objectState]: isDate && dateValidation(e.target.value) ? '' : e.target.value ?? ''
                                                            } 
                                                        } 
                                                    }));
                                                    handleFocused();
                                                }
                                                :
                                                (e) => {
                                                    setDateError(dateValidation(e.target.value));
                                                    setRequest(state => ({ 
                                                        ...state, 
                                                        [object]: { 
                                                            ...state[object], 
                                                            [objectState]: isDate && dateValidation(e.target.value) ? '' : e.target.value ?? ''
                                                        } 
                                                    }));
                                                    handleFocused();
                                                }
                                        }
                                        {...(focused && { focused: 'true' })}
                                    />
                                }
                                
                            </Col>
                            {props.label !== 'IČO' && <Col xs={12} md={6} className='input-help-text d-flex align-items-start ps-0 ps-md-3'>{props.info}</Col>}
                            {
                                hasError
                                &&
                                <Container style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                    <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                    {/* Bez vyplnění pole &quot;{props.label}&quot; není možné pokračovat. */}
                                    {dateError ? 'Nesprávný formát vyplnění, zkuste to znovu.' : `Bez vyplnění pole "${props.label}" není možné pokračovat.`}
                                </Container>
                            }
                            {
                                hasWarning
                                &&
                                <Container style={{color: '#C78300', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                    <img src={warningYellow} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                    Chyba formátu &quot;{props.label}&quot;, {objectState === 'ico' ? 'může obsahovat pouze osm číslic' : objectState === 'zip' ? 'může obsahovat pouze pět číslic' : objectState === 'email' ? 'email nemá správný formát' : objectState === 'phone' ? 'musí obsahovat alespoň devět číslic' : 'může obsahovat pouze písmena'}.
                                </Container>
                            }
                        </Row>
                    </label>
                    :
                    <label
                        htmlFor={refer}
                        className='d-flex flex-column label'
                        style={{marginTop: props.id > 1 && '1.5rem', color: hasError ? '#C52A3A' : hasWarning ? '#C78300' : ''}}
                    >
                        <Row className=''>
                            <Col xs={12} md={6} className='d-flex flex-column'>
                                {props.label}
                                {type === 'select' ? 
                                    <select
                                        id={refer}
                                        ref={inputRef}
                                        style={{border: hasError ? 'solid #C52A3A 1px' : hasWarning ? 'solid #C78300 1px' : ''}}
                                        className='id-input2 dropdown-input'
                                        required={props.required}
                                        onBlur={parentProperty ?
                                            (e) => { 
                                                setRequest(state => ({ 
                                                    ...state, 
                                                    [object]: { 
                                                        ...state[object], 
                                                        [propertyToUpdate]: { 
                                                            ...state[object]?.[propertyToUpdate], 
                                                            [parentProperty]: { 
                                                                ...state[object]?.[propertyToUpdate]?.[parentProperty], 
                                                                [objectState]: e.target.value ?? ''
                                                            } 
                                                        } 
                                                    } 
                                                })); 
                                                handleFocused();
                                            }
                                            :
                                            propertyToUpdate ?
                                                (e) => {
                                                    setRequest(state => ({ 
                                                        ...state, 
                                                        [object]: { 
                                                            ...state[object], 
                                                            [propertyToUpdate]: { 
                                                                ...state[object]?.[propertyToUpdate], 
                                                                [objectState]: e.target.value ?? ''
                                                            } 
                                                        } 
                                                    }));
                                                    handleFocused();
                                                }
                                                :
                                                (e) => {
                                                    setRequest(state => ({ 
                                                        ...state, 
                                                        [object]: { 
                                                            ...state[object], 
                                                            [objectState]: e.target.value ?? ''
                                                        } 
                                                    }));
                                                    handleFocused();
                                                }
                                        }
                                        {...(focused && { focused: 'true' })}
                                    >    
                                        <option value={''}>Vyberte</option>
                                        {COUNTRIES.map((country, idx) => (
                                            <option key={country.value ?? idx} value={country.value ?? ''}>{country.label ?? ''}</option>
                                        ))}
                                    </select>
                                    :
                                    <input
                                        id={refer}
                                        ref={inputRef}
                                        type={type}
                                        style={{border: hasError ? 'solid #C52A3A 1px' : hasWarning ? 'solid #C78300 1px' : ''}}
                                        className='id-input2'
                                        required={props.required}
                                        disabled={isDate ? (defaultDOB && !dateValidation(inputValue) && isUserWithToken(request, propertyToUpdate)) : canDisableExceptions(request, state, propertyToUpdate)} // Conditional check to avoid input disabling when date is not valid
                                        onBlur={parentProperty ?
                                            (e) => { 
                                                setDateError(dateValidation(e.target.value));
                                                setRequest(state => ({ 
                                                    ...state, 
                                                    [object]: { 
                                                        ...state[object], 
                                                        [propertyToUpdate]: { 
                                                            ...state[object]?.[propertyToUpdate], 
                                                            [parentProperty]: { 
                                                                ...state[object]?.[propertyToUpdate]?.[parentProperty], 
                                                                [objectState]: isDate && dateValidation(e.target.value) ? '' : e.target.value ?? ''
                                                            } 
                                                        } 
                                                    } 
                                                })); 
                                                handleFocused();
                                            }
                                            :
                                            propertyToUpdate ?
                                                (e) => {
                                                    setDateError(dateValidation(e.target.value));
                                                    setRequest(state => ({ 
                                                        ...state, 
                                                        [object]: { 
                                                            ...state[object], 
                                                            [propertyToUpdate]: { 
                                                                ...state[object]?.[propertyToUpdate], 
                                                                [objectState]: isDate && dateValidation(e.target.value) ? '' : e.target.value ?? ''
                                                            } 
                                                        } 
                                                    }));
                                                    handleFocused();
                                                }
                                                :
                                                (e) => {
                                                    setDateError(dateValidation(e.target.value));
                                                    setRequest(state => ({ 
                                                        ...state, 
                                                        [object]: { 
                                                            ...state[object], 
                                                            [objectState]: isDate && dateValidation(e.target.value) ? '' : e.target.value ?? ''
                                                        } 
                                                    }));
                                                    handleFocused();
                                                }
                                        }
                                        {...(focused && { focused: 'true' })}
                                    />
                                }
                            </Col>
                            {
                                hasError
                                &&
                                <Container style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                    <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                    {dateError ? 'Nesprávný formát vyplnění, zkuste to znovu.' : `Bez vyplnění pole "${props.label}" není možné pokračovat.`}
                                </Container>
                            }
                            {
                                hasWarning
                                &&
                                <Container style={{color: '#C78300', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                    <img src={warningYellow} alt='warning icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                    Chyba formátu &quot;{props.label}&quot;, {objectState === 'ico' ? 'může obsahovat pouze osm číslic' : objectState === 'zip' ? 'může obsahovat pouze pět číslic' : objectState === 'email' ? 'email nemá správný formát' : objectState === 'phone' ? 'musí obsahovat alespoň devět číslic' : 'může obsahovat pouze písmena'}.
                                </Container>
                            }
                        </Row>
                    </label>
            }
        </>
    );
};

const applicantModalShape = PropTypes.shape({
    
    ico: PropTypes.string,
    isFO: PropTypes.bool,
    isFOBusiness: PropTypes.bool,
    isFOMore: PropTypes.bool,
    names: PropTypes.string,
    lastName: PropTypes.string,
    lastNameOrigin: PropTypes.string,
    dateOfBirth: PropTypes.string,
    nationality: PropTypes.string,
    shippingSameAsPr: PropTypes.bool,
    shippingAddress: PropTypes.shape({
        city: PropTypes.string,
        cityPart: PropTypes.string,
        street: PropTypes.string,
        descNum: PropTypes.string,
        orientNum: PropTypes.string,
        zip: PropTypes.string,
    }),
    address: PropTypes.shape({
        city: PropTypes.string,
        cityPart: PropTypes.string,
        street: PropTypes.string,
        descNum: PropTypes.string,
        orientNum: PropTypes.string,
        zip: PropTypes.string,
    }),
    powerOfAttorneyFile:  PropTypes.shape({
        uid: PropTypes.string,
        fileName: PropTypes.string,
        url: PropTypes.string,
        section: PropTypes.string,
        size: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        personId: PropTypes.string,
    }),
    phone: PropTypes.string,
    email: PropTypes.string,
    idDataBox: PropTypes.string,
    appendix: PropTypes.string,
    poName: PropTypes.string,
    mandate: PropTypes.string,
    mandateFrom: PropTypes.string,
    mandateTO: PropTypes.string,
});


FormInput.propTypes = {
    label: PropTypes.string.isRequired,
    refer: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    required: PropTypes.bool.isRequired,
    info: PropTypes.string,
    request: PropTypes.shape({
        applicationId: PropTypes.string,
        buildIntention: PropTypes.object,
        form: PropTypes.shape({
            applicantMe: PropTypes.bool,
            applicantAttorney: PropTypes.bool,
            applicantMore: PropTypes.bool,
            applicant: applicantModalShape,
            powerOfAttorney: PropTypes.any,
            attorney: applicantModalShape,
            applicants: PropTypes.arrayOf(applicantModalShape)
        }),
    }),
    setRequest: PropTypes.func.isRequired,
    getRef: PropTypes.func,
    state: PropTypes.string.isRequired,
    object: PropTypes.string.isRequired,
    propertyToUpdate: PropTypes.string,
    parentProperty: PropTypes.string,
};

export default FormInput;