import React from 'react';

class StateManager {
    constructor(initialState) {
        this.state = initialState;
        this.listeners = new Set();
    }

    setState(newState) {
        const stateUpdate = typeof newState === 'function' ? newState(this.state) : newState;
        this.state = { ...this.state, ...stateUpdate };
        this.listeners.forEach((listener) => listener(this.state));
    }

    subscribe(listener) {
        this.listeners.add(listener);
        return () => this.listeners.delete(listener);
    }

    getState() {
        return this.state;
    }
}

const globalStateManager = new StateManager({
    stage: 1,
    checkbox: {
        first: null,
        second: null,
    },
});

export const useGlobalState = () => {
    const [, forceUpdate] = React.useState();

    React.useEffect(() => {
        const unsubscribe = globalStateManager.subscribe(() => forceUpdate({}));
        return unsubscribe;
    }, []);

    return [ globalStateManager.getState(), globalStateManager.setState.bind(globalStateManager) ];
};
