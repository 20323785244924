import { useState } from 'react';
import { BlockBlobClient } from '@azure/storage-blob';

const useUpload = (
    isNotForBlob, // bool
) => {
    const [uploadState, setUploadState] = useState({
        uploadSuccess: false,
        uploadError: false,
        uploadCancelled: false,
        uploadProgress: null,
    });

    const upload = isNotForBlob ?
        () => {
            setUploadState(prev => ({ ...prev, uploadProgress: 100, uploadSuccess: true }));
        } : 
        async (
            url,
            file,
            abortSignal,
        ) => {

            if (!url || !file || !abortSignal) {
                return;
            }

            try {
                setUploadState(prev => ({ ...prev, uploadProgress: 0, uploadError: false }));
                const onProgress = (prog) => {
                    if(!abortSignal.aborted) {
                        const progress = Math.floor((prog.loadedBytes / file.size) * 100);
                        setUploadState(prev => ({ ...prev, uploadProgress: progress }));
                    }
                };

                const blockBlobClient = new BlockBlobClient(url);
        
                const uploadOptions = {
                    abortSignal,
                    onProgress,
                };
                const data = await blockBlobClient.uploadData(file, uploadOptions);
                if (Math.floor(data._response.status / 100) === 2) {
                    setUploadState(prev => ({ ...prev, uploadSuccess: true }));
                }
            } catch (error) {
                if (error.name === 'AbortError') {
                    setUploadState(prev => ({ ...prev, uploadCancelled: true }));
                    console.log('Upload aborted');
                } else {
                    setUploadState(prev => ({ ...prev, uploadError: true }));
                    console.log('Upload error:', error);
                }
            }
        }; 

    return { 
        upload, 
        uploadSuccess: uploadState.uploadSuccess, 
        uploadError: uploadState.uploadError, 
        setUploadState, 
        uploadProgress: uploadState.uploadProgress, 
        uploadCancelled: uploadState.uploadCancelled 
    };
};

export default useUpload;
