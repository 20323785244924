import React, { useContext, useEffect, useState } from 'react';
import RizeniDetailHeaderSection from './RizeniDetailHeaderSection';
import RizeniDetailMainSection from './RizeniDetailMainSection';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import urlJoin from 'url-join';
import apiConfig from '../../config/api-config';
import { AuthContext } from 'react-oauth2-code-pkce';
//import {fetchQR} from '../../apiCalls/componentsApiCalls.js';

const RizeniApiCall = () => {
    const [rizeniData, setRizeniData] = useState({
        rizeniDetail: null,
        qr: null,
    });
    const dataPresent = Object.values(rizeniData).some(data => data !== null);
    const { token } = useContext(AuthContext);
    const { id } = useParams();

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (rizeniData.rizeniDetail === null) {
            
            (async () => {
                
                const response = await axios.get(
                    urlJoin(apiConfig.getAndQueryBaseUrl, 'procedures', encodeURIComponent(id)),
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        cancelToken: source.token,
                    });
                //const response2 = await fetchQR(id, token, source);
                if ((200 <= response?.status) && (response?.status < 300)) {
                    setRizeniData(prev => ({
                        ...prev,
                        rizeniDetail: response.data.data,
                        //qr : response2
                    }));}
                
            })();
            return () => {
                source.cancel('Operation canceled by the user.');
            };
        }
    }, [rizeniData.rizeniDetail]);


    return (
        <>  
            {dataPresent && <>
                <RizeniDetailHeaderSection rizeniHeaderData={rizeniData}/>
                <RizeniDetailMainSection rizeniData={rizeniData}/>
            </>}
        </>
    );
};

export default RizeniApiCall;
