import React, { useEffect, useState } from 'react';
import { Col, Pagination, Row } from 'react-bootstrap';
import { shouldNotGoToFirstPage, shouldNotGoToLastPage, shouldNotGoToNextPage, shouldNotGoToPreviousPage } from '../../../helperFunctions/helpers';
import { firstPage, lastPage, nextPage, previousPage } from '../../../assets';
import PropTypes from 'prop-types';

const PaginateResults = ({ resultPerPage, startIndex, setStartIndex, listData, isMobileView }) => {
    const dataLength = listData.length;
    const lastIndexVal = dataLength % resultPerPage === 0 ? (dataLength - resultPerPage) : (dataLength - (dataLength % resultPerPage));
    const paginationNumbers = Math.ceil(dataLength / resultPerPage);
    const refArray = Array(paginationNumbers).fill(null);
    const [firstHalfStartLimit, setFirstHalfStartLimit] = useState(0);
    const firstHalfEndLimit = !isMobileView ? firstHalfStartLimit + 3 : firstHalfStartLimit + 2; 
    const secondHalfStartLimit = !isMobileView ? paginationNumbers - 3 : paginationNumbers - 2;
    const activeIndex = startIndex > resultPerPage ? ((startIndex / resultPerPage) + 1) : 1;

    useEffect(() => {
        if (activeIndex === 1) {
            setFirstHalfStartLimit(0);
            return;
        }

        if (activeIndex === firstHalfStartLimit) {
            setFirstHalfStartLimit(prev => prev - 1);
            return;
        }

        if (activeIndex <= secondHalfStartLimit) {
            if (activeIndex > firstHalfEndLimit) {
                if (!isMobileView) {
                    setFirstHalfStartLimit(activeIndex - 3);
                } else {
                    setFirstHalfStartLimit(activeIndex - 2);
                }
            } 
        } else {
            if (!isMobileView) {
                setFirstHalfStartLimit(refArray.length - 6);
            } else {
                setFirstHalfStartLimit(refArray.length - 4);
            }
        }

    }, [activeIndex]);

    useEffect(() => {
        if (dataLength === 0) {
            return;
        }

        if (startIndex === dataLength) {
            setStartIndex(prev => prev - resultPerPage);
        }

    }, [dataLength]);
    
    return (
        <Col className={`w-100 d-flex flex-column align-items-center justify-content-center ${isMobileView ? 'pb-3' : ''}`}>
            <Pagination className='pagination-container'>
                <Row className='d-flex align-items-center justify-content-center pagination-controls' onClick={() => {
                    if (shouldNotGoToFirstPage(startIndex, resultPerPage)) {
                        return;
                    }
                    setStartIndex(0);}}>
                    <img className={`control-btn ${shouldNotGoToFirstPage(startIndex, resultPerPage) ? 'disabled' : ''}`} src={firstPage} alt={'prvni strana'} />
                </Row>
                <Row className='d-flex align-items-center justify-content-center pagination-controls' onClick={() => {
                    if (shouldNotGoToPreviousPage(startIndex, resultPerPage)) {
                        return;
                    }
                    setStartIndex(prev => prev - resultPerPage);}}>
                    <img className={`control-btn ${shouldNotGoToPreviousPage(startIndex, resultPerPage) ? 'disabled' : ''}`} src={previousPage} alt={'predchozi strana'} />
                </Row>
                <Row className='d-flex align-items-center justify-content-center'>
                    {paginationNumbers > 6 ? 
                        <>  
                            {refArray.map((_, idx) => {
                                if (idx >= firstHalfStartLimit && idx < firstHalfEndLimit) {
                                    return (<Pagination.Item key={idx} onClick={() => setStartIndex(idx * resultPerPage)} active={startIndex === idx * resultPerPage}>
                                        {idx + 1}
                                    </Pagination.Item>);
                                }    
                            })}
                            <Pagination.Item>
                                <select name="index" id="index" onChange={(e) => {
                                    if (e.target.id === 'ellipsis') {
                                        return;
                                    }
                                    setStartIndex(+e.target.value * resultPerPage);
                                }} 
                                value={startIndex / resultPerPage}>
                                    <option id='ellipsis'>
                                        {'...'}
                                    </option>
                                    {refArray.map((_, idx) => {
                                        if (idx >= firstHalfEndLimit && idx < secondHalfStartLimit) {
                                            return (
                                                <option key={idx} value={idx}>
                                                    {idx + 1}
                                                </option>
                                            );
                                        }
                                        return null;
                                    })}
                                </select>
                            </Pagination.Item>
                            {refArray.map((_, idx) => { 
                                if (idx >= secondHalfStartLimit) {
                                    return (<Pagination.Item key={idx} onClick={() => setStartIndex(idx * resultPerPage)}  active={startIndex === idx * resultPerPage}>
                                        {idx + 1}
                                    </Pagination.Item>);
                                } 
                            })}
                        </>
                        : 
                        <>
                            {refArray.map((_, idx) => {
                                return <Pagination.Item key={idx} onClick={() => setStartIndex(idx * resultPerPage)}  active={startIndex === idx * resultPerPage}>
                                    {idx + 1}
                                </Pagination.Item>;
                            })}
                        </>
                    }
                </Row>
                <Row className='d-flex align-items-center justify-content-center pagination-controls' onClick={() => {
                    if (shouldNotGoToNextPage(startIndex, listData, resultPerPage)) {
                        return;
                    }
                    setStartIndex(prev => prev + resultPerPage);}}>
                    <img className={`control-btn ${shouldNotGoToNextPage(startIndex, listData, resultPerPage) ? 'disabled' : ''}`} src={nextPage} alt={'pristi strana'} />
                </Row>
                <Row className='d-flex align-items-center justify-content-center pagination-controls' onClick={() => {
                    if (shouldNotGoToLastPage(startIndex, listData, resultPerPage)) {
                        return;
                    }
                    setStartIndex(lastIndexVal);}}>
                    <img className={`control-btn ${shouldNotGoToLastPage(startIndex, listData, resultPerPage) ? 'disabled' : ''}`} src={lastPage} alt={'posledni strana'} />
                </Row>
            </Pagination>
        </Col>
    );
};

PaginateResults.propTypes = {
    resultPerPage: PropTypes.number,
    listData: PropTypes.arrayOf(Object),
    startIndex: PropTypes.number,
    setStartIndex: PropTypes.func,
    isMobileView: PropTypes.bool,
};


export default PaginateResults;