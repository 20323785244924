import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import '../../css/rizeni.css';

const RizeniDetailPodani = ({ item }) => {
    const [moreInfo, setMoreInfo] = React.useState(false);

    return (
        <Container className='mb-4 podani-container border rounded p-4'>
            <Row className='border-bottom py-1'>
                <Col className='podani-row' xs={4}>Stav</Col>
                <Col className='podani-row podani-row-info'>{item?.status}</Col>
            </Row>
            <Row className='border-bottom py-1'>
                <Col className='podani-row' xs={4}>Číslo jednací</Col>
                <Col className='podani-row podani-row-info'>{item?.procedureNumber}</Col>
            </Row>
            <Row className='border-bottom py-1'>
                <Col className='podani-row' xs={4}>Dotčený orgán</Col>
                <Col className='podani-row podani-row-info'>{item?.authority?.authorityName}</Col>
            </Row>
            {(item?.participants !== null && item?.participants?.length > 0) && <Row className='border-bottom py-1'>
                <Col className='podani-row' xs={4}>Účastníci</Col>
                <Col>
                    <p className='podani-row podani-row-info'>{item?.participants[0]?.isPO ? item?.participants[0]?.poName : `${item?.participants[0]?.names ?? ''} ${item?.participants[0]?.lastName ?? ''}`}</p>
                    {item?.participants[1] && <p className='podani-row podani-row-info'>{item?.participants[1]?.isPO ? item?.participants[1]?.poName : `${item?.participants[1]?.names ?? ''} ${item?.participants[1]?.lastName ?? ''}`}</p>}
                    {!moreInfo && item?.participants?.length - 2 > 0 &&
                    <p className='podani-row podani-row-info'>a {item?.participants?.length - 2} dalších
                        <span className='podani-row podani-row-info podani-row-info--more' 
                            onClick={() => setMoreInfo(!moreInfo)}> (zobrazit všechny)</span>
                    </p>
                    }
                    {moreInfo && item.participants.filter((participant, index) => index !== 0 && index !== 1).map((participant, index) => {
                        return <p key={index} className='podani-row podani-row-info'>{participant?.isPO ? participant?.poName : `${participant?.names ?? ''} ${participant?.lastName ?? ''}`}</p>;
                    })}
                </Col>
            </Row>}
            <Row className='border-bottom py-1'>
                <Col className='podani-row' xs={4}>Číslo záměru</Col>
                <Col className='podani-row podani-row-info'>{item?.projectId}</Col>
            </Row>
            <Row className='py-1'>
                <Col className='podani-row' xs={4}>Záměr</Col>
                <Col className='podani-row podani-row-info'>{item?.projectName}</Col>
            </Row>
        </Container>
    );
};

RizeniDetailPodani.propTypes = {
    item: PropTypes.shape({
        status: PropTypes.string,
        procedureId: PropTypes.string,
        authority: PropTypes.object,
        participants: PropTypes.arrayOf(PropTypes.object),
        projectId: PropTypes.string,
        projectName: PropTypes.string,
        procedureNumber: PropTypes.string,
    })
};

export default RizeniDetailPodani;
