import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import '../../css/uploads.css';
import { UploadedAttachmentElement } from '../index.js';
import {
    PLNA_MOC_FIFTH,
    PLNA_MOC_FIRST,
    PLNA_MOC_FOURTH,
    PLNA_MOC_SECOND,
    PLNA_MOC_THIRD
} from '../../constants/sharedConstants.js';

const UploadedAttachments = ({ 
    stepsRef, 
    category, 
    attachments, 
    setUploads, 
    progressElements, 
    setProgressElements, 
    currentApplicant,
    setCurrentApplicant,
    setFoundApplicantIndex,
    applicantArr,
    setAttorneyRequest,
    receivedToken,
}) => {
    const [oldAttachments, setOldAttachments] = useState([...attachments]);
    const newAttachments = [...attachments].filter(el => !([...oldAttachments].some(element => element.uid === el.uid)));

    useEffect(() => {
        if (attachments.length === 0) {
            return;
        }

        const oldDocs = [...attachments].filter(el => [...oldAttachments].find(element => element.uid === el.uid));
        setOldAttachments([...oldDocs]);
        
    }, [attachments.length]);

    return (
        <Container className={`${attachments.length === 0 ? 'd-none' : ''} pt-3`} fluid>
            <Container 
                className={`bg-white ${(category !== PLNA_MOC_SECOND && attachments.length > 0) || 
                (category === PLNA_MOC_SECOND && 
                currentApplicant?.powerOfAttorneyFile instanceof Object && 
                currentApplicant?.powerOfAttorneyFile?.fileName) ? 'border' : ''}`} fluid
            >
                {attachments.length > 0 && attachments.map((fileObj, index, array) => { 
                    if ((category === PLNA_MOC_SECOND && currentApplicant?.id === fileObj?.personId) || (category === PLNA_MOC_FIRST)) {
                        return (<UploadedAttachmentElement
                            key={fileObj.uid || index}
                            fileObject={fileObj}
                            isNotLastLine={index !== array.length -1}
                            category={category}
                            setUploads={setUploads}
                            isNew={Boolean(newAttachments.find(element => element.uid === fileObj.uid)) && !Object.hasOwn(fileObj, 'flagged')}
                            progressElements={progressElements}
                            setProgressElements={setProgressElements}
                            currentApplicant={currentApplicant}
                            setCurrentApplicant={setCurrentApplicant}
                            setAttorneyRequest={setAttorneyRequest}
                        />);
                    } else if((category === PLNA_MOC_FIFTH)||(category === PLNA_MOC_THIRD)) {
                        return (<UploadedAttachmentElement
                            key={fileObj.uid || index}
                            fileObject={fileObj}
                            isNotLastLine={index !== array.length -1}
                            category={category}
                            setUploads={setUploads}
                            isNew={Boolean(newAttachments.find(element => element.uid === fileObj.uid)) && !Object.hasOwn(fileObj, 'flagged')}
                            progressElements={progressElements}
                            setProgressElements={setProgressElements}
                            currentApplicant={currentApplicant}
                            setCurrentApplicant={setCurrentApplicant}
                            setApplicantRepreRequest={setAttorneyRequest}
                        />);
                    } else if(category === PLNA_MOC_FOURTH) {
                        return (<UploadedAttachmentElement
                            key={fileObj.uid || index}
                            fileObject={fileObj}
                            isNotLastLine={index !== array.length -1}
                            category={category}
                            setUploads={setUploads}
                            isNew={Boolean(newAttachments.find(element => element.uid === fileObj.uid)) && !Object.hasOwn(fileObj, 'flagged')}
                            progressElements={progressElements}
                            setProgressElements={setProgressElements}
                            currentApplicant={currentApplicant}
                            setCurrentApplicant={setCurrentApplicant}
                            setAttorneyRepreRequest={setAttorneyRequest}
                        />);
                    }
                    else if (!category.startsWith('power_of_attorney')) {
                        return (<UploadedAttachmentElement
                            key={fileObj.uid || index}
                            fileObject={fileObj}
                            isNotLastLine={index !== array.length -1}
                            category={category}
                            setUploads={setUploads}
                            isNew={Boolean(newAttachments.find(element => element.uid === fileObj.uid)) && !Object.hasOwn(fileObj, 'flagged')}
                            progressElements={progressElements}
                            setProgressElements={setProgressElements}
                            stepsRef={stepsRef}
                            setFoundApplicantIndex={setFoundApplicantIndex}
                            applicantArr={applicantArr}
                            receivedToken={receivedToken}
                        />);
                    }
                })}
            </Container>
        </Container>
    );
};

UploadedAttachments.propTypes = {
    category: PropTypes.string,
    currentApplicant: PropTypes.objectOf(String),
    setCurrentApplicant: PropTypes.func,
    attachments: PropTypes.arrayOf(Object),
    setUploads: PropTypes.func,
    progressElements: PropTypes.arrayOf(Object),
    setProgressElements: PropTypes.func,
    setFoundApplicantIndex: PropTypes.func,
    setAttorneyRequest: PropTypes.func,
    stepsRef: PropTypes.objectOf(Array),
    applicantArr: PropTypes.arrayOf(Object),
    receivedToken: PropTypes.string,
};

export default UploadedAttachments;

