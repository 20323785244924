import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ID_TYPES } from '../../constants/sharedConstants';
import axios from 'axios';
import { GovButton } from '@gov-design-system-ce/react';
import PropTypes, { arrayOf, object } from 'prop-types';
import urlJoin from 'url-join';
import apiConfig from '../../config/api-config';
import { AuthContext } from 'react-oauth2-code-pkce';
import { formSave } from '../../apiCalls/formApiCalls';
import { getLinkforForm18 } from '../../helperFunctions/helpers';
import authConfig from '../../config/authorization-config';
import FormContext from '../../formContexts/FormContext';

const AddProjektantInfoAndGenerateLink = ({urlPath, accompanyingDocumentStatus, documentToDownload, request, setRequest}) => {
    const { token } = useContext(AuthContext);
    const { id } = useContext(FormContext);
    const saveData = {
        applicationId: request.applicationId,
        buildApplication: {
            shareForm: {
                ...request.buildApplication.shareForm,
            },
            authorizedPersonSharedLink: request.buildApplication.authorizedPersonSharedLink,
        }
    };
    const [receivedToken, setReceivedToken] = useState('');
    const [showCopySuccess, setShowCopySuccess] = useState(false);
    const isDisabled = Object.values(request.buildApplication.shareForm).some(el => !el);
    const isDisabledForLink = request.buildApplication.authorizedPersonSharedLink === '';

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(saveData, token, urlPath, source);
    };

    useEffect(() => {
        if (isDisabledForLink) {
            return;
        }

        saveChanges();
    }, [request.buildApplication.authorizedPersonSharedLink]);

    useEffect(() => {
        if (!receivedToken) {
            return;
        }

        setRequest(prev => ({...prev, buildApplication: {...prev.buildApplication, authorizedPersonSharedLink: getLinkforForm18(id, urlPath, receivedToken, authConfig)}}));
    }, [receivedToken]);

    useEffect(() => {
        if (showCopySuccess) {
            const timer = setTimeout(() => {
                setShowCopySuccess(false);
            }, 60000);

            return () => clearTimeout(timer);
        }
    }, [showCopySuccess]);
    
    const getMagicLink = async () => {
        if (!id) {
            return;
        }

        const source = axios.CancelToken.source();

        try {
            const response = await axios.post(
                urlJoin(apiConfig.validateAndPersistBaseUrl, `share/${id}`),
                {
                    ...request.buildApplication.shareForm,
                    // before mistakenly ID from select list was ignored that's why ID was hardcoded here
                    paperType: request.buildApplication.shareForm.paperType ?? 'ID',
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        
                    },
                    cancelToken: source ? source.token : null,
                });

            if (200 <= response.status < 300) {
                setReceivedToken(response.data);
            }
        } catch (error) {
            console.log(error);
        }

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    };
    
    const downloadPdf = async () => {
        try {
            const source = axios.CancelToken.source();
            if (!documentToDownload || !Array.isArray(documentToDownload)) {
                return;
            }
            const response = await axios.get(
                urlJoin(apiConfig.validateAndPersistBaseUrl, `preview-pdf/attachment/${documentToDownload[0].uid}`),
                {
                    responseType: 'blob',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    cancelToken: source.token,
                });

            if ((200 <= response?.status) && (response?.status < 300)) {
                window.open(URL.createObjectURL(response.data));
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.log('Error', error);
            }
        }
    };

    return (
        <>
            <Row className='border p-4 row-wrapper mt-4'>
                <h3 className='p-0 id-title'>Zpracovatel dokumentace</h3>
                <Container className='p-0'>
                    <label className='d-flex flex-column pb-4 body-input'>
                    Jméno
                        <input
                            type='text'
                            className='id-input' 
                            onChange={(e) => setRequest(prev => ({...prev, buildApplication: {...prev.buildApplication, shareForm: {...prev.buildApplication.shareForm, firstName: e.target.value}}}))}
                            value={request.buildApplication.shareForm.firstName ?? ''}
                        />
                    </label>
                    <label className='d-flex flex-column pb-4 body-input'>
                    Příjmení
                        <input
                            type='text'
                            className='id-input' 
                            onChange={(e) => setRequest(prev => ({...prev, buildApplication: {...prev.buildApplication, shareForm: {...prev.buildApplication.shareForm, lastName: e.target.value}}}))}
                            value={request.buildApplication.shareForm.lastName ?? ''} 
                        />
                    </label>
                    <label className='d-flex flex-column pb-4 body-input'>
                    Druh dokladu
                        <select 
                            name="druh-dokladu"
                            id='druh-dokladu'
                            className='dropdown-input id-input2' 
                            onChange={(e) => setRequest(prev => ({...prev, buildApplication: {...prev.buildApplication, shareForm: {...prev.buildApplication.shareForm, paperType: e.target.value}}}))}
                            value={request.buildApplication.shareForm.paperType ?? 'ID'}
                        >
                            {ID_TYPES.map((id) => (
                                <option key={id.value} value={id.value}>{id.label}</option>
                            ))}
                        </select>
                    </label>
                    <label className='d-flex flex-column pb-4 body-input'>
                    Číslo dokladu
                        <input
                            type='text'
                            className='id-input' 
                            onChange={(e) => setRequest(prev => ({...prev, buildApplication: {...prev.buildApplication, shareForm: {...prev.buildApplication.shareForm, paperNumber: e.target.value}}}))}
                            value={request.buildApplication.shareForm.paperNumber ?? ''}
                        />
                    </label>
                    <Row className='g-0 pb-3 pt-2'>
                        <div className='body-input'>
                            <GovButton
                                nativeType='button'
                                type='outlined'
                                variant='primary'
                                size='m'
                                expanded={true}
                                onGov-click={getMagicLink}
                                disabled={isDisabled}
                            >
                                Vytvořit odkaz
                            </GovButton>
                        </div>
                    </Row>
                </Container>
            </Row>

            <Col>
                <Row>
                    <Col>
                        <div className='link-container'>
                            <p className='p-0 px-1 new-construction-help-text'>Odkaz pro přístup do průvodního listu dokumentace</p>
                            <label className='d-flex p-0 id-checkbox-label'>
                                <input type="text" className='new-construction-input text-truncate' defaultValue={request.buildApplication.authorizedPersonSharedLink ?? ''} disabled/>
                                <GovButton
                                    nativeType='button'
                                    className='label-copy-button my-0'
                                    type='outlined'
                                    variant='primary'
                                    size='m'
                                    onGov-click={async () => {
                                        try {
                                            if (request.buildApplication.authorizedPersonSharedLink.trim() === '') {
                                                return;
                                            }
                                            setShowCopySuccess(true);
                                            await navigator.clipboard.writeText(request.buildApplication.authorizedPersonSharedLink.trim());
                                        } catch (err) {
                                            setShowCopySuccess(false);
                                            console.error('Failed to copy: ', err);
                                        }
                                    }}
                                    disabled={isDisabledForLink}
                                >
                                    Kopírovat
                                </GovButton>
                            </label>
                        </div>
                    </Col>
                    {showCopySuccess && (
                        <Col className='align-self-end'>
                            <p className='gov-color--success-400 gov-text--s mb-2 px-2'>Odkaz úspěšně zkopírován do schránky!</p>
                        </Col>
                    )}
                </Row>
            </Col>
            <Row className='py-4'>
                <span className='dokumentace-text fst-italic p-0 projektant-help-text'>Odkaz zkopírujte a zašlete zpracovateli dokumentace e-mailem. Na tomto odkazu zpracovatel dokumentace vyplní Průvodní list a vloží jej přímo do vaší žádosti. Stav zpracování můžete sledovat níže. Je-li třeba průvodní list upravit, postupujte stejně.</span>
            </Row>
            <Row>
                <Container className={`odkaz-stav-container d-flex align-items-center justify-content-center p-4 border ${accompanyingDocumentStatus === null ? 'processing'
                    : accompanyingDocumentStatus === true ? 'success' 
                        : (accompanyingDocumentStatus === false || request.buildApplication.authorizedPersonSharedLink) ? 'waiting' 
                            : ''}`}>
                    <Container className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
                        <div className='odkaz-stav-header d-flex flex-md-column justify-content-between pb-3 pb-md-0'>
                            <Row className='mb-2'>Stav</Row>
                            <Row className='odkaz-stav-text'>
                                {accompanyingDocumentStatus === null ? 
                                    'Průvodní list je rozpracován' : 
                                    accompanyingDocumentStatus === true ? 
                                        'Průvodní list vložen.' : 
                                        (!request.buildApplication.authorizedPersonSharedLink && accompanyingDocumentStatus === false) ? 
                                            'Odkaz nemůže být bez zadaných údajů vytvořen.' : 
                                            accompanyingDocumentStatus === false ? 
                                                'Průvodní list čeká na vyplnění.' : 
                                                ''
                                }
                            </Row>
                        </div>
                        <div>
                            <Row className='flex-1'>
                                {(accompanyingDocumentStatus === true && documentToDownload?.length > 0) &&
                                    <GovButton
                                        nativeType='button'
                                        type='outlined'
                                        variant='secondary'
                                        size='m'
                                        onGov-click={downloadPdf}
                                        iconLeft='basic/file-earmark-arrow-down'
                                    >
                                        Stáhnout náhled průvodního listu &#40;pdf&#41;
                                    </GovButton>
                                }
                            </Row>
                        </div>
                    </Container>
                </Container>
            </Row>
        </>
    );
};

AddProjektantInfoAndGenerateLink.propTypes = {
    urlPath: PropTypes.string,
    request: PropTypes.object,
    setRequest: PropTypes.func,
    accompanyingDocumentStatus: PropTypes.bool,
    documentToDownload: arrayOf(object),
};

export default AddProjektantInfoAndGenerateLink;