import React, { useState, useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { ZamerComponent13, FormProgress, ZadatelComponent13, PovoleniComponent13, StavbaComponent13, PrilohyComponent13, KontrolaComponent13, PrijemceComponent13 } from '../index.js';
import { AuthContext } from 'react-oauth2-code-pkce';
import { formSave } from '../../apiCalls/formApiCalls.js';
import { FORM_13_INTENTION } from '../../constants/sharedConstants.js';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import FormContext from '../../formContexts/FormContext.js';

const Form13MainSection = ({
    stage,
    progressElements,
    setProgressElements,
    isMobileView,
}) => {
    const [loading, setLoading] = useState(true);
    const { token } = useContext(AuthContext);
    let { id: urlId } = useParams();
    const urlPath = 'quick-save-api/build-application-13/save';
    const stepsRef = useRef([]);
    const [foundApplicantIndex, setFoundApplicantIndex] = useState(null);
    const [applicantArr, setApplicantArr] = useState([]);
    const prevStage = usePrevious(stage);
    const {uploadError, setUploadError, setId} = useContext(FormContext);

    useEffect(() => {
        if (!urlId) {
            (async () => {
                let data = JSON.stringify({
                    'buildApplication': {
                        'title': FORM_13_INTENTION.title
                    }
                });

                const response = await formSave(data, token, urlPath);
                if ((200 <= response?.status) && (response?.status < 300)) {
                    console.log('START NOVE ZADOSTI 13', response.data);
                    setId(response.data);
                    setLoading(false);
                }
            })();
        }

        else {
            setId(urlId);
            setLoading(false);
        }
    }, []);
    
    useEffect(() => {
        if (prevStage !== stage && uploadError.length > 0) {
            setUploadError([]);
        }
    }, [stage, uploadError]);

    return (
        <Container fluid className='d-flex justify-content-center px-3 pt-0 pb-3 p-lg-5'>
            <Row className='base-width'>
                <Col className='d-flex justify-content-center justify-content-md-end d-md-block d-lg-none mb-4 mb-lg-0'>
                    {isMobileView && 
                        <FormProgress
                            stepsRef={stepsRef}
                            isMobile={isMobileView}
                        />
                    }
                </Col>
                <Col xs={{ span: 12 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 8 }} className='pe-lg-3'>
                    {loading === false ? 
                        <>
                            {stage === 1 && <ZamerComponent13
                                progressElements={progressElements}
                                setProgressElements={setProgressElements}
                                urlPath={urlPath}
                            />}
                            {stage === 2 && <ZadatelComponent13
                                progressElements={progressElements}
                                setProgressElements={setProgressElements}
                                urlPath={urlPath}
                                stage={stage}
                                foundApplicantIndex={foundApplicantIndex}
                                applicantArr={applicantArr}
                                setApplicantArr={setApplicantArr}
                                setFoundApplicantIndex={setFoundApplicantIndex}
                            />}
                            {stage === 3 && <PovoleniComponent13
                                progressElements={progressElements}
                                setProgressElements={setProgressElements}
                                urlPath={urlPath}
                            />}
                            {stage === 4 && <StavbaComponent13
                                urlPath={urlPath}
                                progressElements={progressElements}
                                setProgressElements={setProgressElements}
                            />}
                            {stage === 5 && <PrilohyComponent13
                                progressElements={progressElements}
                                setProgressElements={setProgressElements}
                                urlPath={urlPath}
                                stepsRef={stepsRef}
                                setFoundApplicantIndex={setFoundApplicantIndex}
                                applicantArr={applicantArr}
                            />}
                            {stage === 6 && <PrijemceComponent13
                                progressElements={progressElements}
                                setProgressElements={setProgressElements}
                                urlPath={urlPath}
                                stepsRef={stepsRef}
                                setFoundApplicantIndex={setFoundApplicantIndex}
                                applicantArr={applicantArr}
                            />}
                            {stage === 7 && <KontrolaComponent13/>}
                        </> :
                        <span>Načítám formulář...</span>}
                </Col>
                <Col lg={{ span: 4 }} xl={{ span: 4 }} className='d-flex justify-content-center justify-content-md-end d-none d-lg-block mb-md-5'>
                    {!isMobileView && 
                        <FormProgress
                            stepsRef={stepsRef}
                            isMobile={isMobileView}
                        />
                    }
                </Col>
            </Row>
        </Container>
    );
};

export default Form13MainSection;

Form13MainSection.propTypes = {
    stage: PropTypes.number.isRequired,
    progressElements: PropTypes.arrayOf(Object),
    setProgressElements: PropTypes.func,
    isMobileView: PropTypes.bool,
};