import React, { useEffect, useState, useContext, useImperativeHandle, useRef } from 'react';
import { Container, Row } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/rozsah.css';
import {  shouldNotSave, useOnlyRequiredKeys, checkLimitAndInsertText } from '../../helperFunctions/helpers';
import PropTypes from 'prop-types';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import FormContext from '../../formContexts/FormContext';
import useFirstRender from '../CustomHooks/useFirstRender.jsx';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import { CharCount } from '../../components/index.js';
import { errorIcon } from '../../assets/index.js';
import { useLocation, useNavigate } from 'react-router-dom';

const RozsahComponent07 = ({urlPath}) => {
    const {intention, stepValue, validationRef, setStagesArr, stagesArr, saveFormRef, id} = useContext(FormContext); 
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 700);
    const { token } = useContext(AuthContext);
    const [ focused, setFocused ] = useState({
        approximateParcelArea: false,
        descOfSplitOrMerge: false,
    });
    const approximateParcelAreaRef = useRef(null);
    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            splitParcels: false,
            mergeParcels: false,
            descOfSplitOrMerge: '',
            geometricPlan: '',
            approximateParcelArea: '',
        }
    });
    const firstRender = useFirstRender();
    const prevRequest = usePrevious(request);
    const [count, setCount] = useState({
        [0]: {count: 0, limit: 10000},
        [1]: {count: 0, limit: 10000},
    });

    useEffect(() => {
        function handleResize() {
            if(window.innerWidth <= 800) {
                setIsMobileView(true);
                return;
            } 

            setIsMobileView(false); 
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!id) {
            return;
        }
        const source = axios.CancelToken.source();
        (async () => {
            const response = await getFormById(id, token, source, intention, null, pathname, navigate);

            if ((200 <= response?.status) && (response?.status < 300)) {

                const loadedData = response.data?.buildApplicationForms?.[0];

                if (loadedData) {
                    const formStructure = request.form;
                    const filteredDataToSave = useOnlyRequiredKeys(formStructure, loadedData);
                    setRequest(state => ({ ...state, form: {...state.form, ...filteredDataToSave } }));
                }

            }
            else {
                console.log('ID NOT YET IN DB');
            }
        })();

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }
        saveChanges();
    }, [request.form]);

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;

            if(!request?.form?.splitParcels && !request?.form?.mergeParcels)
            {
                err = true;
            }
            else if((!request.form?.descOfSplitOrMerge?.trim()))
            {
                err = true;
            }
            else if(!request?.form?.geometricPlan?.trim() && !request?.form?.approximateParcelArea?.trim())
            {
                err = true;
            }

            

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, };
                }
                return stg;
            }));

            return !err;
        }
    }));

    const handleFocused = (elementToFocus) => {
        setFocused(prev => ({...prev, [elementToFocus]: true}));
    };

    useEffect(() => {
        if (request.form.geometricPlan?.trim()?.length && approximateParcelAreaRef?.current?.value) {
            approximateParcelAreaRef.current.value = '';
        }
    }, [request.form.geometricPlan]);

    const hasError1 = (!request.form?.descOfSplitOrMerge?.trim() && (error || focused.descOfSplitOrMerge));
    const hasError2 = (!request?.form?.geometricPlan?.trim() && !request?.form?.approximateParcelArea?.trim() && (error || focused.approximateParcelArea));

    return (
        <Container className='py-0 d-flex row align-items-center rozsahContainer' fluid>
            
            <Row className='row-wrapper'>
                <p className='p-0 title primary'>{`${stepValue}. Rozsah`}</p>
            </Row>

            <Row className='row-wrapper'>
                <p className='p-0 mb-4 title secondary'>Žádám o povolení</p>
                <form className='p-0'>
                    <label className={`ls-1p25 p-0 mb-2 d-flex align-items-start ${!isMobileView ? 'w-50' : 'w-100'}`}>
                        <input
                            className='me-3'
                            type="checkbox"
                            checked={request.form.splitParcels}
                            onChange={(e) => {
                                setRequest(state => ({...state, form: {...state.form, splitParcels: e.target.checked }}));
                            }}
                        />
                            dělení pozemků              
                    </label>                  
                    <label className={`ls-1p25 p-0 d-flex align-items-start ${!isMobileView ? 'w-50' : 'w-100'}`}>
                        <input
                            className='me-3'
                            type="checkbox"
                            checked={request.form.mergeParcels}
                            onChange={(e) => {
                                setRequest(state => ({...state, form: {...state.form, mergeParcels: e.target.checked }}));
                            }}
                        />
                            scelení pozemků
                    </label>
                    {
                        error
                        &&
                        !request?.form?.splitParcels && !request?.form?.mergeParcels
                        &&
                        <Container className='mt-1' style={{color: '#C52A3A', fontSize: '0.751rem', marginBottom: '.5rem'}}>
                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                            Bez zaškrtnutí alespoň jednoho pole není možné pokračovat.
                        </Container>
                    }
                </form>
            </Row>

            <Row className=''>
                <form className='p-0'>
                    <label className='d-flex flex-column p-0 row-wrapper textarea-input' style={{color: hasError1 ? '#C52A3A' : ''}}>
                        Popis navrhovaného dělení nebo scelení pozemků
                        <Row className='position-relative textarea-with-count-container'>
                            <textarea
                                className='textarea-with-count mt-2 p-2'
                                onChange={(e) => { checkLimitAndInsertText(e.target, 0, count, setCount); }}    
                                rows="5"
                                defaultValue={request.form.descOfSplitOrMerge}
                                {...(focused.descOfSplitOrMerge && {focused: 'true'})}
                                style={{border: hasError1 ? 'solid #C52A3A 1.5px' : '', borderRadius: hasError1 ? '3px' : ''}}
                                onBlur={(e) => {setRequest(state => ({...state, form: {...state.form, descOfSplitOrMerge: e.target.value }}));
                                    handleFocused('descOfSplitOrMerge');
                                }}
                            />
                            <CharCount 
                                value={request.form.descOfSplitOrMerge} setCount={setCount} propToUpdate={0} count={count[0].count} limit={count[0].limit}
                            />
                        </Row>
                    </label>
                    <label className='d-md-flex align-items-start row-wrapper label'>
                        <div className='d-flex flex-column mb-3 mb-md-0 me-md-4 textarea-input'>
                            Číslo geometrického plánu, pokud je k dispozici
                            <input type='text' className='id-input' defaultValue={request.form.geometricPlan}
                                onBlur={(e) => setRequest(state => ({...state, form: {...state.form, geometricPlan: e.target.value, approximateParcelArea: e.target.value?.trim()?.length ? '' : state.form.approximateParcelArea }}))}
                            />
                        </div>
                        <p className='mt-4 input-help-text'>Pokud vyplníte, není nutné zapisovat výměry vznikajících pozemků ani přikládat přílohu Celková situace v měřítku katastrální mapy</p>
                    </label>
                    {!request.form?.geometricPlan?.trim()
                        &&
                    <label className='d-flex flex-column p-0 row-wrapper textarea-input' style={{color: hasError2 ? '#C52A3A' : ''}}>
                        Uvedení přibližných výměr nově vznikajících pozemků
                        <Row className='position-relative textarea-with-count-container'>
                            <textarea
                                ref={approximateParcelAreaRef}
                                className='textarea-with-count mt-2 p-2'
                                onChange={(e) => { checkLimitAndInsertText(e.target, 1, count, setCount); }}
                                rows="5"
                                {...(focused.approximateParcelArea && {focused: 'true'})}
                                defaultValue={request.form.approximateParcelArea}
                                style={{border: hasError2 ? 'solid #C52A3A 1.5px' : '', borderRadius: hasError2 ? '3px' : ''}}
                                onBlur={(e) => {setRequest(state => ({...state, form: {...state.form, approximateParcelArea: e.target.value }}));
                                    handleFocused('approximateParcelArea');
                                }}
                            />
                            <CharCount 
                                value={request.form.approximateParcelArea} setCount={setCount} propToUpdate={1} count={count[1].count} limit={count[1].limit}
                            />
                        </Row>
                    </label>}
                </form>
            </Row>
            
        </Container>
    );
};

RozsahComponent07.propTypes = {
    urlPath: PropTypes.string.isRequired,
};

export default RozsahComponent07;
