import React from 'react';
import '../../css/reusable.css';
import '../../css/accessibility.css';
import { Container, Row, Col } from 'react-bootstrap';

const NewsOnPortal = () => {
    return (
        <Container className='welcome-section d-flex justify-content-center align-items-center d-md-flex flex-md-column p-3 p-md-5' style={{height: '100%'}} fluid>
            <Row className='d-flex flex-column justify-content-center flex-md-row base-width welcome-section-content'>
                <Row className='row-wrapper'>
                    <Col md={8}>
                        <p className='p-0 mb-3 accessibility-main_title'>Nové funkčnosti na Portálu stavebníka</p>
                        <p className='p-0 mb-3 accessibility-text accessibility-text--bold'>Rámcový seznam rozvoje Portálu stavebníka dle plánovaného vývoje MMR a dále na základě požadavků odborné veřejnosti a žadatelů</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <p className='p-0 accessibility-sub_title margin-20'>06.09.2024</p>
                        <ul>
                            <li className='p-0 accessibility-text mb-2'>Odeslané žádosti – doplnění informace o úřadu, kterému byla žádost podána</li>
                            <li className='p-0 accessibility-text mb-2'>Odeslané žádosti – detail odeslané žádosti – optimalizace zobrazování příloh žádosti a dokumentace</li>
                            <li className='p-0 accessibility-text mb-2'>Odeslané žádosti – úprava filtrování a vyhledávání</li>
                            <li className='p-0 accessibility-text mb-2'>Řízení – rozšíření zobrazovaných dat řízení (bude dále doplňováno)</li>
                            <li className='p-0 accessibility-text mb-2'>Řízení – úprava filtrování a vyhledávání</li>
                            <li className='p-0 accessibility-text mb-2'>Žádost – označení nepovinných polí příznakem „nepovinné“</li>
                            <li className='p-0 accessibility-text mb-2'>Žádost – obrazovka Žadatel – defaultně vybraná varianta, že je doručovací adresa shodná s adresou trvalého bydliště/sídla/místa podnikání</li>
                            <li className='p-0 accessibility-text mb-2'>Žádost – obrazovka Žadatel – zpřístupnění editace data narození uživatele v případě, že nemá v bankovní identitě povolené sdílení data narození</li>
                            <li className='p-0 accessibility-text mb-2'>Žádost – automatické dotahování dat existujícího záměru (zejména se týká těchto údajů: základní informace o záměru, pozemky, stavby)</li>
                            <li className='p-0 accessibility-text mb-2'>Žádost - existující záměr - informování uživatele v případě, že dojde k technickým obtížím při automatickém dotahování dat existujícího záměru z návazných IS</li>
                            <li className='p-0 accessibility-text mb-2'>Žádost – dokumentace – zpřístupnění informace o navýšeném datovém limitu pro dokumentaci na 5GB</li>
                            <li className='p-0 accessibility-text mb-2'>Žádost – optimalizace ovládacích prvků (radiobuttony/checkboxy)</li>
                            <li className='p-0 accessibility-text mb-2'>Žádost – přidat pozemek/stavbu – řazení nabídky katastrálních území/částí obce dle abecedy</li>
                            <li className='p-0 accessibility-text mb-2'>Žádost – zobrazení loaderu (ikona domečku), v případě, že probíhá načítání dat (např. z veřejných registrů)</li>
                            <li className='p-0 accessibility-text mb-2'>Vytvoření kopie žádosti – optimalizace práce s vytvořenou kopií žádosti (uzamčení možnosti přepínat záměr)</li>
                            <li className='p-0 accessibility-text mb-2'>Optimalizace Portálu pro eliminaci zobrazení „bílé stránky“, v případech, kdy se i přesto vyskytne, zobrazení návodu, jak dále postupovat</li>
                        </ul>
                        <p className='p-0 accessibility-text margin-20'></p>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <p className='p-0 accessibility-sub_title margin-20'>23.08.2024</p>
                        <ul>
                            <li className='p-0 accessibility-text mb-2'>Žádost – Žadatel – doplněna možnost zadat osobu oprávněnou jednat jménem fyzické osoby podnikající (FOP)</li>
                            <li className='p-0 accessibility-text mb-2'>Žádost – Žadatel - automatické vyplnění dat právnické osoby (PO) u žadatele na základě IČO</li>
                            <li className='p-0 accessibility-text mb-2'>Žádost - defaultně předvyplňována státní příslušnost Česko</li>
                            <li className='p-0 accessibility-text mb-2'>Žádost – Záměr - v případě volby Existující záměr - Zobrazení informace o neexistenci/nedohledání záměru, pokud zadaný záměr nebyl nalezen, omezení délky názvu záměru na 100 znaků+ doplnění upozornění v případě překročení tohoto limitu</li>
                            <li className='p-0 accessibility-text mb-2'>Žádost – Povolení - doplněna validace na nemožnost zadat budoucí datum pro již vydané povolení</li>
                            <li className='p-0 accessibility-text mb-2'>Žádost – Pozemky a stavby - optimalizace zadávání Pozemků a Staveb - úprava chování našeptávačů v modálním okně při přidávání pozemku, úprava case sensitive (VELKÁ / malá písmena)</li>
                            <li className='p-0 accessibility-text mb-2'>Žádost – Pozemky a stavby - aktualizace csv souborů pro import pozemků a staveb</li>
                            <li className='p-0 accessibility-text mb-2'>Žádost – Stavby – v případě přidání stavby výběrem z katastru nemovitostí upraveno řazení částí obce v listboxu (rozbalovací seznam) dle abecedy, pole obec nastaveno jako povinné, pro korektní fungování našeptávače pro nabídku relevantních katastrálních území</li>
                            <li className='p-0 accessibility-text mb-2'>Žádost – Dokumentace - navýšení limitu pro nahrávání souborů dokumentace</li>
                            <li className='p-0 accessibility-text mb-2'>Žádost – Dokumentace - zvýšení uživatelské přívětivosti a přehlednosti při generování linku pro zpracovatele dokumentace a indikace zkopírování linku do schránky</li>
                            <li className='p-0 accessibility-text mb-2'>Žádost – Dokumentace - v průvodním listu dokumentace umožněn výběr nové stavby i změny stavby</li>
                            <li className='p-0 accessibility-text mb-2'>Žádost – Dokumentace - Průvodní list - úprava csv souboru pro import staveb do Průvodního listu</li>
                            <li className='p-0 accessibility-text mb-2'>Žádost – Dokumentace - Průvodní list - Zpracovatel dokumentace/Zhotovitel stavby – umožněna možnost zvolit fyzickou osobu podnikající při změně z právnické osoby</li>
                            <li className='p-0 accessibility-text mb-2'>Žádost – Dokumentace – Průvodní list - doplněno automatické načítání údajů PO a FOP na základě zadaného IČO - zpracovatel dokumentace/ zhotovitel stavby</li>
                            <li className='p-0 accessibility-text mb-2'>Optimalizace - odesílání žádosti s existujícím Záměrem</li>
                            <ul>
                                <li className='p-0 accessibility-text mb-2'>Odstraněn problém se zobrazením bílé stránky při přechodu na obrazovku Žadatel v osnově žádosti</li>
                                <li className='p-0 accessibility-text mb-2'>Dokumentace - Průvodní list - optimalizace generování magic linku</li>
                                <li className='p-0 accessibility-text mb-2'>Záměr - pozemky/stavby - popis v dialogovém okně importu</li>
                                <li className='p-0 accessibility-text mb-2'>Záměr - pozemky - doplněna poznámka u datového pole &quot;Parcelní číslo&quot;</li>
                                <li className='p-0 accessibility-text mb-2'>Dokumentace - korektury textu</li>
                                <li className='p-0 accessibility-text mb-2'>Přílohy – Upraveno chování šipky</li>
                                <li className='p-0 accessibility-text mb-2'>Žádosti - Rozpracované - Odstraněna ikona ukazující na počet žádosti, pokud nastane stav, že již není žádná rozpracovaná žádost</li>
                                <li className='p-0 accessibility-text mb-2'>Záměr - Stavby/Pozemky – grid - optimalizace fungování tlačítka Upravit</li>
                            </ul>
                            <li className='p-0 accessibility-text mb-2'>Úvod – optimalizace designu dlaždice pro odhlášení z Portálu</li>
                            <li className='p-0 accessibility-text mb-2'>Moje žádosti – odeslané – možnost vytvořit kopii žádosti (lze i z detailu odeslané žádosti)</li>
                            <li className='p-0 accessibility-text mb-2'>Žádost - sjednocení fontů v PDF Náhledu žádostí</li>
                            <li className='p-0 accessibility-text mb-2'>Žádost - v náhledovém PDF žádosti je zaškrtnout příznak, v případě, že plná moc byla vložena do Portálu</li>
                            <li className='p-0 accessibility-text mb-2'>Úprava oslovení přihlášeného uživatele na Vítejte</li>
                            <li className='p-0 accessibility-text mb-2'>Odeslané žádosti – doplněna možnost zobrazit Detail žádosti (PDF žádosti, přílohy a dokumentace)</li>
                            <li className='p-0 accessibility-text mb-2'>Řízení - rozšíření zobrazovaných dat na záložce Řízení (doplnění role uživatele v řízení)</li>
                            <li className='p-0 accessibility-text mb-2'>Řízení – doplněna možnost filtrování a vyhledávání</li>
                            <li className='p-0 accessibility-text mb-2'>Sjednocení názvů PDF a XML souborů v BPP adresáři</li>
                            <li className='p-0 accessibility-text margin-20'>Aktualizace číselníku dotčených orgánů a stavebních úřadů</li>
                        </ul>
                        <p className='p-0 accessibility-text margin-20'></p>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <p className='p-0 accessibility-sub_title margin-20'>09.08.2024</p>
                        <ul>
                            <li className='p-0 accessibility-text mb-2'>Moje řízení (přehled) - doplnění stávajícího přehledu řízení (dlaždice i grid), která se zobrazují uživateli na obrazovce Moje řízení o roli uživatele v řízení (žadatel, zástupce, účastník řízení).</li>
                            <li className='p-0 accessibility-text mb-2'>Provedena revize všech modálních oken aplikace pro přidání stavby/pozemku na jednotlivých žádostech.</li>
                            <li className='p-0 accessibility-text mb-2'>Žádost - dokumentace - listinná podoba – žadatel nemá možnost nahrát dokumentaci, pokud na obrazovce Dokumentace vybere možnost, že dokumentaci doručí na úřad v listinné podobě</li>
                            <li className='p-0 accessibility-text mb-2'>Upravena chybová informace pro případ, že uživateli expirovala session a uživatel se musí znovu přihlásit.</li>
                            <li className='p-0 accessibility-text mb-2'>Moje žádosti (odeslané) - doplněno číslo a název záměru</li>
                            <li className='p-0 accessibility-text mb-2'>Úpravy formuláře č.4. (Žádost o povolení stavby nebo zařízení)</li>
                            <ul>
                                <li className='p-0 accessibility-text mb-2'>Přílohy k „Souhlasy všech účastníků řízení“ nabízí portál k nahrání pouze v případě, že uživatel na obrazovce Rozsah, vybere ANO, že žádá ve zrychleném řízení o povolení stavby</li>
                                <li className='p-0 accessibility-text mb-2'>V modálním okně „Přidat pozemek“ přejmenována Doplňující informace na Požadavky k pozemku a sjednocena volba Dělení/Scelení</li>
                                <li className='p-0 accessibility-text mb-2'>Na obrazovce záměru změněno pořadí nabízených bloků k vyplnění</li>
                            </ul>
                            <li className='p-0 accessibility-text mb-2'>Číslování na dlaždicích žádostí na Portálu v souladu s jejich číslováním ve vyhlášce č. 149/2024. Sb.</li>
                            <li className='p-0 accessibility-text mb-2'>Žádost - existující záměr - žadatel může vybrat již dříve nahranou dokumentaci k záměru (pokud taková existuje) a na ni se v žádosti jen odkázat, aby nemusel dokumentaci znovu nahrávat.</li>
                            <li className='p-0 accessibility-text mb-2'>Žadateli se nově zobrazuje informace, že nemá žádný existující záměr, nebo jím hledaný záměr nebyl nalezen/neexistuje, v situaci, kdy při vytvoření žádosti vybere možnost “existujíc záměr”, ale v evidenci buď žádný záměr nemá, nebo nebyl nalezen.</li>
                            <li className='p-0 accessibility-text margin-20'>Ulehčení/urychlení vyplnění pole Státní příslušnost neb ve většině případů je státní příslušnost Česko, méně často Slovensko.</li>
                        </ul>
                        <p className='p-0 accessibility-text margin-20'></p>
                        <p className='p-0 accessibility-text accessibility-text--bold mb-3'>Ministerstvo pro místní rozvoj</p>
                        <p className='p-0 accessibility-text'>Staroměstské náměstí 6<br/>110 00 Praha 1<br/>e-mail: <a href="mailto:web@mmr.gov.cz">web@mmr.gov.cz</a></p>
                    </Col>
                </Row>
            </Row>

        </Container>
        
    );
};

export default NewsOnPortal;
