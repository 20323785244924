import React from 'react';
import '../../css/reusable.css';
import '../../css/accessibility.css';
import { Container, Row, Col, Table } from 'react-bootstrap';

const PersonalData = () => {
    return (
        <Container className='welcome-section d-flex justify-content-center align-items-center d-md-flex flex-md-column p-3 p-md-5' style={{height: '100%'}} fluid>
            <Row className='d-flex flex-column justify-content-center flex-md-row base-width welcome-section-content'>
                <Row className='row-wrapper'>
                    <Col md={8}>
                        <p className='p-0 mb-3 accessibility-main_title'>Informace o zpracování osobních údajů</p>
                        <p className='p-0 accessibility-help_text'>V souladu se zásadou transparentnosti je Ministerstvo pro místní rozvoj jako správce povinno informovat subjekt údajů o zpracování osobních údajů, které se tohoto subjektu údajů týkají. Informační povinnost podle čl. 13 Obecného nařízení Evropského parlamentu a Rady (EU) 2016/679 ze dne 27. dubna 2016, o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (dále též &quot;Obecné nařízení&quot;), se vztahuje na správce v případě, že osobní údaje získal přímo od subjektu údajů, tedy subjekt údajů své osobní údaje tomuto správci přímo poskytl nebo sdělil.</p>
                    </Col>
                    
                </Row>

                <Row>
                    <Col md={8}>
                        <p className='p-0 accessibility-text mb-2'>Ministerstvo pro místní rozvoj podává informace o zpracování osobních údajů osob, jejichž osobní údaje jsou využívány v informačním systému portál stavebníka.</p>
                        <p className='p-0 accessibility-text margin-20'>Tato informace o zpracování osobních údajů může být v budoucnu na základě legislativních či provozních potřeb měněna. O případné změně Vás budeme informovat. Aktuální podobu informace naleznete vždy na této webové stránce.</p>
                        
                        <p className='p-0 accessibility-sub_title margin-20'>Portál stavebníka – informace dle článku 13 Obecného nařízení</p>
                        <Table bordered>
                            <tbody>
                                <tr>
                                    <td><p className='p-0 accessibility-text accessibility-text--bold mb-3'>Informace o správci</p></td>
                                    <td>Ministerstvo pro místní rozvoj IČO - 66002222</td>
                                    <td>Adresa:<br/>
Staroměstské náměstí 6, Praha 1, 110 00<br/>
ID datové schránky: 26iaava<br/>
email: <a href='mailto:info@mmr.gov.cz'>info@mmr.gov.cz</a></td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td>Pověřenec pro ochranu osobních údajů:</td>
                                    <td>pověřenec pro ochranu osobních údajů<br/>
adresa: Staroměstské náměstí 6, Praha 1,110 00<br/>
email: <a href='mailto:poverenec@mmr.gov.cz'>poverenec@mmr.gov.cz</a></td>
                                </tr>

                                <tr>
                                    <td><p className='p-0 accessibility-text accessibility-text--bold mb-3'>Zpracování osobních údajů</p></td>
                                    <td>Zdroj osobních údajů:<br/>•	subjekt údajů</td>
                                    <td></td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td>Účel zpracování:<br/>•	přístup k informacím veřejných orgánů a komunikaci s veřejnými orgány (dle zákona č. 365/2000 Sb., o informačních systémech veřejné správy, zákona č. 12/2020 Sb., o právu na digitální služby a o změně některých zákonů, zákona č. 111/2009 Sb., o základních registrech, zákona č. 300/2008 Sb., o elektronických úkonech a autorizované konverzi dokumentů, zákona č. 250/2017 Sb., o elektronické identifikaci, zákona č. 297/2016 Sb., o službách vytvářejících důvěru pro elektronické transakce, zákona č. 106/1999 Sb., o svobodném přístupu k informacím).</td>
                                    <td>Čl. 13 odst. 1 písm. c)</td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td>Právní základ zpracování:<br/>•	Plnění právní povinnosti dle čl. 6 odst. 1 písm. c) Obecného nařízení.</td>
                                    <td>Čl. 13 odst. 1 písm. c)</td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td>Doba uložení:<br/>•	Uložené dokumenty po dobu užívání služby. Logy jsou uchovávány dle požadavků zákona č. 181/2014 Sb., o kybernetické bezpečnosti, a souvisejících právních předpisů.</td>
                                    <td>Čl. 13 odst. 2 písm. a)</td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td>Máte povinnost osobní údaje poskytnout?<br/>•	Ano, pokud chcete využívat služeb portálu stavebníka</td>
                                    <td>Čl. 13 odst. 2 písm. e)</td>
                                </tr>

                                <tr>
                                    <td><p className='p-0 accessibility-text accessibility-text--bold mb-3'>Příjemci osobních údajů</p></td>
                                    <td>V rámci České republiky:<br/>•	orgány veřejné moci<br/>•	veřejné subjekty</td>
                                    <td>Čl. 13 odst. 1 písm. e)</td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td>Mezinárodní spolupráce či třetí země:<br/>•	Ministerstvo pro místní rozvoj nepředává osobní údaje mezinárodním organizacím či do třetích zemí</td>
                                    <td>Čl. 13 odst. 1 písm. e)</td>
                                </tr>

                                <tr>
                                    <td><p className='p-0 accessibility-text accessibility-text--bold mb-3'>Vaše práva</p></td>
                                    <td>Ve věci Vašich osobních údajů máte právo od Ministerstva pro místní rozvoj požadovat:<br/>
•	přístup k nim (článek 15 Obecného nařízení)<br/>
•	jejich opravu (článek 16 Obecného nařízení)<br/>
•	jejich výmaz (článek 17 Obecného nařízení)<br/>
•	omezení jejich zpracování (článek 18 Obecného nařízení)</td>
                                    <td>Čl. 13 odst. 2 písm. b)</td>
                                </tr>

                                <tr>
                                    <td></td>
                                    <td>Máte právo podat stížnost u Úřadu pro ochranu osobních údajů jako dozorového orgánu.<br/>
IČ: 70837627<br/>
Adresa: Pplk. Sochora 27,<br/>
170 00 Praha 7<br/>
E-mail: <a href='mailto:posta@uoou.cz'>posta@uoou.cz</a></td>
                                    <td>Čl. 13 odst. 2 písm. d)</td>
                                </tr>
                            </tbody>
                        </Table>
                       
                    </Col>
                </Row>
            </Row>
            
        </Container>
        
    );
};

export default PersonalData;