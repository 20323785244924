import React from 'react';
import '../../css/landingPage.css';
import { Col, Container, Row } from 'react-bootstrap';
import { LandingPageLogin } from '../index.js';

const LandingPageHeaderSection = () => {
    return (
        <Container fluid className="landing-page-header-section d-flex justify-content-center align-items-center d-md-flex flex-md-column p-3 p-md-5">
            <Row className="d-flex flex-column justify-content-center flex-md-row base-width">
                <Col xs={12} lg={{span: 6}} className='pb-5 p-lg-0 d-flex flex-column justify-content-center'>
                    <p className="landing-page-header-first">
                        Podejte žádost, zkontrolujte stav řízení,<span className="line-break"><br/></span> či se jen podívejte, co se ve vašem okolí plánuje...
                    </p>
                    <p className="landing-page-header-second">
                        ... jednoduše on-line!
                    </p>
                </Col>
                <Col xs={12} lg={{span: 6}} className="d-flex pb-5 pb-lg-0 justify-content-center justify-content-lg-end">
                    <LandingPageLogin />
                </Col>
            </Row>
        </Container>
    );
};

export default LandingPageHeaderSection;
