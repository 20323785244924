import React, { useContext, useEffect, useState } from 'react';
import ZadostiDetailHeaderSection from './ZadostiDetailHeaderSection';
import { ZadostiDetailMainSection } from '..';
import { AuthContext } from 'react-oauth2-code-pkce';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { fetchQR, fetchZadostiDetail } from '../../apiCalls/componentsApiCalls';
import { Container } from 'react-bootstrap';

const ZadostiApiCall = () => {
    const [zadostiDetail, setZadostiDetail] = useState({
        detail: null,
        qr: null
    });
    const { id } = useParams();
    const { token } = useContext(AuthContext);

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (!zadostiDetail.detail) {
            (async () => {
                const response = await fetchZadostiDetail(id, token, source);
                const response2 = await fetchQR(id, token, source);

                console.log(response);
                if ((200 <= response?.status) && (response?.status < 300)) {                        
                    setZadostiDetail({
                        detail: response.data.data,
                        qr: response2
                    });
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        }
    }, [zadostiDetail]);

    return (
        <Container fluid className='px-2 py-4 px-md-5 px-xl-0'>
            <ZadostiDetailHeaderSection zadostiDetail={zadostiDetail}/>
            <ZadostiDetailMainSection zadostiDetail={zadostiDetail}/>
        </Container>
    );
};

export default ZadostiApiCall;