import React, { useEffect, useState } from 'react';
import { Form, Modal, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
// import { propTypes } from 'react-bootstrap/esm/Image';

const PERMISSION_DEFAULT = {
    date: '', 
    legalAuthority: '', 
    name: '', 
    number: ''
};

const PridatPovolenModal = ({show, setShow, editedPermission, savePermission, cancelEditPermission}) => {
    const [permission, setPermission] = useState(null);

    useEffect(() => {
        setPermission(editedPermission === null ? PERMISSION_DEFAULT : editedPermission);
    }, [editedPermission]);

    const handleAddPermission = (more = false) => {
        savePermission(permission);
        !more && setShow(false);
        setPermission(PERMISSION_DEFAULT);
    };

    const handleCancelEditation = () => {
        cancelEditPermission();
        setShow(false);
        setPermission(PERMISSION_DEFAULT);
    };

    return (
        <Modal show={show} onHide={handleCancelEditation} size="lg" aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Přidat povolení
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="grid-example">                
                <Form>
                    <Row className='d-flex flex-column flex-xl-row'>
                        <Col className='me-5' md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Název povolení</Form.Label>
                                <Form.Control type="text" placeholder="" 
                                    value={permission?.name}
                                    onChange={(e) => {
                                        setPermission({
                                            ...permission,
                                            name : e.target.value
                                        });                                
                                    }}/>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Označení právního orgánu, který povolení vydal</Form.Label>
                                <Form.Control type="text" placeholder=""
                                    value={permission?.legalAuthority}
                                    onChange={(e) => {
                                        setPermission({
                                            ...permission,
                                            legalAuthority : e.target.value
                                        });                                
                                    }}/>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Číslo jednací</Form.Label>
                                <Form.Control type="text" placeholder=""
                                    value={permission?.number}
                                    onChange={(e) => {
                                        setPermission({
                                            ...permission,
                                            number : e.target.value
                                        });
                                    }}/>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Ze dne</Form.Label>
                                <Form.Control type="date" placeholder=""
                                    value={permission?.date}
                                    onChange={(e) => {
                                        setPermission({
                                            ...permission,
                                            date : e.target.value
                                        });                                
                                    }}/>
                            </Form.Group>
                        </Col>
                        <Col md={5}></Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className='d-flex'>
                <Col lg={12}>
                    <Row className='d-flex flex-column flex-xl-row'>
                        <Col className='me-5 text-center' md={6}>
                            <button type='button' className='px-5 btn btn-lg oze-button block' onClick={() => handleAddPermission()}>
                                Přidat
                            </button> 
                            <button type='button' className='px-5 btn btn-lg oze-button outline' onClick={() => { handleAddPermission(true); }}>
                                Přidat další
                            </button>
                        </Col>
                        <Col className='lg-align-items-end text-lg-end' md={5}>                            
                            <button type='button' className='px-5 btn btn-lg oze-button outline' onClick={() => handleCancelEditation()}>
                                Zrušit
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Modal.Footer>
        </Modal>
    );
};

PridatPovolenModal.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    editedPermission: PropTypes.object,
    savePermission: PropTypes.func.isRequired,
    cancelEditPermission: PropTypes.func.isRequired
};

export default PridatPovolenModal;