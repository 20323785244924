import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import UploadedAttachments from '../../FileUploads/UploadedAttachments';
import PropTypes from 'prop-types';
import FileUpload from '../../FileUploads/FileUpload';
import { checkAnoNeFields, checkCsvHeaders, extractFileName, isExceeding, spreadUploadedModalDocuments } from '../../../helperFunctions/helpers';
import Papa from 'papaparse';
import { 
    csvPozemky,
    csvPozemky01or02or04or09or16or17or18,
    csvPozemky06,
    csvPozemky07,
    csvPozemky08or10or15,
    csvProjektant,
    csvStavby,
    csvStavby10,
    csvStavby11,
    csvStavby12,
    csvStavby13,
    csvStavby14,
    csvStavby18,
    importError,
    importWarning, 
} from '../../../assets';
import {
    AFFECTED_POZEMKY_TABLE,
    AFFECTED_STAVBY_TABLE,
    APPROVED_STAVBY_TABLE,
    CSV_MODEL_POZEMKU,
    CSV_MODEL_POZEMKU_01_OR_02_OR_04_OR_09_OR_16_OR_17_OR_18,
    CSV_MODEL_POZEMKU_06,
    CSV_MODEL_POZEMKU_07,
    CSV_MODEL_PROJEKTANT_DOCUMENT,
    CSV_MODEL_STAVBY,
    FORM_01_INTENTION,
    FORM_02_INTENTION,
    FORM_14_INTENTION,
    FORM_04_INTENTION,
    FORM_06_INTENTION,
    FORM_07_INTENTION,
    FORM_08_INTENTION,
    FORM_10_INTENTION,
    FORM_11_INTENTION,
    FORM_12_INTENTION,
    FORM_13_INTENTION,
    FORM_18_INTENTION,
    POZEMKY_TABLE,
    PROJEKTANT_DOCUMENTATION_TABLE,
    STAVBY_TABLE,
    CSV_MODEL_STAVBY_14,
    CSV_MODEL_STAVBY_10,
    CSV_MODEL_STAVBY_11,
    CSV_MODEL_STAVBY_12,
    CSV_MODEL_STAVBY_13,
    CSV_MODEL_STAVBY_18,
    FORM_15_INTENTION,
    CSV_MODEL_POZEMKU_08_OR_10_15,
    FORM_09_INTENTION,
    FORM_16_INTENTION,
    FORM_17_INTENTION,
} from '../../../constants/sharedConstants';
import FormContext from '../../../formContexts/FormContext';

const ImportModal = ({
    category,
    progressElements,
    setProgressElements,
    showImportModal,
    setShowImportModal,
    handleCsv,
    isForPozemky,
    isForStavby,
    isForProjektant
}) => {
    const [importAttachments, setImportAttachments] = useState({
        [category]: [],
    });
    const { intention } = useContext(FormContext);
    const [data, setData] = useState([]);
    const [missingFields, setMissingFields] = useState([]);
    const [isEmpty, setIsEmpty] = useState(false);
    const [isWrongFormat, setIsWrongFormat] = useState(false);
    const [isExceeded, setIsExceeded] = useState(false);
    const cannotProceed = Boolean(importAttachments[category].length === 0 ||
        (importAttachments[category].length > 0 && importAttachments[category][0]?.message !== undefined) ||
        missingFields.length > 0 || isExceeded || isEmpty || isWrongFormat);
    const isForm08or10or15 = (intention === FORM_08_INTENTION.title || intention === FORM_10_INTENTION.title || intention === FORM_15_INTENTION.title);
    const isForm10 = (intention === FORM_10_INTENTION.title);
    const isForm11 = (intention === FORM_11_INTENTION.title);
    const isForm12 = (intention === FORM_12_INTENTION.title);
    const isForm13 = (intention === FORM_13_INTENTION.title);
    const isForm07 = (intention === FORM_07_INTENTION.title);
    const isForm06 = (intention === FORM_06_INTENTION.title);
    const isForm01or02or04or09or16or17or18 = (
        intention === FORM_01_INTENTION.title || 
        intention === FORM_02_INTENTION.title || 
        intention === FORM_04_INTENTION.title ||
        intention === FORM_09_INTENTION.title ||
        intention === FORM_16_INTENTION.title ||
        intention === FORM_17_INTENTION.title ||
        intention === FORM_18_INTENTION.title
    );
    const isForm14 = (intention === FORM_14_INTENTION.title);
    const isForm18 = (intention === FORM_18_INTENTION.title);

    useEffect(() => {
        if (importAttachments[category].length === 0) {
            return;
        }

        try {
            Papa.parse(importAttachments[category][0].csvFile, {
                skipEmptyLines: true,
                encoding: 'UTF-8',
                complete: async (results) => {
                    const headers = results.data[0];
                    if (!headers) {
                        return;
                    }
    
                    if (isForStavby && isExceeding(intention, [...results.data]?.slice(1).length)) {
                        setIsExceeded(true);
                        return;
                    }
    
                    if (checkCsvHeaders(
                        headers,
                        isForPozemky ? (
                            isForm08or10or15 ? CSV_MODEL_POZEMKU_08_OR_10_15 :
                                isForm07 ? CSV_MODEL_POZEMKU_07 :
                                    isForm06 ? CSV_MODEL_POZEMKU_06 :
                                        isForm01or02or04or09or16or17or18 ? CSV_MODEL_POZEMKU_01_OR_02_OR_04_OR_09_OR_16_OR_17_OR_18 :
                                            CSV_MODEL_POZEMKU
                        )
                            : isForStavby ? (isForm10 ? CSV_MODEL_STAVBY_10 :
                                isForm11 ? CSV_MODEL_STAVBY_11 :
                                    isForm12 ? CSV_MODEL_STAVBY_12 :
                                        isForm13 ? CSV_MODEL_STAVBY_13 :
                                            isForm14 ? CSV_MODEL_STAVBY_14 :
                                                isForm18 ? CSV_MODEL_STAVBY_18 :
                                                    CSV_MODEL_STAVBY)
                                : isForProjektant ? CSV_MODEL_PROJEKTANT_DOCUMENT
                                    : undefined,
                        setMissingFields
                    )) {
    
                        if (results.data.length <= 1) {
                            setIsEmpty(true);
                            return;
                        } else if (isForPozemky &&
                            !isForm08or10or15 &&
                            !isForm06 &&
                            [...results.data]
                                ?.slice(1)
                                .some(arr => [...arr]?.slice(6, isForm07 ? 8 : isForm01or02or04or09or16or17or18 ? 10 : 12)
                                    ?.some(el => el?.trim().toLowerCase() !== 'ano' && el?.trim().toLowerCase() !== 'ne'))
                        ) {
                            setIsWrongFormat(true);
                            return;
                        } else if (isForStavby &&
                            (
                                (isForm18 && checkAnoNeFields(results.data, 10)) ||
                                (isForm11 && (checkAnoNeFields(results.data, 11) || checkAnoNeFields(results.data, 13))) ||
                                (isForm12 && (checkAnoNeFields(results.data, 9) || checkAnoNeFields(results.data, 11))) ||
                                (isForm13 && (checkAnoNeFields(results.data, 10) || checkAnoNeFields(results.data, -1))) ||
                                (isForm14 && checkAnoNeFields(results.data, -1))
                            )
                        ) {
                            setIsWrongFormat(true);
                            return;
                        }
    
                        setData(results.data);
    
                    }
                }
            });
        } catch (error) {
            console.log(error);
        }

    }, [importAttachments[category]]);

    useEffect(() => {
        if (missingFields.length > 0 || isExceeded || isEmpty || isWrongFormat) {
            setImportAttachments({ [category]: [] });
        }
    }, [missingFields.length, isExceeded, isEmpty, isWrongFormat]);

    const handleFileInput = (files, category) => {
        const updateTablesDynamically = (file, category) => {
            const extractedFileName = extractFileName(file.name);
            const size = file.size;
            const fileToUpload = {
                fileName: extractedFileName,
                section: category,
                size,
                csvFile: file,
                message: file.message ?? undefined
            };
            spreadUploadedModalDocuments(fileToUpload, setImportAttachments, category);
        };

        files.forEach(file => updateTablesDynamically(file, category));
    };

    const handleClickImport = async () => {
        await handleCsv(data);
        setShowImportModal(false);
    };

    const handleDownloadTemplate = () => {
        switch (category) {
        case POZEMKY_TABLE:
        case AFFECTED_POZEMKY_TABLE: {
            if (isForm08or10or15) {
                window.open(csvPozemky08or10or15);
            } else if (isForm07) {
                window.open(csvPozemky07);
            } else if (isForm06) {
                window.open(csvPozemky06);
            } else if (isForm01or02or04or09or16or17or18) {
                window.open(csvPozemky01or02or04or09or16or17or18);
            } else {
                window.open(csvPozemky);
            }
            break;
        }
        case STAVBY_TABLE:
        case APPROVED_STAVBY_TABLE:
            if (isForm10) {
                window.open(csvStavby10);
            } else if (isForm11) {
                window.open(csvStavby11);
            } else if (isForm12) {
                window.open(csvStavby12);
            } else if (isForm13) {
                window.open(csvStavby13);
            } else if (isForm14) {
                window.open(csvStavby14);
            } else if (isForm18) {
                window.open(csvStavby18);
            } else {
                window.open(csvStavby);
            }
            break;
        case AFFECTED_STAVBY_TABLE:
            window.open(csvStavby);
            break;
        case PROJEKTANT_DOCUMENTATION_TABLE:
            window.open(csvProjektant);
            break;
        default:
            console.log('invalid download');
            break;
        }
        

        console.log(category);
    };

    useEffect(() => {
        setImportAttachments({ [category]: [] });
        setMissingFields([]);
        setIsExceeded(false);
        setIsEmpty(false);
        setIsWrongFormat(false);
    }, [showImportModal]);

    return (
        <Container>
            <Modal show={showImportModal} onHide={() => setShowImportModal(false)} size='lg' centered>
                <Modal.Header closeButton bsPrefix='d-flex justify-content-between align-items-center px-sm-5 modal-header-custom'>
                    <Modal.Title bsPrefix='modal-title'>{`Import ${isForPozemky ? 'pozemků' : isForStavby ? 'staveb' : isForProjektant ? 'projektanta' : ''}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body bsPrefix='px-sm-5 modal-body-custom'>
                    <Row className='row-wrapper'>
                        <p className='p-0 mb-3 sub-title'>
                            {`${isForPozemky ? 'Pozemky' : isForStavby ? 'Stavby' : isForProjektant ? 'Projektanti' : ''} je možné importovat ze souboru CSV, v kódování UTF-8 s hodnotami oddělenými čárkou ",". Můžete využít `}
                            <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handleDownloadTemplate}>
                                vzor
                            </span>
                            .
                        </p>
                        <Container className='mb-3 d-flex flex-row align-items-center'>
                            <img src={importWarning} alt='upo[zorneni' />
                            <p className='p-0 ps-2 sub-title-warning'>Import vždy smaže všechny dříve zadané pozemky.</p>
                        </Container>
                        {isExceeded &&
                            (<Container className='import_error_container d-flex px-4 py-3 mb-1'>
                                <img src={importError} alt='upozorneni' />
                                <p className='import_error_text ps-3'>
                                    <span className='import_error_span'>Chyba importu souboru: </span>
                                    {`Nelze vložit více než ${intention === 'Návrh na stanovení ochranného pásma' || intention === 'Žádost o povolení změny v užívání stavby' ? 7 : 5} staveb. Prosím opravte soubor a zkuste znovu.`}
                                </p>
                            </Container>)}
                        {missingFields.length > 0 &&
                            (<Container className='import_error_container d-flex px-4 py-3 mb-1'>
                                <img src={importError} alt='upozorneni' />
                                <p className='import_error_text ps-3'>
                                    <span className='import_error_span'>Chyba importu souboru: </span>
                                    Nenalezen povinný sloupec {missingFields.map((el, idx, arr) =>
                                        `"${el}"${idx < arr.length - 1 ? ', ' : ''}`
                                    )}. Prosím opravte soubor a zkuste znovu.
                                </p>
                            </Container>)}
                        {isEmpty &&
                            (<Container className='import_error_container d-flex px-4 py-3 mb-1'>
                                <img src={importError} alt='upozorneni' />
                                <p className='import_error_text ps-3'>
                                    <span className='import_error_span'>Chyba importu souboru: </span>
                                    soubor neobsahuje data.
                                </p>
                            </Container>)}
                        {isWrongFormat &&
                            (<Container className='import_error_container d-flex px-4 py-3 mb-1'>
                                <img src={importError} alt='upozorneni' />
                                <p className='import_error_text ps-3'>
                                    <span className='import_error_span'>Chyba importu souboru: </span>
                                    nahraný soubor neodpovídá požadovanému datovému standardu.
                                </p>
                            </Container>)}
                        <UploadedAttachments
                            category={category}
                            attachments={importAttachments[category]}
                            setUploads={setImportAttachments}
                            progressElements={progressElements}
                            setProgressElements={setProgressElements}
                        />
                        <FileUpload
                            handleFileInput={handleFileInput}
                            category={category}
                            setMissingFields={setMissingFields}
                            setIsExceeded={setIsExceeded}
                            setIsEmpty={setIsEmpty}
                            setIsWrongFormat={setIsWrongFormat}
                        />
                    </Row>
                </Modal.Body>
                <Modal.Footer bsPrefix='d-flex px-sm-5 modal-footer-custom'>
                    <Row className='button-wrapper'>
                        <Col xs={12} lg={6} className='button-container'>
                            <button
                                className={`modal-button filled ${cannotProceed ? 'disabled-filled' : ''}`}
                                disabled={cannotProceed}
                                onClick={() => handleClickImport()}
                            >
                                Importovat
                            </button>
                        </Col>
                        <Col className='d-flex justify-content-end button-container'>
                            <button
                                className='modal-button'
                                onClick={() => setShowImportModal(false)}
                            >
                                Zrušit
                            </button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

ImportModal.propTypes = {
    category: PropTypes.string,
    progressElements: PropTypes.arrayOf(Object),
    setProgressElements: PropTypes.func,
    isForStavby: PropTypes.bool,
    isForProjektant: PropTypes.bool,
    showImportModal: PropTypes.bool,
    isForPozemky: PropTypes.bool,
    setShowImportModal: PropTypes.func,
    handleCsv: PropTypes.func
};

export default ImportModal;
