import React, { useEffect, useMemo, useState } from 'react';
// import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import '../../css/tableView.css';
import { arrowDownBlue, arrowUpBlue } from '../../assets';
// import { Link } from 'react-router-dom';
// import { GovButton } from '@gov-design-system-ce/react';
import moment from 'moment';
import cs from 'moment/locale/cs';
import { getFilterBodyBySections } from '../../helperFunctions/helpers';
import { RIZENI } from '../../constants/sharedConstants';
import useFirstRender from '../CustomHooks/useFirstRender';

moment.locale('cs', cs);

const RizeniTableDisplay = ({ items, setPostBody, postBody }) => {
    const isReset = useMemo(() => JSON.stringify(postBody) === JSON.stringify(getFilterBodyBySections(RIZENI)), [postBody]);
    const [sortKeys, setSortKeys] = useState({
        projectNumber: [...(postBody.sorts || [])].find(el => el.field === 'projectNumber')?.asc ?? null, // ?bool
        projectId: [...(postBody.sorts || [])].find(el => el.field === 'projectId')?.asc ?? null, // ?bool
        projectName: [...(postBody.sorts || [])].find(el => el.field === 'projectName')?.asc ?? null, // ?bool
        status: [...(postBody.sorts || [])].find(el => el.field === 'status')?.asc ?? null, // ?bool
        role: [...(postBody.sorts || [])].find(el => el.field === 'role')?.asc ?? null, // ?bool
        date: [...(postBody.sorts || [])].find(el => el.field === 'date')?.asc ?? null, // ?bool
    });
    const firstRender = useFirstRender();
    
    const handleClick = (field, setSortKeys) => {
        setSortKeys(prev => {
            const ref = {};
            for (const key in prev) {
                if (key === field) {
                    ref[key] = !(prev[key]);
                } else {
                    ref[key] = null;
                }
            }

            return ref;
        });
    };

    useEffect(() => {
        if (firstRender) {
            return;
        }

        if (isReset) {
            setSortKeys({
                projectNumber: null,
                projectId: null,
                projectName: null,
                status: null,
                role: null,
                date: false,
            });
        }
    }, [isReset]);

    useEffect(() => {
        if (Object.values(sortKeys).every(val => val === null) || firstRender) {
            return;
        }

        const sortKey = Object.keys(sortKeys).find(key => sortKeys[key] !== null);
        setPostBody(prev => {
            const addedSort = {field: sortKey, asc: sortKeys[sortKey]};
            return {...prev, sorts: [...prev.sorts.filter(el => Object.keys(sortKeys).indexOf(el.field) === -1), {...addedSort}]};
        });
    }, [sortKeys]);

    // const handleTitleAndDate = (date) => {
    //     return moment(date).format('LL');
    // };

    return (
        <div style={{padding: '0', margin: '0'}}>
            <table className="border" style={{minWidth: '1100px', width: '100%'}}>
                <thead>
                    <tr>
                        <th 
                            className='py-2 px-3' 
                            style={{cursor: 'pointer'}}
                            onClick={() => handleClick('projectNumber', setSortKeys)}
                        >
                            <p 
                                className='table-head py-2 d-flex'>
                                Číslo jednací
                                {(sortKeys.projectNumber !== null && !isReset) &&
                                <img 
                                    className='ms-2' 
                                    src={sortKeys.projectNumber ? arrowUpBlue : arrowDownBlue} 
                                    alt='arrow' 
                                />}
                            </p>
                        </th>
                        <th 
                            className='py-2 px-3' 
                            style={{cursor: 'pointer'}}
                            onClick={() => handleClick('projectId', setSortKeys)}
                        >
                            <p 
                                className='table-head py-2 d-flex'>
                                Číslo záměru
                                {(sortKeys.projectId !== null && !isReset) &&
                                <img 
                                    className='ms-2' 
                                    src={sortKeys.projectId ? arrowUpBlue : arrowDownBlue} 
                                    alt='arrow' 
                                />}
                            </p>
                        </th>
                        <th 
                            className='py-2 px-3' 
                            style={{cursor: 'pointer'}}
                            onClick={() => handleClick('projectName', setSortKeys)}
                        >
                            <p 
                                className='table-head py-2 d-flex'>
                                Název záměru
                                {(sortKeys.projectName !== null && !isReset) &&
                                <img 
                                    className='ms-2' 
                                    src={sortKeys.projectName ? arrowUpBlue : arrowDownBlue} 
                                    alt='arrow' 
                                />}
                            </p>
                        </th>
                        <th 
                            className='py-2 px-3' 
                            style={{cursor: 'pointer'}}
                            onClick={() => handleClick('status', setSortKeys)}
                        >
                            <p 
                                className='table-head py-2 d-flex'>
                                Stav
                                {(sortKeys.status !== null && !isReset) &&
                                <img
                                    className='ms-2' 
                                    src={sortKeys.status ? arrowUpBlue : arrowDownBlue} 
                                    alt='arrow' 
                                />}
                            </p>
                        </th>
                        {/* <th 
                            className='py-2 px-3' 
                            style={{cursor: 'pointer'}}
                            onClick={() => handleClick('role', setSortKeys)}
                        >
                            <p 
                                className='table-head py-2 d-flex'>
                                Moje role
                                {(sortKeys.role !== null && !isReset) &&
                                <img
                                    className='ms-2' 
                                    src={sortKeys.role ? arrowUpBlue : arrowDownBlue} 
                                    alt='arrow' 
                                />}
                            </p>
                        </th> */}
                        {/* <th 
                            className='py-2 px-3' 
                            style={{cursor: 'pointer'}}
                            onClick={() => handleClick('date', setSortKeys)}
                        >
                            <p 
                                className='table-head py-2 d-flex'>
                                Poslední úprava
                                {sortKeys.date !== null &&
                                <img
                                    className='ms-2' 
                                    src={(sortKeys.date && !isReset) ? arrowUpBlue : arrowDownBlue} 
                                    alt='arrow' 
                                />}
                            </p>
                        </th> */}
                    </tr>
                </thead>
                <tbody>
                    {items.length > 0 && items.map((item, idx) => {
                        return (
                            <tr key={item.id ?? idx}>
                                <td className='py-2 px-3'>
                                    <p
                                        className='row-number py-2 text-truncate'
                                        title={item.procedureNumber}
                                    >
                                        {item.procedureNumber}
                                    </p>
                                </td>
                                <td className='py-2 px-3'>
                                    <p
                                        className='row-info py-2 text-truncate'
                                        title={item.projectNumber}
                                    >
                                        {item.projectNumber}
                                    </p>
                                </td>
                                <td className='py-2 px-3'>
                                    <p
                                        className='row-info py-2 text-truncate'
                                        title={item.projectName}
                                    >
                                        {item.projectName}
                                    </p>
                                </td>
                                <td className='py-2 px-3'>
                                    <p
                                        className='row-info py-2 text-truncate'
                                        title={item.status === 'ACTIVE' ? 'Běžící' : 
                                            item.status === 'COMPLETED' ? 'Dokončené' : 
                                                item.status === 'PAUSED' ? 'Přerušené' :  
                                                    ''}
                                    >
                                        {item.status === 'ACTIVE' ? 'Běžící' : 
                                            item.status === 'COMPLETED' ? 'Dokončené' : 
                                                item.status === 'PAUSED' ? 'Přerušené' :  
                                                    ''}
                                    </p>
                                </td>
                                {/* <td className='py-2 px-3'>
                                    <p
                                        className='row-info py-2 text-truncate'
                                        title={item.role === 'BUILDER_OR_REPRESENTATIVE' ? 'Žadatel nebo zástupce' :
                                            item.role === 'LAND_OR_LAW_OWNER' ? 'Vlastník pozemku' : 
                                                item.role === 'NEIGHBOUR' ? 'Soused' : 
                                                    item.role === 'OTHER' ? 'Ostatní' : 
                                                        ''}
                                    >
                                        {item.role === 'BUILDER_OR_REPRESENTATIVE' ? 'Žadatel nebo zástupce' :
                                            item.role === 'LAND_OR_LAW_OWNER' ? 'Vlastník pozemku' : 
                                                item.role === 'NEIGHBOUR' ? 'Soused' : 
                                                    item.role === 'OTHER' ? 'Ostatní' : 
                                                        ''}
                                    </p>
                                </td> */}
                                {/* <td className='py-2 px-3'>
                                    <p
                                        className='row-info py-2 text-truncate'
                                        title={handleTitleAndDate(item.date)}
                                    >
                                        {handleTitleAndDate(item.date)}
                                    </p>
                                </td> */}
                                {/* <td className='py-2 px-3'>
                                    <Link 
                                        // to={`/rizeni/${encodeURIComponent(item.procedureId)}`}
                                    >
                                        <GovButton className='m-0 p-0 button' type='outlined' variant={'primary'} size='m'>
                                            <Container className='d-flex justify-content-center align-items-center' style={{width: '100px'}}>
                                                Zobrazit detail
                                            </Container>
                                        </GovButton>
                                    </Link>
                                </td> */}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

RizeniTableDisplay.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        projectNumber: PropTypes.string,
        procedureNumber: PropTypes.string,
        procedurePID: PropTypes.string,
        projectId: PropTypes.string,
        projectPID: PropTypes.string,
        projectName: PropTypes.string,
        department: PropTypes.string,
        status: PropTypes.string,
        date: PropTypes.string,
    })),
    setPostBody: PropTypes.func,
    postBody: PropTypes.object,
};

export default RizeniTableDisplay;