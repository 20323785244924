import React, { useState, useEffect, useContext, useImperativeHandle } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../../css/povoleni.css';
import { canFulFillPrijemceList, getPrijemceList, shouldNotSave, spreadObject } from '../../helperFunctions/helpers';
import {dateValidation, validDateBeforeOrEquals} from '../../helperFunctions/validationHelpers.js';
import PropTypes from 'prop-types';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import FormContext from '../../formContexts/FormContext';
import { AUTHORITY_MODEL, BUILDING } from '../../constants/sharedConstants';
import SearchWithDataList from '../SearchWithDataList.jsx';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import useFirstRender from '../CustomHooks/useFirstRender.jsx';
import { errorIcon } from '../../assets/index.js';
import { useLocation, useNavigate } from 'react-router-dom';
import useDebounce from '../CustomHooks/useDebounce.jsx';

const PovoleniComponent09 = ({urlPath}) => {
    const {intention, stepValue, validationRef, setStagesArr, stagesArr, saveFormRef, id} = useContext(FormContext); 
    const { token } = useContext(AuthContext);
    
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const maxDate = new Date();
    const [isDateError, setIsDateError] = useState({
        authorityDate: false,
        authorityDateFuture: false,
    });
    const handleErrors = (errorElement, hasError) => {
        setIsDateError(prev => ({...prev, [errorElement]: hasError}));
    };
    const [ focused, setFocused ] = useState({
        permissionDated: false,
        permissionRefNumber: false

    });

    const [request, setRequest] = useState({
        'applicationId': id,
        buildIntention: {
            permissionRefNumber: '',
            permissionDated: '',
            verifiedDocumentationId: '',
            permissionAuthority: AUTHORITY_MODEL,
        }
    });
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [searchRequest, setSearchRequest] = useState(
        {
            title: '',
            typeTitle: null
        }
    );
    const [permAuthList, setPermAuthList] = useState([]);
    const firstRender = useFirstRender();
    const prevRequest = usePrevious(request);
    const buildIntention = request?.buildIntention;
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const debouncedSearchTitle = useDebounce(searchRequest.title);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (!canFulFillPrijemceList(request, firstRender, searchRequest, setPermAuthList, 'permissionAuthority')) {
            return;
        }

        setIsSearchLoading(true);
        getPrijemceList(searchRequest, token, setPermAuthList, setIsSearchLoading, signal);

        return () => {
            controller.abort();
        };
    }, [debouncedSearchTitle]);

    useEffect(() => {
        if (!id) {
            return;
        }
        const source = axios.CancelToken.source();
        (async () => {
            const response = await getFormById(id, token, source, intention, null, pathname, navigate);
            if ((200 <= response?.status) && (response?.status < 300)) {

                const intention = response?.data?.buildIntention;

                if (intention) {
                    setRequest(state => ({ ...state, buildIntention: spreadObject(intention) }));
                    
                    if (intention?.permissionAuthority && intention?.permissionAuthority?.authorityName) {
                        setSearchRequest(prev => ({...prev, title: intention?.permissionAuthority?.authorityName ?? '', typeTitle: BUILDING }));
                    }  
                }
            } else {
                console.log('ID NOT YET IN DB');
            }
        })();

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }

        saveChanges();
    }, [request.buildIntention]);

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    const hasError2 = (!buildIntention?.permissionRefNumber?.trim() && (error || focused.permissionRefNumber));
    const hasError3 = (!buildIntention?.permissionDated?.trim() && (error || focused.permissionDated));
    const hasError4 = (buildIntention?.permissionDated?.trim() && (!validDateBeforeOrEquals(buildIntention?.permissionDated,maxDate)));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;
 
            if (!buildIntention?.permissionRefNumber?.trim()
                || !buildIntention?.permissionDated?.trim()
                || !buildIntention?.permissionAuthority?.authorityName?.trim())
            {
                err = true;
            } else if (isDateError.authorityDate) {
                err = true;
            } else if(isDateError.authorityDateFuture){
                err = true;
            } else if(hasError3||hasError2||hasError4){
                err = true;
            }
            

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, };
                }
                return stg;
            }));

            return !err;
        }
    }));

    const handleFocused = (elementToFocus) => {
        setFocused(prev => ({...prev, [elementToFocus]: true}));
    };

    return (
        <Container className='' fluid>
            
            <Row className='row-wrapper'>
                <p className='p-0 step-title'>{`${stepValue}. Povolení`}</p>                    
            </Row>

            <Row className='mb-4'>
                <p className='p-0 povoleni-subtitle'>Povolení záměru určeného ke změně</p>
            </Row>
            <Row>
                <p className='p-0 mb-3 povoleni-label'>Stavební úřad, který povolení záměru vydal</p>
                <form className='p-0 d-flex flex-column'>
                    <label className='mb-4'>
                        <Col md={6}>
                            <Container fluid>
                                <SearchWithDataList
                                    request={request}
                                    authorityType={'permissionAuthority'}
                                    authorityObj={request.buildIntention.permissionAuthority}
                                    setRequest={setRequest}
                                    paramFirst={'title'}
                                    paramSecond={'typeTitle'}
                                    setSearchRequest={setSearchRequest}
                                    searchRequest={searchRequest} // obj
                                    listData={permAuthList}
                                    setListData={setPermAuthList} // arr
                                    dynamicVal={BUILDING} // str
                                    isSearchLoading={isSearchLoading}
                                    setIsSearchLoading={setIsSearchLoading}
                                />
                            </Container>
                        </Col>
                    </label>
                    <Col md={6}>
                        <label className='d-flex flex-column povoleni-label' style={{ color: hasError2 ? '#C52A3A' : '' }}>
                        Číslo jednací
                            <input 
                                type='text'
                                defaultValue={buildIntention.permissionRefNumber}
                                style={{ border: hasError2 ? 'solid 1px #C52A3A' : '' }}
                                max={new Date().toISOString().split('T')[0]}
                                onBlur={(e) => {
                                    setRequest(state => ({ ...state, buildIntention: {...state.buildIntention, permissionRefNumber: e.target.value } }));
                                    handleFocused('permissionRefNumber');
                                }}
                                {...(focused.permissionRefNumber && {focused: 'true'})}
                                className='povoleni-input bg-white p-2 mt-1'
                            />
                        </label>
                    </Col>
                    {hasError2 && <Col xs={{ span: 12}} md={{ span: 6}} className='mt-2'>
                        <p className='d-flex align-items-center p-0 warning-text'><img src={errorIcon} alt='warning' className='me-2' style={{ height: '1.1rem', width: '1.1rem' }} />Bez vyplnění tohoto pole není možné pokračovat.</p>
                    </Col>}
                    <Col md={6}>
                        <label className='mt-4 povoleni-label' style={{ color: (hasError3 || isDateError.authorityDate || isDateError.authorityDateFuture) ? '#C52A3A' : '' }}>
                            Ze dne
                            <input
                                type='date'
                                value={buildIntention?.permissionDated ?? ''}
                                style={{ border: (hasError3 || hasError4 || isDateError.authorityDate) ? 'solid 1px #C52A3A' : '' }}
                                max={maxDate.toISOString().split('T')[0]}
                                onChange={e => setRequest(state => ({ ...state, buildIntention: {...state.buildIntention, permissionDated: e.target.value } }))}
                                onBlur={(e) => {
                                    const validatedDate = dateValidation(e.target.value); 
                                    validatedDate && setRequest(state => ({ ...state, buildIntention: {...state.buildIntention, permissionDated: '' } }));
                                    setIsDateError(state => ({...state, authorityDate: validatedDate}));
                                    
                                    handleErrors('authorityDateFuture', !e.target.value.trim() || !validDateBeforeOrEquals(e.target.value, maxDate));
                                    handleFocused('permissionDated');
                                }}
                                className='povoleni-input bg-white p-2 mt-1'
                            />
                        </label>
                    </Col>
                    {hasError3 && !isDateError.authorityDate && <Col xs={{ span: 12}} md={{ span: 6}} className='mt-2'>
                        <p className='d-flex align-items-center p-0 warning-text'><img src={errorIcon} alt='warning' className='me-2' style={{ height: '1.1rem', width: '1.1rem' }} />Bez vyplnění pole &quot;Ze dne&quot; není možné pokračovat.</p>
                    </Col>}
                    <Col md={6}>
                        {
                            buildIntention?.permissionDated?.trim()
                            &&
                            !validDateBeforeOrEquals(buildIntention?.permissionDated, maxDate)
                            &&
                            <Container className="mt-md-2 ps-3 ps-md-0" style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                Žádost nelze odeslat. &quot;Ze dne&quot; nesmí být v budoucnosti. Prosím opravte pole &quot;Ze dne&quot;.
                            </Container>
                        }
                    </Col>
                    {isDateError.authorityDate && <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                        <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                            Nesprávný formát vyplnění, zkuste to znovu.
                    </Container>}
                    <Col md={6} className='mt-4'>
                        <label className='povoleni-label p-0'>
                            ID ověřené dokumentace k povolenému záměru  
                        </label>
                    </Col>
                    <Row>
                        <Col md={6}>
                            <input 
                                type='text'
                                defaultValue={buildIntention.verifiedDocumentationId}
                                onBlur={(e) => {
                                    setRequest(state => ({ ...state, buildIntention: {...state.buildIntention, verifiedDocumentationId: e.target.value } }));
                                }}
                                className='povoleni-input bg-white p-2 mt-1'
                            />
                        </Col>
                        <Col md={6}>
                            <p className='ps-md-3 mt-1 mt-md-0 povoleni-help-text'>(podle vyhlášky o dokumentaci staveb) vložené do evidence elektronických dokumentací</p>
                        </Col>
                    </Row>
                </form>
            </Row>
        </Container>
    );
};

PovoleniComponent09.propTypes = {
    urlPath: PropTypes.string.isRequired,
};

export default PovoleniComponent09;
