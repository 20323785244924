import React, { useContext, useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import '../../css/kontrolaComponent.css';
import { file } from '../../assets/index';
import axios from 'axios';
import urlJoin from 'url-join';
import apiConfig from '../../config/api-config';
import { AuthContext } from 'react-oauth2-code-pkce';
import FormContext from '../../formContexts/FormContext.js';
import { FileUpload, UploadedAttachments } from '../../components/index.js';
import { extractExtensionName, extractFileName, getUrl, shouldNotSave, spreadUploadedDocuments } from '../../helperFunctions/helpers.js';
import { formSave, getFormById } from '../../apiCalls/formApiCalls.js';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import useFirstRender from '../CustomHooks/useFirstRender.jsx';
import { DOCUMENT_FIRST } from '../../constants/sharedConstants.js';
import { pdfjs } from 'react-pdf';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const KontrolaComponent18 = ({ 
    progressElements, 
    setProgressElements, 
    urlPath,
    receivedToken,
}) => {
    const { token } = useContext(AuthContext);
    const {intention, stepValue, id} = useContext(FormContext); 
    const [request, setRequest] = useState(
        {
            'applicationId': id,
            'buildApplication': {
                'documentations': []
            },
        }
    );

    const prevRequest = usePrevious(request);
    const firstRender = useFirstRender();
    const { setUploadedBytes, setUploadError, uploadError } = useContext(FormContext); 
    const [documents, setDocuments] = useState({
        [DOCUMENT_FIRST]: [],
    });

    const allDocuments = Object.values(documents).flat();
    const documentsToTrack = [...allDocuments].filter(el => !el.flagged && el.blobFile);
    const documentsToUpdate = [...allDocuments].filter(fileObj => !Object.hasOwn(fileObj, 'blobFile'));
    const existingFilesToUpdate = documentsToUpdate.filter(el => el.existing);
    const newFilesToUpdate = documentsToUpdate.filter(el => !el.existing);
    
    useEffect(() => {
        if (allDocuments.length === 0) {
            return;
        }
        
        setProgressElements(documentsToTrack.map(el => ({...el, progress: null})));

    }, [documentsToTrack.length]);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source, receivedToken);
    };

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (id) {
            (async () => {
                const response = await getFormById(id, token, source, intention, receivedToken, pathname, navigate);

                if ((200 <= response?.status) && (response?.status < 300)) {
                    const loadedData = response.data?.buildApplication;
                    const documents = loadedData?.documentations;

                    if (documents instanceof Array && documents.length > 0) {
                        spreadUploadedDocuments(documents, setDocuments, undefined, setUploadedBytes, receivedToken);
                    }     
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        } else {
            console.log('NON EXISTENT ID');
        }
    }, []);

    useEffect(() => {
        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }
        saveChanges();
    }, [request.buildApplication.documentations]);

    useEffect(() => {
        if (newFilesToUpdate.length >= 50000) {
            setUploadError(prev => [...prev, {
                where: null,
                why: 'total_amount',
                uid: null,
            }]);   
            return;
        } else if (newFilesToUpdate.length < 50000 && uploadError.find(el => el.why === 'total_amount')) {
            setUploadError(prev => [...prev].filter(el => el.why !== 'total_amount')); 
        }

        setRequest(prev => ({ ...prev, buildApplication: { ...prev.buildApplication, documentations: [...documentsToUpdate].map(file => {
            // eslint-disable-next-line no-unused-vars
            const {flagged, existing, ...rest} = file;
            return {...rest};
        }) } }));
    }, [existingFilesToUpdate.length, newFilesToUpdate.length]);

    const createPdf = async () => {
        try {
            const source = axios.CancelToken.source();

            const response = await axios.get(
                urlJoin(apiConfig.validateAndPersistBaseUrl, `preview-pdf/accompanying/${id}`),
                {
                    responseType: 'blob',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    cancelToken: source.token,
                });

            if ((200 <= response?.status) && (response?.status < 300)) {
                console.log('CALL GET PDF', response);
                window.open(URL.createObjectURL(response.data));
            }

        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.log('Error', error);
            }
        }
    };

    const checkIfPruvodniList = async (file) => { // bool
        try {
            const pdfDoc = pdfjs.getDocument({ data: await file.arrayBuffer() });
            const pdf = await pdfDoc.promise;
            const firstPage = await pdf.getPage(1);
            const textContent = await firstPage.getTextContent();
            const pageText = textContent.items.map(item => item.str).join(' ');            
            return pageText.includes('PRŮVODNÍ LIST DOKUMENTACE');  
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    const handleFileInput = async (files, category) => {
        if (category !== DOCUMENT_FIRST) {
            return;
        }

        if (uploadError.find(el => el.why === 'total_amount')) {
            return;
        }
        
        const uploadFile = async (file) => {
            const extractedFileName = extractFileName(file.name);
            const size = file.size;
            const { fileName, uid, url, isDeletable } = await getUrl(token);
            const isCoverLetter = await checkIfPruvodniList(file);
            const fileToUpload = {
                uid,
                fileName: fileName || extractedFileName,
                url,
                section: category,
                subSection: (isCoverLetter || /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g.test(extractedFileName)) ? 'cover_letter_pdf' : '',
                size,
                blobFile: file,
                message: file.message ?? undefined,
                isDeletable,
            };
            spreadUploadedDocuments(fileToUpload, setDocuments, category, undefined);
        };

        for (const file of files) {
            await uploadFile(file);
        }
    };

    return (
        <Container fluid>
            <Row className='row-wrapper'>
                <h2 className='p-0 main-title'>{`${stepValue}. Kontrola a podání`}</h2>
                {/* <p className='p-0 kontrola-subtitle'>Stáhněte a zkontrolujte svou žádost.</p> */}
            </Row>
            <Row className='row-wrapper'>
                <p className='p-0 mb-4 kontrola-section__title'>1. Stáhněte, zkontrolujte a digitálně podepište průvodní list</p>
                <button type='button' className='d-flex justify-content-center align-items-center kontrola-button-proj' onClick={createPdf}><img src={file} alt="file" className='kontrola-button-icon' />Stáhnout průvodní list dokumentace</button>
            </Row>

            <Row>
                <p className='p-0 mb-4 kontrola-section__title'>2. Nahrajte podepsaný dokument</p>
                <Row>
                    <Row>
                        <UploadedAttachments
                            category={DOCUMENT_FIRST}
                            attachments={documents[DOCUMENT_FIRST].filter((file, index, self) => {
                                const latestXmlIndex = self.findLastIndex(el => extractExtensionName(el.fileName) === 'xml');
                                const isXml = extractExtensionName(file.fileName) === 'xml';                
                                if (isXml) {
                                    return index === latestXmlIndex;
                                }
                                return true;
                            })}
                            setUploads={setDocuments} 
                            progressElements={progressElements}
                            setProgressElements={setProgressElements}
                            receivedToken={receivedToken}  
                        />
                    </Row>
                    <FileUpload handleFileInput={handleFileInput} category={DOCUMENT_FIRST} />
                </Row>
            </Row>
        </Container>
    );
};

KontrolaComponent18.propTypes = {
    progressElements: PropTypes.arrayOf(Object),
    setProgressElements: PropTypes.func,
    urlPath: PropTypes.string,
    receivedToken: PropTypes.string,
};

export default KontrolaComponent18;