import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { RizeniDetailPodani} from '../index.js';
import '../../css/rizeni.css';
//import { alarm } from '../../assets';
import PropTypes from 'prop-types';

const RizeniDetailMainSection = ({rizeniData}) => {
    const rizeniDetail = rizeniData.rizeniDetail || null;
    //const rizeniZadosti = rizeniData.rizeniZadosti || null;
    //const rizeniHistory = rizeniData?.rizeniDetail?.history || null;

    return (
        <Container className='d-flex flex-column align-items-center rizeni-detail-main-container'>
            <Row className='d-flex flex-column flex-xl-row base-width'>
                <Col className='d-flex flex-column flex-md-row flex-xl-column justify-content-center justify-content-md-around justify-content-xl-start align-items-center align-items-md-start mb-3 mb-xl-0'>
                    {rizeniDetail && ( 
                        <>
                            {<Row className='d-flex flex-column rizeni-podani'>
                                <p className='p-0 mb-3 rizeni-detail-title'>Řízení</p>
                                <RizeniDetailPodani item={rizeniDetail} />
                            </Row>}
                            {/*<Row className='d-flex flex-column rizeni-zadosti'>*/}
                            {/*    <p className='p-0 mb-3 rizeni-detail-title'>Žádosti</p>*/}
                            {/*    {rizeniZadosti && rizeniZadosti.map((item, index) => (*/}
                            {/*        <RizeniDetailZadost item={item} key={index} />*/}
                            {/*    ))}*/}
                            {/*</Row>*/}
                        </>
                    )} 
                </Col>
                {/*<Col className='d-flex flex-column align-items-center align-items-xl-start'>*/}
                {/*    <p className='d-flex p-0 mb-3 rizeni-detail-title'><img src={alarm} alt='alarm' className='pe-2' />Historie</p>*/}
                {/*    {rizeniHistory && <RizeniDetailHistory history={rizeniHistory} />}*/}
                {/*</Col>*/}
            </Row>
        </Container>
    );
};

RizeniDetailMainSection.propTypes = {
    rizeniData: PropTypes.shape({
        rizeniDetail: PropTypes.shape({
            authority: PropTypes.object,
            projectId: PropTypes.string,
            projectName: PropTypes.string,
            referanceId: PropTypes.string,
            status: PropTypes.string,
            history: PropTypes.arrayOf(PropTypes.object),
        }),
    })
};

export default RizeniDetailMainSection;
