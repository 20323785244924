// FORM INPUTS

const BASIC_INFO =  [
    {
        id: 1,
        label: 'Jméno',
        type: 'text',
        refer: 'basicInfo-name',
        info: 'Uveďte všechna jména',
        required: true,
        state: 'names',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 2,
        label: 'Příjmení',
        type: 'text',
        refer: 'basicInfo-surname',
        required: true,
        state: 'lastName',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 3,
        label: 'Rodné příjmení (nepovinné)',
        type: 'text',
        refer: 'basicInfo-birthSurname',
        info: 'Vyplňte pouze v případě, že rodné příjmení je odlišné od příjmení',
        required: false,
        state: 'lastNameOrigin',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 4,
        label: 'Datum narození',
        type: 'date',
        refer: 'basicInfo-date',
        required: true,
        state: 'dateOfBirth',
        object: 'form',
        propertyToUpdate: 'applicant'
    }
];

const BASIC_INFO_BUSINESS =  [
    {
        id: 1,
        label: 'IČO',
        type: 'text',
        refer: 'basicInfoBusiness-ico',
        info: 'Uveďte identifikační číslo osoby (IČO), bylo-li přiděleno.',
        required: true,
        state: 'ico',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 2,
        label: 'Jméno',
        type: 'text',
        refer: 'basicInfoBusiness-name',
        info: 'Uveďte všechna jména',
        required: true,
        state: 'names',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 3,
        label: 'Příjmení',
        type: 'text',
        refer: 'basicInfoBusiness-surname',
        required: true,
        state: 'lastName',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 4,
        label: 'Rodné příjmení (nepovinné)',
        type: 'text',
        refer: 'basicInfoBusiness-birthSurname',
        info: 'Vyplňte pouze v případě, že rodné příjmení je odlišné od příjmení',
        required: false,
        state: 'lastNameOrigin',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 5,
        label: 'Datum narození',
        type: 'date',
        refer: 'basicInfoBusiness-date',
        required: true,
        state: 'dateOfBirth',
        object: 'form',
        propertyToUpdate: 'applicant'
    }
];
const BASIC_INFO_BUSINESS_APPENDIX =  [
    {
        id: 5,
        label: 'Dodatek odlišující osobu podnikatele nebo druh podnikání vztahující se k této osobě nebo jí provozovanému druhu podnikání (nepovinné)',
        type: 'text',
        refer: 'basicInfoBusiness-appendix',
        required: false,
        state: 'appendix',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
];

const BASIC_INFO_PO =  [
    {
        id: 1,
        label: 'IČO',
        info: 'Uveďte identifikační číslo osoby (IČO), bylo-li přiděleno.',
        type: 'text',
        refer: 'basicInfoPO-ico',
        required: true,
        state: 'ico',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 2,
        label: 'Název nebo obchodní firma',
        type: 'text',
        refer: 'basicInfoPO-firm',
        required: true,
        state: 'poName',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
];

const ATTORNEY_BASIC_INFO =  [
    {
        id: 1,
        label: 'Jméno',
        type: 'text',
        refer: 'attorneyBasicInfo-name',
        info: 'Uveďte všechna jména',
        required: true,
        state: 'names',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
    {
        id: 2,
        label: 'Příjmení',
        type: 'text',
        refer: 'attorneyBasicInfo-surname',
        required: true,
        state: 'lastName',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
    {
        id: 3,
        label: 'Rodné příjmení (nepovinné)',
        type: 'text',
        refer: 'attorneyBasicInfo-birthSurname',
        info: 'Vyplňte pouze v případě, že rodné příjmení je odlišné od příjmení',
        required: false,
        state: 'lastNameOrigin',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
    {
        id: 4,
        label: 'Datum narození',
        type: 'date',
        refer: 'attorneyBasicInfo-date',
        required: true,
        state: 'dateOfBirth',
        object: 'form',
        propertyToUpdate: 'attorney'
    }
];

const ATTORNEY_BASIC_INFO_BUSINESS =  [
    {
        id: 1,
        label: 'IČO',
        type: 'text',
        refer: 'attorneyBasicInfoBusiness-ico',
        info: 'Uveďte identifikační číslo osoby (IČO), bylo-li přiděleno.',
        required: true,
        state: 'ico',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
    {
        id: 2,
        label: 'Jméno',
        type: 'text',
        refer: 'attorneyBasicInfoBusiness-name',
        info: 'Uveďte všechna jména',
        required: true,
        state: 'names',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
    {
        id: 3,
        label: 'Příjmení',
        type: 'text',
        refer: 'attorneyBasicInfoBusiness-surname',
        required: true,
        state: 'lastName',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
    {
        id: 4,
        label: 'Rodné příjmení (nepovinné)',
        type: 'text',
        refer: 'attorneyBasicInfoBusiness-birthSurname',
        info: 'Vyplňte pouze v případě, že rodné příjmení je odlišné od příjmení',
        required: false,
        state: 'lastNameOrigin',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
    {
        id: 5,
        label: 'Datum narození',
        type: 'date',
        refer: 'attorneyBasicInfoBusiness-date',
        required: true,
        state: 'dateOfBirth',
        object: 'form',
        propertyToUpdate: 'attorney'
    }
];
const ATTORNEY_BASIC_INFO_BUSINESS_APPENDIX =  [
    {
        id: 5,
        label: 'Dodatek odlišující osobu podnikatele nebo druh podnikání vztahující se k této osobě nebo jí provozovanému druhu podnikání (nepovinné)',
        type: 'text',
        refer: 'attorneyBasicInfoBusiness-appendix',
        required: false,
        state: 'appendix',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
];

const ATTORNEY_BASIC_INFO_PO =  [
    {
        id: 1,
        label: 'IČO',
        type: 'text',
        refer: 'attorneyBasicInfoPO-ico',
        required: true,
        state: 'ico',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
    {
        id: 2,
        label: 'Název nebo obchodní firma',
        type: 'text',
        refer: 'attorneyBasicInfoPO-firm',
        required: true,
        state: 'poName',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
];

const PERSON_AUTHORIZED =  [
    {
        id: 1,
        label: 'Jméno',
        type: 'text',
        refer: 'personAuthorized-name',
        info: 'Uveďte všechna jména',
        required: true,
        state: 'names',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 2,
        label: 'Příjmení',
        type: 'text',
        refer: 'personAuthorized-surname',
        required: true,
        state: 'lastName',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 3,
        label: 'Rodné příjmení (nepovinné)',
        type: 'text',
        refer: 'personAuthorized-birthSurname',
        required: false,
        state: 'lastNameOrigin',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 4,
        label: 'Datum narození',
        type: 'date',
        refer: 'personAuthorized-date',
        required: true,
        state: 'dateOfBirth',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 5,
        label: 'Státní příslušnost',
        type: 'select',
        refer: 'personAuthorized-nationality',
        required: true,
        state: 'nationality',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 6,
        label: 'Funkce nebo oprávnění',
        marker: 'PO',
        info: 'Doložte příslušný doklad o oprávnění v případě, že oprávnění jednat jménem právnické osoby není možné ověřit z veřejných rejstříků.',
        type: 'text',
        refer: 'personAuthorized-function',
        required: true,
        state: 'mandate',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 6,
        label: 'Funkce nebo oprávnění',
        marker: 'FOP',
        info: 'Doložte příslušný doklad o oprávnění v případě, že oprávnění jednat jménem fyzické osoby podnikající není možné ověřit z veřejných rejstříků.',
        type: 'text',
        refer: 'personAuthorized-function',
        required: true,
        state: 'mandate',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 7,
        label: 'Oprávnění jednat od',
        type: 'date',
        refer: 'personAuthorized-from',
        required: true,
        state: 'mandateFrom',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 8,
        label: 'Oprávnění jednat do',
        type: 'date',
        refer: 'personAuthorized-to',
        required: false,
        state: 'mandateTO',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
];

const ATTORNEY_PERSON_AUTHORIZED =  [
    {
        id: 1,
        label: 'Jméno',
        type: 'text',
        refer: 'attorneyPersonAuthorized-name',
        info: 'Uveďte všechna jména',
        required: true,
        state: 'names',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
    {
        id: 2,
        label: 'Příjmení',
        type: 'text',
        refer: 'attorneyPersonAuthorized-surname',
        required: true,
        state: 'lastName',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
    {
        id: 3,
        label: 'Rodné příjmení (nepovinné)',
        type: 'text',
        refer: 'attorneyPersonAuthorized-birthSurname',
        required: false,
        state: 'lastNameOrigin',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
    {
        id: 4,
        label: 'Datum narození',
        type: 'date',
        refer: 'attorneyPersonAuthorized-date',
        required: true,
        state: 'dateOfBirth',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
    {
        id: 5,
        label: 'Státní příslušnost',
        type: 'select',
        refer: 'attorneyPersonAuthorized-nationality',
        required: true,
        state: 'nationality',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
    {
        id: 6,
        label: 'Funkce nebo oprávnění',
        marker: 'PO',
        info: 'Doložte příslušný doklad o oprávnění v případě, že oprávnění jednat jménem právnické osoby není možné ověřit z veřejných rejstříků.',
        type: 'text',
        refer: 'attorneyPersonAuthorized-function',
        required: true,
        state: 'mandate',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
    {
        id: 6,
        label: 'Funkce nebo oprávnění',
        marker: 'FOP',
        info: 'Doložte příslušný doklad o oprávnění v případě, že oprávnění jednat jménem fyzické osoby podnikající není možné ověřit z veřejných rejstříků.',
        type: 'text',
        refer: 'attorneyPersonAuthorized-function',
        required: true,
        state: 'mandate',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
    {
        id: 7,
        label: 'Oprávnění jednat od',
        type: 'date',
        refer: 'attorneyPersonAuthorized-from',
        required: true,
        state: 'mandateFrom',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
    {
        id: 8,
        label: 'Oprávnění jednat do',
        type: 'date',
        refer: 'attorneyPersonAuthorized-to',
        required: false,
        state: 'mandateTO',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
];
const REPRE_PERSON_AUTHORIZED =  [
    {
        id: 1,
        label: 'Jméno',
        type: 'text',
        refer: 'personAuthorized-name',
        info: 'Uveďte všechna jména',
        required: true,
        state: 'repreNames',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 2,
        label: 'Příjmení',
        type: 'text',
        refer: 'personAuthorized-surname',
        required: true,
        state: 'repreLastName',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 3,
        label: 'Rodné příjmení (nepovinné)',
        type: 'text',
        refer: 'personAuthorized-birthSurname',
        required: false,
        state: 'repreLastNameOrigin',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 4,
        label: 'Datum narození',
        type: 'date',
        refer: 'personAuthorized-date',
        required: true,
        state: 'repreDateOfBirth',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 5,
        label: 'Státní příslušnost',
        type: 'select',
        refer: 'personAuthorized-nationality',
        required: true,
        state: 'repreNationality',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 6,
        label: 'Funkce nebo oprávnění',
        marker: 'PO',
        info: 'Doložte příslušný doklad o oprávnění v případě, že oprávnění jednat jménem právnické osoby není možné ověřit z veřejných rejstříků.',
        type: 'text',
        refer: 'attorneyPersonAuthorized-function',
        required: true,
        state: 'mandate',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
    {
        id: 6,
        label: 'Funkce nebo oprávnění',
        marker: 'FOP',
        info: 'Doložte příslušný doklad o oprávnění v případě, že oprávnění jednat jménem fyzické osoby podnikající není možné ověřit z veřejných rejstříků.',
        type: 'text',
        refer: 'attorneyPersonAuthorized-function',
        required: true,
        state: 'mandate',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
    {
        id: 7,
        label: 'Oprávnění jednat od',
        type: 'date',
        refer: 'personAuthorized-from',
        required: true,
        state: 'mandateFrom',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 8,
        label: 'Oprávnění jednat do',
        type: 'date',
        refer: 'personAuthorized-to',
        required: false,
        state: 'mandateTO',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
];
const REPRE_ATTORNEY_PERSON_AUTHORIZED =  [
    {
        id: 1,
        label: 'Jméno',
        type: 'text',
        refer: 'personAuthorized-name',
        info: 'Uveďte všechna jména',
        required: true,
        state: 'repreNames',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
    {
        id: 2,
        label: 'Příjmení',
        type: 'text',
        refer: 'personAuthorized-surname',
        required: true,
        state: 'repreLastName',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
    {
        id: 3,
        label: 'Rodné příjmení (nepovinné)',
        type: 'text',
        refer: 'personAuthorized-birthSurname',
        required: false,
        state: 'repreLastNameOrigin',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
    {
        id: 4,
        label: 'Datum narození',
        type: 'date',
        refer: 'personAuthorized-date',
        required: true,
        state: 'repreDateOfBirth',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
    {
        id: 5,
        label: 'Státní příslušnost',
        type: 'select',
        refer: 'personAuthorized-nationality',
        required: true,
        state: 'repreNationality',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
    {
        id: 6,
        label: 'Funkce nebo oprávnění',
        marker: 'PO',
        info: 'Doložte příslušný doklad o oprávnění v případě, že oprávnění jednat jménem právnické osoby není možné ověřit z veřejných rejstříků.',
        type: 'text',
        refer: 'attorneyPersonAuthorized-function',
        required: true,
        state: 'mandate',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
    {
        id: 6,
        label: 'Funkce nebo oprávnění',
        marker: 'FOP',
        info: 'Doložte příslušný doklad o oprávnění v případě, že oprávnění jednat jménem fyzické osoby podnikající není možné ověřit z veřejných rejstříků.',
        type: 'text',
        refer: 'attorneyPersonAuthorized-function',
        required: true,
        state: 'mandate',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
    {
        id: 7,
        label: 'Oprávnění jednat od',
        type: 'date',
        refer: 'personAuthorized-from',
        required: true,
        state: 'mandateFrom',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
    {
        id: 8,
        label: 'Oprávnění jednat do',
        type: 'date',
        refer: 'personAuthorized-to',
        required: false,
        state: 'mandateTO',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
];

const LOCATION = [
    {
        id: 1,
        label: 'Obec',
        type: 'text',
        refer: 'location-city',
        required: true,
        state: 'city',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'address'
    },
    {
        id: 2,
        label: 'Část obce (nepovinné)',
        type: 'text',
        refer: 'location-cityPart',
        required: false,
        state: 'cityPart',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'address'

    },
    {
        id: 3,
        label: 'Ulice',
        type: 'text',
        refer: 'location-street',
        required: false,
        state: 'street',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'address'
    },
    {
        id: 4,
        label: 'Číslo popisné',
        type: 'text',
        refer: 'location-descriptiveNumber',
        info: 'Pokud je místo čísla popisného přiděleno číslo evidenční, uveďte před číslem písmeno E.',
        required: false,
        state: 'descNum',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'address'
    },
    {
        id: 5,
        label: 'Číslo orientační (nepovinné)',
        type: 'text',
        refer: 'location-orientationNumber',
        required: false,
        state: 'orientNum',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'address'
    },
    {
        id: 6,
        label: 'PSČ',
        type: 'text',
        refer: 'location-zipCode',
        required: true,
        state: 'zip',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'address'
    },
];

const LOCATION_BUSINESS = [
    {
        id: 1,
        label: 'Obec',
        info: 'Uveďte adresu zapsanou v obchodním rejstříku nebo jiné zákonem upravené evidenci jako místo podnikání (sídlo).',
        type: 'text',
        refer: 'location-city',
        required: true,
        state: 'city',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'address'
    },
    {
        id: 2,
        label: 'Část obce',
        type: 'text',
        refer: 'location-cityPart',
        required: false,
        state: 'cityPart',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'address'

    },
    {
        id: 3,
        label: 'Ulice',
        type: 'text',
        refer: 'location-street',
        required: false,
        state: 'street',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'address'
    },
    {
        id: 4,
        label: 'Číslo popisné',
        type: 'text',
        refer: 'location-descriptiveNumber',
        info: 'Pokud je místo čísla popisného přiděleno číslo evidenční, uveďte před číslem písmeno E.',
        required: false,
        state: 'descNum',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'address'
    },
    {
        id: 5,
        label: 'Číslo orientační (nepovinné)',
        type: 'text',
        refer: 'location-orientationNumber',
        required: false,
        state: 'orientNum',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'address'
    },
    {
        id: 6,
        label: 'PSČ',
        type: 'text',
        refer: 'location-zipCode',
        required: true,
        state: 'zip',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'address'
    },
];

const DELIVERY_LOCATION = [
    {
        id: 1,
        label: 'Obec',
        type: 'text',
        refer: 'deliveryLocation-city',
        required: true,
        state: 'city',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'shippingAddress'
    },
    {
        id: 2,
        label: 'Část obce (nepovinné)',
        type: 'text',
        refer: 'deliveryLocation-cityPart',
        required: false,
        state: 'cityPart',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'shippingAddress'
    },
    {
        id: 3,
        label: 'Ulice',
        type: 'text',
        refer: 'deliveryLocation-street',
        required: false,
        state: 'street',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'shippingAddress'
    },
    {
        id: 4,
        label: 'Číslo popisné',
        type: 'text',
        refer: 'deliveryLocation-descriptiveNumber',
        info: 'Pokud je místo čísla popisného přiděleno číslo evidenční, uveďte před číslem písmeno E.',
        required: false,
        state: 'descNum',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'shippingAddress'
    },
    {
        id: 5,
        label: 'Číslo orientační (nepovinné)',
        type: 'text',
        refer: 'deliveryLocation-orientationNumber',
        required: false,
        state: 'orientNum',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'shippingAddress'
    },
    {
        id: 6,
        label: 'PSČ',
        type: 'text',
        refer: 'deliveryLocation-zipCode',
        required: true,
        state: 'zip',
        object: 'form',
        propertyToUpdate: 'applicant',
        parentProperty: 'shippingAddress'
    },
];

const CONTACTS = [
    {
        id: 1,
        label: 'Telefon (nepovinné)',
        type: 'text',
        refer: 'contacts-tel',
        required: false,
        state: 'phone',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 2,
        label: 'E-mail (nepovinné)',
        type: 'text',
        refer: 'contacts-email',
        required: false,
        state: 'email',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
    {
        id: 3,
        label: 'ID datové schránky (nepovinné)',
        type: 'text',
        refer: 'contacts-idDataBox',
        required: false,
        state: 'idDataBox',
        object: 'form',
        propertyToUpdate: 'applicant'
    },
];

const ATTORNEY_LOCATION = [
    {
        id: 1,
        label: 'Obec',
        type: 'text',
        refer: 'attorneyLocation-city',
        required: true,
        state: 'city',
        object: 'form',
        propertyToUpdate: 'attorney',
        parentProperty: 'address'
    },
    {
        id: 2,
        label: 'Část obce (nepovinné)',
        type: 'text',
        refer: 'attorneyLocation-cityPart',
        required: false,
        state: 'cityPart',
        object: 'form',
        propertyToUpdate: 'attorney',
        parentProperty: 'address'

    },
    {
        id: 3,
        label: 'Ulice',
        type: 'text',
        refer: 'attorneyLocation-street',
        required: false,
        state: 'street',
        object: 'form',
        propertyToUpdate: 'attorney',
        parentProperty: 'address'
    },
    {
        id: 4,
        label: 'Číslo popisné',
        type: 'text',
        refer: 'attorneyLocation-descriptiveNumber',
        info: 'Pokud je místo čísla popisného přiděleno číslo evidenční, uveďte před číslem písmeno E.',
        required: false,
        state: 'descNum',
        object: 'form',
        propertyToUpdate: 'attorney',
        parentProperty: 'address'
    },
    {
        id: 5,
        label: 'Číslo orientační (nepovinné)',
        type: 'text',
        refer: 'attorneyLocation-orientationNumber',
        required: false,
        state: 'orientNum',
        object: 'form',
        propertyToUpdate: 'attorney',
        parentProperty: 'address'
    },
    {
        id: 6,
        label: 'PSČ',
        type: 'text',
        refer: 'attorneyLocation-zipCode',
        required: true,
        state: 'zip',
        object: 'form',
        propertyToUpdate: 'attorney',
        parentProperty: 'address'
    },
];

const ATTORNEY_LOCATION_BUSINESS = [
    {
        id: 1,
        label: 'Obec',
        info: 'Uveďte adresu zapsanou v obchodním rejstříku nebo jiné zákonem upravené evidenci jako místo podnikání (sídlo).',
        type: 'text',
        refer: 'attorneyLocation-city',
        required: true,
        state: 'city',
        object: 'form',
        propertyToUpdate: 'attorney',
        parentProperty: 'address'
    },
    {
        id: 2,
        label: 'Část obce',
        type: 'text',
        refer: 'attorneyLocation-cityPart',
        required: false,
        state: 'cityPart',
        object: 'form',
        propertyToUpdate: 'attorney',
        parentProperty: 'address'

    },
    {
        id: 3,
        label: 'Ulice',
        type: 'text',
        refer: 'attorneyLocation-street',
        required: false,
        state: 'street',
        object: 'form',
        propertyToUpdate: 'attorney',
        parentProperty: 'address'
    },
    {
        id: 4,
        label: 'Číslo popisné',
        type: 'text',
        refer: 'attorneyLocation-descriptiveNumber',
        info: 'Pokud je místo čísla popisného přiděleno číslo evidenční, uveďte před číslem písmeno E.',
        required: false,
        state: 'descNum',
        object: 'form',
        propertyToUpdate: 'attorney',
        parentProperty: 'address'
    },
    {
        id: 5,
        label: 'Číslo orientační (nepovinné)',
        type: 'text',
        refer: 'attorneyLocation-orientationNumber',
        required: false,
        state: 'orientNum',
        object: 'form',
        propertyToUpdate: 'attorney',
        parentProperty: 'address'
    },
    {
        id: 6,
        label: 'PSČ',
        type: 'text',
        refer: 'attorneyLocation-zipCode',
        required: true,
        state: 'zip',
        object: 'form',
        propertyToUpdate: 'attorney',
        parentProperty: 'address'
    },
];

const ATTORNEY_DELIVERY_LOCATION = [
    {
        id: 1,
        label: 'Obec',
        type: 'text',
        refer: 'attorneyDeliveryLocation-city',
        required: true,
        state: 'city',
        object: 'form',
        propertyToUpdate: 'attorney',
        parentProperty: 'shippingAddress'
    },
    {
        id: 2,
        label: 'Část obce (nepovinné)',
        type: 'text',
        refer: 'attorneyDeliveryLocation-cityPart',
        required: false,
        state: 'cityPart',
        object: 'form',
        propertyToUpdate: 'attorney',
        parentProperty: 'shippingAddress'
    },
    {
        id: 3,
        label: 'Ulice',
        type: 'text',
        refer: 'attorneyDeliveryLocation-street',
        required: false,
        state: 'street',
        object: 'form',
        propertyToUpdate: 'attorney',
        parentProperty: 'shippingAddress'
    },
    {
        id: 4,
        label: 'Číslo popisné',
        type: 'text',
        refer: 'attorneyDeliveryLocation-descriptiveNumber',
        info: 'Pokud je místo čísla popisného přiděleno číslo evidenční, uveďte před číslem písmeno E.',
        required: false,
        state: 'descNum',
        object: 'form',
        propertyToUpdate: 'attorney',
        parentProperty: 'shippingAddress'
    },
    {
        id: 5,
        label: 'Číslo orientační (nepovinné)',
        type: 'text',
        refer: 'attorneyDeliveryLocation-orientationNumber',
        required: false,
        state: 'orientNum',
        object: 'form',
        propertyToUpdate: 'attorney',
        parentProperty: 'shippingAddress'
    },
    {
        id: 6,
        label: 'PSČ',
        type: 'text',
        refer: 'attorneyDeliveryLocation-zipCode',
        required: true,
        state: 'zip',
        object: 'form',
        propertyToUpdate: 'attorney',
        parentProperty: 'shippingAddress'
    },
];

const ATTORNEY_CONTACTS = [
    {
        id: 1,
        label: 'Telefon (nepovinné)',
        type: 'text',
        refer: 'attorneyContacts-tel',
        required: false,
        state: 'phone',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
    {
        id: 2,
        label: 'E-mail (nepovinné)',
        type: 'text',
        refer: 'attorneyContacts-email',
        required: false,
        state: 'email',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
    {
        id: 3,
        label: 'ID datové schránky (nepovinné)',
        type: 'text',
        refer: 'attorneyContacts-idDataBox',
        required: false,
        state: 'idDataBox',
        object: 'form',
        propertyToUpdate: 'attorney'
    },
];

export {
    BASIC_INFO,
    BASIC_INFO_BUSINESS,
    BASIC_INFO_BUSINESS_APPENDIX,
    BASIC_INFO_PO,
    ATTORNEY_BASIC_INFO,
    ATTORNEY_BASIC_INFO_BUSINESS,
    ATTORNEY_BASIC_INFO_BUSINESS_APPENDIX,
    ATTORNEY_BASIC_INFO_PO,
    PERSON_AUTHORIZED,
    ATTORNEY_PERSON_AUTHORIZED,
    REPRE_PERSON_AUTHORIZED,
    REPRE_ATTORNEY_PERSON_AUTHORIZED,
    LOCATION,
    LOCATION_BUSINESS,
    DELIVERY_LOCATION,
    CONTACTS,
    ATTORNEY_LOCATION,
    ATTORNEY_LOCATION_BUSINESS,
    ATTORNEY_DELIVERY_LOCATION,
    ATTORNEY_CONTACTS,
};
