import React, { useState, useContext, useEffect, useRef, useImperativeHandle } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/zamerComponent.css';
import { arrowDownBlue, errorIcon } from '../../assets/index.js';
import PropTypes from 'prop-types';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import { PozemkyTable, StavbyTable, FormInput, ZamerSelectionControls } from '../../components/index.js';
import { BUILDING_ADDRESS } from '../../constants/form15.js';
import FormContext from '../../formContexts/FormContext.js';
import { shouldNotSave, spreadParcelsAndConstructions, spreadObject, hasZamerError } from '../../helperFunctions/helpers.js';
import { dateValidation } from '../../helperFunctions/validationHelpers.js';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import { useLocation, useNavigate } from 'react-router-dom';

const buildIntentionModel = {
    title: { value: null, dirty: false },
    address: {
        city: '',
        cityPart: '',
        street: '',
        descNum: '',
        orientNum: '',
        zip: '',
    },
    newBuilding: false,
    buildingTypePurpose: '',
    changeOfFinishedBuilding: false,
    reasonChangeOfBuildingUse: false,
    newBuildingUse: '',
    buildingTemporary: false,
    setOfBuildings: false,
    techOrMarketFacility: false,
    siteFacility: false,
    maintenanceAndModificationPermission: false,
    waterworks: false,
    buildingTemporaryDuration: '',
    parcelChangeSuggestion: '',
    waterworksPurpose: '',
    watercourseName: '',
    watercourseId: '',
    hydrogeologicalAreaName: '',
    hydrogeologicalAreaID: '',
    documentationId: '',
    useNeighborFacilityReason: '',
};

const ZamerComponent15 = ({ progressElements, setProgressElements, urlPath }) => {
    const {intention, stepValue, validationRef, setStagesArr, stagesArr, saveFormRef, id} = useContext(FormContext); 
    const [ focused, setFocused ] = React.useState({
        nazev: false,
        nazev2: false,
        date: false,
        ucelVodnihoDila: false,
        nazevVodnihoDila: false,
        idVodnihoDila: false,
    });

    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            id: null,
        },
        buildIntention: buildIntentionModel
    });
    const [isIntentionCheckboxDisabled, setIsIntentionCheckboxDisabled] = useState(false);

    const buildIntention = request?.buildIntention;

    const prevRequest = usePrevious(request);
    const { token } = useContext(AuthContext);
    const initialRender = useRef(true);
    const [state, setState] = useState({
        inputValue: '',
        zamerType: 'new',
    });
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const [isDateError, setIsDateError] = useState({
        tmpBuildingDate: false,
    });
    const [loading, setLoading] = useState(false);
    const [parcelAndConstructionData, setParcelAndConstructionData] = useState({
        buildConstructions: [],
        buildParcels: [],
    });

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (id) {
            (async () => {
                const response = await getFormById(id, token, source, intention, null, pathname, navigate);
    
                if ((200 <= response?.status) && (response?.status < 300)) {
                    const isCopied = response.data?.buildApplication?.isCopied || false;
                    setIsIntentionCheckboxDisabled(isCopied);
                    spreadParcelsAndConstructions(
                        response.data, //obj
                        setParcelAndConstructionData, //func
                        parcelAndConstructionData // obj                
                    );

                    // do not send id: null if a form is present
                    const formData = response.data?.buildApplicationForms?.[0];
                    if (formData && formData.id) {
                        setRequest(state => ({ ...state, form: {id: formData.id} }));
                    }

                    const intention = response.data?.buildIntention;
                    if (intention) {
                        // eslint-disable-next-line no-unused-vars
                        const { buildConstructions, buildParcels, affectedBuildConstructions, affectedBuildParcels, approvedConstructions, ...rest } = intention;
                        setRequest(state => ({ ...state, buildIntention: spreadObject(rest) }));

                        setState(prevSate => ({ 
                            ...prevSate,
                            inputValue: intention.title.value,
                            zamerType: intention.eszId ? 'existing' : 'new' 
                        }));
                    }
                    setLoading(false);
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        }
        else {
            console.log('NON EXISTENT ID');
            setLoading(false);
        }
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        if (
            (request?.buildIntention?.buildParcels) ||
            (request?.buildIntention?.buildConstructions)
        ) {
            setParcelAndConstructionData(prev => ({
                ...prev,
                buildParcels: [...(request?.buildIntention?.buildParcels ?? [])],
                buildConstructions: [...(request?.buildIntention?.buildConstructions ?? [])],
            }));
            setRequest(prev => ({...prev, buildConstructions: request?.buildIntention?.buildConstructions, buildParcels: request?.buildIntention?.buildParcels}));
        }

        if (initialRender.current) {
            initialRender.current = false;
        } else {
            // avoid multiple save for same object
            if (shouldNotSave(prevRequest.buildIntention, request.buildIntention)) {
                return;
            }

            if (request.buildIntention?.title?.value === null) {
                return;
            }

            saveChanges();
        }
    }, [request.buildIntention]);

    const handleFocused = (elementToFocus) => {
        setFocused(prev => ({...prev, [elementToFocus]: true}));
    };

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;
            let warning = false;

            if (hasZamerError(buildIntention)) {
                err = true;
            } else if ((buildIntention?.buildingTemporary && !buildIntention?.buildingTemporaryDuration?.trim()) || isDateError.tmpBuildingDate)
            {
                err = true;
            }
            else if(buildIntention?.waterworks && (
                !buildIntention?.waterworksPurpose))
            {
                err = true;
            }

            if (buildIntention?.address?.zip) {
                const cleanedValue = buildIntention?.address?.zip.replace(/\s+/g, '');
                warning = (isNaN(cleanedValue) || cleanedValue.length !== 5);
            }

            

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, warning};
                }
                return stg;
            }));

            return !err;
        }
    }));

    return (
        <>
            {loading ? <div className='loading'></div> :
                <Container fluid>
                    <Row className='row-wrapper'>
                        <h2 className='p-0 m-0 main-title'>{`${stepValue}. Záměr`}</h2>
                    </Row>

                    <ZamerSelectionControls
                        request={request}
                        setRequest={setRequest}
                        state={state}
                        setState={setState}
                        buildIntentionModel={buildIntentionModel}
                        isIntentionCheckboxDisabled={isIntentionCheckboxDisabled}
                    />

                    {/* Místo záměru */}
                    <Row className='row-wrapper border rounded p-4'>
                        <p className='section-title p-0 mb-4'>Místo (nepovinné)</p>
                        <form className='p-0'>

                            {BUILDING_ADDRESS.map(item => (
                                <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                            ))}
                        </form>
                    </Row>

                    {/* Charakteristika záměru */}
                    <Row className='row-wrapper'>
                        <p className='p-0 mb-4 section-title'>Základní údaje o odstraňované stavbě, zařízení nebo terénních úpravách</p>
                        <form className='d-flex flex-column p-0'>

                            <label className='mb-2 id-checkbox-label'>
                                <input type="checkbox" className='id-checkbox-input' checked={Boolean(buildIntention.buildingTemporary)}
                                    onChange={(e) => {  setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, buildingTemporary: e.target.checked } })); }} />
                                Stavba dočasná
                            </label>
                            {buildIntention.buildingTemporary && <Row className='mt-3 row-wrapper'>
                                <Col md={6}>
                                    <label className='label p-0' style={{ color: (!buildIntention?.buildingTemporaryDuration?.trim() && (error || focused.date)) || isDateError.tmpBuildingDate ? '#C52A3A' : 'black' }}>
                                    Doba trvání dočasné stavby do
                                        <input
                                            required
                                            type='date'
                                            style={{ border: ((error && !buildIntention?.buildingTemporaryDuration?.trim()) || isDateError.tmpBuildingDate) && 'solid 1px #C52A3A' }}
                                            className='id-input2 mb-2'
                                            value={buildIntention?.buildingTemporaryDuration ?? ''}
                                            {...(focused.date && { focused: 'true' })}
                                            onChange={(e) => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, buildingTemporaryDuration: e.target.value } })); }}
                                            onBlur={(e) => {
                                                const validatedDate = dateValidation(e.target.value); 
                                                validatedDate && setRequest(state => ({ ...state, buildIntention: { ...state?.buildIntention, buildingTemporaryDuration: '' } }));
                                                setIsDateError(state => ({...state, tmpBuildingDate: validatedDate}));
                                                handleFocused('date');
                                            }}
                                        />
                                        {isDateError.tmpBuildingDate && <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                            Nesprávný formát vyplnění, zkuste to znovu.
                                        </Container>}
                                        {(!buildIntention?.buildingTemporaryDuration?.trim() && (error || focused.date)) && !isDateError.tmpBuildingDate &&
                                            <Container className='' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                                Bez vyplnění pole &quot;Doba trvání dočasné stavby do&quot; není možné pokračovat.
                                            </Container>}
                                    </label>
                                </Col>
                            </Row>}

                            <label className='mb-2 id-checkbox-label'>
                                <input type="checkbox" className='id-checkbox-input' checked={Boolean(buildIntention.waterworks)}
                                    onChange={(e) => {  setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, waterworks: e.target.checked } })); }} />
                                Vodní dílo
                            </label>
                            {buildIntention.waterworks
                                &&
                            <Row className='border p-4'>
                                <Row className='mb-4'>
                                    <Col xs={12} md={6}>
                                        <label className='select-input' style={{color: (!buildIntention?.waterworksPurpose && (error || focused.ucelVodnihoDila)) ? '#C52A3A': ''}}>
                                            Účel stavby vodního díla
                                            <select
                                                type='select'
                                                required
                                                value={buildIntention.waterworksPurpose}
                                                style={{appearance: 'none', background: `url(${arrowDownBlue}) no-repeat right .8rem center`, padding: '.5rem 2rem .5rem .8rem', backgroundColor: 'white', borderRadius: '3px', border:  (!buildIntention?.waterworksPurpose && (error || focused.ucelVodnihoDila)) ? '#C52A3A solid 1px' : 'solid 1px #DDDDDD'}}
                                                onChange={(e) => {  setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, waterworksPurpose: e.target.value } })); }}
                                                className='mt-1 select' 
                                                onBlur={() => handleFocused('ucelVodnihoDila')}
                                                {...(focused.ucelVodnihoDila && { focused: 'true' })}
                                            >
                                                <option value='' disabled>vyberte</option>
                                                <option value={'01 umožnění odběru povrchové vody pro vodárenské účely'}>01 umožnění odběru povrchové vody pro vodárenské účely</option>
                                                <option value={'02 umožnění odběru povrchové vody pro průmyslové účely'}>02 umožnění odběru povrchové vody pro průmyslové účely</option>
                                                <option value={'03 umožnění odběru povrchové vody pro závlahy'}>03 umožnění odběru povrchové vody pro závlahy</option>
                                                <option value={'04 umožnění odběru povrchové vody pro ostatní účely'}>04 umožnění odběru povrchové vody pro ostatní účely</option>
                                                <option value={'05 podzemní voda'}>05 podzemní voda</option>
                                                <option value={'06 vodní energie, využití energetického potenciálu'}>06 vodní energie, využití energetického potenciálu</option>
                                                <option value={'07 vodní doprava, plavební účely'}>07 vodní doprava, plavební účely</option>
                                                <option value={'08 ochrana před povodněmi a ostatními škodlivými účinky vod'}>08 ochrana před povodněmi a ostatními škodlivými účinky vod</option>
                                                <option value={'09 odvádění odpadních a jiných vod koryty vodních toků'}>09 odvádění odpadních a jiných vod koryty vodních toků</option>
                                                <option value={'10 chov ryb nebo vodní drůbeže, popřípadě jiných vodních živočichů'}>10 chov ryb nebo vodní drůbeže, popřípadě jiných vodních živočichů</option>
                                                <option value={'11 rekreace u vody a jiné účely'}>11 rekreace u vody a jiné účely</option>
                                                <option value={'12 nadlepšování průtoků'}>12 nadlepšování průtoků</option>
                                                <option value={'13 stabilizace toku'}>13 stabilizace toku</option>
                                                <option value={'14 usazovací účinek'}>14 usazovací účinek</option>
                                                <option value={'99 jiné'}>99 jiné</option>
                                            </select>
                                        </label>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <p className='mt-3 mt-md-4 building-change ps-md-3'>
                                            Uveďte, zda se nástavba, přístavba nebo stavební 
                                            úprava se navrhuje z důvodu změny ve způsobu užívání 
                                            stavby (změna ve způsobu užívání stavby, změna v provozním 
                                            zařízení stavby, změna ve způsobu výroby nebo podstatném 
                                            rozšíření výroby, změna v činnosti, jejíž účinky by mohly 
                                            ohrozit život nebo veřejné zdraví, život a zdraví zvířat, 
                                            bezpečnost nebo životní prostředí).
                                        </p>
                                    </Col>
                                </Row>
                                <Row className='mb-4'>
                                    <Col xs={12} md={6}>
                                        <label className='select-input' >
                                            Název vodního toku
                                            <input
                                                type='text'
                                                defaultValue={buildIntention.watercourseName ?? ''}
                                                onBlur={(e) => {
                                                    setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, watercourseName: e.target.value } }));
                                                    handleFocused('nazevVodnihoDila');
                                                }}
                                                {...(focused.nazevVodnihoDila && { focused: 'true' })}
                                                className='mt-1 id-input2 waterworks bg-white p-2'
                                            />
                                        </label>
                                    </Col>
                                </Row>
                                <Row className='mb-4'>
                                    <Col xs={12} md={6}>
                                        <label className='select-input' >
                                            ID vodního toku
                                            <input
                                                type='text'
                                                defaultValue={buildIntention.watercourseId ?? ''}
                                                onBlur={(e) => {
                                                    setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, watercourseId: e.target.value } }));
                                                    handleFocused('idVodnihoDila');
                                                }}
                                                {...(focused.idVodnihoDila && { focused: 'true' })}
                                                className='mt-1 id-input2 waterworks bg-white p-2'
                                            />
                                        </label>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <p className='mt-3 mt-md-4 building-change ps-md-3'>
                                            Číselný identifikátor vodního toku podle údajů v evidenci vodních toků (§ 2 vyhlášky č. 252/2013 Sb., o rozsahu údajů v evidencích stavu povrchových a podzemních vod a o způsobu zpracování, ukládání a předávání těchto údajů do informačních systémů veřejné správy).
                                        </p>
                                    </Col>
                                </Row>
                                <Row className='mb-4'>
                                    <Col xs={12} md={6} className='d-flex flex-column'>
                                        <label className='select-input p-0'>
                                                Název hydrogeologického rajónu
                                            <input type='text' defaultValue={request.buildIntention.hydrogeologicalAreaName}
                                                onBlur={(e) => {setRequest(state => ({...state, buildIntention: {...state.buildIntention, hydrogeologicalAreaName: e.target.value}}));}}
                                                className='mt-1 form-zamer-input waterworks bg-white p-2'
                                            />
                                        </label>
                                    </Col>
                                    <Col md={6}>
                                        <p className='mt-3 mt-md-4 building-change ps-md-3'>
                                            Pouze v případě, že se záměr týká podzemních vod.
                                        </p>
                                    </Col>
                                </Row>
                                <Row className='mb-4'>
                                    <Col xs={12} md={6} className='d-flex flex-column'>
                                        <label className='select-input'>
                                            ID hydrogeologického rajónu
                                            <input type='text' defaultValue={request.buildIntention.hydrogeologicalAreaID}
                                                onBlur={(e) => {setRequest(state => ({...state, buildIntention: {...state.buildIntention, hydrogeologicalAreaID: e.target.value}}));}}
                                                className='mt-1 form-zamer-input waterworks bg-white p-2'
                                            />
                                        </label>
                                    </Col>
                                    <Col xs={12} md={6} className=''>
                                        <p className='mt-3 mt-md-5 building-change ps-md-3'>
                                        Číselný identifikátor hydrogeologického rajónu podle údajů v evidenci hydrogeologických rajónů (§ 4 vyhlášky č. 252/2013 Sb.).
                                        </p>
                                        <p className='mt-2 building-change ps-md-3'>
                                        Pouze v případě, že se záměr týká podzemních vod.
                                        </p>
                                    </Col>
                                </Row>
                            </Row>}
                        </form>
                    </Row>

                    <StavbyTable
                        title={'Stavby'}
                        subtitle={'Údaje o odstraňované stavbě'}
                        progressElements={progressElements}
                        setProgressElements={setProgressElements}
                        urlPath={urlPath}
                        dataToAdd={parcelAndConstructionData.buildConstructions}
                    />
                    <PozemkyTable
                        subtitle={'na kterých se nachází odstraňovaná terenní úprava'}
                        progressElements={progressElements}
                        setProgressElements={setProgressElements}
                        urlPath={urlPath}
                        dataToAdd={parcelAndConstructionData.buildParcels}
                        setDataToAdd={setParcelAndConstructionData}
                    />
                </Container>}
        </>
    );
};

ZamerComponent15.propTypes = {
    progressElements: PropTypes.arrayOf(Object),
    setProgressElements: PropTypes.func,
    urlPath: PropTypes.string.isRequired,
};

export default ZamerComponent15;
