import { Col, Container, Row } from 'react-bootstrap';
import React, { useContext, useEffect, useState, useImperativeHandle } from 'react';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce';
import FormContext from '../../formContexts/FormContext';
import PropTypes from 'prop-types';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import '../../css/stavbaComponent.css';
import {  shouldNotSave, spreadObject, spreadParcelsAndConstructions, checkLimitAndInsertText } from '../../helperFunctions/helpers';
import { errorIcon, infoCircle } from '../../assets';
import useFirstRender from '../CustomHooks/useFirstRender';
import usePrevious from '../CustomHooks/usePrevious';
import { CharCount, StavbyTable } from '../../components/index.js';
import { validDateAfterOrEquals, dateValidation } from '../../helperFunctions/validationHelpers.js';
import { useLocation, useNavigate } from 'react-router-dom';

const StavbaComponent13 = ({ urlPath, progressElements, setProgressElements }) => {
    const {intention, stepValue, validationRef, setStagesArr, stagesArr, saveFormRef, id} = useContext(FormContext);
    const { token } = useContext(AuthContext);
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const [isDateError, setIsDateError] = useState({
        expectedEndDate: null,
        startDate: null,
        endDate: null,
    });
    const [ parcelAndConstructionData, setParcelAndConstructionData ] = useState({
        approvedConstructions: [],
    });
    const [ focused, setFocused ] = React.useState({
        nazevStavby: false,
        obec: false,
        predcasneUzivaniDuvod: false,
        start: false,
        end: false,
        estimatedCompletionDate: false,
        cityPart: false,
    });
    const [count, setCount] = useState({
        [0]: {count: 0, limit: 10000},
    });
    const [isExceeded, setIsExceeded] = useState(false);
    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            requestJustification: '',
        }, 
        buildIntention: {
            buildingName: '',
            address: {
                city: '',
                cityPart: '',
            },
            testOperationStart: '',
            testOperationEnd: '',
            setOfBuildings: false,
        }
    });
    const buildIntention =  request?.buildIntention;
    const firstRender = useFirstRender();
    const prevRequest = usePrevious(request);
    const [moreInfoState, setMoreInfoState] = useState({
        reasoning: false,
    });
    const minDate = new Date();
    const dateErrorStart = (!buildIntention?.testOperationStart?.trim() || isDateError.startDate || !validDateAfterOrEquals(buildIntention?.testOperationStart, minDate)) && (error || focused.start);
    const dateErrorEnd = (!buildIntention?.testOperationEnd?.trim() || isDateError.endDate || !validDateAfterOrEquals(buildIntention?.testOperationEnd, minDate)) && (error || focused.end);
    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!id) {
            return;
        }
        const source = axios.CancelToken.source();
        (async () => {
            const response = await getFormById(id, token, source, intention, null, pathname, navigate);
            if ((200 <= response?.status) && (response?.status < 300)) {
                spreadParcelsAndConstructions(
                    response.data, //obj
                    setParcelAndConstructionData, //func
                    parcelAndConstructionData // obj                
                );

                const loadedData = response?.data?.buildIntention;

                if (loadedData) {
                    // eslint-disable-next-line no-unused-vars
                    const { buildConstructions, buildParcels, affectedBuildConstructions, affectedBuildParcels, approvedConstructions, ...rest } = loadedData;
                    setRequest(state => ({ ...state, buildIntention: spreadObject(rest) }));
                } 

                const loadedDataForm = response?.data?.buildApplicationForms[0];

                if (loadedDataForm) {
                    setRequest(state => ({ ...state, form: {...state.form, requestJustification: loadedDataForm.requestJustification} }));
                }                
            } else {
                console.log('ID NOT YET IN DB');
            }
        })();

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    }, []);
 
    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        if (request?.buildIntention?.buildConstructions && parcelAndConstructionData.buildConstructions.length === 0) {
            setParcelAndConstructionData(prev => ({
                ...prev,
                buildConstructions: prev?.buildConstructions?.length === 0 ? [...(request?.buildIntention?.buildConstructions ?? [])] : prev.buildConstructions,
            }));
        }
        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }

        // console.log(request);

        saveChanges();
    }, [request.form, request.buildIntention]);

    const handleFocused = (elementToFocus) => {
        setFocused(prev => ({...prev, [elementToFocus]: true}));
    };

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;
    
            if (!buildIntention?.buildingName?.trim()) {
                err = true;
            } 
            // else if (!buildIntention?.address?.city?.trim()) {
            //     err = true;
            // } 
            else if (!buildIntention?.estimatedCompletionDate?.trim() || isDateError.expectedEndDate) {
                err = true;
            } else if (!request?.form?.requestJustification?.trim()) {
                err = true;
            } else if (!buildIntention?.testOperationStart?.trim() || !validDateAfterOrEquals(buildIntention?.testOperationStart, minDate) || isDateError.startDate) {
                err = true;
            } else if (!buildIntention?.testOperationEnd?.trim() || !validDateAfterOrEquals(buildIntention?.testOperationEnd, minDate) || isDateError.endDate) {
                err = true;
            }
    
            

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, };
                }
                return stg;
            }));
    
            return !err;
        }
    }));
    const setError = () => {
        setStagesArr(prev => [...prev].map(stg => {
            if (Number(stg.stage) === Number(stepValue)) { 
                return {...stg, error: true};
            }
            return stg;
        }));
    };
    useEffect(() => {
        if (Object.values(isDateError).every(el => el === null)) {
            return;
        }

        if (Object.values(isDateError).some(el => el)) {
            setError();
        }
    }, [isDateError]);

    return (
        <Container fluid>
            <Row className='row-wrapper'>
                <h2 className='p-0 m-0 main-title'>{`${stepValue}. Stavba`}</h2>
            </Row>
            <Col md={6} className='mb-4'>
                <label className='p-0 povoleni-label' style={{color: (!buildIntention?.buildingName?.trim() && (error || focused.nazevStavby)) ? '#C52A3A' : ''}}>
                    Název stavby
                    <input 
                        type='text'
                        required
                        {...(focused.nazevStavby && { focused: 'true' })}
                        defaultValue={buildIntention?.buildingName}
                        style={{border: error && !buildIntention?.buildingName?.trim() ? '#C52A3A solid 1px' : ''}}
                        onBlur={(e) => { 
                            setRequest(prev => ({ ...prev, buildIntention: {...prev.buildIntention, buildingName: e.target.value}}));
                            handleFocused('nazevStavby');
                        }}
                        className=' id-input2 bg-white p-2 mt-1'
                    />
                    {(!buildIntention?.buildingName?.trim() && (error || focused.nazevStavby)) &&
                        <Container style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                            Bez vyplnění pole &quot;Název stavby&quot; není možné pokračovat.
                        </Container>}
                </label>
            </Col>

            <Row className='border p-4 row-wrapper'>
                <h3 className='p-0 id-title'>Místo stavby</h3>
                <Row className='d-flex pb-4'>
                    <Col md={6}>
                        <label className='p-0 povoleni-label' /* style={{color: error && !buildIntention?.address?.city?.trim() || (focused.obec && !buildIntention?.address?.city?.trim()) ? '#C52A3A' : ''}} */>
                        Obec (nepovinné)
                            <input
                                type='text'
                                // required
                                // {...(focused.obec && { focused: 'true' })}
                                defaultValue={buildIntention?.address?.city}
                                // style={{border: error && !buildIntention?.address?.city?.trim() ? '#C52A3A solid 1px' : ''}}
                                onBlur={(e) => {
                                    setRequest(prev => ({...prev, buildIntention: {...prev.buildIntention, address: {...prev.buildIntention?.address, city: e.target.value}}}));
                                    // handleFocused('obec');
                                }}
                                className='mt-1 id-input2 waterworks bg-white p-2'
                            />
                            {/* {!error && <Container className='input-message--error' style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                Bez vyplnění pole &quot;Obec&quot; není možné pokračovat.
                            </Container>}
                            {
                                error
                                &&
                                !buildIntention?.address?.city?.trim()
                                &&
                                <Container style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                    <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                    Bez vyplnění pole &quot;Obec&quot; není možné pokračovat.
                                </Container>
                            } */}
                        </label>
                    </Col>
                </Row>
                <Row className='d-flex pb-4'>
                    <Col md={6}>
                        <label className='p-0 povoleni-label'>
                        Část obce (nepovinné)
                            <input
                                type='text'
                                defaultValue={buildIntention?.address?.cityPart}
                                {...(focused.cityPart && { focused: 'true' })}
                                onBlur={(e) => {
                                    setRequest(prev => ({...prev, buildIntention: {...prev.buildIntention, address: {...prev.buildIntention?.address, cityPart: e.target.value}}}));
                                }}
                                className='mt-1 id-input2 waterworks bg-white p-2'
                            />
                        </label>
                    </Col>
                </Row>
            </Row>


            <Row className='row-wrapper'>
                <Col md={6}>
                    <label className='id-checkbox-label p-0'>
                        <input type='checkbox' className='id-checkbox-input' checked={buildIntention?.setOfBuildings ?? false}
                            onChange={(e) => { setRequest(prev => ({ ...prev, buildIntention: {...prev.buildIntention, setOfBuildings: e.target.checked}})); }}
                        />
                            Stavba byla povolena jako součást souboru staveb
                    </label>
                </Col>
                <Col md={6}>
                    <p className='mt-sm-2 mt-md-0 building-change ps-md-3'>
                        Pokud je stavba předmětem evidence v katastru nemovitostí nebo její výstavbou dochází k rozdělení pozemku<br/>
                        Pokud jsou údaje o stavbě obsahem digitální technické mapy kraje
                    </p>
                </Col>
            </Row>
            <Col md={6}>
                <label className='mb-4 label p-0' style={{color: (!buildIntention?.estimatedCompletionDate?.trim() && (error || focused.estimatedCompletionDate)) || isDateError.expectedEndDate ? '#C52A3A' : ''}}>
                    Předpokládaný termín dokončení stavby
                    <input
                        style={{border: ((error && !buildIntention?.estimatedCompletionDate?.trim()) || isDateError.expectedEndDate) ? '#C52A3A solid 1px' : ''}}
                        type='date'
                        required
                        {...(focused.estimatedCompletionDate && { focused: 'true' })}
                        className='id-input2'
                        value={buildIntention?.estimatedCompletionDate ?? ''}
                        onChange={e => {
                            const { value } = e.target;
                            const formatError = dateValidation(value);
                            if (!formatError) setIsDateError(state => ({...state, expectedEndDate: formatError}));
                            setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, estimatedCompletionDate: value } }));
                        }}
                        onBlur={(e) => { 
                            const { value } = e.target;
                            const formatError = dateValidation(value); 
                            if (formatError) setRequest(state => ({ ...state, buildIntention: { ...state?.buildIntention, estimatedCompletionDate: '' } }));
                            setIsDateError(state => ({...state, expectedEndDate: formatError}));
                            handleFocused('estimatedCompletionDate');
                        }}
                    />
                    {isDateError.expectedEndDate && <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                        <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                        Nesprávný formát vyplnění, zkuste to znovu.
                    </Container>}
                    {(!buildIntention?.estimatedCompletionDate?.trim() && (error || focused.estimatedCompletionDate)) && !isDateError.expectedEndDate &&
                        <Container style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                            Bez vyplnění pole &quot;Předpokládaný termín dokončení stavby&quot; není možné pokračovat.
                        </Container>}
                </label>
            </Col>
            <Row className='row-wrapper'>
                <StavbyTable
                    title={'Údaje o stavbě'}
                    setIsExceeded={setIsExceeded}
                    progressElements={progressElements}
                    setProgressElements={setProgressElements}
                    urlPath={urlPath}
                    approvedConstructions={true}
                    dataToAdd={parcelAndConstructionData.approvedConstructions}
                />
                {isExceeded && <p className='d-flex align-items-start p-0 mt-3 stavba-info__text'> <img src={infoCircle} alt="infoCircle" className='me-2' />V jedné žádosti je možné zadat nejvýše 5 staveb.</p>}
            </Row>

            <Row className='row-wrapper'>
                <p className='p-0 mt-5 mb-4 section-title'>Požadovaný zkušební provoz</p>
                <Col md={6}>
                    <label className='mb-4 label p-0' style={{color: dateErrorStart ? '#C52A3A' : ''}}>
                        Zahájení
                        <input
                            style={{border: dateErrorStart ? '#C52A3A solid 1px' : ''}}
                            type='date'
                            required
                            {...(focused.start && { focused: 'true' })}
                            className='id-input2'
                            value={buildIntention?.testOperationStart ?? ''}
                            min={minDate.toJSON().slice(0, 10)}
                            onChange={(e) => {
                                const { value } = e.target;
                                const formatError = dateValidation(value);
                                if (!formatError) setIsDateError(state => ({...state, startDate: formatError}));
                                setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, testOperationStart: value } }));
                            }}
                            onBlur={(e) => {
                                const { value } = e.target;
                                const formatError = dateValidation(value);
                                if (formatError) setRequest(state => ({ ...state, buildIntention: { ...state?.buildIntention, testOperationStart: '' } }));
                                setIsDateError(state => ({...state, startDate: formatError}));
                                handleFocused('start');
                                if (!formatError && !validDateAfterOrEquals(value, minDate)) {
                                    setError();
                                }
                            }}
                        />
                        {((!buildIntention?.testOperationStart?.trim() || isDateError.startDate) && (error || focused.start)) && <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                            {!buildIntention?.testOperationStart?.trim() ? 
                                'Bez vyplnění pole "Zahájení" není možné pokračovat.' : 
                                isDateError.startDate ? 
                                    'Nesprávný formát vyplnění, zkuste to znovu.' :
                                    ''}        
                        </Container>}
                    </label>
                </Col>
                <Col md={6}>
                    {(!error && !focused.start && !focused.end) &&
                        <p className='building-change my-2 my-md-0 ps-0 mt-md-4 ps-md-3'>
                            Datum zahájení i datum dokončení zkušebního provozu nesmí být dříve než datum odeslání žádosti.
                        </p>
                    }
                    {((error || focused.start || focused.end) && 
                            ((buildIntention?.testOperationStart?.trim() && 
                            !validDateAfterOrEquals(buildIntention?.testOperationStart, minDate)) ||
                        (buildIntention?.testOperationEnd?.trim() && 
                        !validDateAfterOrEquals(buildIntention?.testOperationEnd, minDate)))) &&
                        <Container className="mt-md-4 ps-md-3" style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                            Žádost nelze odeslat. Datum zahájení/ukončení zkušebního provozu nesmí nastat před podáním žádosti, prosím opravte datum zahájení/ukončení zkušebního provozu.
                        </Container>
                    }
                </Col>
                <Col md={6}>
                    <label className='mb-4 label p-0' style={{color: dateErrorEnd ? '#C52A3A' : ''}}>
                        Dokončení
                        <input
                            style={{border: dateErrorEnd ? '#C52A3A solid 1px' : ''}}
                            type='date'
                            required
                            {...(focused.end && { focused: 'true' })}
                            className='id-input2'
                            value={buildIntention?.testOperationEnd ?? ''}
                            min={minDate.toJSON().slice(0, 10)}
                            onChange={(e) => {
                                const { value } = e.target;
                                const formatError = dateValidation(value);
                                if (!formatError) setIsDateError(state => ({...state, endDate: formatError}));
                                setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, testOperationEnd: value } }));
                            }}
                            onBlur={(e) => {
                                const { value } = e.target;
                                const formatError = dateValidation(value);
                                if (formatError) setRequest(state => ({ ...state, buildIntention: { ...state?.buildIntention, testOperationEnd: '' } }));
                                setIsDateError(state => ({...state, endDate: formatError}));
                                handleFocused('end');
                                if (!formatError && !validDateAfterOrEquals(value, minDate)) {
                                    setError();
                                }
                            }}
                        />
                        {((!buildIntention?.testOperationEnd?.trim() || isDateError.endDate) && (error || focused.end)) && <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                            {!buildIntention?.testOperationEnd?.trim() ? 
                                'Bez vyplnění pole "Dokončení" není možné pokračovat.' :
                                isDateError.endDate ? 
                                    'Nesprávný formát vyplnění, zkuste to znovu.' :
                                    ''}
                        </Container>}
                    </label>
                </Col>
            </Row>

            <Container>
                <Row className='justification-container'>
                    <Col>
                        <label htmlFor='predcasneUzivaniDuvod' className='px-0 m-0 justification-text' style={{color: (!request?.form?.requestJustification?.trim() && (error || focused.predcasneUzivaniDuvod)) ? '#C52A3A' : ''}}>Odůvodnění žádosti</label>
                    </Col>
                    <Col className='d-flex align-items-center justify-content-end'>
                        <p className='p-0 pb-2 justification-link' onClick={() => setMoreInfoState(prev => ({...prev, reasoning: !prev.reasoning}))}>{moreInfoState?.reasoning ? 'zabalit' : 'více informací'}</p>
                    </Col>
                </Row>
                {moreInfoState.reasoning && <Row className='py-2 justification-detail-text'>
                    <span className='px-0'>Zejména k ověření jakých vlastností provedené stavby nebo funkčnosti jakého technologického zařízení má zkušební provoz sloužit</span>
                </Row>}
                <Row className='py-2'>
                    <Row className='position-relative textarea-with-count-container'>
                        <textarea
                            className='textarea-with-count text-area--validation p-2'
                            onChange={(e) => { checkLimitAndInsertText(e.target, 0, count, setCount); }}
                            id='predcasneUzivaniDuvod'
                            required
                            style={{border: error && !request?.form?.requestJustification?.trim() ? '#C52A3A solid 1px' : ''}}
                            defaultValue={request.form.requestJustification}
                            {...(focused.predcasneUzivaniDuvod && { focused: 'true' })}
                            onBlur={(e) => {
                                setRequest(prev => ({...prev, form: {...prev.form, requestJustification: e.target.value}}));
                                handleFocused('predcasneUzivaniDuvod');
                            }}
                        />
                        <CharCount 
                            value={request.form.requestJustification} setCount={setCount} propToUpdate={0} count={count[0].count} limit={count[0].limit}
                        />
                    </Row>
                </Row>
            </Container>
        </Container>
    );
};

StavbaComponent13.propTypes = {
    setCheckbox: PropTypes.func,
    urlPath: PropTypes.string.isRequired,
    progressElements: PropTypes.arrayOf(Object),
    setProgressElements: PropTypes.func,
};

export default StavbaComponent13;

