import React from 'react';
import '../../css/reusable.css';
import '../../css/accessibility.css';
import { Container, Row, Col } from 'react-bootstrap';

const Accessibility = () => {
    return (
        <Container className='welcome-section d-flex justify-content-center align-items-center d-md-flex flex-md-column p-3 p-md-5' style={{height: '100%'}} fluid>
            <Row className='d-flex flex-column justify-content-center flex-md-row base-width welcome-section-content'>
                <Row className='row-wrapper'>
                    <Col md={8}>
                        <p className='p-0 mb-3 accessibility-main_title'>Prohlášení o přístupnosti</p>
                        <p className='p-0 accessibility-help_text'>Ministerstvo pro místní rozvoj se zavazuje ke zpřístupnění svých internetových stránek <a href='http://portal.stavebnisprava.gov.cz'>http://portal.stavebnisprava.gov.cz</a> v souladu se zákonem č. 99/2019 Sb., o přístupnosti internetových stránek a mobilních aplikací a o změně zákona č. 365/2000 Sb., o informačních systémech veřejné správy a o změně některých dalších zákonů, ve znění pozdějších předpisů, který provádí Směrnici Evropského parlamentu a Rady (EU) 2016/2102 ze dne 26. října 2016, o přístupnosti internetových stránek a mobilních aplikací.</p>
                    </Col>
                    
                </Row>

                <Row>
                    <Col md={8}>
                        <p className='p-0 accessibility-text margin-20'>Toto prohlášení o přístupnosti se vztahuje na internetové stránky <a href="http://portal.stavebnisprava.gov.cz">http://portal.stavebnisprava.gov.cz</a>.</p>
                        
                        <p className='p-0 accessibility-sub_title margin-20'>Stav souladu</p>
                        <p className='p-0 accessibility-text margin-20'>Tyto internetové stránky jsou plně v souladu se zákonem č. 99/2019 Sb., o přístupnosti internetových stránek a mobilních aplikací a o změně zákona č. 365/2000 Sb., o informačních systémech veřejné správy a o změně některých dalších zákonů, ve znění pozdějších předpisů.</p>
                        
                        <p className='p-0 accessibility-sub_title margin-20'>Podporované webové prohlížeče</p>
                        <p className='p-0 accessibility-text mb-2'>Optimální zobrazení a práci umožňují tyto webové prohlížeče:</p>
                        <p className='p-0 accessibility-text mb-2'>Pro desktop: Google Chrome, Microsoft Edge, Safari</p>
                        <p className='p-0 accessibility-text margin-20'>Pro mobilní zařízení: Google Chrome, Safari</p>
                        
                        <p className='p-0 accessibility-sub_title margin-20'>Vypracování tohoto prohlášení o přístupnosti</p>
                        <p className='p-0 accessibility-text margin-20'>Prohlášení o přístupnosti bylo vypracováno dne 1. 7. 2024.<br/>Pro vypracování tohoto prohlášení byla použita metoda vlastního posouzení provedeného Ministerstvem pro místní rozvoj.</p>
                        
                        <p className='p-0 accessibility-sub_title margin-20'>Zpětná vazba a kontaktní údaje</p>
                        <p className='p-0 accessibility-text margin-20'>Stále pracujeme na tom, aby tyto stránky byly bez problémů přístupné všem uživatelům. Pokud narazíte na jakýkoli problém, dejte nám prosím vědět. Pro sdělení námětů, postřehů či informací o problémech se zobrazováním těchto stránek prosím využijte e-mail <a href="mailto:digitalizace@mmr.gov.cz">digitalizace@mmr.gov.cz</a>.</p>
                        
                        <p className='p-0 accessibility-sub_title margin-20'>Postupy pro prosazování práva</p>
                        <p className='p-0 accessibility-text mb-3'>V případě neuspokojivé odpovědi na oznámení nebo žádost zaslanou v souladu s čl. 7 odst. 1 písm. b) směrnice o přístupnosti internetových stránek a mobilních aplikací kontaktujte příslušný orgán pro prosazování práva:</p>
                        <p className='p-0 accessibility-text accessibility-text--bold mb-3'>Ministerstvo pro místní rozvoj</p>
                        <p className='p-0 accessibility-text'>Staroměstské náměstí 6<br/>110 00 Praha 1<br/>e-mail: <a href="mailto:web@mmr.gov.cz">web@mmr.gov.cz</a></p>
                    </Col>
                </Row>
            </Row>
            
        </Container>
        
    );
};

export default Accessibility;