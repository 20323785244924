import React, {useState} from 'react';
import { HeaderSection, StavbyMainSection } from '../components';
import { Container } from 'react-bootstrap';

const Stavby = () => {
    const [isActive, setIsActive] = useState('table');

    return (
        <Container className='d-flex flex-column align-items-center' fluid>
            <HeaderSection headerText={'Stavba je součást záměru, označující budovu nebo soubor budov, které jsou v řízení upravovány'} page={'stavby'} mainHeader={'Moje stavby'} />
            <StavbyMainSection isActive={isActive} setIsActive={setIsActive}/>
        </Container>);
};

export default Stavby;