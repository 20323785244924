import React, { useState, useEffect, useContext, useImperativeHandle } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../../css/povoleni.css';
import { canFulFillPrijemceList, getPrijemceList, shouldNotSave, spreadObject } from '../../helperFunctions/helpers';
import PropTypes from 'prop-types';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import FormContext from '../../formContexts/FormContext';
import { SearchWithDataList } from '../index.js';
import { AUTHORITY_MODEL, BUILDING } from '../../constants/sharedConstants';
import usePrevious from '../CustomHooks/usePrevious';
import useFirstRender from '../CustomHooks/useFirstRender';
import { errorIcon } from '../../assets';
import {
    dateValidation,
    validDateBeforeOrEquals
} from '../../helperFunctions/validationHelpers.js';
import { useLocation, useNavigate } from 'react-router-dom';
import useDebounce from '../CustomHooks/useDebounce.jsx';

const PovoleniComponent11 = ({urlPath}) => {
    const {intention, stepValue, validationRef, setStagesArr, stagesArr, saveFormRef, id} = useContext(FormContext); 
    const { token } = useContext(AuthContext);
    const maxDate = new Date();
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const [isDateError, setIsDateError] = useState({
        authorityDate: false,
        authorityDateFuture: false,
    });
    const [ focused, setFocused ] = useState({
        permissionDated: false,
        permissionRefNumber: false
    });

    const [request, setRequest] = useState({
        'applicationId': id,
        buildIntention: {
            permissionAuthority: AUTHORITY_MODEL,
            permissionRefNumber: '',
            permissionDated: '',
            verifiedDocumentationId: '',
            authorisedByInspector: '',
        }
    });
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const buildIntention = request?.buildIntention;
    const [searchRequest, setSearchRequest] = useState(
        {
            title: '',
            typeTitle: null
        }
    );
    const [permAuthList, setPermAuthList] = useState([]);
    const firstRender = useFirstRender();
    const prevRequest = usePrevious(request);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const debouncedSearchTitle = useDebounce(searchRequest.title);

    const handleErrors = (errorElement, hasError) => {
        setIsDateError(prev => ({...prev, [errorElement]: hasError}));
    };

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (!canFulFillPrijemceList(request, firstRender, searchRequest, setPermAuthList, 'permissionAuthority')) {
            return;
        }

        setIsSearchLoading(true);
        getPrijemceList(searchRequest, token, setPermAuthList, setIsSearchLoading, signal);

        return () => {
            controller.abort();
        };
    }, [debouncedSearchTitle]);

    useEffect(() => {
        if (!id) {
            return;
        }
        const source = axios.CancelToken.source();
        (async () => {
            const response = await getFormById(id, token, source, intention, null, pathname, navigate);
            if ((200 <= response?.status) && (response?.status < 300)) {

                const intention = response?.data?.buildIntention;

                if (intention) {
                    setRequest(state => ({ ...state, buildIntention: spreadObject(intention) }));
                    
                    if (intention?.permissionAuthority && intention?.permissionAuthority?.authorityName) {
                        setSearchRequest(prev => ({...prev, title: intention?.permissionAuthority?.authorityName ?? '', typeTitle: BUILDING }));
                    }  
                }
            } else {
                console.log('ID NOT YET IN DB');
            }
        })();

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }

        saveChanges();
    }, [request]);

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    const hasError2 = (!buildIntention?.permissionRefNumber?.trim() && (error || focused.permissionRefNumber));
    const hasError3 = (!buildIntention?.permissionDated?.trim() && (error || focused.permissionDated));
    const hasError4 = (buildIntention?.permissionDated?.trim() && (!validDateBeforeOrEquals(buildIntention?.permissionDated,maxDate)));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;
            if (!request.buildIntention?.permissionAuthority?.authorityName|| request.buildIntention?.permissionAuthority?.authorityName?.trim() === '')
            {
                err = true;
            }
            else if (!buildIntention?.permissionRefNumber?.trim()
                || !buildIntention?.permissionDated?.trim())
            {
                err = true;
            }
            else if (isDateError.authorityDate) {
                err = true;
            } else if(isDateError.authorityDateFuture){
                err = true;
            } else if(hasError3||hasError2||hasError4){
                err = true;
            }

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, };
                }
                return stg;
            }));

            return !err;
        }
    }));

    const handleFocused = (elementToFocus) => {
        setFocused(prev => ({...prev, [elementToFocus]: true}));
    };


    return (
        <Container className='' fluid>
                
            <Row className='row-wrapper'>
                <p className='p-0 step-title'>{`${stepValue}. Povolení`}</p>                    
            </Row>

            <Row className='mb-4'>
                <p className='p-0 mb-2 povoleni-subtitle'>Povolení stavby</p>
                <p className='p-0 povoleni-subtitle-help-text'>Identifikační údaje o povolení stavby</p>
            </Row>
            <Row>
                <p className='p-0 mb-3 povoleni-label'>Správní orgán, který povolení záměru vydal</p>
                <form className='p-0 d-flex flex-column'>
                    <Col md={6}>
                        <label className='mb-4 label'>
                            <SearchWithDataList
                                request={request}
                                authorityType={'permissionAuthority'}
                                authorityObj={request.buildIntention.permissionAuthority}
                                setRequest={setRequest}
                                paramFirst={'title'}
                                paramSecond={'typeTitle'}
                                setSearchRequest={setSearchRequest}
                                searchRequest={searchRequest} // obj
                                listData={permAuthList}
                                setListData={setPermAuthList} // arr
                                dynamicVal={BUILDING} // str
                                isSearchLoading={isSearchLoading}
                                setIsSearchLoading={setIsSearchLoading}
                            />
                        </label>
                    </Col>
                    <Col md={6}>
                        <label className='povoleni-label' style={{ color: hasError2 ? '#C52A3A' : '' }}>
                            Číslo jednací
                            <input 
                                type='text'
                                defaultValue={buildIntention.permissionRefNumber}
                                style={{ border: hasError2 ? 'solid 1px #C52A3A' : '' }}
                                onBlur={(e) => {
                                    setRequest(state => ({ ...state, buildIntention: {...state.buildIntention, permissionRefNumber: e.target.value } }));
                                    handleFocused('permissionRefNumber');
                                }}
                                className='povoleni-input bg-white p-2 mt-1'
                            />
                        </label>
                    </Col>
                    {hasError2 && <Col xs={{ span: 12}} md={{ span: 6}} className='mt-2'>
                        <p className='d-flex align-items-center p-0 warning-text'><img src={errorIcon} alt='warning' className='me-2' style={{ height: '1.1rem', width: '1.1rem' }} />Bez vyplnění pole &quot;Číslo jednací&quot; není možné pokračovat.</p>
                    </Col>}
                    <Col md={6}>
                        <label className='mt-4 povoleni-label' style={{ color: (hasError3 || isDateError.authorityDate || isDateError.authorityDateFuture) ? '#C52A3A' : '' }}>
                            Ze dne
                            <input 
                                type='date'
                                value={buildIntention?.permissionDated ?? ''}
                                style={{ border: (hasError3 || hasError4 || isDateError.authorityDate) ? 'solid 1px #C52A3A' : '' }}
                                max={maxDate.toISOString().split('T')[0]}
                                onChange={e => setRequest(state => ({ ...state, buildIntention: {...state.buildIntention, permissionDated: e.target.value } }))}
                                onBlur={(e) => {
                                    const validatedDate = dateValidation(e.target.value); 
                                    validatedDate && setRequest(state => ({ ...state, buildIntention: { ...state?.buildIntention, permissionDated: '' } }));
                                    setIsDateError(state => ({...state, authorityDate: validatedDate}));
                                    handleErrors('authorityDateFuture', !e.target.value.trim() || !validDateBeforeOrEquals(e.target.value, maxDate));
                                    handleFocused('permissionDated');
                                }}
                                className='povoleni-input bg-white p-2 mt-1'
                            />
                        </label>
                    </Col>
                    {hasError3 && !isDateError.authorityDate && <Col xs={{ span: 12}} md={{ span: 6}} className='mt-2'>
                        <p className='d-flex align-items-center p-0 warning-text'><img src={errorIcon} alt='warning' className='me-2' style={{ height: '1.1rem', width: '1.1rem' }} />Bez vyplnění pole &quot;Ze dne&quot; není možné pokračovat.</p>
                    </Col>}
                    <Col md={6}>
                        {

                            ((error
                                    &&
                                    buildIntention?.permissionDated?.trim()
                                    &&
                                    !validDateBeforeOrEquals(buildIntention?.permissionDated, maxDate))
                                ||
                                (error
                                    &&
                                    buildIntention?.permissionDated?.trim()
                                    &&
                                    !validDateBeforeOrEquals(buildIntention?.permissionDated, maxDate)))
                            &&
                            <Container className="mt-md-4 ps-md-3" style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                Žádost nelze odeslat. Ze dne nesmí být v budoucnosti, prosím opravte ze dne.
                            </Container>
                        }
                    </Col>
                    {isDateError.authorityDate && <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                        <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                            Nesprávný formát vyplnění, zkuste to znovu.
                    </Container>}
                    <Row className='mt-4'>
                        <Col md={6}>
                            <label className=' povoleni-label'>
                                ID ověřené dokumentace k povolenému záměru
                                <input 
                                    type='text'
                                    defaultValue={buildIntention.verifiedDocumentationId}
                                    onBlur={(e) => {
                                        setRequest(state => ({ ...state, buildIntention: {...state.buildIntention, verifiedDocumentationId: e.target.value } }));
                                    }}
                                    className='povoleni-input bg-white p-2 mt-1'
                                />
                            </label>
                        </Col>
                        <Col md={6}>
                            <p className='povoleni-help-text mt-3 mt-md-4 ps-md-3'>(podle vyhlášky o dokumentaci staveb) vložené do evidence elektronických dokumentací</p>
                        </Col>
                    </Row>
                    <Row className='my-4'>
                        <Col md={6}>
                            <label className='povoleni-label'>
                            Jméno autorizovaného inspektora, registrační číslo autorizovaného inspektora, datum vyhotovení a číslo certifikátu (nepovinné)
                                <input 
                                    type='text'
                                    defaultValue={buildIntention.authorisedByInspector}
                                    onBlur={(e) => {
                                        setRequest(state => ({ ...state, buildIntention: {...state.buildIntention, authorisedByInspector: e.target.value } }));
                                    }}
                                    className='povoleni-input bg-white p-2 mt-1'
                                />
                            </label>
                        </Col>
                        <Col md={6} className='d-flex align-items-end'>
                            <p className='ps-md-3 mt-3 mt-md-0 povoleni-help-text'>Uveďte v případě, že stavba byla povolena na základě certifikátu autorizovaného inspektora</p>
                        </Col>
                    </Row>
                </form>
            </Row>
        </Container>
    );
};

PovoleniComponent11.propTypes = {
    urlPath: PropTypes.string.isRequired,
};

export default PovoleniComponent11;
