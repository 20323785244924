import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import '../../../css/formTableView.css';    
import { v4 as uuidv4 } from 'uuid';
import { ACCOMPANYING_PERSON_MODEL, COUNTRIES } from '../../../constants/sharedConstants';
import { arrowDownBlue, errorIcon } from '../../../assets';

const AddProjektantModal = ({
    listData,
    setProjektantDocumentationRequest,
    showProjektantModal,
    setShowProjektantModal,
    setListData,
}) => {
    const [request, setRequest] = useState(ACCOMPANYING_PERSON_MODEL);
    const [inputError, setInputError] = useState({
        documentationPart: true,
        firstName: true,
        lastName: true,
        nationality: true,
        ais: true,
    });
    const isDisabled = !request?.documentationPart?.trim() || !request?.firstName?.trim() || !request?.lastName?.trim() || !request?.nationality?.trim() || !request?.ais?.trim();
    const resetRequest = () => {
        setRequest(ACCOMPANYING_PERSON_MODEL);
    };

    const handleCancel = () => {
        resetRequest();
        setShowProjektantModal(false);
    };
    
    const handleAddProjektant = () => {
        setListData(prev => [...prev, {...request, uid: uuidv4()}]);
        setProjektantDocumentationRequest(prev => ({ ...prev, accompanyingDocument: {...prev.accompanyingDocument, designers: [...listData, request]
            .map(el => {
                if (Object.hasOwn(el, 'uid')) {
                    // eslint-disable-next-line no-unused-vars
                    const {uid, ...others} = el;
                    return others;
                } else {
                    return el;
                }
            })}
        }));

        handleCancel();
    };

    const handleAddAnother = () => {
        handleAddProjektant();
        resetRequest();
        setShowProjektantModal(true);
    };

    return (
        <Modal show={showProjektantModal} onHide={() => handleCancel()} size='lg' centered>
            <Modal.Header className='modal-header--radius modal-background' closeButton bsPrefix='d-flex justify-content-between align-items-center px-sm-5 modal-header-custom modal-background'>
                <Modal.Title bsPrefix='modal-title'>Přidat projektanta</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-background' bsPrefix='px-sm-5 modal-body-custom d-flex flex-column'>
                {/* {
                    BASIC_INFO_PROJEKTANT_MODAL.map((item, idx) => {
                        if (idx === 0) {
                            return <Fragment key={`${item.id}-${item.refer}`} >
                                <label className='d-flex flex-column mb-4 label document-part'>
                                Část dokumentace
                                    <input 
                                        type={'text'}
                                        className='id-input2'
                                        value={request.documentationPart}
                                        onChange={(e) => setRequest(prev => ({...prev, documentationPart: e.target.value }))}
                                    />
                                </label>
                                <FormInput {...item} request={request} setRequest={setRequest} />
                            </Fragment>;
                        } else if (idx === 4) {
                            return <Fragment key={`${item.id}-${item.refer}`} >
                                <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                <StatniPrislusnost request={request} setRequest={setRequest} />
                            </Fragment>;
                        } else {
                            return <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />;
                        }
                    })
                } */}
                <label className='d-flex flex-column label pb-4'>
                    <Row className=''>
                        <Col xs={12} lg={6} className='d-flex flex-column' style={{fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: !inputError.documentationPart ? '#C52A3A' : '#686868'}}>
                        Část dokumentace
                            <input
                                type={'text'}
                                className='id-input2'
                                style={{border: !inputError.documentationPart && 'solid 1px #C52A3A'}}
                                value={request.documentationPart}
                                onChange={(e) => setRequest(prev => ({...prev, documentationPart: e.target.value}))}
                                onBlur={(e) => setInputError(state => ({...state, documentationPart: e.target.value.trim()}))}
                            />

                            {!inputError.documentationPart && <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                Bez vyplnění pole &quot;Část dokumentace&quot; není možné pokračovat.
                            </Container>}
                        </Col>
                    </Row>
                </label>
                <label className='d-flex flex-column label pb-4'>
                    <Row className=''>
                        <Col xs={12} lg={6} className='d-flex flex-column' style={{fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: '#686868'}}>
                        Titul před jménem (nepovinné)
                            <input
                                type={'text'}
                                className='id-input2'
                                value={request.titleBefore}
                                onChange={(e) => setRequest(prev => ({...prev, titleBefore: e.target.value}))}
                            />
                        </Col>
                    </Row>
                </label>
                <label className='d-flex flex-column label pb-4'>
                    <Row className=''>
                        <Col xs={12} lg={6} className='d-flex flex-column' style={{fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: '#686868'}}>
                        Titul za jménem (nepovinné)
                            <input
                                type={'text'}
                                className='id-input2'
                                value={request.titleAfter}
                                onChange={(e) => setRequest(prev => ({...prev, titleAfter: e.target.value}))}
                            />
                        </Col>
                    </Row>
                </label>
                <label className='d-flex flex-column label pb-4'>
                    <Row className=''>
                        <Col xs={12} lg={6} className='d-flex flex-column' style={{fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: !inputError.firstName ? '#C52A3A' : '#686868'}}>
                        Jméno
                            <input
                                type={'text'}
                                className='id-input2'
                                style={{border: !inputError.firstName && 'solid 1px #C52A3A'}}
                                value={request.firstName}
                                onChange={(e) => setRequest(prev => ({...prev, firstName: e.target.value}))}
                                onBlur={(e) => setInputError(state => ({...state, firstName: e.target.value.trim()}))}
                            />

                            {!inputError.firstName && <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                Bez vyplnění pole &quot;Jméno&quot; není možné pokračovat.
                            </Container>}
                        </Col>
                    </Row>
                </label>
                <label className='d-flex flex-column label pb-4'>
                    <Row className=''>
                        <Col xs={12} lg={6} className='d-flex flex-column' style={{fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: !inputError.lastName ? '#C52A3A' : '#686868'}}>
                        Příjmení
                            <input
                                type={'text'}
                                className='id-input2'
                                style={{border: !inputError.lastName && 'solid 1px #C52A3A'}}
                                value={request.lastName}
                                onChange={(e) => setRequest(prev => ({...prev, lastName: e.target.value}))}
                                onBlur={(e) => setInputError(state => ({...state, lastName: e.target.value.trim()}))}
                            />

                            {!inputError.lastName && <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                Bez vyplnění pole &quot;Příjmení&quot; není možné pokračovat.
                            </Container>}
                        </Col>
                    </Row>
                </label>
                <label className='d-flex flex-column label pb-4'>
                    <Row className=''>
                        <Col xs={12} lg={6} className='d-flex flex-column' style={{fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: '#686868'}}>
                        Rodné příjmení (nepovinné)
                            <input
                                type={'text'}
                                className='id-input2'
                                value={request.lastNameOrigin}
                                onChange={(e) => setRequest(prev => ({...prev, lastNameOrigin: e.target.value}))}
                            />
                        </Col>
                    </Row>
                </label>
                <label className='d-flex flex-column mb-4 label' style={{color: !inputError.nationality ? '#C52A3A' : '#686868'}}>
                                Státní příslušnost
                    <Row>
                        <Col xs={12} md={6}>
                            <select type='select' value={request?.nationality}
                                style={{appearance: 'none', background: `url(${arrowDownBlue}) no-repeat right .8rem center`, padding: '.5rem 2rem .5rem .8rem', backgroundColor: 'white', borderRadius: '3px', border: !inputError.nationality ? 'solid 1px #C52A3A' : 'solid 1px #DDDDDD'}}
                                onChange={(e) => { setRequest(prev => ({ ...prev, nationality: e.target.value  })); }}
                                onBlur={(e) => setInputError(state => ({...state, nationality: e.target.value.trim()}))}
                                className='id-input2'
                            >
                                <option value={''}>Vyberte</option>
                                {COUNTRIES.map((country) => (
                                    <option key={country.value} value={country.value}>{country.label}</option>
                                ))}
                            </select>

                            {!inputError.nationality && <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                Bez vyplnění pole &quot;Státní příslušnost&quot; není možné pokračovat.
                            </Container>}
                        </Col>
                    </Row>
                </label>
                <label className='d-flex flex-column label pb-4'>
                    <Row className=''>
                        <Col xs={12} lg={6} className='d-flex flex-column' style={{fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: !inputError.ais ? '#C52A3A' : '#686868'}}>
                        Číslo v evidenci autorizovaných osob
                            <input
                                type={'text'}
                                className='id-input2'
                                style={{border: !inputError.ais && 'solid 1px #C52A3A'}}
                                value={request.ais}
                                onChange={(e) => setRequest(prev => ({...prev, ais: e.target.value}))}
                                onBlur={(e) => setInputError(state => ({...state, ais: e.target.value.trim()}))}
                            />

                            {!inputError.ais && <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                Bez vyplnění pole &quot;Číslo v evidenci autorizovaných osob&quot; není možné pokračovat.
                            </Container>}
                        </Col>
                    </Row>
                </label>
                <label className='d-flex flex-column label pb-4'>
                    <Row className=''>
                        <Col xs={12} lg={6} className='d-flex flex-column' style={{fontWeight: '400', fontSize: '0.909rem', lineHeight: '1.464rem', letterSpacing: '0.014rem', color: '#686868'}}>
                        Obor (nepovinné)
                            <input
                                type={'text'}
                                className='id-input2'
                                value={request.field}
                                onChange={(e) => setRequest(prev => ({...prev, field: e.target.value}))}
                            />
                        </Col>
                    </Row>
                </label>
            </Modal.Body>
            <Modal.Footer className='modal-footer--radius modal-background' bsPrefix='d-flex px-sm-5 modal-footer-custom'>
                <Row className='button-wrapper pt-5'>
                    <Col className='d-flex flex-column flex-lg-row button-wrapper--inner' xs={12} lg={6}>
                        <button className={`mb-3 mb-lg-0 me-lg-3 modal-button modal-button--filled ${isDisabled ? 'disabled-filled' : ''}`}
                            onClick={() => handleAddProjektant()} 
                            disabled={isDisabled}>
                            Přidat
                        </button>
                        <button className={`modal-button ${isDisabled ? 'disabled-unfilled' : ''}`} 
                            onClick={() => handleAddAnother()} 
                            disabled={isDisabled}>
                            Přidat další
                        </button>
                    </Col>
                    <Col className='d-flex justify-content-end'>
                        <button className='modal-button' onClick={() => handleCancel()}>Zrušit</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};
    // TODO add pridat dalsi
AddProjektantModal.propTypes = {
    setListData: PropTypes.func,
    showProjektantModal: PropTypes.bool,
    setShowProjektantModal: PropTypes.func,
    setProjektantDocumentationRequest: PropTypes.func,
    listData: PropTypes.arrayOf(Object),
    isAffected: PropTypes.bool,
};

export default AddProjektantModal;