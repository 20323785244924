import axios from 'axios';
import React, { useEffect, useState, useContext, useRef } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { /* icon_change, */ trashbin } from '../../../assets';
import { Container } from 'react-bootstrap';
import '../../../css/formTableView.css';
import { ButtonsAndPaginate } from '../../../components/index.js';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { PROJEKTANT_DOCUMENTATION_TABLE } from '../../../constants/sharedConstants.js';
import { formSave } from '../../../apiCalls/formApiCalls.js';
import usePrevious from '../../CustomHooks/usePrevious.jsx';
import useFirstRender from '../../CustomHooks/useFirstRender.jsx';
import { setCsvDataAndRequest, shouldNotSave } from '../../../helperFunctions/helpers.js';
import FormContext from '../../../formContexts/FormContext.js';

const ProjektantDocumentationTable = ({
    urlPath,
    isAffected,
    progressElements,
    setProgressElements,  
    receivedToken,
    designers,
}) => {
    const resultPerPage = 25;
    const [listData, setListData] = useState([]);
    const { token } = useContext(AuthContext);
    const { id } = useContext(FormContext);
    const [startIndex, setStartIndex] = useState(0);
    const endIndex = startIndex + resultPerPage;
    const [projektantDocumentationRequest, setProjektantDocumentationRequest] = useState({
        'applicationId': id,
        accompanyingDocument: {
            designers: [],
        }
    });
    const currentPageRef = useRef(null);
    const [currentlyShowing, setCurrentlyShowing] = useState(0);
    const prevRequest = usePrevious(projektantDocumentationRequest);
    const firstRender = useFirstRender();
    const preloadRef = useRef(false);

    useEffect(() => {
        if (!designers) {
            return;
        }

        if (designers.length > 0) {
            preloadRef.current = true;
        }

        setCsvDataAndRequest(
            listData,
            setListData, 
            setProjektantDocumentationRequest, 
            'designers', 
            designers,
            receivedToken,
        );

    }, [designers]);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(projektantDocumentationRequest, token, urlPath, source, receivedToken);
    };
    useEffect(() => {
        if (shouldNotSave(prevRequest, projektantDocumentationRequest, firstRender)) {
            return;
        }

        if (preloadRef.current) {
            preloadRef.current = false;
            return;
        }

        saveChanges();
    }, [projektantDocumentationRequest.accompanyingDocument.designers.length]);

    const handleRemove = (keyStr) => {
        const newData = listData.filter(item => {
            return String(item.uid) !== String(keyStr);});
        setListData(newData);
        setProjektantDocumentationRequest(prev => ({...prev, accompanyingDocument: {...prev.accompanyingDocument,
            designers: [...newData]
                .map(el => {
                    if (Object.hasOwn(el, 'uid')) {
                        // eslint-disable-next-line no-unused-vars
                        const {uid, ...others} = el;
                        return others;
                    } else {
                        return el;
                    }
                })}
        }));
    };

    const handleCsv = async (data) => {
        const newLines = [];

        await data.forEach((line, index) => {
            let newLine = {};

            if (index === 0) {
                return;
            }

            if (line.some(el => el)) {
                newLine = {
                    documentationPart: line[0] ?? '', // "string"
                    titleBefore: line[1]  ?? '', // "string",
                    titleAfter: line[2] ?? '', // "string",
                    firstName: line[3] ?? '', // "string",
                    lastName: line[4]  ?? '', // "string",
                    lastNameOrigin: line[5] ?? '', // "string"
                    nationality: line[6] ?? '', // "string"
                    ais: line[7] ?? '', // "string"
                    field: line[8] ?? '', // "string"
                };
            }

            newLines.push(newLine);
        });

        setListData([...newLines.map(el => ({
            ...el,
            uid: uuidv4()
        }))]);

        setProjektantDocumentationRequest(prev => ({ ...prev, accompanyingDocument: {...prev.accompanyingDocument, designers: [...newLines]
            .map(el => {
                if (Object.hasOwn(el, 'uid')) {
                    // eslint-disable-next-line no-unused-vars
                    const {uid, ...others} = el;
                    return others;
                } else {
                    return el;
                }
            })}
        }));
    };

    useEffect(() => {
        if (listData.length === 0 || !currentPageRef.current) {
            return;
        }

        setCurrentlyShowing(currentPageRef.current.childNodes.length);
    }, [listData.length, startIndex]);

    return (
        <Container className='py-5' fluid>
            <h2 className='m-0 pb-2'>Projektanti jednotlivých částí dokumentace</h2>
            <Container className='overflow-x-scroll' fluid>
                {(listData.length > 0) &&
                    <table className='border my-3' >
                        <thead>
                            <tr>
                                <th></th>
                                <th className='py-2 px-3'><p className='table-head py-2 d-flex'>Jméno a příjmení</p></th>
                                <th className='py-2 px-3'><p className='table-head py-2 d-flex'>Číslo v evidenci</p></th>
                                <th className='py-2 px-3'><p className='table-head py-2 d-flex'>Obor</p></th>
                                {/* <th className='py-2 px-3'></th> */}
                                <th className='py-2 px-3'></th>
                            </tr>
                        </thead>
                        <tbody ref={currentPageRef}>
                            {listData.length > 0 && listData.map((data, index, array) => {
                                if ((array.length <= resultPerPage) || (index >= startIndex && index < endIndex)) {
                                    return (
                                        <tr key={data.uid}>
                                            <td className='py-2 px-3'><p className='row-number'>{index + 1}</p></td>
                                            <td className='py-2 px-3'><p className='row-info py-2'>{`${data?.titleBefore}. ${data?.firstName} ${data?.lastName} ${data?.titleAfter}.`}</p></td>
                                            <td className='py-2 px-3'><p className='row-info py-2'>{data?.ais}</p></td>
                                            <td className='py-2 px-3'><p className='row-info py-2'>{data?.field}</p></td>
                                            {/* <td className='py-2 px-3'>
                                                <Container className='d-flex justify-content-center align-items-center table-button-upravit'>
                                                    <img src={icon_change} alt='upravit' className='pe-2' />
                                                    <button type='button'
                                                        onClick={() => handleUpdate(data)}
                                                        style={{ background: 'transparent', border: 'none', color: '#2362A2' }}
                                                        disabled={true}
                                                    >
                                                                Upravit
                                                    </button>
                                                </Container>
                                            </td> */}
                                            <td className='py-2 px-3'>
                                                <button
                                                    type='button'
                                                    onClick={() => handleRemove(data.uid)}
                                                    className='d-flex justify-content-center align-items-center table-button-smazat'
                                                >
                                                    <img src={trashbin} alt='trashbin' className='pe-2' />
                                                        Smazat
                                                </button>
                                            </td>
                                        </tr>);
                                }
                            })}
                        </tbody>
                    </table>
                }
            </Container>
            <ButtonsAndPaginate
                category={PROJEKTANT_DOCUMENTATION_TABLE}
                listData={listData}
                setListData={setListData}
                startIndex={startIndex}
                setStartIndex={setStartIndex}
                handleCsv={handleCsv}
                progressElements={progressElements}
                setProgressElements={setProgressElements}   
                setProjektantDocumentationRequest={setProjektantDocumentationRequest}
                projektantDocumentationRequest={projektantDocumentationRequest}
                resultPerPage={resultPerPage}
                currentlyShowing={currentlyShowing}
                isAffected={isAffected}
            />
        </Container>
    );
};

ProjektantDocumentationTable.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    progressElements: PropTypes.arrayOf(Object),
    setProgressElements: PropTypes.func,
    handleCsv: PropTypes.func,
    
    urlPath: PropTypes.string,
    receivedToken: PropTypes.string,
    isAffected: PropTypes.bool,
    formType: PropTypes.number,
    buildParcels: PropTypes.arrayOf(Object),
    affectedBuildParcels: PropTypes.arrayOf(Object),
    designers: PropTypes.arrayOf(Object),
};

export default ProjektantDocumentationTable;
