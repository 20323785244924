import React from 'react';
import '../../css/rizeniDetail.css';
import '../../css/reusable.css';
import { Container, Row, Col } from 'react-bootstrap';
import { GovBreadcrumbs } from '@gov-design-system-ce/react';
import { arrowRight } from '../../assets/index.js';
import PropTypes from 'prop-types';

const RizeniDetailHeaderSection = ({rizeniHeaderData}) => {
    return (
        <Container fluid className='p-3 pb-5 p-md-5 d-flex flex-column align-items-center'>
            <Row className='breadcrumbs-section-detail base-width'>
                <Col className='d-flex justify-content-end align-items-start flex-column'>
                    <GovBreadcrumbs wcag-label="Aktuálně se necházíte na této úrovni">
                        <ul className='header__ul'>
                            <li className='d-flex align-items-center'><a href="/">Úvod</a><img src={arrowRight} style={{marginLeft: '10px', marginRight: '16px', height: '10px', width: '10px'}}/></li>
                            <li className='d-flex align-items-center'><a href="/rizeni">Řízení</a><img src={arrowRight} style={{marginLeft: '10px', marginRight: '16px', height: '10px', width: '10px'}}/></li>
                            <li className='d-flex align-items-center header__li-rizeni-id'><strong>{`Řízení ${rizeniHeaderData?.rizeniDetail?.referenceId}`}</strong></li>
                        </ul>
                    </GovBreadcrumbs>
                </Col>
            </Row>
            <Row className='base-width pt-5'>
                <Col xs={12} sm={6}>
                    <Row className='d-flex flex-column'>
                        <Col className='header__detail-id-big'>{`Řízení: ${rizeniHeaderData?.rizeniDetail?.referenceId}`}</Col>
                        <Col className='header__detail-typography' style={{marginTop: '.5rem', marginBottom: '.5rem'}}>{`Záměr: ${rizeniHeaderData?.rizeniDetail?.projectId}`}</Col>
                        <Col className='header__detail-project'>{rizeniHeaderData?.rizeniDetail?.projectName}</Col>
                    </Row>
                </Col>
                {/*<Col xs={12} sm={6} className='pt-4 d-flex flex-column align-items-center align-items-sm-end'>*/}
                {/*    <div>*/}
                {/*        <img src={rizeniHeaderData?.qr?.data}/>*/}
                {/*        <Container className='d-flex justify-content-center align-items-center' style={{marginTop: '.5rem'}}>*/}
                {/*            <img src={iconInfo} style={{width: '1rem', height: '1rem', marginRight: '6px'}}/>*/}
                {/*            <p style={{fontSize: '.75rem', fontWeight: '400', lineHeight: '18px', letterSpacing: '0.20000000298023224px', color: '#2362A2'}}>Jak kód použít?</p>*/}
                {/*        </Container>*/}
                {/*    </div>*/}
                {/*</Col>*/}
            </Row>
        </Container>
    );
};

RizeniDetailHeaderSection.propTypes = {
    rizeniHeaderData: PropTypes.shape({
        rizeniDetail: PropTypes.shape({
            authority: PropTypes.object,
            participants: PropTypes.arrayOf(PropTypes.object),
            projectId: PropTypes.string,
            projectName: PropTypes.string,
            referenceId: PropTypes.string,
            status: PropTypes.string,
            mode: PropTypes.string,

        }),
        qr: PropTypes.shape({
            data: PropTypes.string,
        }),
    })
};

export default RizeniDetailHeaderSection;
