import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';


const NewestEventCard = ({ item }) => {
    return (
        <Container className='newest-event-container mb-2 pt-3 pb-3'>
            <Row className='d-flex align-items-center justify-content-between'>
                <Col sm={10} className='d-flex align-items-center'>
                    <div className='border rounded content-box d-flex justify-content-center me-3' style={{ width: '88px' }}>
                        {item.date}
                    </div>
                    <p className='m-0'>{item.text}</p>
                </Col>
                <Col className='d-flex justify-content-end'>
                    <button type='button' className='newest-event-button'>Detail</button>
                </Col>
            </Row>
        </Container>
    );
};

NewestEventCard.propTypes = {
    item: PropTypes.shape({
        date: PropTypes.string,
        text: PropTypes.string,
    })
};

export default NewestEventCard;