import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../../css/progress.css';
import { Container } from 'react-bootstrap';

const Progress = ({ progress }) => {
    const [smoothenProgress, setSmoothenProgress] = useState(progress);

    useEffect(() => {
        if (progress === null) {
            return;
        }
            
        const timeout = setTimeout(() => {
            setSmoothenProgress(progress);
        }, 50); 

        return () => clearTimeout(timeout);
    }, [progress]);

    return (
        <Container className='d-flex align-items-start upload-progress-container'>
            <progress min={0} max={100} value={smoothenProgress}/>
        </Container>
    );
};

Progress.propTypes = {
    progress: PropTypes.number,
};

export default Progress;