import React from 'react';
import { Container } from 'react-bootstrap';
import { HeaderSection } from '../index.js';

const HomePageHeaderSection = () => {
    return (
        <Container fluid className='d-flex flex-column align-items-start' style={{ padding: '0px' }}>
            <HeaderSection />
        </Container>
    );
};

export default HomePageHeaderSection;