import React, { useEffect, useState } from 'react';
import '../css/reusable.css';
import { Container } from 'react-bootstrap';
import { FormFooter, FormHeader, Form11MainSection, FormFinisher } from '../components';
import { useGlobalState } from '../constants/states';
import { isNotUploading, shouldNotUpdateExceptions } from '../helperFunctions/helpers';
import { getItemsBasedOnCheckbox, items } from '../constants/form11';
import { FORM_11_INTENTION } from '../constants/sharedConstants';
import usePrevious from '../components/CustomHooks/usePrevious';
import FormContext from '../formContexts/FormContext';

const Form11 = () => {
    const [state, setState] = useGlobalState();
    const [progressElements, setProgressElements] = useState([]);
    const [noActiveUpload, setNoActiveUpload] = useState(null);
    const [ isMobileView, setIsMobileView ] = useState(window.innerWidth < 992);
    const [isFinished, setIsFinished] = useState(false);
    const [id, setId] = useState(null);
    const [uploadError, setUploadError] = useState([]);
    const [isSuccessOrFailSubmission, setIsSuccessOrFailSubmission] = useState(null);
    const [uploadedBytes, setUploadedBytes] = useState(0);
    const [stagesArr, setStagesArr] = useState(items);
    const [ fetch, setFetch ] = useState(false);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (!noActiveUpload) {
                e.preventDefault();
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [noActiveUpload]);

    useEffect(() => {
        function handleResize() {
            if(window.innerWidth < 992) {
                setIsMobileView(true);
                return;
            } 

            setIsMobileView(false); 
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    useEffect(() => {
        if (isNotUploading(progressElements)) {
            setNoActiveUpload(true);
            return;
        }

        setNoActiveUpload(false);

    }, [progressElements]);

    const { stage, checkbox } = state;
    const prevChecboxState = usePrevious(checkbox);
    
    const setCheckbox = (newCheckbox) => {
        setState({ ...state, checkbox: newCheckbox });
    };

    const setStage = (newStage) => {
        setState({ ...state, stage: newStage });
    };

    useEffect(() => {
        if (shouldNotUpdateExceptions(checkbox)) {
            return;
        }
        
        if (!prevChecboxState) {
            return;
        }

        getItemsBasedOnCheckbox(checkbox.first, checkbox.second, setStagesArr, prevChecboxState);

    }, [checkbox]);

    useEffect(() => {
        return () => {
            setCheckbox({
                first: null,
                second: null,
            });
            setStage(1);
        };
    }, []);

    const validationFunctionRef = React.useRef();
    const saveFormRef = React.useRef();

    return (
        <Container className={`d-flex flex-column align-items-center pt-5 background ${isMobileView ? 'mobile' : ''}`} fluid>
            {isFinished ? <FormFinisher isSuccessOrFailSubmission={isSuccessOrFailSubmission} header={FORM_11_INTENTION.title} id={id} linkRef={'form11'}
                setStage={setStage} setIsFinished={setIsFinished} /> :
                <>
                    <FormContext.Provider
                        value={{
                            intention: FORM_11_INTENTION.title,
                            stepValue: stage,
                            uploadError,
                            setUploadError,
                            uploadedBytes,
                            setUploadedBytes,
                            validationRef: validationFunctionRef,
                            setStagesArr,
                            stagesArr,
                            saveFormRef,
                            id,
                            setId,
                            fetch,
                            setFetch
                        }}
                    >
                        <FormHeader isMobileView={isMobileView} header={FORM_11_INTENTION} />
                        <Form11MainSection isMobileView={isMobileView} checkbox={checkbox} setCheckbox={setCheckbox} stage={stage} progressElements={progressElements} setProgressElements={setProgressElements} />
                        <FormFooter setIsSuccessOrFailSubmission={setIsSuccessOrFailSubmission} formNumber={'11'} setIsFinished={setIsFinished} />
                    </FormContext.Provider>
                </>}
        </Container>
    );
};

export default Form11;