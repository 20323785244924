import React, { useEffect, useState, useContext, useImperativeHandle } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/rozsah.css';
import PropTypes from 'prop-types';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import FormContext from '../../formContexts/FormContext';
import {AffectedConstructionsAndParcels, FindAndProcessIco} from '../index';
import { spreadParcelsAndConstructions, useOnlyRequiredKeys, shouldNotSave, spreadObject } from '../../helperFunctions/helpers';
import { dateValidation } from '../../helperFunctions/validationHelpers.js';
import { errorIcon } from '../../assets/index.js';
import { COUNTRIES, PROPERTY_DEVELOPER_MODEL } from '../../constants/sharedConstants.js';
import useFirstRender from '../CustomHooks/useFirstRender.jsx';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import { useLocation, useNavigate } from 'react-router-dom';

const RozsahComponent16 = ({ urlPath, progressElements, setProgressElements }) => {
    const { intention, stepValue, validationRef, setStagesArr, stagesArr,saveFormRef, id } = useContext(FormContext); 
    const [ isMobileView, setIsMobileView ] = useState(window.innerWidth <= 700);
    const { token } = useContext(AuthContext);
    
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const [isDateError, setIsDateError] = useState({
        startDate: false,
        endDate: false,
    });
    const [ focused, setFocused ] = React.useState({
        nazevStavby: false,
        start: false,
        end: false,
        icoNum: false,
        names: false,
        lastName: false,
        city: false,
        zip: false,
        poName: false,
        nationality: false
    });

    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            buildingPermission: false,
            facilityPermission: false,
            terrainPermission: false,
            explosives: false,
        },
        buildIntention: {
            useNeighborFacility: false,
            selfHelp: true,
            propertyDeveloper: false,
            propertyDeveloperDetails: PROPERTY_DEVELOPER_MODEL,
            startDate: '',
            endDate: '',
        }
    });
    const buildIntention = request?.buildIntention;
    const firstRender = useFirstRender();
    const prevRequest = usePrevious(request);
    const [parcelAndConstructionData, setParcelAndConstructionData] = useState({
        affectedBuildConstructions: [],
        affectedBuildParcels: [],
    });
    const icoPattern = /^[0-9]{8}$/;

    useEffect(() => {
        function handleResize() {
            if(window.innerWidth <= 800) {
                setIsMobileView(true);
                return;
            } 

            setIsMobileView(false); 
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    const { pathname } = useLocation();
    const navigate = useNavigate();
    
    useEffect(() => {
        if (!id) {
            return;
        }

        const source = axios.CancelToken.source();
        (async () => {
            const response = await getFormById(id, token, source, intention, null, pathname, navigate);

            if ((200 <= response?.status) && (response?.status < 300)) {
                spreadParcelsAndConstructions(
                    response.data, //obj
                    setParcelAndConstructionData, //func
                    parcelAndConstructionData // obj                
                );

                const loadedData = response?.data?.buildApplicationForms[0];

                if (loadedData) {
                    const formStructure = request.form;
                    const filteredDataToSave = useOnlyRequiredKeys(formStructure, loadedData);
                    setRequest(state => ({ ...state, form: {...state.form, ...filteredDataToSave } }));
                }

                const loadedBuildInt = response?.data?.buildIntention;

                if (loadedBuildInt) {
                    setRequest(state => ({
                        ...state,
                        buildIntention: {
                            ...spreadObject(loadedBuildInt),
                            useNeighborFacility: loadedBuildInt.useNeighborFacility ?? false,
                            selfHelp: loadedBuildInt.selfHelp ?? true,
                            propertyDeveloper: loadedBuildInt.propertyDeveloper ?? false,
                            startDate: loadedBuildInt.startDate ?? '',
                            endDate: loadedBuildInt.endDate ?? '',
                            propertyDeveloperDetails: loadedBuildInt.propertyDeveloperDetails ?? null,
                        }
                    }));
                }
            } else {
                console.log('ID NOT YET IN DB');
            }
        })();

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();

        const isPropertyDeveloper = Boolean(request.buildIntention.propertyDeveloper);
        if (!isPropertyDeveloper) {       
            const updatedRequest = {...request, buildIntention: {...request.buildIntention, propertyDeveloperDetails: {
                ...PROPERTY_DEVELOPER_MODEL,
                nationality: ''
            }} };
            await formSave(updatedRequest, token, urlPath, source);
            return;
        }

        await formSave(request, token, urlPath, source);
    };

    const handleFocused = (elementToFocus) => {
        setFocused(prev => ({...prev, [elementToFocus]: true}));
    };

    useEffect(() => {
        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }

        saveChanges();
    }, [request.form, request.buildIntention]);

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;
            let warning = false;
            if (buildIntention?.useNeighborFacility && !buildIntention?.useNeighborFacilityReason?.trim())
            {
                err = true;
            }
            else if (!buildIntention?.startDate || isDateError.startDate)
            {
                err = true;
            } else if (!buildIntention?.endDate || isDateError.endDate)
            {
                err = true;
            }

            if (buildIntention?.propertyDeveloperDetails?.ico?.trim()?.length > 0)
            {   
                warning = !icoPattern.test(buildIntention?.propertyDeveloperDetails?.ico?.trim().replace(/\s+/g, ''));
            }

            

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, warning};
                }
                return stg;
            }));

            return !err;
        }
    }));

    return (
        <Container className='py-0 d-flex row align-items-center rozsahContainer' fluid>
            
            <Row className='row-wrapper'>
                <p className='p-0 title primary'>{`${stepValue}. Rozsah`}</p>
            </Row>

            <Row className='row-wrapper'>
                <p className='p-0 mb-4 title secondary'>Žádám o dodatečné povolení:</p>
                <form className='p-0'>
                    <label className={`ls-1p25 p-0 mb-2 d-flex align-items-start ${!isMobileView ? 'w-50' : 'w-100'}`}>
                        <input
                            className='me-3'
                            type="checkbox"
                            checked={request?.form?.buildingPermission}
                            onChange={(e) => {
                                setRequest(state => ({...state, form: {...state.form, buildingPermission: e.target.checked }}));
                            }}
                        />
                            stavby              
                    </label>                  
                    <label className={`ls-1p25 p-0 mb-2 d-flex align-items-start ${!isMobileView ? 'w-50' : 'w-100'}`}>
                        <input
                            className='me-3'
                            type="checkbox"
                            checked={request?.form?.facilityPermission}
                            onChange={(e) => {
                                setRequest(state => ({...state, form: {...state.form, facilityPermission: e.target.checked }}));
                            }}
                        />
                            zařízení
                    </label>
                    <label className={`ls-1p25 p-0 d-flex align-items-start ${!isMobileView ? 'w-50' : 'w-100'}`}>
                        <input
                            className='me-3'
                            type="checkbox"
                            checked={request?.form?.terrainPermission}
                            onChange={(e) => {
                                setRequest(state => ({...state, form: {...state.form, terrainPermission: e.target.checked }}));
                            }}
                        />
                            terénních úprav
                    </label>
                </form>
            </Row>

            <Row className='row-wrapper'>
                <form className='p-0'>
                    <label className={`ls-1p25 p-0 d-flex align-items-start ${!isMobileView ? 'w-50' : 'w-100'}`}>
                        <input
                            className='me-3'
                            type="checkbox"
                            checked={buildIntention?.useNeighborFacility}
                            onChange={(e) => {
                                setRequest(state => ({...state, buildIntention: {...state.buildIntention, useNeighborFacility: e.target.checked }}));
                            }}
                        />
                K provedení stavby, zařízení, nebo terénní úpravy má být použit sousední pozemek nebo stavba
                    </label>
                </form>
            </Row>

            {buildIntention?.useNeighborFacility &&
                <Row className='row-wrapper'>
                    <AffectedConstructionsAndParcels
                        isAffected={true}
                        progressElements={progressElements}
                        setProgressElements={setProgressElements}
                        urlPath={urlPath}
                        request={request}
                        setRequest={setRequest}
                        affectedBuildParcels={parcelAndConstructionData.affectedBuildParcels}
                        affectedBuildConstructions={parcelAndConstructionData.affectedBuildConstructions}  
                    />
                </Row>
            }
            <Container fluid className='mb-4'>
                <p className='p-0 mb-4 title secondary'>Termín zahájení a předpokládaný termín dokončení stavby nebo terénní úpravy</p>
                <Col md={6}>
                    <label className='mb-4 label p-0' style={{ color: (!buildIntention?.startDate && (error || focused.start)) || isDateError.startDate ? '#C52A3A' : 'black' }}>
                    Zahájení
                        <input
                            type='date'
                            required
                            className='id-input2'
                            style={{ border: ((error && !buildIntention?.startDate) || isDateError.startDate) && 'solid 1px #C52A3A' }}
                            {...(focused.start && { focused: 'true' })}
                            value={buildIntention?.startDate ?? ''}
                            onChange={e => setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, startDate: e.target.value } }))}
                            onBlur={(e) => { 
                                const validatedDate = dateValidation(e.target.value); 
                                validatedDate && setRequest(state => ({ ...state, buildIntention: { ...state?.buildIntention, startDate: '' } }));
                                setIsDateError(state => ({...state, startDate: validatedDate}));
                                handleFocused('start');
                            }}
                        />
                        {isDateError.startDate && <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                            Nesprávný formát vyplnění, zkuste to znovu.
                        </Container>}
                        {(!buildIntention?.startDate && (error || focused.start)) && !isDateError.startDate &&
                            <Container className='' style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                Bez vyplnění pole &quot;Zahájení&quot; není možné pokračovat.
                            </Container>}
                    </label>
                </Col>
                <Col md={6}>
                    <label className='mb-4 label p-0' style={{ color: (!buildIntention?.endDate && (error || focused.end)) || isDateError.endDate ? '#C52A3A' : 'black' }}>
                    Dokončení
                        <input
                            type='date'
                            required
                            className='id-input2'
                            style={{ border: ((error && !buildIntention?.endDate || isDateError.endDate)) && 'solid 1px #C52A3A' }}
                            {...(focused.end && { focused: 'true' })}
                            value={buildIntention?.endDate ?? ''}
                            onChange={e => setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, endDate: e.target.value } }))}
                            onBlur={(e) => { 
                                const validatedDate = dateValidation(e.target.value); 
                                validatedDate && setRequest(state => ({ ...state, buildIntention: { ...state?.buildIntention, endDate: '' } }));
                                setIsDateError(state => ({...state, endDate: dateValidation(e.target.value)}));
                                handleFocused('end');
                            }}
                        />
                        {isDateError.endDate && <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                            Nesprávný formát vyplnění, zkuste to znovu.
                        </Container>}
                        {(!buildIntention?.endDate && (error || focused.end)) && !isDateError.endDate &&
                            <Container className='' style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                Bez vyplnění pole &quot;Dokončení&quot; není možné pokračovat.
                            </Container>}
                    </label>
                </Col>
            </Container>

            <Row className='row-wrapper'>
                <p className='p-0 mb-4 title secondary'>Způsob provádění stavby, zařízení, nebo terénní úpravy</p>
                <Row className='d-flex pb-2'>
                    <Col md={6} sm={12}>
                        <label className='d-flex mb-2 label'>
                            <input
                                type="radio"
                                name='execution'
                                className='radio'
                                checked={Boolean(buildIntention.selfHelp)}
                                onChange={() => { 
                                    setRequest(prev => ({ 
                                        ...prev,
                                        buildIntention: { 
                                            ...prev.buildIntention, 
                                            selfHelp: true, 
                                            propertyDeveloper: false,
                                            propertyDeveloperDetails: {
                                                ...PROPERTY_DEVELOPER_MODEL,
                                                nationality: ''
                                            } 
                                        }
                                    })); }}
                            />
                        Svépomocí 
                        </label>
                    </Col>                     
                </Row>
                <Row className='d-flex'>
                    <Col md={6} sm={12}>
                        <label className='d-flex label'>
                            <input
                                type="radio"
                                name='execution'
                                className='radio'
                                checked={Boolean(buildIntention.propertyDeveloper)}
                                onChange={() => { 
                                    setRequest(prev => ({ 
                                        ...prev, 
                                        buildIntention: { 
                                            ...prev.buildIntention, 
                                            selfHelp: false, 
                                            propertyDeveloper: true, 
                                            propertyDeveloperDetails: {
                                                ...prev.buildIntention.propertyDeveloperDetails, 
                                                ...PROPERTY_DEVELOPER_MODEL,
                                                id: prev.buildIntention?.propertyDeveloperDetails?.id ?? ''
                                            }  
                                        } 
                                    })); }}
                            />
                        Stavebním podnikatelem                    
                        </label>
                    </Col>
                </Row>
            </Row>

            {buildIntention.propertyDeveloper &&                 
            <Container className='py-2'>
                <p className='p-0 pb-4 section-title'>Stavební podnikatel</p>
                <Row className='d-flex pb-2'>
                    <Col md={6} sm={12}>
                        <label className='d-flex mb-2 label'>
                            <input
                                type="radio"
                                name='developer'
                                className='radio'
                                checked={buildIntention?.propertyDeveloperDetails?.isFOBusiness ?? true}
                                onChange={() => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {
                                    ...prev.buildIntention.propertyDeveloperDetails, 
                                    ...PROPERTY_DEVELOPER_MODEL, 
                                    isFOBusiness: true, 
                                    isPO: false,
                                    id: prev.buildIntention?.propertyDeveloperDetails?.id ?? ''
                                }  }})); }}
                            />
                            fyzická osoba podnikající
                        </label>
                    </Col>                     
                </Row>
                <Row className='d-flex pb-2'>
                    <Col md={6} sm={12}>
                        <label className='d-flex mb-2 label'>
                            <input
                                type="radio"
                                name='developer'
                                className='radio'
                                checked={buildIntention?.propertyDeveloperDetails?.isPO ?? false}
                                onChange={() => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {
                                    ...prev.buildIntention.propertyDeveloperDetails, 
                                    ...PROPERTY_DEVELOPER_MODEL, 
                                    isFOBusiness: false, 
                                    isPO: true,
                                    id: prev.buildIntention?.propertyDeveloperDetails?.id ?? ''
                                }  }})); }}
                            />
                            právnická osoba
                        </label>
                    </Col>                     
                </Row>
            </Container>}

            {buildIntention.propertyDeveloper && 
            <Container className='py-2' fluid>
                <Row className='mb-4'>
                    <p className='p-0 section-title'>Identifikační údaje stavebního podnikatele</p>
                </Row>
                <Row className='pb-4'>
                    <FindAndProcessIco
                        updateProp={'propertyDeveloperDetails'}
                        parentProp={'buildIntention'}
                        focusKey={'icoNum'}
                        handleFocused={handleFocused}
                        focused={focused} 
                        request={request}
                        setRequest={setRequest}
                    />
                </Row>
                {buildIntention?.propertyDeveloperDetails?.isFOBusiness && <>
                    <Row className='d-flex pb-4'>
                        <Col md={6}>
                            <label className='label' style={{color: (!buildIntention?.propertyDeveloperDetails?.names?.trim() && (error || focused.names)) ? '#C52A3A' : ''}}>
                                Jméno
                                <input 
                                    type='text'
                                    required
                                    defaultValue={buildIntention?.propertyDeveloperDetails?.names ?? ''}
                                    {...(focused.names && { focused: 'true' })}
                                    style={{border: error && !buildIntention?.propertyDeveloperDetails?.names?.trim() ? '#C52A3A solid 1px' : ''}}
                                    onBlur={(e) => {
                                        setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, names: e.target.value}  }}));
                                        handleFocused('names');
                                    }}
                                    className='mt-1 id-input2'
                                />
                            </label>
                        </Col>
                        <Col md={6}>
                            <p className='mt-md-4 mt-3 building-change ps-md-3'>
                            Uveďte všechna jména
                            </p>
                        </Col>
                        {(!buildIntention?.propertyDeveloperDetails?.names?.trim() && (error || focused.names)) &&
                            <Container className='' style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                Bez vyplnění pole &quot;Jméno&quot; není možné pokračovat.
                            </Container>}
                    </Row>
                    <Row className='d-flex pb-4'>
                        <Col md={6}>
                            <label className='label' style={{color: (!buildIntention?.propertyDeveloperDetails?.lastName?.trim() && (error || focused.lastName)) ? '#C52A3A' : ''}}>
                                Příjmení
                                <input 
                                    type='text'
                                    required
                                    defaultValue={buildIntention?.propertyDeveloperDetails?.lastName ?? ''}
                                    style={{border: error && !buildIntention?.propertyDeveloperDetails?.lastName?.trim() ? '#C52A3A solid 1px' : ''}}
                                    {...(focused.lastName && { focused: 'true' })}
                                    onBlur={(e) => {
                                        setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, lastName: e.target.value}  }}));
                                        handleFocused('lastName');
                                    }}
                                    className='mt-1 id-input2'
                                />
                                {(!buildIntention?.propertyDeveloperDetails?.lastName?.trim() && (error || focused.lastName)) &&
                                    <Container className='' style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                        <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                        Bez vyplnění pole &quot;Příjmení&quot; není možné pokračovat.
                                    </Container>}
                            </label>
                        </Col>
                    </Row>
                    <Row className='d-flex pb-4'>
                        <Col md={6}>
                            <label className='label'>
                                Rodné příjmení
                                <input 
                                    type='text'
                                    defaultValue={buildIntention?.propertyDeveloperDetails?.lastNameOrigin ?? ''}
                                    // ref={(refEl) => separateCleanUpFields.current['fo/lastNameOrigin'] = refEl}
                                    onBlur={(e) => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, lastNameOrigin: e.target.value}  }})); }}
                                    className='mt-1 id-input2'
                                />
                            </label>
                        </Col>
                        <Col md={6}>
                            <p className='mt-md-4 mt-3 building-change ps-md-3'>
                            Vyplňte pouze v případě, že rodné příjmení je odlišné od příjmení
                            </p>
                        </Col>
                    </Row>
                    <Row className='d-flex pb-4'>
                        <Container fluid>
                            <Col md={6}>
                                <label className='label p-0' style={{color: (!buildIntention?.propertyDeveloperDetails?.nationality?.trim() && (error || focused.nationality)) ? '#C52A3A' : ''}}>
                                Státní příslušnost
                                    <select
                                        type='select'
                                        required
                                        defaultValue={buildIntention?.propertyDeveloperDetails?.nationality ?? ''}
                                        {...(focused.nationality && {focused: 'true'})}
                                        style={{border: error && !buildIntention?.propertyDeveloperDetails?.nationality?.trim() ? '#C52A3A solid 1px' : ''}}
                                        onBlur={(e) => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, nationality: e.target.value}  }}));
                                            handleFocused('nationality');
                                        }}
                                        className='id-input2 dropdown-input'
                                    >
                                        <option value={''}>Vyberte</option>
                                        {COUNTRIES.map((country) => (
                                            <option key={country.value} value={country.value}>{country.label}</option>
                                        ))}
                                        
                                        
                                    </select>
                                </label>
                            </Col>
                        </Container>
                    </Row>
                    <Row className='d-flex pb-4'>
                        <Col md={6}>
                            <label className='label'>
                            Dodatek odlišující osobu podnikatele nebo druh podnikání vztahující se k této osobě nebo jí provozovanému druhu podnikání (nepovinné)
                                <input 
                                    type='text'
                                    defaultValue={buildIntention?.propertyDeveloperDetails?.appendix ?? ''}
                                    // ref={(refEl) => separateCleanUpFields.current['fo/supplement'] = refEl}
                                    onBlur={(e) => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, appendix: e.target.value}  }})); }}
                                    className='mt-1 id-input2'
                                />
                            </label>
                        </Col>
                    </Row>
                </>
                
                }
                {buildIntention?.propertyDeveloperDetails?.isPO && <>
                    <Row className='d-flex pb-4'>
                        <Col md={6} className='mb-4'>
                            <label className='p-0 povoleni-label' style={{color: (!buildIntention?.buildingName?.trim() && (error || focused.nazevStavby)) ? '#C52A3A' : ''}}>
                                Název stavby
                                <input 
                                    type='text'
                                    required
                                    {...(focused.nazevStavby && { focused: 'true' })}
                                    defaultValue={buildIntention?.propertyDeveloperDetails?.poName ?? ''}
                                    style={{border: error && !buildIntention?.buildingName?.trim() ? '#C52A3A solid 1px' : ''}}
                                    onBlur={(e) => { 
                                        setRequest(prev => ({ ...prev, buildIntention: {...prev.buildIntention, buildingName: e.target.value}}));
                                        handleFocused('nazevStavby');
                                    }}
                                    className=' id-input2 bg-white mt-1'
                                />
                                {(!buildIntention?.buildingName?.trim() && (error || focused.nazevStavby)) &&
                                    <Container className='' style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                        <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                        Bez vyplnění pole &quot;Název stavby&quot; není možné pokračovat.
                                    </Container>}
                            </label>
                        </Col>
                    </Row>
                </>}

                <Row className='border p-4 row-wrapper'>
                    <h3 className='p-0 id-title'>{buildIntention?.propertyDeveloperDetails?.isFOBusiness ? 'Místo podnikání' : 'Sídlo'}</h3>
                    <Row className='d-flex pb-4'>
                        <Col md={6}>
                            <label className='p-0 povoleni-label' style={{color: (!buildIntention?.propertyDeveloperDetails?.address?.city?.trim() && (error || focused.city)) ? '#C52A3A' : ''}}>
                                Obec
                                <input 
                                    // ref={(refEl) => commonCleanUpFields.current['city'] = refEl}
                                    // name={'city'}
                                    type='text'
                                    required
                                    style={{border: error && !buildIntention?.propertyDeveloperDetails?.address?.city?.trim() ? '#C52A3A solid 1px' : ''}}
                                    {...(focused.city && { focused: 'true' })}
                                    defaultValue={buildIntention?.propertyDeveloperDetails?.address?.city ?? ''}
                                    onBlur={(e) => { 
                                        setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, address: {...prev.buildIntention?.propertyDeveloperDetails?.address, city: e.target.value}}  }})); 
                                        handleFocused('city');
                                    }}
                                    className='mt-1 id-input2'
                                />
                                {(!buildIntention?.propertyDeveloperDetails?.address?.city?.trim() && (error || focused.city)) &&
                                    <Container className='' style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                        <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                        Bez vyplnění pole &quot;obec&quot; není možné pokračovat.
                                    </Container>}
                            </label>
                        </Col>
                    </Row>
                    <Row className='d-flex pb-4'>
                        <Col md={6}>
                            <label className='p-0 povoleni-label'>
                                Část obce
                                <input 
                                    // ref={(refEl) => commonCleanUpFields.current['cityPart'] = refEl}
                                    // name={'cityPart'}
                                    type='text'
                                    defaultValue={buildIntention?.propertyDeveloperDetails?.address?.cityPart ?? ''}
                                    onBlur={(e) => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, address: {...prev.buildIntention?.propertyDeveloperDetails?.address, cityPart: e.target.value}}  }})); }}
                                    className='mt-1 id-input2'
                                />
                            </label>
                        </Col>
                    </Row>
                    <Row className='d-flex pb-4'>
                        <Col md={6}>
                            <label className='p-0 povoleni-label'>
                                Ulice
                                <input 
                                    // ref={(refEl) => commonCleanUpFields.current['street'] = refEl}
                                    // name={'street'}
                                    type='text' 
                                    defaultValue={buildIntention?.propertyDeveloperDetails?.address?.street ?? ''}
                                    onBlur={(e) => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, address: {...prev.buildIntention?.propertyDeveloperDetails?.address, street: e.target.value}}  }})); }}
                                    className='mt-1 id-input2'
                                />
                            </label>
                        </Col>
                    </Row>
                    <Row className='d-flex pb-4'>
                        <Col md={6}>
                            <label className='p-0 povoleni-label'>
                                Číslo popisné
                                <input 
                                    // ref={(refEl) => commonCleanUpFields.current['descNum'] = refEl}
                                    // name={'descNum'}
                                    type='text' 
                                    defaultValue={buildIntention?.propertyDeveloperDetails?.address?.descNum ?? ''}
                                    onBlur={(e) => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, address: {...prev.buildIntention?.propertyDeveloperDetails?.address, descNum: e.target.value}}  }})); }}
                                    className='mt-1 id-input2'
                                />
                            </label>
                        </Col>
                        <Col md={6}>
                            <p className='mt-md-4 mt-3 building-change ps-md-3'>
                            Pokud je místo čísla popisného přiděleno číslo evidenční, uveďte před číslem písmeno E.
                            </p>
                        </Col>
                    </Row>
                    <Row className='d-flex pb-4'>
                        <Col md={6}>
                            <label className='p-0 povoleni-label'>
                                Číslo orientační
                                <input 
                                    // ref={(refEl) => commonCleanUpFields.current['orientNum'] = refEl}
                                    // name={'orientNum'}
                                    type='text' 
                                    defaultValue={buildIntention?.propertyDeveloperDetails?.address?.orientNum ?? ''}
                                    onBlur={(e) => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, address: {...prev.buildIntention?.propertyDeveloperDetails?.address, orientNum: e.target.value}}  }})); }}
                                    className='mt-1 id-input2'
                                />
                            </label>
                        </Col>
                    </Row>
                    <Row className='d-flex pb-4'>
                        <Col md={6}>
                            <label className='p-0 povoleni-label' style={{color: (!buildIntention?.propertyDeveloperDetails?.address?.zip?.trim() && (error || focused?.zip)) ? '#C52A3A' : ''}}>
                                PSČ
                                <input 
                                    // ref={(refEl) => commonCleanUpFields.current['zip'] = refEl}
                                    // name={'zip'}
                                    type='text'
                                    required
                                    style={{border: error && !buildIntention?.propertyDeveloperDetails?.address?.zip?.trim() ? '#C52A3A solid 1px' : ''}}
                                    defaultValue={buildIntention?.propertyDeveloperDetails?.address?.zip ?? ''}
                                    {...(focused?.zip && { focused: 'true' })}
                                    onBlur={(e) => {
                                        setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, address: {...prev.buildIntention?.propertyDeveloperDetails?.address, zip: e.target.value}}  }}));
                                        handleFocused('zip');
                                    }}
                                    className='mt-1 id-input2'
                                />
                                {(!buildIntention?.propertyDeveloperDetails?.address?.zip?.trim() && (error || focused?.zip)) &&
                                    <Container className='' style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                        <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                        Bez vyplnění pole &quot;PSČ&quot; není možné pokračovat.
                                    </Container>}
                            </label>
                        </Col>
                    </Row>
                </Row>
            </Container>}
            
        </Container>
    );
};

RozsahComponent16.propTypes = {
    urlPath: PropTypes.string.isRequired,
    progressElements: PropTypes.arrayOf(Object),
    setProgressElements: PropTypes.func,
    
    
};

export default RozsahComponent16;