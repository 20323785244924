import axios from 'axios';
import React, { useEffect, useState, useContext, useRef } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { iconChange } from '../../../assets';
import { 
    CADASTRAL_AREA,
    DESCRIPTIVE_NUMBER,
    ICS,
    PARCEL_NUMBER,
    REGISTRATION_NUMBER,
    TYPE_OF_USE,
    UNIT_NUMBER,
    AFFECTED_STAVBY_TABLE,
    APPROVED_STAVBY_TABLE,
    STAVBY_TABLE,
} from '../../../constants/sharedConstants.js';
import { trashbin } from '../../../assets';
import { Container } from 'react-bootstrap';
import '../../../css/formTableView.css';
import { ButtonsAndPaginate } from '../../../components/index.js';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { enterCsvData, getAnoNeValue, getFormType, isExceeding, removeCsvData, setCsvDataAndRequest, shouldNotSaveTable } from '../../../helperFunctions/helpers.js';
import { formSave } from '../../../apiCalls/formApiCalls.js';
import FormContext from '../../../formContexts/FormContext.js';

const StavbyTable = ({
    progressElements,
    setProgressElements,
    urlPath,
    title,
    subtitle,
    isAffected,
    dataToAdd,
    approvedConstructions, // bool | undefined
    receivedToken,
    setIsExceeded,
}) => {
    const [showStavbaModal, setShowStavbaModal] = useState(false);
    const resultPerPage = 25;
    const [listData, setListData] = useState([]);
    const { token } = useContext(AuthContext);
    const [startIndex, setStartIndex] = useState(0);
    const endIndex = startIndex + resultPerPage;
    const {intention, id} = useContext(FormContext); 
    const [tableRequest, setTableRequest] = useState({
        'applicationId': id,
    });
    const currentPageRef = useRef(null);
    const [currentlyShowing, setCurrentlyShowing] = useState(0);
    let saveCall = Promise.resolve();
    const dynamicKey = isAffected ? 'affectedBuildConstructions' : approvedConstructions ? 'approvedConstructions' : 'buildConstructions';
    const preloadRef = useRef(false);
    const [itemToUpdate, setItemToUpdate] = useState(null);
    const formType = getFormType(urlPath);
    const isForm18 = Boolean(receivedToken); // for F18 url format is different. getformtype is not for F18.
    const isForm14 = formType === 14;
    const isForm11 = formType === 11;
    const isForm12 = formType === 12;
    const isForm13 = formType === 13;
    
    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        saveCall = saveCall.then(() => formSave(tableRequest, token, urlPath, source, receivedToken ?? undefined));      
        try {
            await saveCall;
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (isExceeding(intention, listData.length, 'forWarning')) {
            if(setIsExceeded) setIsExceeded(true);
        } else {
            if(setIsExceeded) setIsExceeded(false);
        }
    }, [listData]);

    useEffect(() => {
        if (!dataToAdd) {
            return;
        }

        if (dataToAdd.length > 0) {
            preloadRef.current = true;
        }

        setCsvDataAndRequest(
            listData,
            setListData, 
            setTableRequest, 
            dynamicKey, 
            dataToAdd
        );

    }, [dataToAdd]);
    
    useEffect(() => {
        if (shouldNotSaveTable(tableRequest)) {
            return;
        }

        if (preloadRef.current) {
            preloadRef.current = false;
            return;
        }

        saveChanges();
    }, [tableRequest]);

    const handleRemove = (keyStr) => {
        const newData = listData.filter(item => String(item.uid) !== String(keyStr));
        setListData(newData);

        removeCsvData(setTableRequest, newData, dynamicKey);
    };

    const handleUpdate = (data) => {
        setItemToUpdate(data);
        setShowStavbaModal(true);
    };

    const trimValue = (value) => value?.trim() ?? '';

    const handleCsv = async (data) => {
        const newLines = [];

        await data.forEach((line, index) => {
            let newLine = {};

            if (index === 0) {
                return;
            }

            if (line.some(el => el)) {
                // TODO do not hardcode use commonFields logic dynamically
                if (isForm11) {
                    newLine = {
                        constructionId: trimValue(line[0]), // string, ICS
                        cadastralTerritory: trimValue(line[1]), // string, Katastralni uzemi
                        cadastralTerritoryCode: trimValue(line[2]), // string, ID Katastralniho uzemi
                        parcelNumber: trimValue(line[3]), // string, Parcelni cislo
                        constructionNumber: trimValue(line[4]), // string, Cislo popisne
                        registrationNumber: trimValue(line[5]), // string, Cislo evidencni
                        projectId: trimValue(line[6]), // string, Id zameru
                        projectName: trimValue(line[7]), // string, Nazev zameru,
                        parcelType: trimValue(line[8]), // string, Typ parcely 
                        constructionType: trimValue(line[9]), // 'Druh a účel stavby',
                        usePurpose: trimValue(line[10]), // 'Účel užívání',
                        temporary: getAnoNeValue(line[11]), // 'Stavba dočasná (Ano/Ne)',
                        numberOfApartments: trimValue(line[12]), // 'Počet bytů',
                        partOfConstruction: getAnoNeValue(line[13]), // 'Část stavby (Ano/Ne)',
                        location: trimValue(line[14]), // 'Údaj o poloze definičního bodu stavby',
                        classification: trimValue(line[15]), // 'Klasifikační třída podle Průkazu energetické náročnosti budovy',
                        dirty: Boolean(line[16]) ?? false, // bool
                    };
                } else if (isForm12) {
                    newLine = {
                        constructionId: trimValue(line[0]), // string, ICS
                        cadastralTerritory: trimValue(line[1]), // string, Katastralni uzemi
                        parcelNumber: trimValue(line[2]), // string, Parcelni cislo
                        constructionNumber: trimValue(line[3]), // string, Cislo popisne
                        registrationNumber: trimValue(line[4]), // string, Cislo evidencni
                        projectId: trimValue(line[5]), // string, Id zameru
                        projectName: trimValue(line[6]), // string, Nazev zameru,
                        constructionType: trimValue(line[7]), // 'Druh a účel stavby',
                        usePurpose: trimValue(line[8]), // 'Účel užívání',
                        temporary: getAnoNeValue(line[9]), // 'Stavba dočasná (Ano/Ne)',
                        numberOfApartments: trimValue(line[10]), // 'Počet bytů',
                        partOfConstruction: getAnoNeValue(line[11]), // 'Část stavby (Ano/Ne)',
                        location: trimValue(line[12]), // 'Údaj o poloze definičního bodu stavby',
                        classification: trimValue(line[13]), // 'Klasifikační třída podle Průkazu energetické náročnosti budovy',
                        dirty: Boolean(line[14]) ?? false, // bool
                    };
                } else if (isForm13) {
                    newLine = {
                        constructionId: trimValue(line[0]), // string, ICS
                        cadastralTerritory: trimValue(line[1]), // string, Katastralni uzemi
                        parcelNumber: trimValue(line[2]), // string, Parcelni cislo
                        constructionNumber: trimValue(line[3]), // string, Cislo popisne
                        registrationNumber: trimValue(line[4]), // string, Cislo evidencni
                        projectId: trimValue(line[5]), // string, Id zameru
                        projectName: trimValue(line[6]), // string, Nazev zameru,
                        parcelType: trimValue(line[7]), // string, Typ parcely 
                        constructionType: trimValue(line[8]), // 'Druh a účel stavby',
                        usePurpose: trimValue(line[9]), // 'Účel užívání',
                        temporary: getAnoNeValue(line[10]), // 'Stavba dočasná (Ano/Ne)',
                        numberOfApartments: trimValue(line[11]), // 'Počet bytů',
                        partOfConstruction: getAnoNeValue(line[12]), // 'Část stavby (Ano/Ne)',
                        dirty: Boolean(line[13]) ?? false, // bool
                    };
                }
                // TODO do not hardcode use commonFields logic dynamically
                const commonLines = {
                    constructionId: trimValue(line[0]), // string, ICS
                    cadastralTerritory: trimValue(line[1]), // string, Katastralni uzemi
                    cadastralTerritoryCode: trimValue(line[2]), // string, ID Katastralniho uzemi
                    parcelNumber: trimValue(line[3]), // string, Parcelni cislo
                    constructionNumber: trimValue(line[4]), // string, Cislo popisne
                    flatNumber: trimValue(line[5]), // string, Cislo jednotky
                    registrationNumber: trimValue(line[(!isForm18 && !isForm14) ? 6 : 5]), // string, Cislo evidencni
                    constructionPurpose: trimValue(line[(!isForm18 && !isForm14) ? 7: 6]), // string, Zpusob vyuziti
                    projectId: trimValue(line[(!isForm18 && !isForm14) ? 8 : 7]), // string, Id zameru
                    projectName: trimValue(line[(!isForm18 && !isForm14) ? 9 : 8]), // string, Nazev zameru,
                    parcelType: trimValue(line[(!isForm18 && !isForm14) ? 10: 9]), // string, Typ parcely 
                };

                if (isForm18) {
                    // eslint-disable-next-line no-unused-vars
                    const {flatNumber, ...rest} = commonLines;
                    newLine = {
                        ...rest,
                        setOfBuildings: line[10]?.trim()?.toLowerCase() === 'ano' ? true : line[10]?.trim()?.toLowerCase() === 'ne' ? false : null, // bool Je soucasti souboru staveb (Ano/Ne),
                        setOfBuildingsMarking: trimValue(line[11]),// string Oznaceni v ramci souboru staveb,
                        enclosedArea: trimValue(line[12]), // string Obestaveny prostor,
                        buildUpArea: trimValue(line[13]), // string Zastavena plocha,
                        floorArea: trimValue(line[14]), // string Podlahova plocha,
                        undergroundFloors: trimValue(line[15]), // string Pocet podzemnnich podlazi,
                        overgroundFloors: trimValue(line[16]), // string Pocet nadzemnich podlazi,
                        constructionType: trimValue(line[17]), // string Druh konstrukce,
                        heatingType: trimValue(line[18]), // string Zpusob vytapeni,
                        waterSupplyConnection: trimValue(line[19]), // string Pripojka vodobodu,
                        sewerNetworkConnection: trimValue(line[20]), // string Pripojka kanalizacni site,
                        gasConnection: trimValue(line[21]), // string Pripojka plynu,
                        elevator: trimValue(line[22]), // string Vytah,
                        depth: trimValue(line[23]), // string Hloubka stavby,
                        height: trimValue(line[24]), // string Vyska stavby,
                        estimatedPersonCapacity: trimValue(line[25]), // string Predpokladana kapacita poctu osob ve stavbe,
                        estimatedStartEnd: trimValue(line[26]), // string Planovany zacatek a konec realizace stavby
                        dirty: Boolean(line[27]) ?? false, // bool
                    };
                } else if (isForm14) {
                    // eslint-disable-next-line no-unused-vars
                    const {flatNumber, ...rest} = commonLines;
                    newLine = {
                        ...rest,
                        constructionType: trimValue(line[10]), // string Druh a ucel stavby, 
                        usePurpose: trimValue(line[11]), // string Ucel uzivani, 
                        temporary: getAnoNeValue(line[12]), // bool Stavba docasna (Ano/Ne)
                        dirty: Boolean(line[13]) ?? false, // bool
                    };
                } else if (!isForm11 && !isForm12 && !isForm13) {
                    newLine = {
                        ...commonLines,
                        dirty: Boolean(line[11]) ?? false, // bool
                    };
                }


            }

            newLines.push(newLine);
        });

        setListData([...newLines.map(el => ({
            ...el,
            uid: uuidv4()
        }))]);

        enterCsvData(setTableRequest, newLines, dynamicKey);
    };

    useEffect(() => {
        if (listData.length === 0 || !currentPageRef.current) {
            return;
        }

        setCurrentlyShowing(currentPageRef.current.childNodes.length);
    }, [listData.length, startIndex]);

    return (
        <Container className='' fluid>
            {/* <h2 className='m-0 pb-2'>{isFrom18 ? 'Stavby a jejich parametry' : isAffected ? 'Sousední stavby' : 'Stavby'}</h2> */}
            <h2 className='m-0 pb-2'>{title}</h2>
            {subtitle && <p className='p-0 mb-4 table-subtitle'>{subtitle}</p>}
            <Container className='overflow-x-scroll' fluid>
                {(listData.length > 0) &&
                <>
                    <table className='border my-3' >
                        <thead>
                            <tr>
                                <th></th>
                                <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${CADASTRAL_AREA}`}</p></th>
                                <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${PARCEL_NUMBER}`}</p></th>
                                {(formType === 1 || formType === 2 || formType === 4) && 
                                <th className='py-2 px-3'>
                                    <p className='table-head py-2 d-flex'>
                                        {`${DESCRIPTIVE_NUMBER}/${UNIT_NUMBER.charAt(0).toLowerCase() + UNIT_NUMBER.slice(1)}`}
                                    </p>
                                </th>}
                                {(formType !== 1 && formType !== 2 && formType !== 4) && 
                                <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${DESCRIPTIVE_NUMBER}`}</p></th>}
                                {(formType === 9 || formType === 8) &&
                                <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${UNIT_NUMBER}`}</p></th>}
                                <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${REGISTRATION_NUMBER}`}</p></th>
                                {(formType !== 11 && formType !== 12 && formType !== 13) && <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${TYPE_OF_USE}`}</p></th>}
                                {(formType !== 9 && formType !== 1 && formType !== 2 && formType !== 8 && formType !== 4 && formType !== 10 && formType !== 14 && !isForm18 && formType !== 11 && formType !== 12 && formType !== 13) && 
                                <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${UNIT_NUMBER}`}</p></th>}
                                <th className='py-2 px-3'><p className='table-head py-2 d-flex'>{`${ICS}`}</p></th>
                                {/* <th className='py-2 px-3'></th> */}
                                <th className='py-2 px-3'></th>
                            </tr>
                        </thead>
                        <tbody ref={currentPageRef}>
                            {listData.length > 0 && listData.map((data, index, array) => {
                                if ((array.length <= resultPerPage) || (index >= startIndex && index < endIndex)) {
                                    return (
                                        <tr key={data.uid}>
                                            <td className='py-2 px-3'><p className='row-number'>{index + 1}</p></td>
                                            <td className='py-2 px-3'><p className='row-info py-2'>{data?.cadastralTerritory ?? ''}</p></td>
                                            <td className='py-2 px-3'><p className='row-info py-2'>{data?.parcelNumber ?? ''}</p></td>
                                            {(formType === 1 || formType === 2 || formType === 4) && 
                                            <td className='py-2 px-3'><p className='row-info py-2'>{data?.constructionNumber ?? ''}{(data?.constructionNumber && data?.flatNumber) ? '/' : ''}{data?.flatNumber ?? ''}</p></td>}
                                            {(formType !== 1 && formType !== 2 && formType !== 4) && 
                                            <td className='py-2 px-3'><p className='row-info py-2'>{data?.constructionNumber ?? ''}</p></td>}
                                            {(formType === 9 || formType === 8) &&
                                            <td className='py-2 px-3'><p className='row-info py-2'>{data?.flatNumber ?? ''}</p></td>}
                                            <td className='py-2 px-3'><p className='row-info py-2'>{data?.registrationNumber ?? ''}</p></td>
                                            {(formType !== 11 && formType !== 12 && formType !== 13) && <td className='py-2 px-3'><p className='row-info py-2'>{data?.constructionPurpose ?? ''}</p></td>}
                                            {(formType !== 9 && formType !== 1 && formType !== 2 && formType !== 8 && formType !== 4 && formType !== 10 && formType !== 14 && !isForm18 && formType !== 11 && formType !== 12 && formType !== 13) && 
                                            <td className='py-2 px-3'><p className='row-info py-2'>{data?.flatNumber ?? ''}</p></td>}
                                            <td className='py-2 px-3'><p className='row-info py-2'>{data?.constructionId ?? ''}</p></td>
                                            <td className='py-2 px-3'>
                                                <button 
                                                    type='button'
                                                    onClick={() => handleUpdate(data)}
                                                    className='d-flex justify-content-center align-items-center table-button-upravit'
                                                >
                                                    <img src={iconChange} alt='upravit' className='pe-2' />
                                                                Upravit
                                                </button>
                                            </td>
                                            <td className='py-2 px-3'>
                                                <button
                                                    type='button'
                                                    onClick={() => handleRemove(data.uid)}
                                                    className='d-flex justify-content-center align-items-center table-button-smazat'
                                                >
                                                    <img src={trashbin} alt='trashbin' className='pe-2' />
                                                        Smazat
                                                </button>
                                            </td>
                                        </tr>);
                                }
                            })}
                        </tbody>
                    </table>
                </>
                  
                }
            </Container>
            <ButtonsAndPaginate
                showStavbaModal={showStavbaModal}
                setShowStavbaModal={setShowStavbaModal}
                itemToUpdate={itemToUpdate}
                setItemToUpdate={setItemToUpdate}
                category={isAffected ? AFFECTED_STAVBY_TABLE : approvedConstructions ? APPROVED_STAVBY_TABLE : STAVBY_TABLE}
                listData={listData}
                setListData={setListData}
                startIndex={startIndex}
                setStartIndex={setStartIndex}
                progressElements={progressElements}
                setProgressElements={setProgressElements}    
                handleCsv={handleCsv}
                setTableRequest={setTableRequest}
                resultPerPage={resultPerPage}
                currentlyShowing={currentlyShowing}
                isAffected={isAffected}
            />
        </Container>
    );
};

StavbyTable.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.any,
    progressElements: PropTypes.arrayOf(Object),
    setProgressElements: PropTypes.func,
    urlPath: PropTypes.string,
    isAffected: PropTypes.bool,
    dataToAdd: PropTypes.arrayOf(Object),
    affectedBuildConstructions: PropTypes.arrayOf(Object),
    approvedConstructions: PropTypes.any,
    receivedToken: PropTypes.string,
    setIsExceeded: PropTypes.func,
    setNewBuilding: PropTypes.func,
    newBuilding: PropTypes.any,
};

export default StavbyTable;
