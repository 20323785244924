import React, { useState, useContext, useEffect } from 'react';
import '../css/navbar.css';
import '../css/reusable.css';
import { Link, useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { AuthContext } from 'react-oauth2-code-pkce';
import { personIcon, arrowDown, lev, iconHamburger, navNovaZadost, iconLogout, iconPlus, iconAutoSave } from '../assets/index.js';

const Navbar = () => {
    const { tokenData, token, logOut, login } = useContext(AuthContext);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
    const [mobileNavVisible, setMobileNavVisible] = useState(false);
    const [isScrollDisabled, setIsScrollDisabled] = useState(false);

    const location = useLocation();

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
    const toggleDropdown2 = () => setIsDropdownOpen2(!isDropdownOpen2);

    const toggleNavbar = () => {
        setMobileNavVisible(!mobileNavVisible);
        setIsScrollDisabled(!isScrollDisabled);
    };

    useEffect(() => {
        document.body.style.overflowY = isScrollDisabled ? 'hidden' : 'auto';

        return () => {
            document.body.style.overflowY = 'auto';
        };
    }, [isScrollDisabled]);

    const NAVBAR_ITEMS = [
        {
            name: '',
            upperCaseName: 'ÚVOD'
        },
        {
            name: 'zadosti',
            upperCaseName: 'ŽÁDOSTI'
        },
        {
            name: 'rizeni',
            upperCaseName: 'ŘÍZENÍ'
        },
        {
            name: 'zamery',
            upperCaseName: 'ZÁMĚRY'
        },
        {
            name: 'stavby',
            upperCaseName: 'STAVBY'
        },
    ];

    const NAVBAR_REQ = [
        {
            name: 'zadosti/new',
            upperCaseName: 'NOVÁ ŽÁDOST',
            img: navNovaZadost
        },
    ];

    return (
        <>
            {mobileNavVisible && <Container fluid className='hamburger-visibility nav-layer-active pb-3'>
                {token ?
                    <Row>
                        <Col className='nav-layer__column pb-5 nav__button-logout' xs={12}>
                            <img src={personIcon} className='nav__person-icon--gov-blue me-3' />
                            {tokenData?.firstName}&nbsp;{tokenData?.lastName}
                        </Col>
                        <Link to='/zadosti/new' style={{ textDecoration: 'none' }}><Col className='nav-layer__column nav__button-logout' xs={12} onClick={toggleNavbar}>VYTVOŘIT ŽÁDOST</Col></Link>
                        <Link style={{ textDecoration: 'none' }}><Col className='nav-layer__column nav__button-logout' xs={12} onClick={toggleNavbar}>VYTVOŘIT ZÁMĚR</Col></Link>
                        <Link to='/uvod' style={{ textDecoration: 'none' }}><Col className='nav-layer__column' xs={12} onClick={toggleNavbar}>ÚVOD</Col></Link>
                        <Link to='/zadosti' style={{ textDecoration: 'none' }}><Col className='nav-layer__column' xs={12} onClick={toggleNavbar}>ŽÁDOSTI</Col></Link>
                        <Link to='/rizeni' style={{ textDecoration: 'none' }}><Col className='nav-layer__column' xs={12} onClick={toggleNavbar}>ŘÍZENÍ</Col></Link>
                        <Link to='/zamery' style={{ textDecoration: 'none' }}><Col className='nav-layer__column' xs={12} onClick={toggleNavbar}>ZÁMĚRY</Col></Link>
                        <Link to='/stavby' style={{ textDecoration: 'none' }}><Col className='nav-layer__column' xs={12} onClick={toggleNavbar}>STAVBY</Col></Link>
                        <Col className='nav-layer__column nav__button-logout' xs={12} onClick={toggleNavbar} style={{ border: 'none' }}>
                            <div className='navbar__div--logout gov-color--error-400' onClick={() => {
                                logOut();
                                toggleNavbar();
                            }}>
                                <img className='me-3' src={iconLogout} />
                                odhlásit se
                            </div>
                        </Col>
                    </Row> :
                    <Row>
                        <Col className='nav-layer__column' xs={12} onClick={login}>Přihlásit se</Col>
                    </Row>
                }
            </Container>}
            <nav>
                <Container fluid className='nav m-0 p-0'>
                    <Row className='w-100 m-0 px-3 px-xl-0'>
                        <Col xs={12} className='nav-top d-flex align-items-center justify-content-center m-0 p-0'>
                            <Row className='nav-row d-flex align-items-center m-0 p-0'>
                                <Col xs={6} className='p-0 m-0 d-flex align-items-center'>
                                    <img className='logo' alt={'logo ČR'} src={lev} />
                                    <p className='nav__p-title' style={{ minWidth: '170px' }}>Portál stavební správy</p>
                                </Col>
                                <Col xs={6} className='d-flex justify-content-end p-0 m-0'>
                                    {token && (
                                        <>
                                            <Container fluid className='nav-dropdown-container nav__dropdown-button-visibility' /* style={{ marginRight: '32px' }} */>
                                                <button className='button-nav d-flex align-items-center' onClick={toggleDropdown2}>
                                                    <img src={iconPlus} style={{ width: '16px', height: '16px', marginRight: '12px' }} />
                                                    Vytvořit
                                                    <img src={arrowDown} style={{ width: '16px', height: '16px', marginLeft: '12px' }} alt='otevřít uživatelský profil drop-down' />
                                                </button>
                                                {isDropdownOpen2 && (
                                                    <div className='user-dropdown'>
                                                        <p className='p-1'>Vytvořit žádost</p>
                                                        <p className='p-1'>Vytvořit záměr</p>
                                                    </div>
                                                )}
                                            </Container>

                                            {location?.pathname.includes('/zadost/form') && <Container className='d-flex d-md-none justify-content-end align-items-end me-3' fluid>
                                                <Row>
                                                    <Col>
                                                        <p className='p-0 nav-autosave'>Uloženo<img src={iconAutoSave} alt="autosave" style={{marginLeft: '12px'}}/></p>
                                                    </Col>
                                                </Row>
                                            </Container>}
                                            
                                            <div className='nav-dropdown-container' /* style={{ marginRight: '32px' }} */>
                                                <button className='button-nav d-flex align-items-center' onClick={toggleDropdown}>
                                                    <img src={personIcon} style={{ width: '16px', height: '16px', marginRight: '12px' }} alt='ikona uživatele' />
                                                    {tokenData?.firstName}&nbsp;{tokenData?.lastName}
                                                    <img src={arrowDown} style={{ width: '16px', height: '16px', marginLeft: '12px' }} alt='otevřít uživatelský profil drop-down' />
                                                </button>
                                                {isDropdownOpen && (
                                                    <div className='user-dropdown p-1'>
                                                        <p onClick={() => {
                                                            logOut();
                                                            setIsScrollDisabled(false);
                                                            setMobileNavVisible(false);
                                                        }}>Odhlásit</p>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    )}
                                    <div className='hamburger-visibility' onClick={toggleNavbar}>
                                        <img src={iconHamburger} alt='hamburger menu' />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        {token
                            &&
                        <Col xs={12} className='nav-bottom visibility w-100 d-flex justify-content-center'>
                            <Row className='nav-row visibility h-100'>
                                <Col xs={6} className='d-flex justify-content-between'>
                                    {NAVBAR_ITEMS.map(item => {
                                        return (
                                            <Link to={`/${item.name}`} key={item.name} style={{ textDecoration: 'none', color: '#FFFFFF'}}>
                                                <Col xs={2} className=''>
                                                    <p className={location?.pathname === `/${item.name}` ? 'p-navbar  clicked h-100' : 'p-navbar h-100'}>{item.upperCaseName}</p>
                                                </Col>
                                            </Link>
                                        );
                                    })}
                                </Col>
                                <Col></Col>
                                <Col xs={4} className='d-flex'>
                                    {NAVBAR_REQ.map(item => {
                                        return (
                                            <Link to={`/${item.name}`} key={item.name} className='text-end' style={{ textDecoration: 'none', color: '#FFFFFF', width: '100%'}}>
                                                <Col className={location?.pathname === `/${item.name}` ? 'nav-button-create p-navbar clicked h-100' : 'nav-button-create p-navbar h-100'} >
                                                    <img src={item.img} style={{ width: '24px', height: '24px', marginRight: '0.5rem' }} />
                                                    <p className='nav__button-p'>{item.upperCaseName}</p>
                                                </Col>
                                            </Link>
                                        );
                                    })}
                                </Col>
                            </Row>
                        </Col>}
                    </Row>
                </Container>
            </nav>
        </>
    );
};

export default Navbar;