import { 
    img01DO,
    img01SU,
    img02,
    img04,
    img07,
    img17,
} from '../assets/index.js';

const FORM_01_INTENTION = {
    title: 'Žádost o předběžnou informaci',
    perex: 'stavebního úřadu podle ustanovení § 172 odst. 2 ve spojení s § 174 odst. 1 a 3 zákona č. 283/2021 Sb., stavební zákon, ve znění pozdějších předpisů, a § 2 vyhlášky č. 149/2024 Sb., o provedení některých ustanovení stavebního zákona a',
    perex1: 'dotčeného orgánu podle ustanovení § 172 odst. 1 a 2 ve spojení s § 174 odst. 2 a 3 zákona č. 283/2021 Sb., stavební zákon, ve znění pozdějších předpisů  a přílohy č. 1 vyhlášky č. 190/2024 Sb., o podrobnostech některých informačních systémů stavebního zákona'
};
const FORM_02_INTENTION = {
    title: 'Žádost o vyjádření nebo závazné stanovisko dotčeného orgánu',
    perex: 'podle ustanovení § 172 odst. 1 a 2 ve spojení s § 177 zákona č. 283/2021 Sb., stavební zákon, ve znění pozdějších předpisů a přílohy č. 2 vyhlášky č. 190/2024 Sb., o podrobnostech některých informačních systémů stavebního zákona'
};
const FORM_03_INTENTION = {
    title: 'Žádost o vyjádření vlastníka veřejné dopravní nebo technické infrastruktury',
    perex: 'podle ustanovení § 172 odst. 2 ve spojení s § 180 zákona č. 283/2021 Sb., stavební zákon, ve znění pozdějších předpisů, a § 3 vyhlášky č. 149/2024 Sb., o provedení některých ustanovení stavebního zákona'
};
const FORM_04_INTENTION = {
    title: 'Žádost o povolení stavby nebo zařízení',
    perex: 'podle ustanovení § 172 odst. 2 ve spojení s § 184, § 200 odst. 2, § 212, § 213, § 216 odst. 2, § 218 odst. 3 a § 228 odst. 2 zákona č. 283/2021 Sb., stavební zákon, ve znění pozdějších předpisů, a § 4 vyhlášky č. 149/2024 Sb., o provedení některých ustanovení stavebního zákona'
};
const FORM_05_INTENTION = {
    title: 'Žádost o změnu povolení',
    perex: 'podle ustanovení § 172 odst. 2 ve spojení s § 200 odst. 1 zákona č. 283/2021 Sb., stavební zákon, ve znění pozdějších předpisů, a § 5 vyhlášky č. 149/2024 Sb., o provedení některých ustanovení stavebního zákona'
};
const FORM_06_INTENTION = {
    title: 'Žádost o povolení změny využití území',
    perex: 'podle ustanovení § 172 odst. 2 ve spojení s § 184, § 200 odst. 2, § 212 a § 213 zákona č. 283/2021 Sb., stavební zákon, ve znění pozdějších předpisů, a § 6 vyhlášky č. 149/2024 Sb., o provedení některých ustanovení stavebního zákona'
};
const FORM_07_INTENTION = {
    title: 'Žádost o povolení dělení nebo scelení pozemků',
    perex: 'podle ustanovení § 172 odst. 2 ve spojení s § 184 a § 216 zákona č. 283/2021 Sb., stavební zákon, ve znění pozdějších předpisů, a § 7 vyhlášky č. 149/2024 Sb., o provedení některých ustanovení stavebního zákona'
};
const FORM_08_INTENTION = {
    title: 'Žádost o vydání rámcového povolení',
    perex: 'podle ustanovení § 172 odst. 2 ve spojení s § 184 a § 221 odst. 1 zákona č. 283/2021 Sb., stavební zákon, ve znění pozdějších předpisů, a § 8 vyhlášky č. 149/2024 Sb., o provedení některých ustanovení stavebního zákona'
};
const FORM_09_INTENTION = {
    title: 'Žádost o změnu záměru před dokončením',
    perex: 'podle ustanovení § 172 odst. 2 ve spojení s § 184 a § 224 odst. 4 zákona č. 283/2021 Sb., stavební zákon, ve znění pozdějších předpisů, a § 9 vyhlášky č. 149/2024 Sb., o provedení některých ustanovení stavebního zákona'
};
const FORM_10_INTENTION = {
    title: 'Návrh na stanovení ochranného pásma',
    perex: 'podle ustanovení § 172 odst. 2 ve spojení s § 184 a § 218 odst. 3 zákona č. 283/2021 Sb., stavební zákon, ve znění pozdějších předpisů, a § 10 vyhlášky č. 149/2024 Sb., o provedení některých ustanovení stavebního zákona'
};
const FORM_11_INTENTION = {
    title: 'Žádost o vydání kolaudačního rozhodnutí',
    perex: 'podle ustanovení § 172 odst. 2 ve spojení s § 232 zákona č. 283/2021 Sb., stavební zákon, ve znění pozdějších předpisů, a § 11 vyhlášky č. 149/2024 Sb., o provedení některých ustanovení stavebního zákona'
};
const FORM_12_INTENTION = {
    title: 'Žádost o povolení předčasného užívání stavby',
    perex: 'podle ustanovení § 172 odst. 2 ve spojení s § 236 odst. 3 zákona č. 283/2021 Sb., stavební zákon, ve znění pozdějších předpisů, a § 12 vyhlášky č. 149/2024 Sb., o provedení některých ustanovení stavebního zákona'
};
const FORM_13_INTENTION = {
    title: 'Žádost o povolení zkušebního provozu',
    perex: 'podle ustanovení § 172 odst. 2 ve spojení s § 237 odst. 3 zákona č. 283/2021 Sb., stavební zákon, ve znění pozdějších předpisů, a § 13 vyhlášky č. 149/2024 Sb., o provedení některých ustanovení stavebního zákona'
};
const FORM_14_INTENTION = {
    title: 'Žádost o povolení změny v užívání stavby',
    perex: 'podle ustanovení § 172 odst. 2 ve spojení s § 239, § 243 a 260 zákona č. 283/2021 Sb., stavební zákon, ve znění pozdějších předpisů, a § 14 vyhlášky č. 149/2024 Sb., o provedení některých ustanovení stavebního zákona'
};
const FORM_15_INTENTION = {
    title: 'Žádost o povolení odstranění stavby, zařízení a terénních úprav',
    perex: 'podle ustanovení § 172 odst. 2 ve spojení s § 248 a § 261 odst. 1 zákona č. 283/2021 Sb., stavební zákon, ve znění pozdějších předpisů, a § 15 vyhlášky č. 149/2024 Sb., o provedení některých ustanovení stavebního zákona'
};
const FORM_16_INTENTION = {
    title: 'Žádost o dodatečné povolení stavby, zařízení a terénních úprav',
    perex: 'podle ustanovení § 172 odst. 2 ve spojení s § 184, § 213 písm. a), § 255 odst. 1 a odst. 2 a § 261 odst. 1 zákona č. 283/2021 Sb., stavební zákon, ve znění pozdějších předpisů, a § 16 vyhlášky č. 149/2024 Sb., o provedení některých ustanovení stavebního zákona'
};
const FORM_17_INTENTION = {
    title: 'Doplnění žádosti či odstranění vad žádosti',
    perex: 'podle ustanovení § 185 odst. 2, § 198 odst. 4, § 234 odst. 1, § 258 odst. 1 zákona č. 283/2021 Sb., stavební zákon, ve znění pozdějších předpisů'
};
const FORM_18_INTENTION = {
    title: 'Průvodní list dokumentace',
    perex: 'podle ustanovení § 157 zákona č. 283/2121 Sb., stavební zákon, ve znění pozdějších předpisů'
};

// TODO BE a FE values not matching see BE values below:
// DocumentTypeEssl.java
// FORM1A("build-application-01a","Žádost o předběžnou informaci SÚ","SR000400A08R","2.2."),
// FORM1B("build-application-01b","Žádost o předběžnou informaci DO","SR000400A09M","3.2."),
// FORM2("build-application-02","Žádost o vyjádření nebo závazné stanovisko DO","SR000400A0AH","3.2."),
// FORM3("build-application-03","Žádost o vyjádření vlastníka VD a TI","SR000400A0BC","1.2."),
// FORM4("build-application-04","Žádost o vydání povolení stavby","SR000400A0C7","2.2."),
// FORM5("build-application-05","Žádost o změnu povolení","SR000400A0D2","2.2."),
// FORM6("build-application-06","Žádost o využití změny území","SR000400A0EX","2.2."),
// FORM7("build-application-07","Žádost o povolení dělení nebo scelení pozemků","SR000400A0FS","2.2."),
// FORM8("build-application-08","Žádost o vydání rámcového povolení","SR000400A0GN","2.2."),
// FORM9("build-application-09","Žádost o změnu záměru před ukončením","SR000400A0HI","2.2."),
// FORM10("build-application-10","Návrh na stanovení ochranného pásma","SR000400A0ID","2.2."),
// FORM11("build-application-11","Žádost o vydání kolaudačního rozhodnutí","SR000400A0J8","2.2."),
// FORM12("build-application-12","Žádost o povolení předčasného užívání stavby","SR000400A0K3","2.2."),
// FORM13("build-application-13","Žádost o povolení zkušebního provozu","SR000400A0LY","2.2."),
// FORM14("build-application-14","Žádost o povolení změny v užívání stavby","SR000400A0MT","2.2."),
// FORM15("build-application-15","Žádost o povolení odstranění stavby nebo terénní úpravy","SR000400A0NO","2.2."),
// FORM16("build-application-16","Žádost o dodatečné povolení stavby a terénní úpravy","SR000400A0OJ","2.2."),
// FORM17("build-application-17","Doplnění žádosti či odstranění vad žádosti","SR000400A0PE","2.2."),

// temporarily add BE values

const ALL_TYPES = [
    'Žádost o předběžnou informaci SÚ',
    'Žádost o předběžnou informaci DO',
    'Žádost o vyjádření nebo závazné stanovisko DO',
    'Žádost o vyjádření vlastníka VD a TI',
    'Žádost o vydání povolení stavby',
    'Žádost o změnu povolení',
    'Žádost o využití změny území',
    'Žádost o povolení dělení nebo scelení pozemků',
    'Žádost o vydání rámcového povolení',
    'Žádost o změnu záměru před ukončením',
    'Návrh na stanovení ochranného pásma',
    'Žádost o vydání kolaudačního rozhodnutí',
    'Žádost o povolení předčasného užívání stavby',
    'Žádost o povolení zkušebního provozu',
    'Žádost o povolení změny v užívání stavby',
    'Žádost o povolení odstranění stavby nebo terénní úpravy',
    'Žádost o dodatečné povolení stavby a terénní úpravy',
    'Doplnění žádosti či odstranění vad žádosti',
];

const PATHS = [
    {
        target: 'form01',
        title: 'Žádost o předběžnou informaci',
    },
    {
        target: 'form02',
        title: 'Žádost o vyjádření nebo závazné stanovisko dotčeného orgánu',
    },
    {
        target: 'form03',
        title: 'Žádost o vyjádření vlastníka veřejné dopravní nebo technické infrastruktury',
    },
    {
        target: 'form04',
        title: 'Žádost o povolení stavby nebo zařízení',
    },
    {
        target: 'form05',
        title: 'Žádost o změnu povolení',
    },
    {
        target: 'form06',
        title: 'Žádost o povolení změny využití území',
    },
    {
        target: 'form07',
        title: 'Žádost o povolení dělení nebo scelení pozemků',
    },
    {
        target: 'form08',
        title: 'Žádost o vydání rámcového povolení',
    },
    {
        target: 'form09',
        title: 'Žádost o změnu záměru před dokončením',
    },
    {
        target: 'form10',
        title: 'Návrh na stanovení ochranného pásma',
    },
    {
        target: 'form11',
        title: 'Žádost o vydání kolaudačního rozhodnutí',
    },
    {
        target: 'form12',
        title: 'Žádost o povolení předčasného užívání stavby',
    },
    {
        target: 'form13',
        title: 'Žádost o povolení zkušebního provozu',
    },
    {
        target: 'form14',
        title: 'Žádost o povolení změny v užívání stavby',
    },
    {
        target: 'form15',
        title: 'Žádost o povolení odstranění stavby, zařízení a terénních úprav',
    },
    {
        target: 'form16',
        title: 'Žádost o dodatečné povolení stavby, zařízení a terénních úprav',
    },
    {
        target: 'form17',
        title: 'Doplnění žádosti či odstranění vad žádosti',
    },
    {
        target: 'form18',
        title: 'Průvodní list dokumentace',
    }
];

const AUTHORITY_MODEL = {
    authorityName: '',
    rosId: '',
    aisRepresentative: '',
    iskoId: '',
    address: {
        city: '',
        cityPart: '',
        street: '',
        descNum: '',
        orientNum: '',
        zip: ''
    }
};

const POWER_OF_ATTORNEY_FILE_MODEL = {
    uid: null,
    fileName: null,
    url: null,
    section: null,
    subSection: null,
    size: null,
    personId: null,
    status: null
};

const APPLICANT_TYPE_FO = 'individual';
const APPLICANT_TYPE_FOP = 'businessman';
const APPLICANT_TYPE_PO = 'more';

const ATTORNEY_TYPE_FO = 'representativeIndividual';
const ATTORNEY_TYPE_FOP = 'representativeBusinessman';
const ATTORNEY_TYPE_PO = 'representativeMore';

const APPLICANT_MODEL = {
    id: null,
    isFO: true,
    isFOBusiness: false,
    isPO: false,
    ico: '',    
    names: '',
    lastName: '',
    lastNameOrigin: '',
    dateOfBirth: '',
    nationality: 'CZE',
    powerOfAttorneyFile: POWER_OF_ATTORNEY_FILE_MODEL,
    shippingSameAsPr: true,
    powerOfAttorneyExists: false,
    powerOfRepresentativeExists: false,
    powerOfAttorneyRepresentative: POWER_OF_ATTORNEY_FILE_MODEL,
    idPowerOfAttorneyExisting: '',
    idPowerOfRepresentativeExisting: '',
    repreDateOfBirth: '',
    repreLastName: '',
    repreLastnameOrigin: '',
    repreNames: '',
    repreNationality: 'CZE',
    includeRepresentative: false,
    address: {
        city: '',
        cityPart: '',
        street: '',
        descNum: '',
        orientNum: '',
        zip: '',
    },
    shippingAddress: {
        city: '',
        cityPart: '',
        street: '',
        descNum: '',
        orientNum: '',
        zip: '',
    },
    phone: '',
    email: '',
    idDataBox: '',    
    appendix: '',
    poName: '',
    mandate: '',
    mandateFrom: '',
    mandateTO: '',
};

const OVERALL_SITUATION = 'overall_situation';
const PLNA_MOC_FIRST = 'power_of_attorney_advocate';
const PLNA_MOC_SECOND = 'power_of_attorney_more';
const PLNA_MOC_THIRD = 'power_of_attorney_applicant_representative';
const PLNA_MOC_FOURTH = 'power_of_attorney_representative';
const PLNA_MOC_FIFTH = 'power_of_attorney_applicant_representative_more';
const WARRANT = 'warrant';
const AFFIDAVIT = 'affidavit';
const OWNER_CONSENT = 'owner_consent';
const EXPRESSION = 'neighboring_owner_statement';
const CONSENT_ALL = 'consent_all';
const PARTICIPANTS_LIST = 'participants_list';
const COMPLIANCE_PROOF = 'compliance_proof';
const PLANNING_CONTRACT = 'planning_contract';
const FULFILLING_PRODUCT = 'fulfilling_function_of_construction';
const OTHER_DOCUMENTS = 'other_documents';

// ACCEPTED FILE FORMATS
const ACCEPTED_FORMATS_ARRAY_DOKUMENTACE_PRILOHY = ['pdf',]; // OZE-1999
const ACCEPTED_FORMATS_ARRAY = ['pdf', 'dwg', 'ifc', 'png', 'jpg', 'jpeg', 'json', 'bim', 'cad', 'shp'];
const ACCEPTED_FORMATS_ARRAY_DOKLADOVA_CAST = ['pdf', 'xml']; // OZE-1999
const ACCEPTED_FORMATS_ARRAY_CSV = ['csv']; // putting an array in case clients may add other formats in the future
// Table Types
const STAVBY_TABLE = 'csv_import_stavby_table';
const POZEMKY_TABLE = 'csv_import_pozemky_table';
const AFFECTED_STAVBY_TABLE = 'csv_import_affected_stavby_table';
const APPROVED_STAVBY_TABLE = 'csv_import_approved_stavby_table';
const AFFECTED_POZEMKY_TABLE = 'csv_import_affected_pozemky_table';
const PROJEKTANT_DOCUMENTATION_TABLE = 'csv_import_projektant_documentation_table';
// search param value for prijemce, adding as global const as we need to show it dynamically in some prijemce components
const BUILDING = 'BUILDING';
const AFFECTED = 'AFFECTED';

// csv vzory
const CSV_MODEL_STAVBY = [
    'ICS',
    'Katastralni uzemi',
    'ID Katastralniho uzemi',
    'Parcelni cislo',
    'Cislo popisne',
    'Cislo jednotky',
    'Cislo evidencni',
    'Zpusob vyuziti',
    'Id zameru',
    'Nazev zameru',
    'Typ parcely'
]; 

const CSV_MODEL_STAVBY_10 = [
    'ICS',
    'Katastralni uzemi',
    'ID Katastralniho uzemi',
    'Parcelni cislo',
    'Cislo popisne',
    'Cislo evidencni',
    'Zpusob vyuziti',
    'Id zameru',
    'Nazev zameru',
    'Typ parcely',
]; 

const CSV_MODEL_STAVBY_11 = [
    'ICS',
    'Katastralni uzemi',
    'ID Katastralniho uzemi',
    'Parcelni cislo',
    'Cislo popisne',
    'Cislo evidencni',
    'Id zameru',
    'Nazev zameru',
    'Typ parcely',
    'Druh a účel stavby',
    'Účel užívání',
    'Stavba dočasná (Ano/Ne)',
    'Počet bytů',
    'Část stavby (Ano/Ne)',
    'Údaj o poloze definičního bodu stavby',
    'Klasifikační třída podle Průkazu energetické náročnosti budovy',
]; 

const CSV_MODEL_STAVBY_12 = [
    'ICS',
    'Katastralni uzemi',
    'Parcelni cislo',
    'Cislo popisne',
    'Cislo evidencni',
    'Id zameru',
    'Nazev zameru',
    'Druh a účel stavby',
    'Účel užívání',
    'Stavba dočasná (Ano/Ne)',
    'Počet bytů',
    'Část stavby (Ano/Ne)',
    'Údaj o poloze definičního bodu stavby',
    'Klasifikační třída podle Průkazu energetické náročnosti budovy',
]; 

const CSV_MODEL_STAVBY_13 = [
    'ICS',
    'Katastralni uzemi',
    'Parcelni cislo',
    'Cislo popisne',
    'Cislo evidencni',
    'Id zameru',
    'Nazev zameru',
    'Typ parcely',
    'Druh a účel stavby',
    'Účel užívání',
    'Stavba dočasná (Ano/Ne)',
    'Počet bytů',
    'Část stavby (Ano/Ne)',
]; 

const CSV_MODEL_STAVBY_14 = [
    'ICS',
    'Katastralni uzemi',
    'ID Katastralniho uzemi',
    'Parcelni cislo',
    'Cislo popisne',
    'Cislo evidencni',
    'Zpusob vyuziti',
    'Id zameru',
    'Nazev zameru',
    'Typ parcely',
    'Druh a ucel stavby', 
    'Ucel uzivani', 
    'Stavba docasna (Ano/Ne)'
]; 

const CSV_MODEL_STAVBY_18 = [
    'ICS',
    'Katastralni uzemi',
    'ID Katastralniho uzemi',
    'Parcelni cislo',
    'Cislo popisne',
    'Cislo evidencni',
    'Zpusob vyuziti',
    'Id zameru',
    'Nazev zameru',
    'Typ parcely',
    'Je soucasti souboru staveb (Ano/Ne)',
    'Oznaceni v ramci souboru staveb',
    'Obestaveny prostor',
    'Zastavena plocha',
    'Podlahova plocha',
    'Pocet podzemnnich podlazi',
    'Pocet nadzemnich podlazi',
    'Zpusob vyuziti',
    'Druh konstrukce',
    'Zpusob vytapeni',
    'Pripojka vodobodu',
    'Pripojka kanalizacni site',
    'Pripojka plynu',
    'Vytah',
    'Hloubka stavby',
    'Vyska stavby',
    'Predpokladana kapacita poctu osob ve stavbe',
    'Planovany zacatek a konec realizace stavby',
]; 


const CSV_MODEL_POZEMKU = [
    'ICS',
    'Katastralni uzemi',
    'ID Katastralniho uzemi',
    'Parcelni cislo',
    'Druh',
    'Vymera',
    'Zmena vyuziti (Ano/Ne)',
    'Deleni sceleni (Ano/Ne)',
    'Deleni (Ano/Ne)',
    'Sceleni (Ano/Ne)',
    'Ochranne pasmo (Ano/Ne)',
    'Staveniste (Ano/Ne)',
    'Typ parcely',
    'Id zameru',
    'Nazev zameru',
    'Stavajici zpusob uzivani pozemku',
    'Novy zpusob uzivani pozemku',
]; 

const CSV_MODEL_POZEMKU_08_OR_10_15 = [
    'ICS',
    'Katastralni uzemi',
    'ID Katastralniho uzemi',
    'Parcelni cislo',
    'Druh',
    'Vymera',
    'Typ parcely',
    'Id zameru',
    'Nazev zameru',
]; 

const CSV_MODEL_POZEMKU_07= [
    'ICS',
    'Katastralni uzemi',
    'ID Katastralniho uzemi',
    'Parcelni cislo',
    'Druh',
    'Vymera',
    'Deleni (Ano/Ne)',
    'Sceleni (Ano/Ne)',
    'Typ parcely',
    'Id zameru',
    'Nazev zameru',
];

const CSV_MODEL_POZEMKU_06 = [
    'ICS',
    'Katastralni uzemi',
    'ID Katastralniho uzemi',
    'Parcelni cislo',
    'Druh',
    'Vymera',
    'Typ parcely',
    'Id zameru',
    'Nazev zameru',
    'Stavajici zpusob uzivani pozemku',
    'Novy zpusob uzivani pozemku',
];

const CSV_MODEL_POZEMKU_01_OR_02_OR_04_OR_09_OR_16_OR_17_OR_18 = [
    'ICS',
    'Katastralni uzemi',
    'ID Katastralniho uzemi',
    'Parcelni cislo',
    'Druh',
    'Vymera',
    'Zmena vyuziti (Ano/Ne)',
    'Deleni sceleni (Ano/Ne)',
    'Ochranne pasmo (Ano/Ne)',
    'Staveniste (Ano/Ne)',
    'Typ parcely',
    'Id zameru',
    'Nazev zameru',
]; 

const CSV_MODEL_PROJEKTANT_DOCUMENT = [
    'Cast dokumentace', // "string"
    'Titul pred jmenem', // "string",
    'Titul za jmenem', // "string",
    'Jmeno', // "string",
    'Prijmeni', // "string",
    'Rodne prijmeni', // "string"
    'Statni prislusnost', // "string"
    'Cislo v evidenci autorizovanych osob', // "string"
    'Obor', // "string"
]; 

// for nejcastejsi zadosti and others to show in homepagemain section and zadosti.
const NEW_REQUEST_HEADER = [
    {
        title: 'Žádost o předběžnou informaci stavebního úřadu',
        subtitle: 'Tento formulář žádosti umožňuje požádat stavební úřad o předběžnou informaci:',
        info: ['o podmínkách využívání území a změn jeho využití', 'o nezbytnosti povolení záměru a jeho kolaudace', 'o tom, podle jakých hledisek bude stavební úřad žádost o vydání rozhodnutí o povolení záměru posuzovat a za jakých předpokladů lze žádosti vyhovět', 'o dotčených orgánech ve vztahu k záměru'],
        category: '01',
        link: '/zadost/form01',
        img: img01SU,
    },
    {
        title: 'Žádost o předběžnou informaci dotčeného orgánu',
        subtitle: 'Tento formulář žádosti umožňuje požádat dotčený orgán o předběžnou informaci:',
        info: ['o nezbytnosti vyjádření nebo závazného stanoviska dotčeného orgánu', 'o hlediscích, které budou posuzovány v žádosti o vydání vyjádření nebo závazného stanoviska a za jakých předpokladů lze žádosti vyhovět'],
        category: 'VZOR',
        link: '/zadost/form01',
        img: img01DO,
    },
    {
        title: 'Žádost o vyjádření nebo závazné stanovisko dotčeného orgánu',
        subtitle: 'Tento formulář žádosti umožňuje požádat dotčený orgán:',
        info: ['o koordinované vyjádření nebo koordinované závazné stanovisko, včetně jednotného environmentálního stanoviska, jehož součástí není stanovisko k posouzení vlivu záměru na životní prostředí (EIA)', 'o jednotné environmentální stanovisko, jehož součástí není stanovisko EIA', 'o jednotné environmentální stanovisko, včetně stanoviska EIA', 'o vyjádření nebo závazné stanovisko z hlediska např. ochrany vod, životního prostředí, ochrany veřejného zdraví, dopravy, památkové péče, požární ochrany atd'],
        category: 'VZOR',
        link: '/zadost/form02',
        img: img02,
    },
    {
        title: 'Žádost o povolení stavby nebo zařízení',
        subtitle: 'Tento formulář žádosti umožňuje požádat o povolení stavby nebo zařízení ve standardním řízení o povolení stavby nebo ve zrychleném řízení o povolení stavby. Součástí této žádosti může být i žádost',
        info: ['o výjimku z požadavků na výstavbu anebo žádost o změnu nebo', 'o zrušení povolení v řízení o povolení veřejně prospěšné stavby nebo veřejně prospěšného opatření.'],
        subtitle1: 'Pokud bude současně se stavbou prováděn i záměr nestavební povahy, umožňuje tento formulář požádat zároveň o:',
        info1: ['změnu využití území', 'dělení nebo scelení pozemků', 'stanovení ochranného pásma, pokud se to netýká blíže neurčeného okruhu osob nebo se nejedná o stavbu vodního díla'],
        category: '03',
        link: '/zadost/form04',
        img: img04,
    },
    {
        title: 'Žádost o povolení dělení nebo scelení pozemků',
        subtitle: 'Tento formulář žádosti se využije v případě, kdy má být velký pozemek rozdělován na menší pozemky, nebo v případě, kdy má dojít ke scelování více pozemků do jednoho pozemku. Žádost musí být podána všemi vlastníky dotčených pozemků, které jsou předmětem povolení, nebo alespoň jedním z vlastníků s doložením souhlasu ostatních vlastníků dotčených pozemků. V případě veřejně prospěšné stavby nebo veřejně prospěšného opatření může žádost podat i stavebník.',
        info: [],
        category: '06',
        link: '/zadost/form07',
        img: img07,
    },
    {
        title: 'Doplnění žádosti či odstranění vad žádosti',
        subtitle: 'Tento formulář slouží k doplnění žádosti a odstranění vad žádosti na výzvu stavebního úřadu či dotčeného orgánu.',
        info: [],
        category: 'VZOR',
        link: '/zadost/form17',
        img: img17,
    },
];

const PERSON_MODEL = {
    id: '',
    //personRelation: 'DOCUMENT_PROCESSOR',
    isFO: false,
    isFOBusiness: true,
    isPO: false,
    ico: '',
    names: '',
    lastName: '',
    lastNameOrigin: '',
    dateOfBirth: '',
    nationality: 'CZE',
    powerOfAttorneyFile: POWER_OF_ATTORNEY_FILE_MODEL,
    powerOfAttorneyExists: false,
    idPowerOfAttorneyExisting: '',
    address: {
        city: '',
        cityPart: '',
        street: '',
        descNum: '',
        orientNum: '',
        zip: ''
    },
    shippingSameAsPr: false,
    shippingAddress: {
        city: '',
        cityPart: '',
        street: '',
        descNum: '',
        orientNum: '',
        zip: ''
    },
    phone: '',
    email: '',
    idDataBox: '',
    appendix: '',
    poName: '',
    mandate: '',
    mandateFrom: '',
    mandateTO: '',
    dirty: false
};

const ACCOMPANYING_PERSON_MODEL = {
    documentationPart: '',
    titleBefore: '',
    titleAfter: '',
    firstName: '',
    lastName: '',
    lastNameOrigin: '',
    nationality: 'CZE',
    ais: '',
    field: '',
};

const POST_BODY_FOR_FILTER = {
    offset: 0,
    size: 100,
    filters: [
        // {
        //     field: '',
        //     operation: 'EQ',
        //     value: '',
        // }
    ],
    sorts: [
        // {
        //     field: '',
        //     asc: true
        // }
    ],
    attributes: null,
    dirty: false,
};

const COUNTRIES = [
    {value: 'AFG', label: 'Afghánistán'},
    {value: 'ALA', label: 'Alandy'},
    {value: 'ALB', label: 'Albánie'},
    {value: 'DZA', label: 'Alžírsko'},
    {value: 'ASM', label: 'Americká Samoa'},
    {value: 'VIR', label: 'Americké Panenské ostrovy'},
    {value: 'AND', label: 'Andorra'},
    {value: 'AGO', label: 'Angola'},
    {value: 'AIA', label: 'Anguilla'},
    {value: 'ATA', label: 'Antarktida'},
    {value: 'ATG', label: 'Antigua a Barbuda'},
    {value: 'ARG', label: 'Argentina'},
    {value: 'ARM', label: 'Arménie'},
    {value: 'ABW', label: 'Aruba'},
    {value: 'AUS', label: 'Austrálie'},
    {value: 'AZE', label: 'Ázerbájdžán'},
    {value: 'BHS', label: 'Bahamy'},
    {value: 'BHR', label: 'Bahrajn'},
    {value: 'BGD', label: 'Bangladéš'},
    {value: 'BRB', label: 'Barbados'},
    {value: 'BEL', label: 'Belgie'},
    {value: 'BLZ', label: 'Belize'},
    {value: 'BLR', label: 'Bělorusko'},
    {value: 'BEN', label: 'Benin'},
    {value: 'BMU', label: 'Bermudy'},
    {value: 'BTN', label: 'Bhútán'},
    {value: 'BOL', label: 'Bolívie'},
    {value: 'BES', label: 'Bonaire, Svatý Eustach a Saba'},
    {value: 'BIH', label: 'Bosna a Hercegovina'},
    {value: 'BWA', label: 'Botswana'},
    {value: 'BVT', label: 'Bouvetův ostrov'},
    {value: 'BRA', label: 'Brazílie'},
    {value: 'IOT', label: 'Britské indickooceánské území'},
    {value: 'VGB', label: 'Britské Panenské ostrovy'},
    {value: 'BRN', label: 'Brunej'},
    {value: 'BGR', label: 'Bulharsko'},
    {value: 'BFA', label: 'Burkina Faso'},
    {value: 'BDI', label: 'Burundi'},
    {value: 'COK', label: 'Cookovy ostrovy'},
    {value: 'CUW', label: 'Curaçao'},
    {value: 'TCD', label: 'Čad'},
    {value: 'MNE', label: 'Černá Hora'},
    {value: 'CZE', label: 'Česko'},
    {value: 'CHN', label: 'Čína'},
    {value: 'DNK', label: 'Dánsko'},
    {value: 'DMA', label: 'Dominika'},
    {value: 'DOM', label: 'Dominikánská republika'},
    {value: 'DJI', label: 'Džibutsko'},
    {value: 'EGY', label: 'Egypt'},
    {value: 'ECU', label: 'Ekvádor'},
    {value: 'ERI', label: 'Eritrea'},
    {value: 'EST', label: 'Estonsko'},
    {value: 'ETH', label: 'Etiopie'},
    {value: 'FRO', label: 'Faerské ostrovy'},
    {value: 'FLK', label: 'Falklandy (Malvíny)'},
    {value: 'FJI', label: 'Fidži'},
    {value: 'PHL', label: 'Filipíny'},
    {value: 'FIN', label: 'Finsko'},
    {value: 'FRA', label: 'Francie'},
    {value: 'GUF', label: 'Francouzská Guyana'},
    {value: 'ATF', label: 'Francouzská jižní a antarktická území'},
    {value: 'PYF', label: 'Francouzská Polynésie'},
    {value: 'GAB', label: 'Gabon'},
    {value: 'GMB', label: 'Gambie'},
    {value: 'GHA', label: 'Ghana'},
    {value: 'GIB', label: 'Gibraltar'},
    {value: 'GRD', label: 'Grenada'},
    {value: 'GRL', label: 'Grónsko'},
    {value: 'GEO', label: 'Gruzie'},
    {value: 'GLP', label: 'Guadeloupe'},
    {value: 'GUM', label: 'Guam'},
    {value: 'GTM', label: 'Guatemala'},
    {value: 'GGY', label: 'Guernsey'},
    {value: 'GIN', label: 'Guinea'},
    {value: 'GNB', label: 'Guinea-Bissau'},
    {value: 'GUY', label: 'Guyana'},
    {value: 'HTI', label: 'Haiti'},
    {value: 'HMD', label: 'Heardův ostrov a MacDonaldovy ostrovy'},
    {value: 'HND', label: 'Honduras'},
    {value: 'HKG', label: 'Hongkong'},
    {value: 'CHL', label: 'Chile'},
    {value: 'HRV', label: 'Chorvatsko'},
    {value: 'IND', label: 'Indie'},
    {value: 'IDN', label: 'Indonésie'},
    {value: 'IRQ', label: 'Irák'},
    {value: 'IRN', label: 'Írán'},
    {value: 'IRL', label: 'Irsko'},
    {value: 'ISL', label: 'Island'},
    {value: 'ITA', label: 'Itálie'},
    {value: 'ISR', label: 'Izrael'},
    {value: 'JAM', label: 'Jamajka'},
    {value: 'JPN', label: 'Japonsko'},
    {value: 'YEM', label: 'Jemen'},
    {value: 'JEY', label: 'Jersey'},
    {value: 'ZAF', label: 'Jižní Afrika'},
    {value: 'SGS', label: 'Jižní Georgie a Jižní Sandwichovy ostrovy'},
    {value: 'SSD', label: 'Jižní Súdán'},
    {value: 'JOR', label: 'Jordánsko'},
    {value: 'CYM', label: 'Kajmanské ostrovy'},
    {value: 'KHM', label: 'Kambodža'},
    {value: 'CMR', label: 'Kamerun'},
    {value: 'CAN', label: 'Kanada'},
    {value: 'CPV', label: 'Kapverdy'},
    {value: 'QAT', label: 'Katar'},
    {value: 'KAZ', label: 'Kazachstán'},
    {value: 'KEN', label: 'Keňa'},
    {value: 'KIR', label: 'Kiribati'},
    {value: 'CCK', label: 'Kokosové (Keelingovy) ostrovy'},
    {value: 'COL', label: 'Kolumbie'},
    {value: 'COM', label: 'Komory'},
    {value: 'COD', label: 'Konžská demokratická republika'},
    {value: 'COG', label: 'Konžská republika'},
    {value: 'PRK', label: 'Korejská lidově demokratická republika'},
    {value: 'KOR', label: 'Korejská republika'},
    {value: 'XXK', label: 'Kosovo'},
    {value: 'CRI', label: 'Kostarika'},
    {value: 'CUB', label: 'Kuba'},
    {value: 'KWT', label: 'Kuvajt'},
    {value: 'CYP', label: 'Kypr'},
    {value: 'KGZ', label: 'Kyrgyzstán'},
    {value: 'LAO', label: 'Laos'},
    {value: 'LSO', label: 'Lesotho'},
    {value: 'LBN', label: 'Libanon'},
    {value: 'LBR', label: 'Libérie'},
    {value: 'LBY', label: 'Libye'},
    {value: 'LIE', label: 'Lichtenštejnsko'},
    {value: 'LTU', label: 'Litva'},
    {value: 'LVA', label: 'Lotyšsko'},
    {value: 'LUX', label: 'Lucembursko'},
    {value: 'MAC', label: 'Macao'},
    {value: 'MDG', label: 'Madagaskar'},
    {value: 'HUN', label: 'Maďarsko'},
    {value: 'MYS', label: 'Malajsie'},
    {value: 'MWI', label: 'Malawi'},
    {value: 'MDV', label: 'Maledivy'},
    {value: 'MLI', label: 'Mali'},
    {value: 'MLT', label: 'Malta'},
    {value: 'IMN', label: 'Man'},
    {value: 'MAR', label: 'Maroko'},
    {value: 'MHL', label: 'Marshallovy ostrovy'},
    {value: 'MTQ', label: 'Martinik'},
    {value: 'MUS', label: 'Mauricius'},
    {value: 'MRT', label: 'Mauritánie'},
    {value: 'MYT', label: 'Mayotte'},
    {value: 'UMI', label: 'Menší odlehlé ostrovy USA'},
    {value: 'MEX', label: 'Mexiko'},
    {value: 'FSM', label: 'Mikronésie'},
    {value: 'MDA', label: 'Moldavsko'},
    {value: 'MCO', label: 'Monako'},
    {value: 'MNG', label: 'Mongolsko'},
    {value: 'MSR', label: 'Montserrat'},
    {value: 'MOZ', label: 'Mosambik'},
    {value: 'MMR', label: 'Myanmar'},
    {value: 'NAM', label: 'Namibie'},
    {value: 'NRU', label: 'Nauru'},
    {value: 'DEU', label: 'Německo'},
    {value: 'NPL', label: 'Nepál'},
    {value: 'NER', label: 'Niger'},
    {value: 'NGA', label: 'Nigérie'},
    {value: 'NIC', label: 'Nikaragua'},
    {value: 'NIU', label: 'Niue'},
    {value: 'NLD', label: 'Nizozemské království'},
    {value: 'NFK', label: 'Norfolk'},
    {value: 'NOR', label: 'Norsko'},
    {value: 'NCL', label: 'Nová Kaledonie'},
    {value: 'NZL', label: 'Nový Zéland'},
    {value: 'OMN', label: 'Omán'},
    {value: 'PAK', label: 'Pákistán'},
    {value: 'PLW', label: 'Palau'},
    {value: 'PSE', label: 'Palestina'},
    {value: 'PAN', label: 'Panama'},
    {value: 'PNG', label: 'Papua Nová Guinea'},
    {value: 'PRY', label: 'Paraguay'},
    {value: 'PER', label: 'Peru'},
    {value: 'PCN', label: 'Pitcairn'},
    {value: 'CIV', label: 'Pobřeží slonoviny'},
    {value: 'POL', label: 'Polsko'},
    {value: 'PRI', label: 'Portoriko'},
    {value: 'PRT', label: 'Portugalsko'},
    {value: 'AUT', label: 'Rakousko'},
    {value: 'REU', label: 'Réunion'},
    {value: 'GNQ', label: 'Rovníková Guinea'},
    {value: 'ROU', label: 'Rumunsko'},
    {value: 'RUS', label: 'Rusko'},
    {value: 'RWA', label: 'Rwanda'},
    {value: 'GRC', label: 'Řecko'},
    {value: 'SPM', label: 'Saint Pierre a Miquelon'},
    {value: 'SLV', label: 'Salvador'},
    {value: 'WSM', label: 'Samoa'},
    {value: 'SMR', label: 'San Marino'},
    {value: 'SAU', label: 'Saúdská Arábie'},
    {value: 'SEN', label: 'Senegal'},
    {value: 'MKD', label: 'Severní Makedonie'},
    {value: 'MNP', label: 'Severní Mariany'},
    {value: 'SYC', label: 'Seychely'},
    {value: 'SLE', label: 'Sierra Leone'},
    {value: 'SGP', label: 'Singapur'},
    {value: 'SVK', label: 'Slovensko'},
    {value: 'SVN', label: 'Slovinsko'},
    {value: 'SOM', label: 'Somálsko'},
    {value: 'ARE', label: 'Spojené arabské emiráty'},
    {value: 'USA', label: 'Spojené státy'},
    {value: 'SRB', label: 'Srbsko'},
    {value: 'CAF', label: 'Středoafrická republika'},
    {value: 'SDN', label: 'Súdán'},
    {value: 'SUR', label: 'Surinam'},
    {value: 'SHN', label: 'Svatá Helena'},
    {value: 'LCA', label: 'Svatá Lucie'},
    {value: 'BLM', label: 'Svatý Bartoloměj'},
    {value: 'KNA', label: 'Svatý Kryštof a Nevis'},
    {value: 'MAF', label: 'Svatý Martin (FR)'},
    {value: 'SXM', label: 'Svatý Martin (NL)'},
    {value: 'STP', label: 'Svatý Tomáš a Princův ostrov'},
    {value: 'VCT', label: 'Svatý Vincenc a Grenadiny'},
    {value: 'SWZ', label: 'Svazijsko'},
    {value: 'SYR', label: 'Sýrie'},
    {value: 'SLB', label: 'Šalomounovy ostrovy'},
    {value: 'ESP', label: 'Španělsko'},
    {value: 'SJM', label: 'Špicberky a Jan Mayen'},
    {value: 'LKA', label: 'Šrí Lanka'},
    {value: 'SWE', label: 'Švédsko'},
    {value: 'CHE', label: 'Švýcarsko'},
    {value: 'TJK', label: 'Tádžikistán'},
    {value: 'TZA', label: 'Tanzanie'},
    {value: 'THA', label: 'Thajsko'},
    {value: 'TWN', label: 'Tchaj-wan'},
    {value: 'TGO', label: 'Togo'},
    {value: 'TKL', label: 'Tokelau'},
    {value: 'TON', label: 'Tonga'},
    {value: 'TTO', label: 'Trinidad a Tobago'},
    {value: 'TUN', label: 'Tunisko'},
    {value: 'TUR', label: 'Turecko'},
    {value: 'TKM', label: 'Turkmenistán'},
    {value: 'TCA', label: 'Turks a Caicos'},
    {value: 'TUV', label: 'Tuvalu'},
    {value: 'UGA', label: 'Uganda'},
    {value: 'UKR', label: 'Ukrajina'},
    {value: 'URY', label: 'Uruguay'},
    {value: 'UZB', label: 'Uzbekistán'},
    {value: 'CXR', label: 'Vánoční ostrov'},
    {value: 'VUT', label: 'Vanuatu'},
    {value: 'VAT', label: 'Vatikán'},
    {value: 'GBR', label: 'Velká Británie a Severní Irsko'},
    {value: 'VEN', label: 'Venezuela'},
    {value: 'VNM', label: 'Vietnam'},
    {value: 'TLS', label: 'Východní Timor'},
    {value: 'WLF', label: 'Wallis a Futuna'},
    {value: 'ZMB', label: 'Zambie'},
    {value: 'ESH', label: 'Západní Sahara'},
    {value: 'ZWE', label: 'Zimbabwe'},
];

const ID_TYPES = [ 
    { value: 'ID', label: 'Občanský Průkaz' }, 
    { value: 'OP', label: 'Občanský Průkaz bez Strojově Čitelných Údajů' }, 
    { value: 'P', label: 'Cestovní Pas' }, 
    { value: 'CA', label: 'Cestovní Průkaz' }, 
    { value: 'IR', label: 'Povolení k Pobytu' }, 
    { value: 'VS', label: 'Vízový Lístek' }, 
    { value: 'PS', label: 'Pobytový Štítek' }, 
    { value: 'IX', label: 'Povolení k Pobytu (knížka)' }, 
    { value: 'IE', label: 'Potvrzení o Přechodném Pobytu/Osvědčení o Registraci' } 
];

const PARCEL_TYPE_LAND = 'Pozemková parcela';
const PARCEL_TYPE_BUILD = 'Stavební parcela';

const STAVBA_MODEL = {
    constructionId: null,
    cadastralTerritory: null,
    constructionNumber: null,
    constructionPurpose: '',
    dirty: false,
    flatNumber: null,
    parcelNumber: null,
    projectId: null,
    projectName: null,
    registrationNumber: null,
    setOfBuildings: false,
    setOfBuildingsMarking: '',
    buildUpArea: null,
    buildingParcel: null,
    cadastralTerritoryCode: null,
    classification: null,
    constructionType: null,
    depth: null,
    elevator: null,
    enclosedArea: null,
    estimatedPersonCapacity: null,
    estimatedStartEnd: null,
    floorArea: null,
    gasConnection: null,
    heatingType: null,
    height: null,
    landParcel: null,
    location: null,
    numberOfApartments: null,
    overgroundFloors: null,
    partOfConstruction: null,
    sewerNetworkConnection: null,
    temporary: null,
    undergroundFloors: null,
    // constructionPurpose: '',
    waterSupplyConnection: null,
    cityName: '',
    usePurpose: null,
};

const POZEMEK_MODEL = {
    constructionId: null, //"string",
    cadastralTerritory: null, //"string",
    parcelNumber: null, // "string",
    type: null, //"string",
    area: null, //"string",
    changeOfUse: null, //bool
    divisionOfFusion: null, //bool
    division: null, //bool
    fusion: null, //bool
    protectionZone: null, //bool
    constructionSite: null, //bool
    parcelType: null, //"string",
    projectId: null, //"string",
    projectName: null, //"string",
    actualParcelUsage: null, //"string",
    newParcelUsage: null, //"string",
    buildingParcel: null,
    cadastralTerritoryCode: null,
    landParcel: null,
    cityName: '',
};

// documents are always used commonly, no needs to get from respective form.js list
const DOCUMENT_FIRST = 'cover_letter';
const DOCUMENT_SECOND = 'summary_technical_report';
// const DOCUMENT_THIRD = 'situation_drawings';
const DOCUMENT_THIRD_1 = 'situation_drawing_of_wider_relationships';
const DOCUMENT_THIRD_2 = 'cadastral_situation_drawing';
const DOCUMENT_THIRD_3 = 'coordination_situation_drawing';
const DOCUMENT_THIRD_4 = 'special_drawings';
const DOCUMENT_THIRD_5 = 'division_or_consolidation_of_land';
const DOCUMENT_FOURTH = 'object_documentation';
const DOCUMENT_FIFTH = 'identification_document';

const RESPECTIVE_SECTION_DOCUMENT_NAMES = {
    [DOCUMENT_FIRST] : 'A — Průvodní list',
    [DOCUMENT_SECOND] : 'B — Souhrnná technická zpráva',
    [DOCUMENT_THIRD_1] : 'C1 - Situační výkres širších vztahů',
    [DOCUMENT_THIRD_2] : 'C2 - Katastrální situační výkres',
    [DOCUMENT_THIRD_3] : 'C3 - Koordinační situační výkres',
    [DOCUMENT_THIRD_4] : 'C4 - Speciální výkresy',
    [DOCUMENT_THIRD_5] : 'C5 - Dělení nebo scelení pozemků',
    [DOCUMENT_FOURTH] : 'D — Dokumentace objektů',
    [DOCUMENT_FIFTH] : 'E — Dokladová část',
    'ZAMER': 'ZAMER',
    // [PLNA_MOC_FIRST]: '',
    // [PLNA_MOC_SECOND]: '',
    [AFFIDAVIT]: 'Čestné prohlášení',
    [OVERALL_SITUATION]: 'Celková situace',
    [OWNER_CONSENT]: 'Souhlas vlastníka',
    [EXPRESSION]: 'Vyjádření vlastníka sousedního pozemku',
    [CONSENT_ALL]: 'Souhlasy všech účastníků řízení',
    [PARTICIPANTS_LIST]: 'Seznam známých účastníků řízení',
    [COMPLIANCE_PROOF]: 'Doklady prokazující shodu vlastností použitých výrobků s požadavky na stavby',
    [PLANNING_CONTRACT]: 'Plánovací smlouva',
    [FULFILLING_PRODUCT]: 'Doklad shody vlastností výrobku',
    [OTHER_DOCUMENTS]: 'Další součásti žádosti',
    [EXPRESSION]: 'Vyjádření vlastníka sousedního pozemku',
};

const PROPERTY_DEVELOPER_MODEL = {
    isFOBusiness: true,
    isPO: false,
    ico: '',
    poName: '',
    names: '',
    lastName: '',
    lastNameOrigin: '',
    nationality: 'CZE',
    appendix: '',
    idDataBox: '',
    address: {
        city: '',
        cityPart: '',
        street: '',
        descNum: '',
        orientNum: '',
        zip: '',
    }
};

// Zamer Table CONSTANTS commons
const CADASTRAL_AREA = 'Katastrální území';
const PARCEL_NUMBER = 'Parcelní číslo';

// Zamer Table CONSTANTS POZEMKY 

const TYPE = 'Druh';
const SURFACE_AREA = 'Výměra';
const CHANGE_OF_USE = 'Změna využití';
const DIVISION_UNIFICATION = 'Dělení scelení';
const DIVISION = 'Dělení';
const UNIFICATION = 'Scelení';
const PROTECTION = 'Ochranné pásmo';
const CONSTRUCTION_SITE = 'Staveniště';
const ACTUAL_PARCEL_USAGE = 'Stávající způsob užívání pozemku';
const NEW_PARCEL_USAGE = 'Nový způsob užívání pozemku';

// Zamer Table CONSTANTS STAVBY

const DESCRIPTIVE_NUMBER = 'Číslo popisné';
const REGISTRATION_NUMBER = 'Číslo evidenční';
const TYPE_OF_USE = 'Způsob využití';
const UNIT_NUMBER = 'Číslo jednotky';
const ICS = 'IČS';

const DRUH_KONSTRUKCE = [
    {
        code: '1',
        name: 'Nezjištěno',
    },
    {
        code: '2',
        name: 'Zděný z nepálených cihel',
    },
    {
        code: '3',
        name: 'Zděný cihelný',
    },
    {
        code: '4',
        name: 'Zděný kamenný',
    },
    {
        code: '5',
        name: 'Zděný jiné',
    },
    {
        code: '6',
        name: 'Zděný smíšený',
    },
    {
        code: '7',
        name: 'Monolitický betonový',
    },
    {
        code: '8',
        name: 'Montované stěnové panely betonové',
    },
    {
        code: '9',
        name: 'Montované stěnové panely dřevěné',
    },
    {
        code: '10',
        name: 'Montované stěnové panely jiné',
    },
    {
        code: '11',
        name: 'Dřevo - sruby a roubenky',
    },
    {
        code: '12',
        name: 'Dřevo - lehký rámcový skelet - panelová montáž',
    },
    {
        code: '13',
        name: 'Dřevo - lehký rámcový skelet - staveništní montáž',
    },
    {
        code: '14',
        name: 'Dřevo - těžký skelet',
    },
    {
        code: '15',
        name: 'Panely z masivního dřeva',
    },
    {
        code: '16',
        name: 'Dřevo - ostatní dřevěné konstrukce',
    },
    {
        code: '17',
        name: 'Kombinovaný',
    },
    {
        code: '18',
        name: 'Speciální a jiné',
    },
];

const ZPUSOB_VYTAPENI = [
    {
        code: '1',
        name: 'Nezjištěno',
    },
    {
        code: '2',
        name: 'Ústřední dálkové',
    },
    {
        code: '3',
        name: 'Ústřední domovní',
    },
    {
        code: '4',
        name: 'Ústřední bytové',
    },
    {
        code: '5',
        name: 'Kombinované',
    },
    {
        code: '6',
        name: 'Lokální zdroje',
    },
    {
        code: '7',
        name: 'Bez vytápění',
    },
];

const PRIPOJKA_VODOVODU = [
    {
        code: '1',
        name: 'Nezjištěno',
    },
    {
        code: '2',
        name: 'Bez zdroje',
    },
    {
        code: '3',
        name: 'Centrální zdroj',
    },
    {
        code: '4',
        name: 'Lokální zdroj',
    },
    {
        code: '5',
        name: 'Lokální zásobník',
    },
    {
        code: '6',
        name: 'Kombinované',
    },
];

const PRIPOJKA_KANALIZACNI_SITE = [
    {
        code: '1',
        name: 'Nezjištěno',
    },
    {
        code: '2',
        name: 'Bez zajištění',
    },
    {
        code: '3',
        name: 'Jednotná kanalizace s centrální čistírnou odpadních vod',
    },
    {
        code: '4',
        name: 'Jednotná kanalizace bez centrální čistírny odpadních vod',
    },
    {
        code: '5',
        name: 'Oddílná kanalizace s centrální čistírnou odpadních vod',
    },
    {
        code: '6',
        name: 'Lokální čistírna odpadních vod',
    },
    {
        code: '7',
        name: 'Lokální zásobník',
    },
    {
        code: '8',
        name: 'Kombinované',
    },
];

const PRIPOJKA_PLYNU = [
    {
        code: '1',
        name: 'Nezjištěno',
    },
    {
        code: '2',
        name: 'Bez zdroje',
    },
    {
        code: '3',
        name: 'Veřejná síť',
    },
    {
        code: '4',
        name: 'Domovní zásobník - bez rozlišení',
    },
    {
        code: '5',
        name: 'Domovní zásobník - LPG',
    },
    {
        code: '6',
        name: 'Domovní zásobník - CNG',
    },
    {
        code: '7',
        name: 'Domovní zásobník - LNG',
    },
    {
        code: '8',
        name: 'Domovní zásobník - bioplyn',
    },
    {
        code: '9',
        name: 'Domovní zásobník - degazační',
    },
    {
        code: '10',
        name: 'Domovní zásobník - jiné',
    },
    {
        code: '11',
        name: 'Kombinované',
    },
];

const VYTAH = [
    {
        code: '1',
        name: 'Nezjištěno',
    },
    {
        code: '2',
        name: 'S výtahem',
    },
    {
        code: '3',
        name: 'S výtahem bezbariérovým',
    },
    {
        code: '4',
        name: 'Bez výtahu',
    },
];
// filters and main sections
const ZAMERY = 'zamery';
const RIZENI = 'rizeni';
const STAVBY = 'stavby';
const ZADOSTI = 'zadosti';

export {
    PATHS,
    FORM_01_INTENTION,
    FORM_02_INTENTION,
    FORM_03_INTENTION,
    FORM_04_INTENTION,
    FORM_05_INTENTION,
    FORM_06_INTENTION,
    FORM_07_INTENTION,
    FORM_08_INTENTION,
    FORM_09_INTENTION,
    FORM_10_INTENTION,
    FORM_11_INTENTION,
    FORM_12_INTENTION,
    FORM_13_INTENTION,
    FORM_14_INTENTION,
    FORM_15_INTENTION,
    FORM_16_INTENTION,
    FORM_17_INTENTION,
    FORM_18_INTENTION,
    ALL_TYPES,
    PLNA_MOC_FIRST,
    PLNA_MOC_SECOND,
    PLNA_MOC_THIRD,
    PLNA_MOC_FOURTH,
    PLNA_MOC_FIFTH,
    OTHER_DOCUMENTS,
    APPLICANT_TYPE_FO,
    APPLICANT_TYPE_FOP,
    APPLICANT_TYPE_PO,
    ATTORNEY_TYPE_FO,
    ATTORNEY_TYPE_FOP,
    ATTORNEY_TYPE_PO,
    APPLICANT_MODEL,
    ACCEPTED_FORMATS_ARRAY,
    ACCEPTED_FORMATS_ARRAY_DOKUMENTACE_PRILOHY,
    ACCEPTED_FORMATS_ARRAY_DOKLADOVA_CAST,
    ACCEPTED_FORMATS_ARRAY_CSV,
    STAVBY_TABLE,
    POZEMKY_TABLE,
    AFFECTED_STAVBY_TABLE,
    APPROVED_STAVBY_TABLE,
    AFFECTED_POZEMKY_TABLE,
    BUILDING,
    AFFECTED,
    AUTHORITY_MODEL,
    POWER_OF_ATTORNEY_FILE_MODEL,
    CSV_MODEL_STAVBY,
    CSV_MODEL_STAVBY_10,
    CSV_MODEL_STAVBY_11,
    CSV_MODEL_STAVBY_12,
    CSV_MODEL_STAVBY_13,
    CSV_MODEL_STAVBY_14,
    CSV_MODEL_STAVBY_18,
    CSV_MODEL_POZEMKU,
    CSV_MODEL_POZEMKU_08_OR_10_15,
    CSV_MODEL_POZEMKU_07,
    CSV_MODEL_POZEMKU_06,
    CSV_MODEL_POZEMKU_01_OR_02_OR_04_OR_09_OR_16_OR_17_OR_18,
    RESPECTIVE_SECTION_DOCUMENT_NAMES,
    NEW_REQUEST_HEADER,
    CSV_MODEL_PROJEKTANT_DOCUMENT,
    PROJEKTANT_DOCUMENTATION_TABLE,
    PERSON_MODEL,
    POST_BODY_FOR_FILTER,
    ACCOMPANYING_PERSON_MODEL,
    COUNTRIES,
    ID_TYPES,
    PARCEL_TYPE_LAND,
    PARCEL_TYPE_BUILD,
    STAVBA_MODEL,
    POZEMEK_MODEL,
    DOCUMENT_FIRST,
    DOCUMENT_SECOND,
    DOCUMENT_THIRD_1,
    DOCUMENT_THIRD_2,
    DOCUMENT_THIRD_3,
    DOCUMENT_THIRD_4,
    DOCUMENT_THIRD_5,
    DOCUMENT_FOURTH,
    DOCUMENT_FIFTH,
    PROPERTY_DEVELOPER_MODEL,
    CADASTRAL_AREA,
    PARCEL_NUMBER,
    TYPE,
    SURFACE_AREA,
    CHANGE_OF_USE,
    DIVISION_UNIFICATION,
    DIVISION,
    UNIFICATION,
    PROTECTION,
    CONSTRUCTION_SITE,
    ACTUAL_PARCEL_USAGE,
    NEW_PARCEL_USAGE,
    DESCRIPTIVE_NUMBER,
    REGISTRATION_NUMBER,
    TYPE_OF_USE,
    UNIT_NUMBER,
    ICS,
    DRUH_KONSTRUKCE,
    ZPUSOB_VYTAPENI,
    PRIPOJKA_VODOVODU,
    PRIPOJKA_KANALIZACNI_SITE,
    PRIPOJKA_PLYNU,
    VYTAH,
    ZAMERY,
    RIZENI,
    STAVBY,
    ZADOSTI,
    WARRANT,
};
