import React from 'react';
import '../css/landingPage.css';
import { LandingPageHeaderSection } from '../components';
import { Container } from 'react-bootstrap';

const LandingPage = () => {
    return(
        <>
            <Container fluid className='landing-page' style={{margin: '0', padding: '0'}}>
                <LandingPageHeaderSection />
                {/* <LandingPageMainSection /> */}
            </Container>
        </>
    );
};

export default LandingPage;