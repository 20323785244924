import React, { useEffect, useState, useContext, useImperativeHandle } from 'react';
import { Container, Row } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/rozsah.css';
import { checkLimitAndInsertText,  shouldNotSave,  } from '../../helperFunctions/helpers';
import PropTypes from 'prop-types';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import FormContext from '../../formContexts/FormContext';
import { CharCount } from '../../components/index.js';
import useFirstRender from '../CustomHooks/useFirstRender.jsx';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import { useLocation, useNavigate } from 'react-router-dom';

const RozsahComponent06 = ({setCheckbox, urlPath}) => {
    const {intention, stepValue, validationRef, setStagesArr, stagesArr, saveFormRef, id} = useContext(FormContext); 
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 700);
    
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const { token } = useContext(AuthContext);
    const [ focused, setFocused ] = useState(false);
    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            applicationExceptionRequirementBuild: null,
            changeDescription: '',
        }
    });
    const prevRequest = usePrevious(request);
    const firstRender = useFirstRender();
    const [count, setCount] = useState({
        [0]: {count: 0, limit: 10000},
    });

    useEffect(() => {
        function handleResize() {
            if(window.innerWidth <= 800) {
                setIsMobileView(true);
                return;
            } 

            setIsMobileView(false); 
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!id) {
            return;
        }
        const source = axios.CancelToken.source();
        (async () => {
            const response = await getFormById(id, token, source, intention, null, pathname, navigate);

            if ((200 <= response?.status) && (response?.status < 300)) {

                const loadedData = response.data?.buildApplicationForms?.[0];

                if (loadedData) {
                    setRequest(state => ({ ...state, form: {...state.form,
                        applicationExceptionRequirementBuild: loadedData?.applicationExceptionRequirementBuild ?? false,
                        changeDescription: loadedData?.changeDescription ?? '',
                    } }));
                }
            }
            else {
                console.log('ID NOT YET IN DB');
            }
        })();

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    }, []);

    useEffect(() => {
        if (request.form?.applicationExceptionRequirementBuild === null) {
            return;
        }

        setCheckbox({
            first: request.form.applicationExceptionRequirementBuild ?? false,
        });
    }, [request.form.applicationExceptionRequirementBuild]);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }
        saveChanges();
    }, [request.form]);

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;

            if (!request?.form?.changeDescription?.trim())
            {
                err = true;
            }


            

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, };
                }
                return stg;
            }));

            return !err;
        }
    }));

    return (
        <Container className='py-0 d-flex row align-items-center rozsahContainer' fluid>
            <Row className='row-wrapper'>
                <p className='p-0 title primary'>{`${stepValue}. Rozsah`}</p>
            </Row>

            <Row className='row-wrapper'>
                <p className='p-0 mb-4 title third'>Popis požadované změny záměru před dokončením</p>
                <form className='p-0'>
                    <label className='d-flex flex-column p-0 textarea-input' style={{ color: (error && !request?.form?.changeDescription?.trim()) || (focused && !request?.form?.changeDescription?.trim()) ? '#C52A3A' : '' }}>
                    Popis změny a její porovnání s povolením záměru a s ověřenou dokumentací pro povolení záměru
                        <Row className='position-relative textarea-with-count-container'>
                            <textarea
                                className='textarea-with-count mt-2 p-2'
                                rows="5"
                                style={{ border: (error && !request?.form?.changeDescription?.trim()) || (focused && !request?.form?.changeDescription?.trim()) ? 'solid 1px #C52A3A' : '' }}
                                defaultValue={request.form.changeDescription}
                                {...(focused && {focused: 'true'})}
                                onChange={(e) => { checkLimitAndInsertText(e.target, 0, count, setCount); }}    
                                onBlur={(e) => {{setRequest(state => ({ ...state, form: { ...state.form, changeDescription: e.target.value } }));
                                    setFocused(true);
                                }}}
                            />
                            <CharCount 
                                value={request.form.changeDescription} setCount={setCount} propToUpdate={0} count={count[0].count} limit={count[0].limit}
                            />
                        </Row>
                    </label>
                </form>
            </Row>

            <Row className=''>
                <p className='p-0 mb-4 title third'>Součástí žádosti je</p>
                <form className='p-0'>
                    <label className={`ls-1p25 p-0 d-flex align-items-start ${!isMobileView ? 'w-50' : 'w-100'}`}>
                        <input
                            className='me-3'
                            type="checkbox"
                            checked={request.form.applicationExceptionRequirementBuild ?? false}
                            onChange={(e) => setRequest(state => ({...state, form: {...state.form, applicationExceptionRequirementBuild: e.target.checked }}))}
                        />
                            žádost o výjimku z požadavků na výstavbu
                    </label>
                </form>
            </Row>

            
        </Container>
    );
};

RozsahComponent06.propTypes = {
    
    setCheckbox: PropTypes.func,
    urlPath: PropTypes.string.isRequired,
};

export default RozsahComponent06;