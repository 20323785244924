import React, {useState, useEffect, useContext, useImperativeHandle} from 'react';
import '../../css/zadostPovoleniStavby.css';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import FormContext from '../../formContexts/FormContext';
import { shouldNotSave, useOnlyRequiredKeys, checkLimitAndInsertText } from '../../helperFunctions/helpers';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import useFirstRender from '../CustomHooks/useFirstRender.jsx';
import { CharCount } from '../../components/index.js';
import { useLocation, useNavigate } from 'react-router-dom';

const ZadostPovoleniStavbyOsnova5b = ({urlPath}) => {
    const {intention, stepValue, validationRef, setStagesArr, stagesArr, saveFormRef, id} = useContext(FormContext); 
    const { token } = useContext(AuthContext);
    
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const [ focused, setFocused ] = useState({
        applicationExceptionReason: false,
        applicationExceptionDescription: false,
        applicationExceptionProvisions: false,
    });

    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            applicationExceptionProvisions: '',
            applicationExceptionDescription: '',
            applicationExceptionReason: '',
        }
    });
    const prevRequest= usePrevious();
    const firstRender = useFirstRender();
    const [count, setCount] = useState({
        [0]: {count: 0, limit: 1000},
        [1]: {count: 0, limit: 1000},
        [2]: {count: 0, limit: 1000},
    });

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!id) {
            return;
        }
        const source = axios.CancelToken.source();
        (async () => {
            const response = await getFormById(id, token, source, intention, null, pathname, navigate);

            if ((200 <= response?.status) && (response?.status < 300)) {

                const loadedData = response.data.buildApplicationForms[0];

                if (loadedData) {
                    const formStructure = request.form;
                    const filteredDataToSave = useOnlyRequiredKeys(formStructure, loadedData);
                    setRequest(state => ({ ...state, form: {...state.form, ...filteredDataToSave } }));
                }
            } else {
                console.log('ID NOT YET IN DB');
            }
        })();

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }

        saveChanges();
    }, [request.form]);

    const form = request?.form;

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;

            if(!form?.applicationExceptionProvisions?.trim()
                || !form?.applicationExceptionDescription?.trim()
                || !form?.applicationExceptionReason?.trim())
            {
                err = true;
            }

            

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, };
                }
                return stg;
            }));

            return !err;
        }
    }));

    const handleFocused = (elementToFocus) => {
        setFocused(prev => ({...prev, [elementToFocus]: true}));
    };

    return (
        <Container className='d-flex flex-column align-items-xl-start' fluid>
            <Row className='d-flex flex-column flex-xl-row'>
                <Col className='fw-bold step-title'>{`${stepValue}. Výjimka z požadavků na výstavbu`}</Col>
            </Row>
            <Row className='d-flex flex-column flex-xl-row info-text-header--italic'>
                <Col>Specifikace požadované výjimky z požadavků na výstavbu a její odůvodnění</Col>
            </Row>
            <Row className='d-flex flex-column flex-xl-row'>
                <Col>
                    <p className='text-area--info-text' style={{ color: (!form?.applicationExceptionProvisions?.trim() && (error || focused.applicationExceptionProvisions)) ? '#C52A3A' : '' }}>
                        Ustanovení prováděcího právního předpisu, ze kterého je povolení výjimky požadováno
                    </p>
                    <Row className='position-relative textarea-with-count-container'>
                        <textarea
                            className='textarea-with-count p-2 text-area--validation' 
                            required
                            {...(focused.applicationExceptionProvisions && {focused: 'true'})}
                            onChange={(e) => { checkLimitAndInsertText(e.target, 0, count, setCount); }}
                            style={{ border: error && !form?.applicationExceptionProvisions?.trim() ? 'solid 1px #C52A3A' : '' }}
                            name="postContent"
                            defaultValue={request.form.applicationExceptionProvisions}
                            onBlur={(e) => { setRequest(state => ({...state, form: {...state.form, applicationExceptionProvisions: e.target.value}}));
                                handleFocused('applicationExceptionProvisions');
                            }}
                            rows={10}
                            cols={80}
                        />
                        <CharCount 
                            value={request.form.applicationExceptionProvisions} setCount={setCount} propToUpdate={0} count={count[0].count} limit={count[0].limit}
                        />
                    </Row>      
                </Col>
            </Row>
            <Row className='d-flex flex-column flex-xl-row'>
                <Col>
                    <p className='text-area--info-text' style={{ color: (!form?.applicationExceptionDescription?.trim() && (error || focused.applicationExceptionDescription)) ? '#C52A3A' : '' }}>
                        Popis požadované výjimky
                    </p>
                    <Row className='position-relative textarea-with-count-container'>
                        <textarea
                            className='textarea-with-count p-2 text-area--validation'
                            required
                            {...(focused.applicationExceptionDescription && {focused: 'true'})}
                            onChange={(e) => { checkLimitAndInsertText(e.target, 1, count, setCount); }}
                            style={{ border: error && !form?.applicationExceptionDescription?.trim() ? 'solid 1px #C52A3A' : '' }}
                            name="postContent"
                            defaultValue={request.form.applicationExceptionDescription}
                            onBlur={(e) => {setRequest(state => ({...state, form: {...state.form, applicationExceptionDescription: e.target.value}}));
                                handleFocused('applicationExceptionDescription');
                            }}
                            rows={10}
                            cols={80}
                        />
                            
                        <CharCount 
                            value={request.form.applicationExceptionDescription} setCount={setCount} propToUpdate={1} count={count[1].count} limit={count[1].limit}
                        />
                    </Row>
                </Col>
            </Row>
            <Row className='d-flex flex-column flex-xl-row'>
                <Col>
                    <p className='text-area--info-text' style={{ color: (!form?.applicationExceptionReason?.trim() && (error || focused.applicationExceptionReason)) ? '#C52A3A' : '' }}>Odůvodnění požadované výjimky</p>
                    <Row className='position-relative textarea-with-count-container'>
                        <textarea
                            className='textarea-with-count p-2 text-area--validation'
                            required
                            {...(focused.applicationExceptionReason && {focused: 'true'})}
                            onChange={(e) => { checkLimitAndInsertText(e.target, 2, count, setCount); }}
                            style={{ border: error && !form?.applicationExceptionReason?.trim() ? 'solid 1px #C52A3A' : '' }}
                            name="postContent"
                            defaultValue={request.form.applicationExceptionReason}
                            onBlur={(e) => {setRequest(state => ({...state, form: {...state.form, applicationExceptionReason: e.target.value}}));
                                handleFocused('applicationExceptionReason');
                            }}
                            rows={10}
                            cols={80}
                        />
                    
                        <CharCount 
                            value={request.form.applicationExceptionReason} setCount={setCount} propToUpdate={2} count={count[2].count} limit={count[2].limit}
                        />
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

ZadostPovoleniStavbyOsnova5b.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    urlPath: PropTypes.string.isRequired,
};

export default ZadostPovoleniStavbyOsnova5b;