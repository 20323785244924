import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';

const CustomListElement = ({item}) => {

    return (
        <Container
            key={item?.id}
        >
            <p>{item?.title ?? ''}</p>
            <p style={{display: 'none'}}>{item?.description ?? ''}</p>
        </Container>
    );
};

CustomListElement.propTypes = {
    item: PropTypes.any,
};

export default CustomListElement;