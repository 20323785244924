import React, { useState, useContext, useEffect, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../../css/zadatelComponent.css';
import PropTypes from 'prop-types';
import { FindAndProcessIco, FormInput } from '../index';
import { arrowDownBlue } from '../../assets';
import {
    BUILDING_CONTRACTOR_BUSINESS, 
    BUILDING_CONTRACTOR_PO,
    BUILDING_CONTRACTOR_PERSON_AUTHORIZED,
    BUILDING_CONTRACTOR_CONTACTS,
    BUILDING_CONTRACTOR_LOCATION,
    // PLNA_MOC_FIRST,
    // PLNA_MOC_SECOND,
    ADDITION_BUILDING_CONT,
    // INPUT_MATERIALS,
} from '../../constants/form18.js';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce';
import { shouldNotSave } from '../../helperFunctions/helpers';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import FormContext from '../../formContexts/FormContext';
import { COUNTRIES } from '../../constants/sharedConstants.js';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import { PERSON_MODEL } from '../../constants/sharedConstants.js';
import useFirstRender from '../CustomHooks/useFirstRender.jsx';
import { useLocation, useNavigate } from 'react-router-dom';

const ZhotovitelComponent18 = ({ 
    urlPath,
    receivedToken
}) => {
    const {intention, stepValue, id} = useContext(FormContext); 
    const { token } = useContext(AuthContext);
    const [request, setRequest] = useState({
        'applicationId': id,
        accompanyingDocument: {
            buildingContractor: PERSON_MODEL,
        },
    });
    const prevRequest = usePrevious();
    const firstRender = useFirstRender();
    const isFOBusiness = request.accompanyingDocument.buildingContractor?.isFOBusiness;
    const isPO = request.accompanyingDocument.buildingContractor?.isPO;
    const [ focused, setFocused ] = useState({
        icoNum: false,
    });

    const handleFocused = (elementToFocus) => {
        setFocused(prev => ({...prev, [elementToFocus]: true}));
    };

    const { pathname } = useLocation();
    const navigate = useNavigate();
    
    useEffect(() => {
        const source = axios.CancelToken.source();
        if (id) {
            (async () => {
                const response = await getFormById(id, token, source, intention, receivedToken, pathname, navigate);
                if ((200 <= response?.status) && (response?.status < 300)) {
                    const loadedData = response.data?.accompanyingDocument;
                    if (loadedData) {
                        setRequest(state => ({ ...state, accompanyingDocument: {...state.accompanyingDocument, 
                            buildingContractor: {...state.accompanyingDocument.buildingContractor, ...(loadedData.buildingContractor || {...PERSON_MODEL, id: loadedData?.buildingContractor?.id ?? ''})}
                        } 
                        }));
                    }
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        } else {
            console.log('NON EXISTENT ID');
        }
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source, receivedToken);
    };

    useEffect(() => {
        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }

        saveChanges();
    }, [request.accompanyingDocument]);

    return (
        <>
            <Container fluid>
                {/* TITLE */}
                <Row className='row-wrapper'>
                    <h2 className='p-0 main-title'>{`${stepValue}. Zhotovitel stavby`}</h2>
                </Row>

                <Row className='row-wrapper'>
                    <form className='d-flex flex-column p-0 form'>
                        <label className='d-flex align-items-center mb-2 label'>
                            <input type='radio' name='id' value='businessman' className='radio' checked={isFOBusiness ?? true}
                                onChange={() => { setRequest(state => ({ ...state, 
                                    accompanyingDocument: { ...state.accompanyingDocument, 
                                        buildingContractor: { 
                                            ...state.accompanyingDocument?.buildingContractor, 
                                            ...PERSON_MODEL, 
                                            isFOBusiness: true, 
                                            isPO: false,
                                            id: state.accompanyingDocument?.buildingContractor?.id ?? ''
                                        } } })); }} />
                                Fyzická osoba podnikající
                        </label>
                        <label className='d-flex align-items-center label'>
                            <input type='radio' name='id' value='more' className='radio' checked={isPO ?? false}
                                onChange={() => { setRequest(state => ({ ...state, 
                                    accompanyingDocument: { ...state.accompanyingDocument, 
                                        buildingContractor: { 
                                            ...state.accompanyingDocument?.buildingContractor, 
                                            ...PERSON_MODEL, 
                                            isFOBusiness: false, 
                                            isPO: true,
                                            id: state.accompanyingDocument?.buildingContractor?.id ?? ''
                                        } } })); }} />
                                Právnická osoba
                        </label>
                    </form>
                </Row>

                <Row className='row-wrapper'>
                    <h3 className='p-0 id-title'>Identifikační údaje zpracovatele</h3>
                    <form className='p-0'>
                        {
                            isFOBusiness &&
                                BUILDING_CONTRACTOR_BUSINESS.map(item => {
                                    if (item.label === 'IČO') {
                                        return (<Fragment key={`${item.id}-${item.refer}`}>
                                            <FindAndProcessIco
                                                info={item.info}
                                                updateProp={'buildingContractor'}
                                                parentProp={'accompanyingDocument'}
                                                focusKey={'icoNum'}
                                                handleFocused={handleFocused}
                                                focused={focused}
                                                request={request}
                                                setRequest={setRequest}
                                            />
                                        </Fragment>);
                                    }
                                    return <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />;
                                })
                        }
                        {
                            isPO &&
                                BUILDING_CONTRACTOR_PO.map(item => {
                                    if (item.label === 'IČO') {
                                        return (<Fragment key={`${item.id}-${item.refer}`}>
                                            <FindAndProcessIco
                                                info={item.info}
                                                updateProp={'buildingContractor'}
                                                parentProp={'accompanyingDocument'}
                                                focusKey={'icoNum'}
                                                handleFocused={handleFocused}
                                                focused={focused}
                                                request={request}
                                                setRequest={setRequest}
                                            />
                                        </Fragment>);
                                    }
                                    return <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />;
                                })
                        }
                        {!isPO && <label className='d-flex flex-column mb-4 label' style={{ marginTop: '1.5rem' }}>
                                Státní příslušnost
                            <Row>
                                <Col xs={12} md={6}>
                                    <select type='select' value={request?.accompanyingDocument?.buildingContractor?.nationality}
                                        style={{appearance: 'none', background: `url(${arrowDownBlue}) no-repeat right .8rem center`, padding: '.5rem 2rem .5rem .8rem', backgroundColor: 'white', borderRadius: '3px', border: 'solid 1px #DDDDDD'}}
                                        // onChange={(e) => setBasicInfo(state => ({ ...state, nationality: e.target.value }))}
                                        onChange={(e) => { setRequest(state => ({ ...state, accompanyingDocument: { ...state.accompanyingDocument, buildingContractor: { ...state.accompanyingDocument.buildingContractor, nationality: e.target.value } } })); }}
                                        className='id-input2'
                                    >
                                        <option value={''}>Vyberte</option>
                                        {COUNTRIES.map((country) => (
                                            <option key={country.value} value={country.value}>{country.label}</option>
                                        ))}
                                    </select>
                                </Col>
                            </Row>
                        </label>}
                        {
                            isFOBusiness &&
                                ADDITION_BUILDING_CONT.map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                        }
                    </form>
                </Row>

                {/* TRVALÝ POBYT / MÍSTO PODNIKÁNÍ */}
                <Row className='border p-4 row-wrapper'>
                    <h3 className='p-0 id-title'>{isFOBusiness ? 'Místo podnikání' : 'Sídlo'}</h3>
                    <form className='p-0'>
                        {
                            BUILDING_CONTRACTOR_LOCATION.map(item => (
                                <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                            ))
                        }
                    </form>
                </Row>

                {/* TODO check if it's figma err */}
                {false && <Row className='border p-4 row-wrapper'>
                    <h3 className='p-0 id-title'>Osoba oprávněná jednat jménem právnické osoby</h3>
                    <form className='p-0'>
                        {
                            BUILDING_CONTRACTOR_PERSON_AUTHORIZED.map(item => (
                                <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                            ))
                        }
                    </form>
                </Row>}

                {/* KONTAKTNÍ ÚDAJE */}
                {!isPO && <Row className=''>
                    <form className='p-0'>
                        {
                            BUILDING_CONTRACTOR_CONTACTS.map(item => (
                                <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                            ))
                        }
                    </form>
                </Row>}
            </Container>
        </>
    );
};

ZhotovitelComponent18.propTypes = {
    
    progressElements: PropTypes.arrayOf(Object),
    setProgressElements: PropTypes.func,
    receivedToken: PropTypes.string,
    urlPath: PropTypes.string.isRequired,
    foundApplicantIndex: PropTypes.number,
    setFoundApplicantIndex: PropTypes.func,
    stage: PropTypes.number,
    applicantArr: PropTypes.arrayOf(Object),
    setApplicantArr: PropTypes.func,
};

export default ZhotovitelComponent18;