import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { fetchExistingDocumentation } from '../apiCalls/componentsApiCalls';
import { AuthContext } from 'react-oauth2-code-pkce';

const DokumentaceRadioControls = ({
    documents,
    setDocuments, 
    setUploadedBytes, 
    request, 
    setRequest, 
    saveChanges, 
    hasInfo = false, 
}) => {
    const { token } = useContext(AuthContext);
    const allDocuments = Object.values(documents).flat();

    const handleRadioChange = ({value}) => {
        setRequest(state => ({ 
            ...state,
            buildIntention: { 
                ...state.buildIntention, 
                documentUploadHere: value === 'here', 
                documentPaperForm: value === 'office',
                documentPreviousVersion: value === 'existing',
            } 
        }));

        if (!allDocuments.length) {
            let objToSendWithoutUseEffect = {};
            objToSendWithoutUseEffect = {
                ...request, 
                buildIntention: { 
                    ...request.buildIntention,
                    documentUploadHere: value === 'here',
                    documentPaperForm: value === 'office',
                    documentPreviousVersion: value === 'existing',
                }
            };
            
            saveChanges(objToSendWithoutUseEffect);
            return;
        }

        const resetObject = {};
        for (let key in documents) {
            if (Object.hasOwn(documents, String(key))) {
                resetObject[key] = [];
            }
        }

        setDocuments(resetObject);
    };

    useEffect(() => {
        if (!request.buildIntention?.documentPreviousVersion || !request.buildIntention.id) {
            return;
        }

        fetchExistingDocumentation(request.buildIntention.bppPid, token, setDocuments, setUploadedBytes);
        
    }, [request.buildIntention?.documentPreviousVersion, request.buildIntention.id]);

    return (
        <form className='d-flex flex-column p-0 mb-4 form'>
            {request.buildIntention.eszId &&
                <label className='d-flex align-items-center mb-2 label'>
                    <input type='radio' name='documentation' value='existing' className='radio' checked={Boolean(request.buildIntention?.documentPreviousVersion)}
                        onChange={(e) => { 
                            handleRadioChange(e.target);
                        }} 
                    />
                            použít nejnovější podanou verzi dokumentace
                </label>}
            <label className='d-flex align-items-center p-0 mb-2 label'>
                <input type='radio' name='documentation' value='here' className='radio' checked={Boolean(request.buildIntention?.documentUploadHere)}
                    onChange={(e) => { 
                        handleRadioChange(e.target);
                    }} 
                />
                {!request.buildIntention.eszId ? 'dokumentaci nahraji zde' : 'vložit novou verzi dokumentace'}
            </label>
            <Row>
                <Col md={hasInfo ? 6 : 12}>
                    <label className='d-flex align-items-start pe-md-4 mb-2 mb-md-0 label'>
                        <input type='radio' name='documentation' value='office' className='radio' checked={Boolean(request.buildIntention?.documentPaperForm)}
                            onChange={(e) => { 
                                handleRadioChange(e.target);
                            }} 
                        />
                        dokumentaci doručím na úřad v listinné podobě
                    </label>
                </Col>
                {hasInfo && <Col md={6}>
                    <p className='p-0 dokumentace-instruction_text'>Dokumentaci pro povolení záměru, která nemusí být zpracovaná projektantem, vloží do Evidence elektronických dokumentací stavební úřad, neučiní-li tak spolu s podáním žádosti stavebník</p>
                </Col>}
            </Row>
        </form>
    );
};

DokumentaceRadioControls.propTypes = {
    setRequest: PropTypes.func,
    request: PropTypes.object,
    hasInfo: PropTypes.bool,
    saveChanges: PropTypes.func,
    documents: PropTypes.object,
    setDocuments: PropTypes.func,
    setUploadedBytes: PropTypes.func,
};

export default DokumentaceRadioControls;