import React, {useState} from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import '../../css/newConstructionModal.css';
import { arrowRight, chevronUp } from '../../assets';

const CONSTRUCTIONS_DATA = {
    budovyJednobytove: [
        {
            name: '1.1.1.0.1.1 Budovy jednobytové',
        },
        {
            name: '1.1.1.0.1.2 Budovy jednobytové se služebním vybavením',
        },
        {
            name: '1.1.1.0.2.1 Chalupy pro rekreaci jednobytové',
        },
        {
            name: '1.1.1.0.2.2 Chaty pro rekreaci jednobytové',
        },
        {
            name: '1.1.1.0.9.1 Oplocení budov 1110',
        },
    ],


    budovyDvoubytove: [
        {
            name: '1.1.2.1.1.1 Budovy dvoubytové',
        },
        {
            name: '1.1.2.1.1.2 Budovy dvoubytové se služebním vybavením',
        },
        {
            name: '1.1.2.1.2.1 Chalupy pro rekreaci dvoubytové',
        },
        {
            name: '1.1.2.1.2.2 Chaty pro rekreaci dvoubytové',
        },
        {
            name: '1.1.2.1.9.1 Oplocení budov 1121',
        },
    ],

    budovyTriAVice: [
        {
            name: '1.1.2.2.1.3 Budovy tříbytové – typové',
        },
        {
            name: '1.1.2.2.1.4 Budovy tříbytové – netypové',
        },
        {
            name: '1.1.2.2.1.5 Budovy čtyř a vícebytové – typové',
        },
        {
            name: '1.1.2.2.1.6 Budovy čtyř a vícebytové – netypové',
        },
        {
            name: '1.1.2.2.2.1 Chalupy pro rekreaci tří a vícebytové',
        },
        {
            name: '1.1.2.2.2.2 Chaty pro rekreaci tří a vícebytové',
        },
        {
            name: '1.1.2.2.9.1 Oplocení budov 1122',
        },
    ],

    budovyBytove: [
        {
            name: '1.1.3.0.1.1 Budovy se službami sociální péče',
        },
        {
            name: '1.1.3.0.1.2 Budovy pro ubytování studentů, zaměstnanců apod.',
        },
        {
            name: '1.1.3.0.1.9 Budovy bytové ostatní j. n.',
        },
        {
            name: '1.1.3.0.9.1 Oplocení budov 1130',
        },
    ],

    hotely: [
        {
            name: '1.2.1.1.1.1 Budovy hotelů a podobných ubytovacích zařízení',
        },
        {
            name: '1.2.1.1.1.2 Budovy restaurací, barů, kaváren',
        },
        {
            name: '1.2.1.1.9.1 Oplocení budov 1211',
        },
    ],

    budovyOstatni: [
        {
            name: '1.2.1.2.1.1 Budovy ostatní pro krátkodobé ubytování (kromě chat bez bytu)',
        },
        {
            name: '1.2.1.2.1.2 Chaty bez bytu',
        },
        {
            name: '1.2.1.2.9.1 Oplocení budov 1212',
        },
    ],

    budovyAdministrativni: [
        {
            name: '1.2.2.0.1.1 Budovy peněžních ústavů',
        },
        {
            name: '1.2.2.0.1.2 Budovy veřejné správy',
        },
        {
            name: '1.2.2.0.1.3 Budovy pošt',
        },
        {
            name: '1.2.2.0.1.9 Budovy administrativní ostatní',
        },
        {
            name: '1.2.2.0.9.1 Oplocení budov 1220',
        },
    ],

    budovyProObchod: [
        {
            name: '1.2.3.0.1.1 Budovy obchodních domů',
        },
        {
            name: '1.2.3.0.1.2 Budovy pro obchod a služby',
        },
        {
            name: '1.2.3.0.7.9 Podzemní obchodní střediska',
        },
        {
            name: '1.2.3.0.9.1 Oplocení budov 1230',
        },
    ],

    budovyProTelekomunikace: [
        {
            name: '1.2.4.1.1.1 Nádraží, terminály a budovy k nim příslušející',
        },
        {
            name: '1.2.4.1.1.2 Budovy pro telekomunikace, rozhlasové a televizní vysílání',
        },
        {
            name: '1.2.4.1.1.3 Zastřešená nástupiště a rampy',
        },
        {
            name: '1.2.4.1.1.4 Budovy letišť',
        },
        {
            name: '1.2.4.1.1.5 Budovy (věže) majáků',
        },
        {
            name: '1.2.4.1.7.9 Podzemní budovy pro telekomunikace, nádraží a terminály',
        },
        {
            name: '1.2.4.1.9.1 Oplocení budov 1241',
        },
    ],

    garaze: [
        {
            name: '1.2.4.2.1.1 Garáže nadzemní',
        },
        {
            name: '1.2.4.2.7.9 Garáže podzemní',
        },
        {
            name: '1.2.4.2.9.1 Oplocení budov 1242',
        },
    ],

    budovyProPrumysl: [
        {
            name: '1.2.5.1.1.1 Budovy pro průmysl',
        },
        {
            name: '1.2.5.1.1.2 Budovy výrobní pro energetiku',
        },
        {
            name: '1.2.5.1.1.3 Budovy vodního hospodářství, čistíren a úpraven vod',
        },
        {
            name: '1.2.5.1.7.9 Podzemní budovy pro průmysl',
        },
        {
            name: '1.2.5.1.9.1 Oplocení budov 1251',
        },
    ],

    budovySkladu: [
        {
            name: '1.2.5.2.1.1 Budovy skladů',
        },
        {
            name: '1.2.5.2.2.1 Zásobníky a jámy - pozemní',
        },
        {
            name: '1.2.5.2.2.2 Sila samostatná',
        },
        {
            name: '1.2.5.2.3.2 Nádrže',
        },
        {
            name: '1.2.5.2.7.9 Podzemní sklady, nádrže a sila',
        },
        {
            name: '1.2.5.2.9.1 Oplocení staveb 1252',
        },
    ],

    budovyProSpolecenskeUcely: [
        {
            name: '1.2.6.1.1.1 Budovy divadel',
        },
        {
            name: '1.2.6.1.2.1 Budovy kin',
        },
        {
            name: '1.2.6.1.3.1 Budovy zoologických a botanických zahrad',
        },
        {
            name: '1.2.6.1.4.9 Budovy pro společenské a kulturní účely j. n.',
        },
        {
            name: '1.2.6.1.7.9 Podzemní budovy pro společenské a kulturní účely',
        },
        {
            name: '1.2.6.1.9.1 Oplocení budov 1261',
        },
    ],

    muzeaAKnihovny: [
        {
            name: '1.2.6.2.1.1 Budovy muzeí, knihoven, galerií, archivů',
        },
        {
            name: '1.2.6.2.9.1 Oplocení budov 1262',
        },
    ],

    skolyUniverzity: [
        {
            name: '1.2.6.3.1.1 Budovy škol a univerzit',
        },
        {
            name: '1.2.6.3.2.1 Budovy pro vědu a výzkum',
        },
        {
            name: '1.2.6.3.7.9 Podzemní budovy pro vzdělávání, výzkum, vědu apod.',
        },
        {
            name: '1.2.6.3.9.1 Oplocení budov 1263',
        },
    ],

    budovyProZdravotnictvi: [
        {
            name: '1.2.6.4.1.1 Budovy nemocnic a nemocnic s poliklinikou',
        },
        {
            name: '1.2.6.4.1.2 Budovy zdravotnických středisek, poliklinik a odborných zdravotnických zařízení',
        },
        {
            name: '1.2.6.4.1.3 Budovy léčebných ústavů a lázeňských léčeben',
        },
        {
            name: '1.2.6.4.1.4 Budovy hygienicko - epidemiologické služby',
        },
        {
            name: '1.2.6.4.1.5 Budovy středisek péče o matku a dítě',
        },
        {
            name: '1.2.6.4.7.9 Podzemní budovy pro zdravotnictví',
        },
        {
            name: '1.2.6.4.9.1 Oplocení budov 1264',
        },
    ],

    budovyProSport: [
        {
            name: '1.2.6.5.1.1 Budovy pro halové sporty',
        },
        {
            name: '1.2.6.5.2.1 Zastřešené tribuny, stadiony',
        },
        {
            name: '1.2.6.5.3.1 Kryté bazény',
        },
        {
            name: '1.2.6.5.4.1 Budovy tělocvičen',
        },
        {
            name: '1.2.6.5.5.1 Budovy jízdáren',
        },
        {
            name: '1.2.6.5.6.9 Budovy pro sport a rekreaci j. n.',
        },
        {
            name: '1.2.6.5.9.1 Oplocení staveb 1265',
        },
    ],

    budovyProZemedelstvi: [
        {
            name: '1.2.7.1.1.1 Budovy pro rostlinnou produkci',
        },
        {
            name: '1.2.7.1.1.3 Skleníky pro pěstování rostlin',
        },
        {
            name: '1.2.7.1.2.1 Budovy pro skladování a úpravu zemědělských produktů',
        },
        {
            name: '1.2.7.1.2.2 Sila pro posklizňovou úpravu a skladování obilí',
        },
        {
            name: '1.2.7.1.3.1 Budovy pro živočišnou produkci',
        },
        {
            name: '1.2.7.1.4.1 Budovy pro lesnictví',
        },
        {
            name: '1.2.7.1.7.9 Podzemní budovy pro zemědělství',
        },
        {
            name: '1.2.7.1.9.1 Oplocení staveb 1271',
        },
    ],

    budovyProBohosluzby: [
        {
            name: '1.2.7.2.1.1 Chrámy, kostely, synagogy apod.',
        },
        {
            name: '1.2.7.2.2.1 Krematoria, pohřební síně',
        },
        {
            name: '1.2.7.2.3.1 Hřbitovy a hřbitovní budovy',
        },
        {
            name: '1.2.7.2.9.1 Oplocení náboženských budov a hřbitovů',
        },
    ],

    historickePamatky: [
        {
            name: '1.2.7.3.1.1 Hrady a zámky',
        },
        {
            name: '1.2.7.3.5.1 Pomníky, kašny a jiná drobná architektura, ostatní kulturní památky',
        },
    ],

    budovyNebytoveOstatni: [
        {
            name: '1.2.7.4.1.1 Budovy nebytové ostatní',
        },
        {
            name: '1.2.7.4.7.9 Podzemní budovy nebytové ostatní',
        },
        {
            name: '1.2.7.4.9.1 Oplocení budov 1274',
        },
    ],



    dalniceASilnice: [
        {
            name: '2.1.1.1.1.1 Dálnice I. a II. třídy',
        },
        {
            name: '2.1.1.1.1.3 Silnice I. třídy',
        },
        {
            name: '2.1.1.1.1.4 Silnice II. třídy',
        },
        {
            name: '2.1.1.1.1.5 Silnice III. třídy',
        },
        {
            name: '2.1.1.1.2.3 Parkoviště u dálnic a silnic 2111',
        },
        {
            name: '2.1.1.1.9.1 Oplocení dálnic a silnic 2111',
        },
        {
            name: '2.1.1.1.9.2 Osvětlovací síť dálnic a silnic 2111',
        },
        {
            name: '2.1.1.1.9.3 Signalizační a zabezpečovací zařízení dálnic a silnic 2111',
        },
    ],

    mistniKomunikace: [
        {
            name: '2.1.1.2.1.2 Místní komunikace',
        },
        {
            name: '2.1.1.2.1.3 Účelové komunikace',
        },
        {
            name: '2.1.1.2.1.9 Komunikace pozemní místní a účelové j. n.',
        },
        {
            name: '2.1.1.2.2.3 Parkoviště a plochy charakteru pozemních místních komunikací',
        },
        {
            name: '2.1.1.2.9.1 Oplocení staveb 2112',
        },
        {
            name: '2.1.1.2.9.2 Osvětlovací síť staveb 2112',
        },
        {
            name: '2.1.1.2.9.3 Signalizační a zabezpečovací zařízení staveb 2112',
        },
    ],

    drahyZeleznicni: [
        {
            name: '2.1.2.1.1.1 Spodek drah železničních dálkových',
        },
        {
            name: '2.1.2.1.2.1 Svršek drah železničních dálkových - tratě',
        },
        {
            name: '2.1.2.1.2.2 Svršek drah železničních dálkových - stanice',
        },
        {
            name: '2.1.2.1.2.3 Svršek drah železničních dálkových - výhybky',
        },
        {
            name: '2.1.2.1.2.4 Svršek drah železničních dálkových - vlečky',
        },
        {
            name: '2.1.2.1.9.1 Oplocení staveb 2121',
        },
        {
            name: '2.1.2.1.9.2 Osvětlovací síť staveb 2121',
        },
        {
            name: '2.1.2.1.9.3 Signalizační a zabezpečovací zařízení staveb 2121',
        },
    ],

    drahyKolejove: [
        {
            name: '2.1.2.2.1.1 Spodek drah kolejových městských a ostatních',
        },
        {
            name: '2.1.2.2.2.1 Svršek drah kolejových - metro, tramvajové dráhy',
        },
        {
            name: '2.1.2.2.2.9 Svršek drah kolejových j. n.',
        },
        {
            name: '2.1.2.2.5.1 Dráhy visuté',
        },
        {
            name: '2.1.2.2.9.1 Oplocení staveb 2122',
        },
        {
            name: '2.1.2.2.9.2 Osvětlovací síť staveb 2122',
        },
        {
            name: '2.1.2.2.9.3 Signalizační a zabezpečovací zařízení staveb 2122',
        },
    ],

    plochyLetist: [
        {
            name: '2.1.3.0.1.1 Dráhy vzletové a přistávací',
        },
        {
            name: '2.1.3.0.1.9 Ostatní plochy letišť',
        },
        {
            name: '2.1.3.0.9.1 Oplocení letišť',
        },
        {
            name: '2.1.3.0.9.2 Osvětlovací síť letišť',
        },
        {
            name: '2.1.3.0.9.3 Signalizační a zabezpečovací zařízení letišť',
        },
    ],

    mostyADalnice: [
        {
            name: '2.1.4.1.1.1 Mosty silničních komunikací vč. estakád',
        },
        {
            name: '2.1.4.1.1.2 Mosty drážních komunikací',
        },
        {
            name: '2.1.4.1.1.3 Mosty sdružené',
        },
        {
            name: '2.1.4.1.1.4 Mosty průmyslové a lávky pro pěší',
        },
        {
            name: '2.1.4.1.2.1 Visuté dálnice',
        },
        {
            name: '2.1.4.1.9.1 Oplocení staveb 2141',
        },
        {
            name: '2.1.4.1.9.2 Osvětlovací síť staveb 2141',
        },
        {
            name: '2.1.4.1.9.3 Signalizační a zabezpečovací zařízení staveb 2141',
        },
    ],

    tunely: [
        {
            name: '2.1.4.2.1.1 Tunely',
        },
        {
            name: '2.1.4.2.1.3 Podchody',
        },
        {
            name: '2.1.4.2.3.1 Štoly (kromě důlních)',
        },
        {
            name: '2.1.4.2.3.8 Šachty (kromě důlních a šachet u podzemních vedení)',
        },
        {
            name: '2.1.4.2.9.2 Osvětlovací síť staveb 2142',
        },
        {
            name: '2.1.4.2.9.3 Signalizační a zabezpečovací zařízení staveb 2142',
        },
    ],

    pristavyAKanaly: [
        {
            name: '2.1.5.1.1.1 Přístavy',
        },
        {
            name: '2.1.5.1.2.1 Plavební kanály',
        },
        {
            name: '2.1.5.1.2.2 Objekty sportovní plavební, loděnice',
        },
        {
            name: '2.1.5.1.2.3 Plavební komory',
        },
        {
            name: '2.1.5.1.2.4 Zdvihadla lodní a žlaby nakloněné',
        },
        {
            name: '2.1.5.1.3.1 Mola, doky, vlnolamy apod.',
        },
        {
            name: '2.1.5.1.4.9 Ostatní vodní díla',
        },
        {
            name: '2.1.5.1.9.1 Oplocení staveb 2151',
        },
        {
            name: '2.1.5.1.9.2 Osvětlovací síť staveb 2151',
        },
        {
            name: '2.1.5.1.9.3 Signalizační a zabezpečovací zařízení staveb 2151',
        },
    ],

    vodniStupne: [
        {
            name: '2.1.5.2.1.1 Přehrady a nádrže na tocích',
        },
        {
            name: '2.1.5.2.2.1 Hráze, jezy a stupně na tocích',
        },
        {
            name: '2.1.5.2.3.1 Vodní elektrárny (spodní stavba)',
        },
        {
            name: '2.1.5.2.4.1 Úpravy vodních toků (regulace)',
        },
        {
            name: '2.1.5.2.9.1 Oplocení staveb 2152',
        },
        {
            name: '2.1.5.2.9.2 Osvětlovací síť staveb 2152',
        },
    ],

    akvadukty: [
        {
            name: '2.1.5.3.1.1 Mosty vodohospodářské (akvadukty)',
        },
        {
            name: '2.1.5.3.4.1 Vodní díla pro zavlažování',
        },
        {
            name: '2.1.5.3.4.2 Vodní díla pro odvodnění',
        },
        {
            name: '2.1.5.3.9.1 Oplocení staveb 2153',
        },
        {
            name: '2.1.5.3.9.2 Osvětlovací síť staveb 2153',
        },
    ],

    vedeniPlynu: [
        {
            name: '2.2.1.1.1.1 Ropovody',
        },
        {
            name: '2.2.1.1.1.2 Plynovody',
        },
        {
            name: '2.2.1.1.1.3 Vedení ostatních chemických produktů dálková trubní',
        },
        {
            name: '2.2.1.1.1.9 Vedení dálková trubní j. n.',
        },
        {
            name: '2.2.1.1.2.9 Stavby pro dálkovou přepravu a distribuci plynu, ropy a ostatních produktů j. n. (kromě budov)',
        },
        {
            name: '2.2.1.1.3.8 Šachty trubních vedení 2211',
        },
        {
            name: '2.2.1.1.4.1 Nezastřešené přečerpávací stanice na dálkových vedeních',
        },
        {
            name: '2.2.1.1.9.1 Oplocení staveb 2211',
        },
        {
            name: '2.2.1.1.9.2 Osvětlovací síť vnější staveb 2211',
        },
    ],

    vedeniVody: [
        {
            name: '2.2.1.2.1.1 Řady vodovodní přívodní a zásobovací',
        },
        {
            name: '2.2.1.2.1.2 Řady násoskové',
        },
        {
            name: '2.2.1.2.1.3 Sítě prameništní sběrné',
        },
        {
            name: '2.2.1.2.1.4 Řady parovodní',
        },
        {
            name: '2.2.1.2.1.5 Řady teplovodní a horkovodní',
        },
        {
            name: '2.2.1.2.1.6 Řady vzduchovodní, vzduchovody',
        },
        {
            name: '2.2.1.2.1.9 Vedení vody dálková trubní j. n.',
        },
        {
            name: '2.2.1.2.2.1 Vedení kanalizační dálková trubní',
        },
        {
            name: '2.2.1.2.3.2 Nádrže, jímky, objekty čistíren odpadních vod - pozemní (kromě budov)',
        },
        {
            name: '2.2.1.2.3.8 Šachty trubních vedení 2212',
        },
        {
            name: '2.2.1.2.4.1 Související čerpací stanice, úpravny vod, stavby vodního hospodářství (kromě budov)',
        },
        {
            name: '2.2.1.2.7.9 Podzemní stavby vodního hospodářství j. n.',
        },
        {
            name: '2.2.1.2.9.1 Oplocení staveb 2212',
        },
        {
            name: '2.2.1.2.9.2 Osvětlovací síť vnější staveb 2212',
        },
    ],

    vedeniTelekomunikacni: [
        {
            name: '2.2.1.3.1.1 Vedení dálková telekomunikační nadzemní (meziměstská, mezistátní)',
        },
        {
            name: '2.2.1.3.1.9 Vedení dálková telekomunikační nadzemní j. n.',
        },
        {
            name: '2.2.1.3.2.1 Vedení dálková kabelová telekomunikační podzemní (meziměstská, mezistátní)',
        },
        {
            name: '2.2.1.3.2.9 Vedení dálková telekomunikační podzemní j. n.',
        },
        {
            name: '2.2.1.3.3.1 Chráničky vedení dálkových telekomunikačních podzemních',
        },
        {
            name: '2.2.1.3.3.8 Šachty podzemních vedení 2213',
        },
        {
            name: '2.2.1.3.4.1 Věže pro vysílání, retranslační věže a telekomunikační stožáry',
        },
        {
            name: '2.2.1.3.4.9 Stavby provozní telekomunikační j. n. (kromě budov)',
        },
        {
            name: '2.2.1.3.9.1 Oplocení staveb 2213',
        },
        {
            name: '2.2.1.3.9.2 Osvětlovací síť vnější staveb 2213',
        },
    ],

    vedeniElektricka: [
        {
            name: '2.2.1.4.1.1 Vedení dálková VVN venkovní nadzemní',
        },
        {
            name: '2.2.1.4.1.2 Vedení dálková VN venkovní nadzemní',
        },
        {
            name: '2.2.1.4.1.3 Vedení železnic dálkových a vleček trakční',
        },
        {
            name: '2.2.1.4.1.9 Vedení dálková elektrická nadzemní j. n.',
        },
        {
            name: '2.2.1.4.2.1 Vedení dálková VVN podzemní',
        },
        {
            name: '2.2.1.4.2.2 Vedení dálková VN podzemní',
        },
        {
            name: '2.2.1.4.2.9 Vedení dálková elektrická podzemní j. n.',
        },
        {
            name: '2.2.1.4.3.8 Šachty podzemních vedení 2214',
        },
        {
            name: '2.2.1.4.4.1 Transformovny, měnírny a rozvodny vedení dálkových',
        },
        {
            name: '2.2.1.4.9.1 Oplocení staveb 2214',
        },
        {
            name: '2.2.1.4.9.2 Osvětlovací síť vnější staveb 2214',
        },
    ],

    vedeniPlynuMistni: [
        {
            name: '2.2.2.1.1.2 Sítě místní plynovodní',
        },
        {
            name: '2.2.2.1.1.9 Vedení ostatních plynů místní trubní',
        },
        {
            name: '2.2.2.1.2.9 Stavby pro místní přepravu a distribuci plynu a ostatních produktů j. n.',
        },
        {
            name: '2.2.2.1.3.8 Šachty místních podzemních vedení 2221',
        },
        {
            name: '2.2.2.1.9.2 Osvětlovací síť vnější staveb 2221',
        },
    ],

    vedeniVodyMistni: [
        {
            name: '2.2.2.2.1.1 Sítě místní vodovodní rozvodné',
        },
        {
            name: '2.2.2.2.1.2 Sítě průmyslové vody cirkulační',
        },
        {
            name: '2.2.2.2.1.3 Vodovody požární',
        },
        {
            name: '2.2.2.2.1.4 Sítě místní parovodní',
        },
        {
            name: '2.2.2.2.1.5 Sítě místní teplovodní a horkovodní',
        },
        {
            name: '2.2.2.2.1.6 Sítě místní vzduchovodní',
        },
        {
            name: '2.2.2.2.1.9 Vedení vody místní trubní j. n.',
        },
        {
            name: '2.2.2.2.3.2 Nádrže vod pozemní',
        },
        {
            name: '2.2.2.2.3.8 Šachty místních vodovodních vedení 2222',
        },
        {
            name: '2.2.2.2.4.1 Věžové zásobníky vody',
        },
        {
            name: '2.2.2.2.5.1 Vrty čerpací (studny vrtané)',
        },
        {
            name: '2.2.2.2.5.2 Studny j. n. a jímání vody',
        },
        {
            name: '2.2.2.2.5.3 Fontány, hydranty, kašny',
        },
        {
            name: '2.2.2.2.9.1 Oplocení staveb 2222',
        },
        {
            name: '2.2.2.2.9.2 Osvětlovací síť vnější staveb 2222',
        },
    ],

    vedeniKanalizaceMistni: [
        {
            name: '2.2.2.3.1.1 Vedení kanalizace místní trubní',
        },
        {
            name: '2.2.2.3.1.9 Vedení kanalizace místní trubní j. n.',
        },
        {
            name: '2.2.2.3.2.1 Stavby místních čistíren a úpraven odpadních vod (kromě budov)',
        },
        {
            name: '2.2.2.3.3.2 Nádrže, jímky místních vedení kanalizace',
        },
        {
            name: '2.2.2.3.3.8 Šachty místních vedení kanalizace',
        },
        {
            name: '2.2.2.3.9.1 Oplocení staveb 2223',
        },
        {
            name: '2.2.2.3.9.2 Osvětlovací síť vnější staveb 2223',
        },
    ],

    vedeniMistniElektricka: [
        {
            name: '2.2.2.4.1.1 Vedení místní VN venkovní nadzemní',
        },
        {
            name: '2.2.2.4.1.2 Vedení místní NN venkovní nadzemní',
        },
        {
            name: '2.2.2.4.1.3 Vedení místních železnic a drah kolejových trakční (trolejová)',
        },
        {
            name: '2.2.2.4.1.4 Vedení místních drah nekolejových trakční (trolejová)',
        },
        {
            name: '2.2.2.4.1.9 Vedení a rozvody místní nadzemní j. n.',
        },
        {
            name: '2.2.2.4.2.1 Vedení místní VN podzemní',
        },
        {
            name: '2.2.2.4.2.3 Sítě kabelové osvětlovací samostatné j. n.',
        },
        {
            name: '2.2.2.4.2.9 Vedení místní elektrická podzemní j. n.',
        },
        {
            name: '2.2.2.4.3.1 Chráničky vedení místních podzemních',
        },
        {
            name: '2.2.2.4.3.8 Šachty vedení místních podzemních',
        },
        {
            name: '2.2.2.4.4.1 Transformovny, rozvodny, měnírny vedení místních',
        },
        {
            name: '2.2.2.4.5.1 Vedení místní telekomunikační nadzemní traťová, síťová',
        },
        {
            name: '2.2.2.4.5.2 Vedení místní telekomunikační nadzemní spojovací',
        },
        {
            name: '2.2.2.4.5.3 Vedení místní telekomunikační nadzemní zprostředkovací',
        },
        {
            name: '2.2.2.4.5.4 Vedení místní telekomunikační nadzemní účastnická',
        },
        {
            name: '2.2.2.4.5.5 Vedení rozhlasu po vedení',
        },
        {
            name: '2.2.2.4.5.8 Telekomunikační sítě rozvodné v nespojových organizacích - nadzemní',
        },
        {
            name: '2.2.2.4.5.9 Vedení místní telekomunikační nadzemní j. n.',
        },
        {
            name: '2.2.2.4.6.1 Vedení kabelová místní podzemní traťová, síťová',
        },
        {
            name: '2.2.2.4.6.2 Vedení kabelová místní podzemní spojovací',
        },
        {
            name: '2.2.2.4.6.3 Vedení kabelová místní podzemní zprostředkovací',
        },
        {
            name: '2.2.2.4.6.4 Vedení kabelová místní podzemní účastnická',
        },
        {
            name: '2.2.2.4.6.5 Sítě pro informatiku (kabelová TV, datové sítě apod.) - podzemní',
        },
        {
            name: '2.2.2.4.6.6 Řídicí sítě slaboproudé (jednotný čas, veřejné požární hlásiče, napájecí sítě rozhlasu, návěštní apod.) - podzemní',
        },
        {
            name: '2.2.2.4.6.7 Vedení kabelová místní podzemní speciální',
        },
        {
            name: '2.2.2.4.6.8 Telekomunikační sítě rozvodné v nespojových organizacích - podzemní',
        },
        {
            name: '2.2.2.4.6.9 Vedení telekomunikační místní podzemní j. n.',
        },
        {
            name: '2.2.2.4.7.1 Ostatní díla pro energetiku - základy apod. energetických zařízení',
        },
        {
            name: '2.2.2.4.7.9 Podzemní stavby pro energetiku',
        },
        {
            name: '2.2.2.4.9.1 Oplocení staveb 2224',
        },
        {
            name: '2.2.2.4.9.2 Osvětlovací síť vnější staveb 2224',
        },
    ],

    stavbyDulni: [
        {
            name: '2.3.0.1.1.1 Díla důlní - vrty a jámy',
        },
        {
            name: '2.3.0.1.1.2 Díla důlní - šibíky, překopy, chodby, štoly',
        },
        {
            name: '2.3.0.1.1.9 Díla důlní - prostorová díla a díla důlní ostatní',
        },
        {
            name: '2.3.0.1.2.1 Dráhy lanové pozemní bezkolejové pro těžbu surovin a dopravu nákladů',
        },
        {
            name: '2.3.0.1.3.1 Objekty úpravy surovin',
        },
        {
            name: '2.3.0.1.3.2 Objekty výroby stavebních hmot',
        },
        {
            name: '2.3.0.1.4.1 Věže, stožáry, věžové zásobníky pro hornictví a těžbu surovin',
        },
        {
            name: '2.3.0.1.5.1 Různé stavby pozemní výrobní pro těžbu (kromě budov)',
        },
        {
            name: '2.3.0.1.7.1 Základy technologických výrobních zařízení',
        },
        {
            name: '2.3.0.1.9.1 Oplocení staveb 2301',
        },
        {
            name: '2.3.0.1.9.2 Osvětlovací síť vnější staveb 2301',
        },
    ],

    stavbyElektraren: [
        {
            name: '2.3.0.2.1.1 Objekty jaderných elektráren (kromě budov)',
        },
        {
            name: '2.3.0.2.1.8 Objekty jaderných elektráren provozní pomocné a speciální (kromě budov)',
        },
        {
            name: '2.3.0.2.2.1 Objekty jaderných neenergetických reaktorů provozní (kromě budov)',
        },
        {
            name: '2.3.0.2.3.1 Objekty elektráren tepelných, větrných, vodních a spaloven (kromě budov)',
        },
        {
            name: '2.3.0.2.3.9 Ostatní stavby elektrárenské pomocné a podobné (kromě budov)',
        },
        {
            name: '2.3.0.2.4.1 Věže chladicí pro energetiku',
        },
        {
            name: '2.3.0.2.4.9 Stavby výrobní pro energetiku j. n. (kromě budov)',
        },
        {
            name: '2.3.0.2.5.1 Komíny a kouřové kanály pro energetiku',
        },
        {
            name: '2.3.0.2.7.1 Základy energetických technologických výrobních zařízení',
        },
        {
            name: '2.3.0.2.7.9 Podzemní stavby elektrárenské',
        },
        {
            name: '2.3.0.2.9.1 Oplocení staveb 2302',
        },
        {
            name: '2.3.0.2.9.1 Oplocení staveb 2302',
        },
    ],

    stavbyProChemickyPrumysl: [
        {
            name: '2.3.0.3.1.1 Stavby pro výrobu a úpravu chemických surovin (kromě budov)',
        },
        {
            name: '2.3.0.3.1.8 Stavby pozemní doplňkové pro chemickou výrobu (kromě budov)',
        },
        {
            name: '2.3.0.3.4.1 Věže, stožáry, věžové zásobníky chemických podniků',
        },
        {
            name: '2.3.0.3.4.9 Stavby výrobní chemických podniků j. n. (kromě budov)',
        },
        {
            name: '2.3.0.3.5.1 Průmyslové komíny chemických podniků',
        },
        {
            name: '2.3.0.3.7.9 Podzemní stavby chemických podniků',
        },
        {
            name: '2.3.0.3.9.1 Oplocení staveb 2303',
        },
        {
            name: '2.3.0.3.9.2 Osvětlovací síť vnější staveb 2303',
        },
    ],

    stavbyProOstatniPrumysl: [
        {
            name: '2.3.0.4.1.1 Stavby hutního a těžkého průmyslu (kromě budov)',
        },
        {
            name: '2.3.0.4.1.8 Stavby pozemní doplňkové pro hutní a těžký průmysl',
        },
        {
            name: '2.3.0.4.4.1 Věže, stožáry, věžové zásobníky - pro ostatní průmysl',
        },
        {
            name: '2.3.0.4.4.1 Věže, stožáry, věžové zásobníky - pro ostatní průmysl',
        },
        {
            name: '2.3.0.4.5.1 Průmyslové komíny pro ostatní průmysl',
        },
        {
            name: '2.3.0.4.7.1 Základy technologických výrobních zařízení pro ostatní průmysl',
        },
        {
            name: '2.3.0.4.7.9 Podzemní stavby pro ostatní průmysl',
        },
        {
            name: '2.3.0.4.9.1 Oplocení staveb 2304',
        },
        {
            name: '2.3.0.4.9.2 Osvětlovací síť vnější staveb 2304',
        },
    ],

    sportovniHriste: [
        {
            name: '2.4.1.1.1.1 Plochy stadionů',
        },
        {
            name: '2.4.1.1.1.2 Plochy hřišť a cvičišť',
        },
        {
            name: '2.4.1.1.3.1 Nekrytá koupaliště',
        },
        {
            name: '2.4.1.1.3.2 Venkovní střelnice',
        },
        {
            name: '2.4.1.1.5.1 Dostihové dráhy',
        },
        {
            name: '2.4.1.1.6.9 Nekryté plochy sportovišť j. n.',
        },
        {
            name: '2.4.1.1.9.1 Oplocení staveb 2411',
        },
        {
            name: '2.4.1.1.9.2 Osvětlovací síť vnější staveb 2411',
        },
    ],

    ostatniStavbyProSport: [
        {
            name: '2.4.1.2.1.1 Parky a zahrady',
        },
        {
            name: '2.4.1.2.1.2 Dětská a školní hřiště',
        },
        {
            name: '2.4.1.2.1.3 Golfová hřiště',
        },
        {
            name: '2.4.1.2.2.1 Plochy lyžařských tratí',
        },
        {
            name: '2.4.1.2.2.2 Plochy nekrytých kluzišť',
        },
        {
            name: '2.4.1.2.3.1 Přístavní příslušenství pro plachetnice, jachty a sportovní lodě',
        },
        {
            name: '2.4.1.2.4.9 Ostatní stavby pro sport a rekreaci (kromě budov)',
        },
        {
            name: '2.4.1.2.9.1 Oplocení staveb 2412',
        },
        {
            name: '2.4.1.2.9.2 Osvětlovací síť vnější staveb 2412',
        },
    ],

    ostatniInzenyrskaDila: [
        {
            name: '2.4.2.0.1.1 Stavby inženýrské vojenské (kromě budov)',
        },
        {
            name: '2.4.2.0.2.1 Odpalovací rampy a základny pro satelity',
        },
        {
            name: '2.4.2.0.4.1 Skládky odpadů',
        },
        {
            name: '2.4.2.0.5.1 Pomníky a jiná drobná architektura',
        },
        {
            name: '2.4.2.0.5.2 Zdi a valy samostatné',
        },
        {
            name: '2.4.2.0.6.1 Konstrukce chmelnic',
        },
        {
            name: '2.4.2.0.6.2 Konstrukce vinic',
        },
        {
            name: '2.4.2.0.7.9 Podzemní stavby vojenské',
        },
        {
            name: '2.4.2.0.8.9 Ostatní inženýrské stavby j. n.',
        },
        {
            name: '2.4.2.0.9.1 Oplocení samostatné j. n.',
        },
        {
            name: '2.4.2.0.9.2 Osvětlovací síť vnější staveb 2420',
        },
    ],
};

const ConstructionSection = ({name, step, isOpen, section, setSection, children}) => {
    let margin = (step - 1) * 24;
    return (
        <Row className='py-3 new-construction-section' onClick={() => setSection(state => ({...state, [isOpen]: !section[isOpen]}))}>
            <p className='p-0 new-construction-title' style={{marginLeft: `${margin}px`}}>
                <img src={section[isOpen] ? chevronUp : arrowRight} alt="arrowDown" className='me-2'/>
                {name}
            </p>
            {children}
        </Row>
    );
};

const SectionData = ({items, step, setItem}) => {
    let margin = (step - 1) * 24;
    return (<div className='d-flex flex-column' style={{marginLeft: `${margin}px`}}>
        {items.map((item) => {
            return <label key={item.name} className='mb-2'>
                <input 
                    type="radio" 
                    name='budovy' 
                    className='radio'
                    value={item.name}
                    onChange={(e) => setItem(e.target.value)}
                />
                {item.name}
            </label>;
        })}
    </div>
    );
};

const NewConstructionModal = ({
    show,
    setShow,
    handleClose,
    setNewBuilding,
    setRequest,
    object = 'buildIntention',
    propertyToUpdate = 'buildingTypePurpose'
}) => {
    const [item, setItem] = useState('');
    const [section, setSection] = useState({
        section1: false,
        section1_1: false,
        section1_1_1: false,
        section1_1_1_0: false,
        section1_1_2: false,
        section1_1_2_1: false,
        section1_1_2_2: false,
        section1_1_3: false,
        section1_1_3_0: false,
        section1_2: false,
        section1_2_1: false,
        section1_2_1_1: false,
        section1_2_1_2: false,
        section1_2_2: false,
        section1_2_2_0: false,
        section1_2_3: false,
        section1_2_3_0: false,
        section1_2_4: false,
        section1_2_4_1: false,
        section1_2_4_2: false,
        section1_2_5: false,
        section1_2_5_1: false,
        section1_2_5_2: false,
        section1_2_6: false,
        section1_2_6_1: false,
        section1_2_6_2: false,
        section1_2_6_3: false,
        section1_2_6_4: false,
        section1_2_6_5: false,
        section1_2_7: false,
        section1_2_7_1: false,
        section1_2_7_2: false,
        section1_2_7_3: false,
        section1_2_7_4: false,

        section2: false,
        section2_1: false,
        section2_1_1: false,
        section2_1_1_1: false,
        section2_1_1_2: false,
        section2_1_2: false,
        section2_1_2_1: false,
        section2_1_2_2: false,
        section2_1_3: false,
        section2_1_3_0: false,
        section2_1_4: false,
        section2_1_4_1: false,
        section2_1_4_2: false,
        section2_1_5: false,
        section2_1_5_1: false,
        section2_1_5_2: false,
        section2_1_5_3: false,
        section2_2: false,
        section2_2_1: false,
        section2_2_1_1: false,
        section2_2_1_2: false,
        section2_2_1_3: false,
        section2_2_1_4: false,
        section2_2_2: false,
        section2_2_2_1: false,
        section2_2_2_2: false,
        section2_2_2_3: false,
        section2_2_2_4: false,
        section2_3: false,
        section2_3_0: false,
        section2_3_0_1: false,
        section2_3_0_2: false,
        section2_3_0_3: false,
        section2_3_0_4: false,
        section2_4: false,
        section2_4_1: false,
        section2_4_1_1: false,
        section2_4_1_2: false,
        section2_4_2: false,
        section2_4_2_0: false,
    });

    const onConfirm = () => {
        if (setNewBuilding) {
            setNewBuilding(state => ({ ...state, select: item })); 
        }
        if (object === 'requestObject') {
            setRequest(prev => ({...prev, [propertyToUpdate]: item}));
        } else {
            setRequest(state => ({ ...state, [object]: { ...state[object], [propertyToUpdate]: item } }));
        }
    };
    // reset values if used in Modal
    useState(() => {if (object === 'requestObject') setItem('');}, []);

    return (
        <Modal show={show} onHide={() => handleClose(setShow)} size='' centered>
            <Modal.Header closeButton bsPrefix='d-flex justify-content-between align-items-center px-sm-5 modal-header-custom'>
                <Modal.Title bsPrefix='modal-title'>Vybrat druh a účel stavby</Modal.Title>
            </Modal.Header>
            <Modal.Body bsPrefix='px-sm-5 row-wrapper modal-body-custom'>

                <ConstructionSection name={'1. Budovy'} step={1} isOpen={'section1'} section={section} setSection={setSection}/>
                {section.section1 && <>
                    <ConstructionSection name={'1.1 Budovy bytové'} step={2} isOpen={'section1_1'} section={section} setSection={setSection} />
                    {section.section1_1 && <>
                        <ConstructionSection name={'1.1.1 Budovy jednobytové'} step={3} isOpen={'section1_1_1'} section={section} setSection={setSection} />
                        {section.section1_1_1 && <>
                            <ConstructionSection name={'1.1.1.0 Budovy jednobytové'} step={4} isOpen={'section1_1_1_0'} section={section} setSection={setSection}/>
                            {section.section1_1_1_0 && 
                                <SectionData items={CONSTRUCTIONS_DATA.budovyJednobytove} step={5} setItem={setItem} />
                            }
                        </>}
                        

                        <ConstructionSection name={'1.1.2 Budovy dvou a vícebytové'} step={3} isOpen={'section1_1_2'} section={section} setSection={setSection}/>
                        {section.section1_1_2 && <>
                            <ConstructionSection name={'1.1.2.1 Budovy dvoubytové'} step={4} isOpen={'section1_1_2_1'} section={section} setSection={setSection}/>
                            {section.section1_1_2_1 && 
                                <SectionData items={CONSTRUCTIONS_DATA.budovyDvoubytove} step={5} setItem={setItem}/>
                            }
                            <ConstructionSection name={'1.1.2.2 Budovy tří a vícebytové'} step={4} isOpen={'section1_1_2_2'} section={section} setSection={setSection}/>
                            {section.section1_1_2_2 && 
                                <SectionData items={CONSTRUCTIONS_DATA.budovyTriAVice} step={5} setItem={setItem}/>
                            }
                        </>}
                        

                        <ConstructionSection name={'1.1.3 Budovy bytové ostatní'} step={3} isOpen={'section1_1_3'} section={section} setSection={setSection}/>
                        {section.section1_1_3 && <>
                            <ConstructionSection name={'1.1.3.0 Budovy bytové ostatní'} step={4} isOpen={'section1_1_3_0'} section={section} setSection={setSection}/>
                            {section.section1_1_3_0 && 
                                <SectionData items={CONSTRUCTIONS_DATA.budovyBytove} step={5} setItem={setItem}/>
                            }
                        </>}
                    </>}
                    

                    <ConstructionSection name={'1.2 Budovy nebytové'} step={2} isOpen={'section1_2'} section={section} setSection={setSection}/>
                    {section.section1_2 && <>
                        <ConstructionSection name={'1.2.1 Hotely a obdobné budovy'} step={3} isOpen={'section1_2_1'} section={section} setSection={setSection}/>
                        {section.section1_2_1 && <>
                            <ConstructionSection name={'1.2.1.1 Hotely'} step={4} isOpen={'section1_2_1_1'} section={section} setSection={setSection}/>
                            {section.section1_2_1_1 && 
                                <SectionData items={CONSTRUCTIONS_DATA.hotely} step={5} setItem={setItem}/>
                            }
                            <ConstructionSection name={'1.2.1.2 Budovy ostatní pro krátkodobé ubytování'} step={4} isOpen={'section1_2_1_2'} section={section} setSection={setSection}/>
                            {section.section1_2_1_2 && 
                                <SectionData items={CONSTRUCTIONS_DATA.budovyOstatni} step={5} setItem={setItem}/>
                            }
                        </>}
                        

                        <ConstructionSection name={'1.2.2 Budovy administrativní'} step={3} isOpen={'section1_2_2'} section={section} setSection={setSection}/>
                        {section.section1_2_2 && <>
                            <ConstructionSection name={'1.2.2.0 Budovy administrativní'} step={4} isOpen={'section1_2_2_0'} section={section} setSection={setSection}/>
                            {section.section1_2_2_0 && 
                                <SectionData items={CONSTRUCTIONS_DATA.budovyAdministrativni} step={5} setItem={setItem}/>
                            }
                        </>}
                        

                        <ConstructionSection name={'1.2.3 Budovy pro obchod'} step={3} isOpen={'section1_2_3'} section={section} setSection={setSection}/>
                        {section.section1_2_3 && <>
                            <ConstructionSection name={'1.2.3.0 Budovy pro obchod'} step={4} isOpen={'section1_2_3_0'} section={section} setSection={setSection}/>
                            {section.section1_2_3_0 && 
                                <SectionData items={CONSTRUCTIONS_DATA.budovyProObchod} step={5} setItem={setItem}/>
                            }
                        </>}
                        

                        <ConstructionSection name={'1.2.4 Budovy pro dopravu a telekomunikace'} step={3} isOpen={'section1_2_4'} section={section} setSection={setSection}/>
                        {section.section1_2_4 && <>
                            <ConstructionSection name={'1.2.4.1 Budovy pro telekomunikace, nádraží, terminály a budovy k nim příslušející'} step={4} isOpen={'section1_2_4_1'} section={section} setSection={setSection}/>
                            {section.section1_2_4_1 && 
                                <SectionData items={CONSTRUCTIONS_DATA.budovyProTelekomunikace} step={5} setItem={setItem}/>
                            }

                            <ConstructionSection name={'1.2.4.2 Garáže'} step={4} isOpen={'section1_2_4_2'} section={section} setSection={setSection}/>
                            {section.section1_2_4_2 && 
                                <SectionData items={CONSTRUCTIONS_DATA.garaze} step={5} setItem={setItem}/>
                            }
                        </>}
                        

                        <ConstructionSection name={'1.2.5 Budovy pro průmysl a skladování'} step={3} isOpen={'section1_2_5'} section={section} setSection={setSection}/>
                        {section.section1_2_5 && <>
                            <ConstructionSection name={'1.2.5.1 Budovy pro průmysl'} step={4} isOpen={'section1_2_5_1'} section={section} setSection={setSection}/>
                            {section.section1_2_5_1 && 
                                <SectionData items={CONSTRUCTIONS_DATA.budovyProPrumysl} step={5} setItem={setItem}/>
                            }

                            <ConstructionSection name={'1.2.5.2 Budovy skladů, nádrže a sila'} step={4} isOpen={'section1_2_5_2'} section={section} setSection={setSection}/>
                            {section.section1_2_5_2 && 
                                <SectionData items={CONSTRUCTIONS_DATA.budovySkladu} step={5} setItem={setItem}/>
                            }
                        </>}
                        

                        <ConstructionSection name={'1.2.6 Budovy pro společenské a kulturní účely, výzkum, vzdělávání a zdravotnictví'} step={3} isOpen={'section1_2_6'} section={section} setSection={setSection}/>
                        {section.section1_2_6 && <>
                            <ConstructionSection name={'1.2.6.1 Budovy pro společenské a kulturní účely'} step={4} isOpen={'section1_2_6_1'} section={section} setSection={setSection}/>
                            {section.section1_2_6_1 && 
                                <SectionData items={CONSTRUCTIONS_DATA.budovyProSpolecenskeUcely} step={5} setItem={setItem}/>
                            }

                            <ConstructionSection name={'1.2.6.2 Muzea a knihovny'} step={4} isOpen={'section1_2_6_2'} section={section} setSection={setSection}/>
                            {section.section1_2_6_2 && 
                                <SectionData items={CONSTRUCTIONS_DATA.muzeaAKnihovny} step={5} setItem={setItem}/>
                            }

                            <ConstructionSection name={'1.2.6.3 Školy, univerzity a budovy pro výzkum'} step={4} isOpen={'section1_2_6_3'} section={section} setSection={setSection}/>
                            {section.section1_2_6_3 && 
                                <SectionData items={CONSTRUCTIONS_DATA.skolyUniverzity} step={5} setItem={setItem}/>
                            }

                            <ConstructionSection name={'1.2.6.4 Budovy pro zdravotnictví'} step={4} isOpen={'section1_2_6_4'} section={section} setSection={setSection}/>
                            {section.section1_2_6_4 && 
                                <SectionData items={CONSTRUCTIONS_DATA.budovyProZdravotnictvi} step={5} setItem={setItem}/>
                            }

                            <ConstructionSection name={'1.2.6.5 Budovy pro sport'} step={4} isOpen={'section1_2_6_5'} section={section} setSection={setSection}/>
                            {section.section1_2_6_5 && 
                                <SectionData items={CONSTRUCTIONS_DATA.budovyProSport} step={5} setItem={setItem}/>
                            }
                        </>}
                        

                        <ConstructionSection name={'1.2.7 Budovy nebytové ostatní'} step={3} isOpen={'section1_2_7'} section={section} setSection={setSection}/>
                        {section.section1_2_7 && <>
                            <ConstructionSection name={'1.2.7.1 Budovy pro zemědělství'} step={4} isOpen={'section1_2_7_1'} section={section} setSection={setSection}/>
                            {section.section1_2_7_1 && 
                                <SectionData items={CONSTRUCTIONS_DATA.budovyProZemedelstvi} step={5} setItem={setItem}/>
                            }

                            <ConstructionSection name={'1.2.7.2 Budovy pro bohoslužby a náboženské aktivity'} step={4} isOpen={'section1_2_7_2'} section={section} setSection={setSection}/>
                            {section.section1_2_7_2 && 
                                <SectionData items={CONSTRUCTIONS_DATA.budovyProBohosluzby} step={5} setItem={setItem}/>
                            }

                            <ConstructionSection name={'1.2.7.3 Historické nebo kulturní památky'} step={4} isOpen={'section1_2_7_3'} section={section} setSection={setSection}/>
                            {section.section1_2_7_3 && 
                                <SectionData items={CONSTRUCTIONS_DATA.historickePamatky} step={5} setItem={setItem}/>
                            }

                            <ConstructionSection name={'1.2.7.4 Budovy nebytové ostatní'} step={4} isOpen={'section1_2_7_4'} section={section} setSection={setSection}/>
                            {section.section1_2_7_4 && 
                                <SectionData items={CONSTRUCTIONS_DATA.budovyNebytoveOstatni} step={5} setItem={setItem}/>
                            }
                        </>}
                    </>}
                    
                </>}
                
                <ConstructionSection name={'2. Inženýrská díla'} step={1} isOpen={'section2'} section={section} setSection={setSection}/>
                {section.section2 && <>
                    <ConstructionSection name={'2.1 Dopravní díla'} step={2} isOpen={'section2_1'} section={section} setSection={setSection}/>
                    {section.section2_1 && <>
                        <ConstructionSection name={'2.1.1 Dálnice, silnice, místní a účelové komunikace'} step={3} isOpen={'section2_1_1'} section={section} setSection={setSection}/>
                        {section.section2_1_1 && <>
                            <ConstructionSection name={'2.1.1.1 Dálnice a silnice I., II. a III. třídy'} step={4} isOpen={'section2_1_1_1'} section={section} setSection={setSection}/>
                            {section.section2_1_1_1 && 
                                <SectionData items={CONSTRUCTIONS_DATA.dalniceASilnice} step={5} setItem={setItem}/>
                            }
                            <ConstructionSection name={'2.1.1.2 Místní a účelové komunikace'} step={4} isOpen={'section2_1_1_2'} section={section} setSection={setSection}/>
                            {section.section2_1_1_2 && 
                                <SectionData items={CONSTRUCTIONS_DATA.mistniKomunikace} step={5} setItem={setItem}/>
                            }
                        </>}

                        <ConstructionSection name={'2.1.2 Dráhy kolejové'} step={3} isOpen={'section2_1_2'} section={section} setSection={setSection}/>
                        {section.section2_1_2 && <>
                            <ConstructionSection name={'2.1.2.1 Dráhy železniční dálkové'} step={4} isOpen={'section2_1_2_1'} section={section} setSection={setSection}/>
                            {section.section2_1_2_1 && 
                                <SectionData items={CONSTRUCTIONS_DATA.drahyZeleznicni} step={5} setItem={setItem}/>
                            }
                            <ConstructionSection name={'2.1.2.2 Dráhy kolejové městské a ostatní'} step={4} isOpen={'section2_1_2_2'} section={section} setSection={setSection}/>
                            {section.section2_1_2_2 && 
                                <SectionData items={CONSTRUCTIONS_DATA.drahyKolejove} step={5} setItem={setItem}/>
                            }
                        </>}

                        <ConstructionSection name={'2.1.3 Plochy letišť'} step={3} isOpen={'section2_1_3'} section={section} setSection={setSection}/>
                        {section.section2_1_3 && <>
                            <ConstructionSection name={'2.1.3.0 Plochy letišť'} step={4} isOpen={'section2_1_3_0'} section={section} setSection={setSection}/>
                            {section.section2_1_3_0 && 
                                <SectionData items={CONSTRUCTIONS_DATA.plochyLetist} step={5} setItem={setItem}/>
                            }
                        </>}

                        <ConstructionSection name={'2.1.4 Mosty, visuté dálnice, tunely, podjezdy a podchody'} step={3} isOpen={'section2_1_4'} section={section} setSection={setSection}/>
                        {section.section2_1_4 && <>
                            <ConstructionSection name={'2.1.4.1 Mosty a visuté dálnice'} step={4} isOpen={'section2_1_4_1'} section={section} setSection={setSection}/>
                            {section.section2_1_4_1 && 
                                <SectionData items={CONSTRUCTIONS_DATA.mostyADalnice} step={5} setItem={setItem}/>
                            }
                            <ConstructionSection name={'2.1.4.2 Tunely a podchody'} step={4} isOpen={'section2_1_4_2'} section={section} setSection={setSection}/>
                            {section.section2_1_4_2 && 
                                <SectionData items={CONSTRUCTIONS_DATA.tunely} step={5} setItem={setItem}/>
                            }
                        </>}

                        <ConstructionSection name={'2.1.5 Přístavy, vodní cesty, vodní stupně a ostatní vodní díla'} step={3} isOpen={'section2_1_5'} section={section} setSection={setSection}/>
                        {section.section2_1_5 && <>
                            <ConstructionSection name={'2.1.5.1 Přístavy a plavební kanály'} step={4} isOpen={'section2_1_5_1'} section={section} setSection={setSection}/>
                            {section.section2_1_5_1 && 
                                <SectionData items={CONSTRUCTIONS_DATA.pristavyAKanaly} step={5} setItem={setItem}/>
                            }
                            <ConstructionSection name={'2.1.5.2 Vodní stupně'} step={4} isOpen={'section2_1_5_2'} section={section} setSection={setSection}/>
                            {section.section2_1_5_2 && 
                                <SectionData items={CONSTRUCTIONS_DATA.vodniStupne} step={5} setItem={setItem}/>
                            }
                            <ConstructionSection name={'2.1.5.3 Akvadukty, vodní díla pro zavlažování a odvodnění'} step={4} isOpen={'section2_1_5_3'} section={section} setSection={setSection}/>
                            {section.section2_1_5_3 && 
                                <SectionData items={CONSTRUCTIONS_DATA.akvadukty} step={5} setItem={setItem}/>
                            }
                        </>}
                    </>}

                    <ConstructionSection name={'2.2 Vedení trubní, telekomunikační a elektrická'} step={2} isOpen={'section2_2'} section={section} setSection={setSection}/>
                    {section.section2_2 && <>
                        <ConstructionSection name={'2.2.1 Vedení dálková trubní, telekomunikační a elektrická'} step={3} isOpen={'section2_2_1'} section={section} setSection={setSection}/>
                        {section.section2_2_1 && <>
                            <ConstructionSection name={'2.2.1.1 Vedení plynu, ropy a ostatních produktů dálková trubní'} step={4} isOpen={'section2_2_1_1'} section={section} setSection={setSection}/>
                            {section.section2_2_1_1 && 
                                <SectionData items={CONSTRUCTIONS_DATA.vedeniPlynu} step={5} setItem={setItem}/>
                            }
                            <ConstructionSection name={'2.2.1.2 Vedení vody dálková trubní'} step={4} isOpen={'section2_2_1_2'} section={section} setSection={setSection}/>
                            {section.section2_2_1_2 && 
                                <SectionData items={CONSTRUCTIONS_DATA.vedeniVody} step={5} setItem={setItem}/>
                            }
                            <ConstructionSection name={'2.2.1.3 Vedení dálková telekomunikační'} step={4} isOpen={'section2_2_1_3'} section={section} setSection={setSection}/>
                            {section.section2_2_1_3 && 
                                <SectionData items={CONSTRUCTIONS_DATA.vedeniTelekomunikacni} step={5} setItem={setItem}/>
                            }
                            <ConstructionSection name={'2.2.1.4 Vedení dálková elektrická'} step={4} isOpen={'section2_2_1_4'} section={section} setSection={setSection}/>
                            {section.section2_2_1_4 && 
                                <SectionData items={CONSTRUCTIONS_DATA.vedeniElektricka} step={5} setItem={setItem}/>
                            }
                        </>}

                        <ConstructionSection name={'2.2.2 Vedení místní trubní, elektrická a telekomunikační'} step={3} isOpen={'section2_2_2'} section={section} setSection={setSection}/>
                        {section.section2_2_2 && <>
                            <ConstructionSection name={'2.2.2.1 Vedení plynu místní trubní'} step={4} isOpen={'section2_2_2_1'} section={section} setSection={setSection}/>
                            {section.section2_2_2_1 && 
                                <SectionData items={CONSTRUCTIONS_DATA.vedeniPlynuMistni} step={5} setItem={setItem}/>
                            }
                            <ConstructionSection name={'2.2.2.2 Vedení vody místní trubní'} step={4} isOpen={'section2_2_2_2'} section={section} setSection={setSection}/>
                            {section.section2_2_2_2 && 
                                <SectionData items={CONSTRUCTIONS_DATA.vedeniVodyMistni} step={5} setItem={setItem}/>
                            }
                            <ConstructionSection name={'2.2.2.3 Vedení kanalizace místní trubní'} step={4} isOpen={'section2_2_2_3'} section={section} setSection={setSection}/>
                            {section.section2_2_2_3 && 
                                <SectionData items={CONSTRUCTIONS_DATA.vedeniKanalizaceMistni} step={5} setItem={setItem}/>
                            }
                            <ConstructionSection name={'2.2.2.4 Vedení místní elektrická a telekomunikační'} step={4} isOpen={'section2_2_2_4'} section={section} setSection={setSection}/>
                            {section.section2_2_2_4 && 
                                <SectionData items={CONSTRUCTIONS_DATA.vedeniMistniElektricka} step={5} setItem={setItem}/>
                            }
                        </>}
                    </>}

                    <ConstructionSection name={'2.3 Soubory staveb pro průmyslové účely'} step={2} isOpen={'section2_3'} section={section} setSection={setSection}/>
                    {section.section2_3 && <>
                        <ConstructionSection name={'2.3.0 Soubory staveb pro průmyslové účely'} step={3} isOpen={'section2_3_0'} section={section} setSection={setSection}/>
                        {section.section2_3_0 && <>
                            <ConstructionSection name={'2.3.0.1 Stavby důlní a pro těžbu'} step={4} isOpen={'section2_3_0_1'} section={section} setSection={setSection}/>
                            {section.section2_3_0_1 && 
                                <SectionData items={CONSTRUCTIONS_DATA.stavbyDulni} step={5} setItem={setItem}/>
                            }
                            <ConstructionSection name={'2.3.0.2 Stavby elektráren (díla energetická výrobní)'} step={4} isOpen={'section2_3_0_2'} section={section} setSection={setSection}/>
                            {section.section2_3_0_2 && 
                                <SectionData items={CONSTRUCTIONS_DATA.stavbyElektraren} step={5} setItem={setItem}/>
                            }
                            <ConstructionSection name={'2.3.0.3 Stavby pro chemický průmysl'} step={4} isOpen={'section2_3_0_3'} section={section} setSection={setSection}/>
                            {section.section2_3_0_3 && 
                                <SectionData items={CONSTRUCTIONS_DATA.stavbyProChemickyPrumysl} step={5} setItem={setItem}/>
                            }
                            <ConstructionSection name={'2.3.0.4 Stavby pro ostatní průmysl j. n.'} step={4} isOpen={'section2_3_0_4'} section={section} setSection={setSection}/>
                            {section.section2_3_0_4 && 
                                <SectionData items={CONSTRUCTIONS_DATA.stavbyProOstatniPrumysl} step={5} setItem={setItem}/>
                            }
                        </>}
                    </>}

                    <ConstructionSection name={'2.4 Ostatní inženýrská díla'} step={2} isOpen={'section2_4'} section={section} setSection={setSection}/>
                    {section.section2_4 && <>
                        <ConstructionSection name={'2.4.1 Stavby pro sport a rekreaci'} step={3} isOpen={'section2_4_1'} section={section} setSection={setSection}/>
                        {section.section2_4_1 && <>
                            <ConstructionSection name={'2.4.1.1 Sportovní hřiště'} step={4} isOpen={'section2_4_1_1'} section={section} setSection={setSection}/>
                            {section.section2_4_1_1 && 
                                <SectionData items={CONSTRUCTIONS_DATA.sportovniHriste} step={5} setItem={setItem}/>
                            }
                            <ConstructionSection name={'2.4.1.2 Ostatní stavby pro sport a rekreaci'} step={4} isOpen={'section2_4_1_2'} section={section} setSection={setSection}/>
                            {section.section2_4_1_2 && 
                                <SectionData items={CONSTRUCTIONS_DATA.ostatniStavbyProSport} step={5} setItem={setItem}/>
                            }
                        </>}
                        
                        <ConstructionSection name={'2.4.2 Ostatní inženýrská díla j. n.'} step={3} isOpen={'section2_4_2'} section={section} setSection={setSection}/>
                        {section.section2_4_2 && <>
                            <ConstructionSection name={'2.4.2.0 Ostatní inženýrská díla j. n.'} step={4} isOpen={'section2_4_2_0'} section={section} setSection={setSection}/>
                            {section.section2_4_2_0 && 
                                <SectionData items={CONSTRUCTIONS_DATA.ostatniInzenyrskaDila} step={5} setItem={setItem}/>
                            }
                        </>}
                    </>}
                </>}
            </Modal.Body>
            <Modal.Footer bsPrefix='d-flex px-sm-5 modal-footer-custom'>
                <Row className='button-wrapper'>
                    <Col className='d-flex flex-column flex-lg-row button-wrapper--inner' xs={12} lg={6}>
                        <button className='modal-button modal-button--filled' onClick={() => {onConfirm(); handleClose(setShow);}}>Vybrat</button>
                    </Col>
                    <Col className='d-flex justify-content-end'>
                        <button className='modal-button' onClick={() => handleClose(setShow) }>Zrušit</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

NewConstructionModal.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
    handleClose: PropTypes.func,
    setNewBuilding: PropTypes.func,
    setRequest: PropTypes.func,
    object: PropTypes.string,
    propertyToUpdate: PropTypes.string,
};

ConstructionSection.propTypes = {
    name: PropTypes.string,
    step: PropTypes.number,
    isOpen: PropTypes.string,
    section: PropTypes.object,
    setSection: PropTypes.func,
    children: PropTypes.node,
};

SectionData.propTypes = {
    items: PropTypes.array,
    step: PropTypes.number,
    setItem: PropTypes.func,
};

export default NewConstructionModal;

