
import axios from 'axios';
import urlJoin from 'url-join';
import apiConfig from '../config/api-config.js';
import {
    DOCUMENT_FIFTH, 
    DOCUMENT_FIRST, 
    DOCUMENT_FOURTH, 
    DOCUMENT_SECOND, 
    PLNA_MOC_FIRST, 
    WARRANT,
    ACCEPTED_FORMATS_ARRAY,
    ACCEPTED_FORMATS_ARRAY_CSV,
    ACCEPTED_FORMATS_ARRAY_DOKLADOVA_CAST,
    ACCEPTED_FORMATS_ARRAY_DOKUMENTACE_PRILOHY,
    AFFECTED_POZEMKY_TABLE,
    AFFECTED_STAVBY_TABLE,
    APPLICANT_MODEL,
    APPLICANT_TYPE_FO,
    APPLICANT_TYPE_FOP,
    APPLICANT_TYPE_PO,
    ATTORNEY_TYPE_FO,
    ATTORNEY_TYPE_FOP,
    ATTORNEY_TYPE_PO,
    BUILDING,
    DOCUMENT_THIRD_1,
    DOCUMENT_THIRD_2,
    DOCUMENT_THIRD_3,
    DOCUMENT_THIRD_4,
    DOCUMENT_THIRD_5,
    FORM_10_INTENTION,
    FORM_11_INTENTION,
    FORM_12_INTENTION,
    FORM_13_INTENTION,
    FORM_14_INTENTION,
    PLNA_MOC_SECOND,
    PLNA_MOC_THIRD,
    PLNA_MOC_FOURTH,
    POST_BODY_FOR_FILTER,
    POZEMKY_TABLE,
    RESPECTIVE_SECTION_DOCUMENT_NAMES,
    RIZENI,
    STAVBY_TABLE,
    PLNA_MOC_FIFTH,
    ZADOSTI,
} from '../constants/sharedConstants.js';
import { v4 as uuidv4 } from 'uuid';
import { getFormById, formSave } from '../apiCalls/formApiCalls.js';
import { deleteExistingZamer, isRequestOK, makeCopyZadostiById } from '../apiCalls/componentsApiCalls.js';

const convertBytes = (bytes, decimals = 2) => {
    if (!Number(bytes)) {
        return 0;
    }
      
    const kbToBytes = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB',];
      
    const index = Math.floor(
        Math.log(bytes) / Math.log(kbToBytes),
    );
      
    return `${parseFloat(
        (bytes / Math.pow(kbToBytes, index)).toFixed(dm),
    )} ${sizes[index]}`;
};

const shouldShowProgress = (uploadSuccess, uploadCancelled, uploadError, progress) => {
    if(uploadSuccess || uploadCancelled || uploadError) {
        return false;
    }
    
    if(progress === null || progress === 100) {
        return false;
    }

    return true;
};

const shouldNotFetch = (uploadSuccess, uploadCancelled, uploadError, message) => {    
    return Boolean(uploadSuccess || uploadCancelled || uploadError || message !== undefined);
};

const getNewAbortController = () => {
    return new AbortController();
};

const cancelUpload = (controller) => {
    controller.abort();
};

const getUrl = async (token) => {
    const source = axios.CancelToken.source();
    try {
        const config = {    
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            cancelToken: source.token,
        };
        const response = await axios.get(
            urlJoin(apiConfig.validateAndPersistBaseUrl, 'blob-storage/get-url'),
            config
        );
        if ((200 <= response?.status) && (response?.status < 300)) {
            return response.data;
        }
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            console.error(error);
        }
    }
};

const extractFileName = (name) => {
    if (name) {
        name.split('.').pop();
        return name;
    }
};

const extractExtensionName = (name) => {
    if (name) {
        return name.split('.').pop().toLowerCase();
    }
};

const getTextfromSectionName = ( // : str | void
    keyName, // str
) => {
    if (!keyName) {
        return;
    }
    if (!RESPECTIVE_SECTION_DOCUMENT_NAMES[keyName]) {
        return;
    }

    return RESPECTIVE_SECTION_DOCUMENT_NAMES[keyName];
};

const isNotForDokumentace = (category) => {
    const dokumentaceCategories = [
        DOCUMENT_FIRST,
        DOCUMENT_SECOND,
        DOCUMENT_THIRD_1,
        DOCUMENT_THIRD_2,
        DOCUMENT_THIRD_3,
        DOCUMENT_THIRD_4,
        DOCUMENT_THIRD_5,
        DOCUMENT_FOURTH,
        DOCUMENT_FIFTH
    ];
    return Boolean(dokumentaceCategories.indexOf(category) === -1);
};

const canUploadFile = (
    file, // obj
    category, // str
    uploadedBytes, // num
) => { // : obj | bool
    let maxSize = (100 * 1024 * 1024); // 100mb based on Radan's desc.

    if (file.size > maxSize && isNotForDokumentace(category)) {
        console.log('size exceeded for non-dokumentace files');
        return {message: 'size'};
    }
    
    // update on OZE-2199, navýšit datový limit z 1,7 GB na 5 GB
    // const totalSize = (1.7 * 1024 * 1024 * 1024); 
    const totalSize = (5 * 1024 * 1024 * 1024); 
    if ((getTextfromSectionName(category)) && (Number(uploadedBytes) + Number(file.size)) > totalSize) {
        console.log('total size exceeded');
        return {message: 'total_size'};
    }

    const fileExtension = extractExtensionName(file.name);

    if (category.startsWith('csv_import') && ACCEPTED_FORMATS_ARRAY_CSV.indexOf(fileExtension) === -1) {
        // putting an array in case clients may add other formats in the future
        console.log('not a csv file');
        return {message: 'format'};
    }
    // for power of attorney files there's no restriction announced so keeping all file types
    if (category.startsWith('power_of_attorney') && ACCEPTED_FORMATS_ARRAY.indexOf(fileExtension) === -1) {
        console.log('incorrect format');
        return {message: 'format'};
    }

    if (category === DOCUMENT_FIFTH && ACCEPTED_FORMATS_ARRAY_DOKLADOVA_CAST.indexOf(fileExtension) === -1) {
        console.log('incorrect format');
        return {message: 'format'};
    }
    
    if (!category.startsWith('csv_import') && !category.startsWith('power_of_attorney') && category !== DOCUMENT_FIFTH && ACCEPTED_FORMATS_ARRAY_DOKUMENTACE_PRILOHY.indexOf(fileExtension) === -1) {
        console.log('incorrect format');
        return {message: 'format'};
    }

    return true;
};

const filterFiles = (
    fileListObj, // arr
    category, // str
    uploadedBytes, // num
) => { // : obj | arr
    const files = Object.values(fileListObj);
    if (files.length === 0) {
        return [];
    }

    return files.map(el => {
        if (canUploadFile(el, category, uploadedBytes) instanceof Object) {
            return Object.assign(el, canUploadFile(el, category, uploadedBytes));
        } else if (canUploadFile(el, category, uploadedBytes) === true) {
            return el;
        }
    });
};

const spreadUploadedDocuments = ( // void|
    docOrDocs, // obj || arr
    setDocuments,
    category = undefined, 
    setUploadedBytes = undefined, 
    isForm18 = undefined, 
    isForExistingDocumentation = undefined
) => {
    if (category) {
        if (docOrDocs instanceof Object && docOrDocs?.fileName !== null) {
            setDocuments((prev) => ({ ...prev, [category]: [...(prev[category] || []), docOrDocs]}));
        }
        return;
    }

    if (!(docOrDocs instanceof Array)) {
        console.log('unknown type');
        return;
    }

    const getAccumulatedBytes = () => {
        return docOrDocs.reduce((acc, curr) => acc + Number(curr.size), 0);
    };

    const setFiles = (cat, doc) => {
        if (isForm18 && doc?.fileName !== null && doc?.section === DOCUMENT_FIRST) {
            // filter out for edge case
            setDocuments(prev => ({ ...prev, [DOCUMENT_FIRST]: [...(prev[DOCUMENT_FIRST] || []).filter(el => el.section === DOCUMENT_FIRST), doc] }));
            return;
        } 
        
        if (cat && doc?.fileName !== null) {      
            setDocuments(prev => ({ ...prev, [cat]: [...(new Map([...(prev[cat] || []), doc]
                .map(el => [el.uid, el]))
                .values()) || []]
                .filter(doc => {
                    if (isForExistingDocumentation) {
                        return doc.existing === true;
                    } 
                    return true;
                }) 
            }));
        }   
    };

    if (!docOrDocs.length) {
        setDocuments(prev => {
            const resetObject = {};
            for (let key in prev) {
                if (Object.hasOwn(prev, String(key))) {
                    resetObject[key] = [];
                }
            }

            return resetObject;
        });
        return;
    }

    const flaggedDocuments = docOrDocs.map(el => ({ ...el, flagged: true, ...(isForExistingDocumentation ? {existing: true} : {}) })); 
    flaggedDocuments.forEach(document => setFiles(document?.section, document));

    if(setUploadedBytes) {
        setUploadedBytes(getAccumulatedBytes());
    }
};

const spreadUploadedModalDocuments = (doc, setDocuments, category) => {
    if (category && category.startsWith('csv_import')) {
        if (doc instanceof Object) {
            setDocuments((prev) => ({ ...prev, [category]: [doc]}));
        }
        return;
    }

    const flaggedDocuments = doc instanceof Array && doc.map(el => ({ ...el, flagged: true }));
    
    const acceptedSections = [
        POZEMKY_TABLE,
        STAVBY_TABLE,
        AFFECTED_POZEMKY_TABLE,
        AFFECTED_STAVBY_TABLE,
    ];

    flaggedDocuments.forEach(el => {
        const cat = el.section;
        if (acceptedSections.indexOf(cat) > -1) {
            setDocuments(prev => ({ ...prev, [cat]: [...(prev[cat] || []),  el] }));
        }
    });
};

const spreadUploadedAttachments = (att, setAttachments, category) => {
    if (att instanceof Array) {    
        const flaggedAttachments = att.map(el => ({ ...el, flagged: true }));
        flaggedAttachments.forEach(el => {
            if (el.section && el.fileName !== null) {
                setAttachments(prev => ({ ...prev, [el.section]: [...(prev[el.section] || []), el] }));
            }
        });
    } else if (att instanceof Object && att.fileName !== null) {
        if (category) {
            setAttachments((prev) => ({ ...prev, [category]: [...(prev[category] || []), att]}));
        } else {
            console.log('testing sprea');
            setAttachments(prev => ({ ...prev, [WARRANT]: [...(prev[WARRANT] || []), { ...att, flagged: true }] }));
        }
    }
};

const isNotUploading = (progressElements) => {
    if (progressElements?.length === 0) {
        return true;
    }
    
    // if (progressElements.every((el) => el.progress === null || el.progress === 100)) {
    //     return true;
    // }

    if (progressElements?.some((el) => el.progress > 0 && el.progress < 100)) {
        return false;
    }

    return true;
};

const shouldNotGoToPreviousPage = (startIndex, resultPerPage) => {
    return startIndex - resultPerPage < 0;  
};

const shouldNotGoToFirstPage = (startIndex, resultPerPage) => {
    return (startIndex === 0 || startIndex - resultPerPage < 0);
};

const shouldNotGoToLastPage = (startIndex, listData, resultPerPage) => {
    return (startIndex === listData.length -1 || startIndex + resultPerPage > listData.length -1);
};

const shouldNotGoToNextPage = (startIndex, listData, resultPerPage) => {
    return startIndex + resultPerPage > listData.length -1;
};

const getFormType = (path) => {
    return Number(/[1-9]+\d*/.exec(path)?.[0]) ?? path;
};

const mapIntentionData = (
    list //ArrayOf(Object) (pozemky nebo stavby)
) => {
    if (list.length === 0) {
        return;
    }
    const mappedList = [...list].map(obj => ({...obj, uid: uuidv4()}));
    return mappedList;
};

const spreadParcelsAndConstructions = (
    data, //obj
    setParcelAndConstructionData, //func
    parcelAndConstructionData // obj
) => {
    const affectedBuildConstructions = data?.affectedBuildConstructions;
    if (affectedBuildConstructions instanceof Array && Object.hasOwn(parcelAndConstructionData, 'affectedBuildConstructions')) {
        if (affectedBuildConstructions.length === 0) {
            setParcelAndConstructionData(prev => ({...prev, affectedBuildConstructions: []}));
        } else {
            setParcelAndConstructionData(prev => ({...prev, affectedBuildConstructions: mapIntentionData(affectedBuildConstructions)}));
        }
    }

    const affectedBuildParcels = data?.affectedBuildParcels;
    if (affectedBuildParcels instanceof Array && Object.hasOwn(parcelAndConstructionData, 'affectedBuildParcels')) {
        if (affectedBuildParcels.length === 0) {
            setParcelAndConstructionData(prev => ({...prev, affectedBuildParcels: []}));
        } else {
            setParcelAndConstructionData(prev => ({...prev, affectedBuildParcels: mapIntentionData(affectedBuildParcels)}));
        }
    }

    const buildConstructions = data?.buildConstructions;
    if (buildConstructions instanceof Array && Object.hasOwn(parcelAndConstructionData, 'buildConstructions')) {
        if (buildConstructions.length === 0) {
            setParcelAndConstructionData(prev => ({...prev, buildConstructions: []}));
        } else {
            setParcelAndConstructionData(prev => ({...prev, buildConstructions: mapIntentionData(buildConstructions)}));
        }
    }

    const buildParcels = data?.buildParcels;
    if (buildParcels instanceof Array && Object.hasOwn(parcelAndConstructionData, 'buildParcels')) {
        if (buildParcels.length === 0) {
            setParcelAndConstructionData(prev => ({...prev, buildParcels: []}));
        } else {
            setParcelAndConstructionData(prev => ({...prev, buildParcels: mapIntentionData(buildParcels)}));
        }
    }

    const approvedConstructions = data?.approvedConstructions;
    if (approvedConstructions instanceof Array && Object.hasOwn(parcelAndConstructionData, 'approvedConstructions')) {
        if (approvedConstructions.length === 0) {
            setParcelAndConstructionData(prev => ({...prev, approvedConstructions: []}));
        } else {
            setParcelAndConstructionData(prev => ({...prev, approvedConstructions: mapIntentionData(approvedConstructions)}));
        }
    }

    const designers = data?.designers;
    if (designers instanceof Array && Object.hasOwn(parcelAndConstructionData, 'designers')) {
        if (designers.length === 0) {
            setParcelAndConstructionData(prev => ({...prev, designers: []}));
        } else {
            setParcelAndConstructionData(prev => ({...prev, designers: mapIntentionData(designers)}));
        }
    }
};

const findChosenIntention = ( // bool | obj
    input, //str
    zameryList //ArrayOf(Object)
) => {
    if (input.trim() === '') {
        return false;
    }

    const str = input.split(/\s*-\s*/)?.[0]?.trim() || null;
    const chosenIntention = str ? zameryList.find(el => {
        const projectId = el.projectId?.trim().toLowerCase();
        return projectId === str.toLowerCase();
    }) : null;

    // const insertedIntention = input.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g);
    // if (!insertedIntention) {
    //     return false;
    // }

    // const chosenIntention = [...zameryList].find((el) => el.id === insertedIntention[0]);
    // if (!chosenIntention) {
    //     return false;
    // }

    return chosenIntention ?? false;
};

const shouldNotSave = (
    prevReq, //obj
    req, //obj
    firstRender = undefined // bool | undefined
) => {
    if (firstRender) {
        return true;
    }

    return Boolean(JSON.stringify(prevReq) === JSON.stringify(req));
};

const canInitiateSaveForPrijemce = (
    firstRender, // obj,
    request, //obj
    prevRequest, // obj
    // eslint-disable-next-line no-unused-vars
    authorityType = undefined, // str
) => {
    if (firstRender) {
        return false;
    }

    // avoid multiple save for same object
    if (shouldNotSave(prevRequest, request)) {
        return false;
    }

    return true;
};

const canFulFillPrijemceList = (
    req = undefined, // obj
    firstRender, // obj,
    searchRequest, // obj
    setPrijemceList, // fn
    authorityType = undefined, // str
) => {
    if (firstRender || (req?.buildIntention?.[authorityType]?.authorityName === searchRequest?.title)) {
        return false;
    }

    if (searchRequest.title?.trim()?.length === 0) {
        setPrijemceList([]);
        return false;
    }

    return true;
};

const appendNumberToGovLabel = (
    developpedZadostiLength, // num | null
) => {
    if (developpedZadostiLength === null) {
        document.querySelector('.developed-amount')?.remove();
        return;
    }
    // as gov buttons are not compatible with "react", using this below.
    setTimeout(() => {
        const cardBtn = document.querySelectorAll('.gov-tabs__item')[1];
        if (!cardBtn) {
            return;
        }
        
        if (developpedZadostiLength > 0) {           
            const amountSpan = document.createElement('span');
            amountSpan.className = 'developed-amount';
            amountSpan.textContent = String(developpedZadostiLength);
            cardBtn?.appendChild(amountSpan);
        }
    }, 250);
};

const updateNumberOnGovLabel = (
    developpedZadostiLength, // num
) => {
    if (developpedZadostiLength === 0) {
        document.querySelector('.developed-amount')?.remove();
        return;
    }

    if (document.querySelector('.developed-amount')) {
        document.querySelector('.developed-amount').textContent = String(developpedZadostiLength);
    }
};

const setCsvDataAndRequest = (
    listData, // arr
    setList, // func
    setReq, // func
    key, // str
    data, // array
    isFor18 = undefined // str|undefined
) => {
    if (!data) {
        return;
    }

    if (listData.length === 0 && data?.length === 0) {
        return;
    }
    setList(data);

    if (isFor18) {
        setReq(prev => ({ ...prev, accompanyingDocument: {...prev.accompanyingDocument, [key]: [...data]
            .map(el => {
                if (Object.hasOwn(el, 'uid')) {
                    // eslint-disable-next-line no-unused-vars
                    const {uid, ...others} = el;
                    return others;
                } else {
                    return el;
                }
            })}
        }));
    } else {
        setReq(prev => ({ ...prev, [key]: [...data]
            .map(el => {
                if (Object.hasOwn(el, 'uid')) {
                    // eslint-disable-next-line no-unused-vars
                    const {uid, ...others} = el;
                    return others;
                } else {
                    return el;
                }
            })
        }));
    }
};

const getPrijemceList = (
    searchReq, // obj
    token, // str
    setReq, // func
    setLoading,
    signal
) => {
    if (searchReq?.typeTitle === null) {
        return;
    }

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        
        signal
    };

    setLoading(true);
    (async () => {
        try {
            const response = await axios.post(
                urlJoin(apiConfig.getAndQueryBaseUrl, 'organizations/search'),
                searchReq,
                config
            );

            if (!signal.aborted && isRequestOK(response.status)) {
                setReq([...new Map([...response.data.data]
                    .map(el => [el.id, el])).values()]);
                setLoading(false);
            }
        }  catch (error) {
            if (!signal.aborted) {
                console.error(error);
                setLoading(false);
            }
        }
    })();
};

const spreadObject = ( // : obj
    obj, // obj
) =>  {
    return Object.entries(obj)
        // eslint-disable-next-line no-unused-vars
        .filter(([_, val]) => val !== null)
        .reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {});
};

const getBuildApplicationFormForPrijemce = async (
    id, // str
    token, // str
    intention, // str
    setReq, // func
    setSearchRequest, // func
    dynamicKey, // str | null
    authorityType, // str
    pathname, // str
    navigate // fn
) => {
    const source = axios.CancelToken.source();

    if (id) {
        const response = await getFormById(id, token, source, intention, null, pathname, navigate);

        if ((200 <= response?.status) && (response?.status < 300)) {
            const intention = response.data?.buildIntention;

            if (intention) {
                setReq(state => ({ ...state, buildIntention: {...state.buildIntention, ...spreadObject(intention), [authorityType]: intention?.[authorityType]} }));
            }     
            if (intention?.[authorityType] === null || !(intention?.[authorityType]?.authorityName)) {
                return;
            }  
            setSearchRequest(prev => ({...prev, title: intention?.[authorityType]?.authorityName ?? '', typeTitle: dynamicKey || BUILDING}));
        }

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    } else {
        console.log('NON EXISTENT ID');
    }
};

const normalizeText = (input) => {
    return (input ?? '').trim().toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

const checkCsvHeaders = ( // : bool | void
    inputArr,
    ref,
    setMissingFields
)  => {
    if (ref === undefined) {
        console.log('invalid');
        return;
    }

    const normalizedInputArr = inputArr.map(normalizeText);
    const normalizedRef = ref.map(normalizeText);

    if (normalizedRef.every((el, idx) => el === normalizedInputArr[idx])) {
        return true;
    }

    const errors = normalizedRef.filter((el, idx) => el !== normalizedInputArr[idx]);

    setMissingFields(prev => [...prev, ...(errors || [])]);
};

const shouldNotSetAuthority = (authority) => {
    if (!authority || !(authority instanceof Object)) {
        return true;
    }

    return Boolean(authority?.authorityName?.trim() === '' || Object.values(authority).every(val => !val));
};

const useOnlyRequiredKeys = (
    struc, // obj
    src, // obj
) => {
    try {
        if (struc) {
            const filteredObject = {};  
            Object.keys(struc).forEach(key => {
                if (key in src) {
                    if (src[key] && typeof struc[key] === 'object' && !Array.isArray(struc[key])) {
                    // make recursion in case it's nested
                        filteredObject[key] = useOnlyRequiredKeys(struc[key], src[key]);
                    } else if (src[key]) {
                    // unfortunately intial values are all null. Skipping null saving with else if
                        filteredObject[key] = src[key];
                    }
                }
            });
            
            return filteredObject;
        }
    } catch (error) {
        console.log('error: ', error);
        return src;
    }
};

const enterCsvData = ( // : void
    setTableRequest, // fn
    newLines, // arr
    dynamicKey, //str
) => {
    setTableRequest(prev => ({ ...prev, [dynamicKey]: [...newLines]
        .map(el => {
            if (Object.hasOwn(el, 'uid')) {
                // eslint-disable-next-line no-unused-vars
                const {uid, ...others} = el;
                return others;
            } else {
                return el;
            }
        })
    }));
};

const removeCsvData = (
    setTableRequest, // fn
    newData, // arr
    dynamicKey, //str
) => {
    setTableRequest(prev => ({
        ...prev,
        [dynamicKey]: newData.map(el => {
            if (Object.hasOwn(el, 'uid')) {
                // eslint-disable-next-line no-unused-vars
                const {uid, ...others} = el;
                return others;
            } else {
                return el;
            }
        })
    }));
};

const shouldNotSaveTable = ( // : bool
    stavbyRequest // obj
) => {
    if (Object.values(stavbyRequest).length <= 1) {
        return true;
    }

    return false;
};

const handleAddManuallyIntoTables = ( // void
    setTableRequest, // fn
    listData, // arr
    request, // obj
    dynamicKey, //str|
    idToUpdate = '',
) => {
    if (idToUpdate) {
        setTableRequest(prev => ({ ...prev, [dynamicKey]: [...listData]
            .map(el => {
                if (el.uid === idToUpdate) {
                    return {...el, ...(request || {})};
                }
                return el;
            })
            .map(el => {
                if (el instanceof Object && Object.hasOwn(el, 'uid')) {
                // eslint-disable-next-line no-unused-vars
                    const {uid, ...others} = el;
                    return others;
                } else {
                    return el;
                }
            })}
        ));

        return;
    }

    setTableRequest(prev => ({ ...prev, [dynamicKey]: [...listData, request]
        .map(el => {
            if (Object.hasOwn(el, 'uid')) {
                // eslint-disable-next-line no-unused-vars
                const {uid, ...others} = el;
                return others;
            } else {
                return el;
            }
        })}
    ));
};

const getLinkforForm18 = ( // : ''|str
    id, urlPath, receivedToken, authConfig
) => {
    if (!authConfig) {
        return '';
    }

    const regex = /build-application-([0-9]+)/;
    const formType = urlPath.match(regex)[1];

    if (!formType) {
        return '';
    }

    return`${authConfig.redirectUri}zadost/form18/${id}/${formType}/${receivedToken}`;
};

const setApplicantsFile = (applicants, setAttachments) => {
    if (!applicants) {
        return;
    }

    if (applicants?.length > 0) {
        applicants.forEach(applicant => {
            if (applicant.powerOfAttorneyFile instanceof Object && applicant.powerOfAttorneyFile?.fileName !== null) {
                spreadUploadedAttachments(applicant.powerOfAttorneyFile, setAttachments);     
            }

            if (applicant.powerOfAttorneyRepresentative instanceof Object && applicant.powerOfAttorneyRepresentative?.fileName !== null) {
                spreadUploadedAttachments(applicant.powerOfAttorneyRepresentative, setAttachments);
            }
        });
    }
};

const setAttorneyFile = (attorney, setAttachments) => {
    if (!attorney) {
        return;
    }

    if (attorney?.powerOfAttorneyFile instanceof Object && attorney.powerOfAttorneyFile?.fileName !== null) {
        spreadUploadedAttachments(attorney.powerOfAttorneyFile, setAttachments);     
    }

    if (attorney?.powerOfAttorneyRepresentative instanceof Object && attorney.powerOfAttorneyRepresentative?.fileName !== null) {
        spreadUploadedAttachments(attorney.powerOfAttorneyRepresentative, setAttachments);
    }
};

const setApplicantRepreFile = (applicant, setAttachments) => {
    if (!applicant) {
        return;
    }
    if (applicant?.powerOfAttorneyRepresentative instanceof Object && applicant.powerOfAttorneyRepresentative?.fileName !== null) {
        spreadUploadedAttachments(applicant.powerOfAttorneyRepresentative, setAttachments);
    }
};

const setAccomanyingDoc = (accompanyingDocument, setAccompanyingDocumentStatus) => {
    if (!accompanyingDocument) {
        return;
    }

    const accompanyingDocumentStatus = accompanyingDocument.accompanyingDocumentStatus;

    setAccompanyingDocumentStatus(accompanyingDocumentStatus === 'NONE' ? false : accompanyingDocumentStatus === 'IN_PROGRESS' ? null : accompanyingDocumentStatus === 'CREATED' ? true : undefined);
};

const isExceeding = ( // : bool
    intention, // str
    val, // num
    forWarning = undefined, // str|undefined
) => {
    if (!intention || !val) {
        return false;
    }

    switch (intention) {
    case FORM_10_INTENTION.title:
    case FORM_14_INTENTION.title:
        return val > (forWarning === undefined ? 7 : 6);
    case FORM_11_INTENTION.title:
    case FORM_12_INTENTION.title:
    case FORM_13_INTENTION.title:
        return val > (forWarning === undefined ? 5 : 4);
    default:
        return false;
    }

};

const spreadTokenData = ( // :obj
    token, // obj
    state, // obj
    key, // str
) => {
    return { 
        names: token?.firstName ?? '',
        lastName: token?.lastName ?? '',
        lastNameOrigin: token?.lastNameOrigin ?? '',
        dateOfBirth: token?.dateOfBirth ?? '',
        nationality: token?.nationality ?? 'CZE',
        address: {
            ...(state?.form?.[key]?.address || {}),
            city: token?.addrCity ?? '',
            cityPart: token?.addrCityPart ?? '',
            street: token?.addrStreet ?? '',
            descNum: token?.addrDescNum ?? '',
            orientNum: token?.addrOrientNum ?? '',
            zip: token?.addrZipCode ?? '',
        },
    };
};

const resetForm = (val, setRequest, setCurrentApplicant, setApplicantArr, decodedToken = null) => {
    setCurrentApplicant(APPLICANT_MODEL);
    setApplicantArr([]);
    setRequest(state => ({...state, form: {
        ...state.form, 
        applicantMe: val === 'me',
        applicantAttorney: val === 'someone',
        applicantMore: val === 'more',
        applicant: {...state.form.applicant, ...APPLICANT_MODEL, id: state.form.applicant.id ?? null,
            ...((val === 'me' && decodedToken !== null)
                ? spreadTokenData(decodedToken, state, 'applicant') 
                : {})
        },
        powerOfAttorney: null,
        attorney: {...state.form.attorney, ...APPLICANT_MODEL, id: state.form.attorney.id ?? null,
            ...((val === 'someone' && decodedToken !== null)
                ? spreadTokenData(decodedToken, state, 'attorney') 
                : {})
        },
        applicants: []
    }}));  
};

const resetApplicant = (applicantType, setRequest, decodedToken = null) => {
    setRequest(state => ({ ...state, form: {
        ...state.form,
        applicant: {
            ...APPLICANT_MODEL,
            ...((applicantType === APPLICANT_TYPE_FO && decodedToken && state.form?.applicantMe)
                ? spreadTokenData(decodedToken, state, 'applicant') 
                : {}),
            isFO: applicantType === APPLICANT_TYPE_FO,
            isFOBusiness: applicantType === APPLICANT_TYPE_FOP,
            isPO: applicantType === APPLICANT_TYPE_PO,
            id: state.form.applicant.id ?? null
        } } }));
};

const resetAttorney = (attorneyType, setRequest, decodedToken = null) => {
    setRequest(state => ({ ...state, form: {
        ...state.form,
        attorney: {
            ...APPLICANT_MODEL,
            ...((attorneyType === ATTORNEY_TYPE_FO && decodedToken !== null)
                ? spreadTokenData(decodedToken, state, 'attorney') 
                : {}),
            isFO: attorneyType === ATTORNEY_TYPE_FO,
            isFOBusiness: attorneyType === ATTORNEY_TYPE_FOP,
            isPO: attorneyType === ATTORNEY_TYPE_PO,
            id: state.form.attorney.id ?? null
        } } }));
};

const hasPowerOfAttorneyError = ( // :bool
    request, // obj
    zadatelAttachments, // arr
) => {
    return Boolean(
        (request.form?.applicantAttorney && request.form?.attorney?.powerOfAttorneyExists && request.form?.attorney?.idPowerOfAttorneyExisting?.trim() === '') ||
        (request.form?.applicantAttorney && !request.form?.attorney?.powerOfAttorneyExists && zadatelAttachments[PLNA_MOC_FIRST].length === 0) ||
        (request.form?.applicantMore && request.form?.applicants?.length === 0)
    );
};

const checkLimitAndInsertText = (input, countIndex, count, setCount) => {
    if (input.value.length > count[countIndex].limit) {
        input.value = input.value.substr(0, count[countIndex].limit);
    }
    setCount(prev => ({ ...prev, [countIndex]: { ...prev[countIndex], count: input.value.length } })); 
};

const shouldNotUpdateExceptions = (checkbox) => {
    return (checkbox.first === null && checkbox.second === null) || (checkbox.first === '' && checkbox.second === '');
};

const filterPruvodniListDocuments = (arr) => { // : File[]
    return arr.filter((file, index, self) => {
        const latestXmlIndex = self.findLastIndex(el => extractExtensionName(el.fileName) === 'xml');
        const latestPruvodniListIndex = self.findLastIndex(el => extractExtensionName(el.fileName) === 'pdf' && el.subSection === 'cover_letter_pdf');
        const isXml = extractExtensionName(file.fileName) === 'xml';
        const isPruvodniList = extractExtensionName(file.fileName) === 'pdf' && file.subSection === 'cover_letter_pdf';               
        if (file.subSection === 'accomp_constructions_ext') {
            return true;
        } else if (isXml) {
            return index === latestXmlIndex;
        } else if (isPruvodniList) {
            return index === latestPruvodniListIndex;
        }
        return true;
    });
};

const filterPruvodniListToDownload = (arr) => { // : File[]
    return arr.filter((_file, index, self) => {
        const latestPruvodniListIndex = self.findLastIndex(el => extractExtensionName(el.fileName) === 'pdf' && el.subSection === 'cover_letter_pdf');
        return index === latestPruvodniListIndex;
    });
};

const canDisableExceptions = ( // : bool
    request, // obj
    propState, // str
    propertyToUpdate, // str
) => {
    const exceptions = ['names', 'lastName', 'dateOfBirth'];
    const isDisabled = Boolean((exceptions.indexOf(propState) > -1) && 
    ((request?.form?.applicantMe && request?.form?.applicant?.isFO && propertyToUpdate === 'applicant' && request?.form?.applicant?.[propState]) || 
        (request?.form?.applicantAttorney && request?.form?.attorney?.isFO && propertyToUpdate === 'attorney' && request?.form?.attorney?.[propState])));

    return isDisabled;
};

const resetZamerValuesManually = (setState, value, setRequest, buildIntentionModel) => {
    setState((prevState) => ({
        ...prevState,
        zamerType: value,
        inputValue: prevState.inputValue || '',
    }));

    setRequest((prev) => ({
        ...prev,
        buildIntention: {...buildIntentionModel, title: { ...prev.buildIntention.title, value: '', dirty: false }},
    }));
};

const resetIntentionWithZamerType = async (value, setState, setRequest, buildIntentionModel, prevZamerType, id, token = null) => {
    if (value === 'new' && prevZamerType === 'existing' && id) {
        let removedZamer = null;
        if (token) removedZamer = await deleteExistingZamer(id, token);
        if (removedZamer) resetZamerValuesManually(setState, value, setRequest, buildIntentionModel);
        return;
    }

    resetZamerValuesManually(setState, value, setRequest, buildIntentionModel);
};

const getFilterBodyBySections = (type = '') => { // :obj
    return {
        ...POST_BODY_FOR_FILTER,
        ...((type === RIZENI || type === ZADOSTI) ? 
            { sorts: [
                {
                    field: type === RIZENI ? 'date' : type === ZADOSTI ? 'requestDate' : '',
                    asc: false
                }
            ] } : {}),
    };
};

// filters
const handleChangeFilterBody = (key, newObject, forRizeniOrZamery = '', setPostBody) => {
    if (key === 'sorts' && (forRizeniOrZamery === RIZENI || forRizeniOrZamery === ZADOSTI)) {
        setPostBody(prev => ({
            ...prev,
            [key]: [newObject],
        }));
        return;
    }

    if (typeof newObject.value === 'string' && newObject.value.trim() === '') {
        setPostBody(prev => ({...prev, filters: [...prev.filters].filter(el => el.field !== newObject.field)}));
        return;
    } else if (newObject.value instanceof Array && newObject.value[0].value.match(/^%%\s*%%$/)) {
        setPostBody(prev => ({...prev, filters: [...prev.filters].filter(el => el.operation !== 'OR')}));
        return;
    }

    setPostBody(prev => ({
        ...prev,
        [key]: [...new Map([...prev[key], newObject].map(el => [el.field, el])).values()],
    }));
};

const handleFilterChange = ({ value }, type = '', field = '', isWithFolding, setPostBody) => {
    switch (type) {
    case 'sort/zadosti/date': {
        const isAscOrDesc = value === 'asc' || value === 'desc';
        const sortObject = {
            field: 'requestDate',
            asc: isAscOrDesc ? value === 'asc' : '',
        };
        handleChangeFilterBody('sorts', sortObject, ZADOSTI, setPostBody);
        break;
    }
    case 'sort/rizeni': {
        const sortObject = {
            field: value,
            asc: value !== 'date',
        };
        handleChangeFilterBody('sorts', sortObject, RIZENI, setPostBody);
        break;
    }
    case 'filter':
    case 'input': {
        let filterObject = {};
        if (type === 'filter') {
            filterObject = {
                ...filterObject,
                ...({field} || {}),
                operation: 'EQ',
                value,
                ...(isWithFolding && { folding: true })
            };
        } else if (type === 'input') {
            filterObject = {
                ...filterObject,
                value: [
                    {
                        field: 'projectName',
                        operation: 'LIKE',
                        value: `%%${value}%%`,
                        ignoreAccent: null,
                        ignoreCase: null
                    },
                    {
                        field: 'projectId',
                        operation: 'LIKE',
                        value: `%%${value}%%`,
                        ignoreAccent: null,
                        ignoreCase: null
                    },
                    {
                        field: 'projectNumber',
                        operation: 'LIKE',
                        value: `%%${value}%%`,
                        ignoreAccent: null,
                        ignoreCase: null
                    }
                ],
                'operation': 'OR'
            };
        } else {
            console.log('invalid operation');
        }

        handleChangeFilterBody('filters', filterObject, '', setPostBody);
        break;
    }
    default:
        break;
    }};

const handlePOAFiles = async (files, category, token, setZadatelAttachments, currentApplicant) => {
    const uploadFile = async (file, section, updateFunction) => {
        const extractedFileName = extractFileName(file.name);
        const size = file.size;
        const { fileName, uid, url, isDeletable } = await getUrl(token);
        const fileToUpload = {
            uid,
            fileName: fileName || extractedFileName,
            url,
            section,
            size,
            blobFile: file,
            message: file.message ?? undefined,
            isDeletable,
        };
        updateFunction(fileToUpload);
    };

    const updateAttachmentsFirst = (fileToUpload) => {
        setZadatelAttachments((prev) => ({
            ...prev,
            [PLNA_MOC_FIRST]: [fileToUpload]
        }));
    };

    const updateAttachmentsSecond = (fileToUpload) => {
        if (currentApplicant.id) {
            fileToUpload.personId = currentApplicant.id;
        }
        setZadatelAttachments((prev) => ({
            ...prev,
            [PLNA_MOC_SECOND]: [...new Map([...prev[PLNA_MOC_SECOND], fileToUpload]
                .map(el => [el.personId, el])).values()]
        }));
    };

    const updateAttachmentsThird = (fileToUpload) => {
        setZadatelAttachments((prev) => ({
            ...prev,
            [PLNA_MOC_THIRD]: [fileToUpload]
        }));
    };

    const updateAttachmentsFourth = (fileToUpload) => {
        setZadatelAttachments((prev) => ({
            ...prev,
            [PLNA_MOC_FOURTH]: [fileToUpload]
        }));
    };

    const updateAttachmentsFifth = (fileToUpload) => {
        if (currentApplicant.id) {
            fileToUpload.personId = currentApplicant.id;
        }
        setZadatelAttachments((prev) => ({
            ...prev,
            [PLNA_MOC_FIFTH]: [...new Map([...prev[PLNA_MOC_FIFTH], fileToUpload]
                .map(el => [el.personId, el])).values()]
        }));
    };

    const cat = {
        [PLNA_MOC_FIRST]: updateAttachmentsFirst,
        [PLNA_MOC_SECOND]: updateAttachmentsSecond,
        [PLNA_MOC_THIRD]: updateAttachmentsThird,
        [PLNA_MOC_FOURTH]: updateAttachmentsFourth,
        [PLNA_MOC_FIFTH]: updateAttachmentsFifth,
    };

    const updateFunction = cat[category];

    if (!updateFunction) {
        return;
    }

    for (const file of files) {
        await uploadFile(file, category, updateFunction);
    }
};

const uploadFile = async (file, section, token, setItems, category) => {
    const extractedFileName = extractFileName(file.name);
    const size = file.size;
    const { fileName, uid, url, isDeletable } = await getUrl(token);
    const fileToUpload = {
        uid,
        fileName: fileName || extractedFileName,
        url,
        section,
        size,
        blobFile: file,
        message: file.message ?? undefined,
        isDeletable,
    };
    if (category === 'documents') {
        spreadUploadedDocuments(fileToUpload, setItems, section, undefined);
    } else if (category === 'attachments') {
        spreadUploadedAttachments(fileToUpload, setItems, section);
    } else {
        console.log('invalid');
    }
};

const handleFiles = async (files, section, token, setItems, category) => {
    for (const file of files) {
        await uploadFile(file, section, token, setItems, category ?? 'attachments');
    }
};

const updateTableValues = (obj, ref) => {
    const objToUpdate = {};
    for (const key in ref) {
        if (obj[key] !== null && obj[key] !== undefined) {
            objToUpdate[key] = obj[key]; 
        }
    }
    return objToUpdate;
};

const hasZamerError = (buildIntention) => {
    return Boolean(!buildIntention?.title?.value?.trim() || buildIntention?.title?.value?.trim()?.length > 100);
};

const getFormTargetNumberByTitle = (title, paths) => {
    const path = paths.find(item => item.title === title);
    if (path) {
        return path.target.replace('form', '');
    }
    return null;
};

const makeCopyRequest = async (id, token, paths) => {
    const source = axios.CancelToken.source();

    try {
        const response = await makeCopyZadostiById(id, token, source);
        const copyTitle = response.data?.data?.buildApplication?.title;
        const path = paths.find(pathItem => pathItem.title === copyTitle);
        const targetNumber = getFormTargetNumberByTitle(copyTitle, paths);

        console.log(response, copyTitle, path, targetNumber);

        if(!targetNumber) {
            // TODO error handling
            return null;
        }

        const urlPath = `quick-save-api/build-application-${targetNumber}/save`;
        const newFormData = JSON.stringify({
            'buildApplication': {
                'title': copyTitle
            }
        });
        const newFormResponse = await formSave(newFormData, token, urlPath, source);
        const applicationId = newFormResponse?.data;

        if(!path || !applicationId) {
            // TODO error handling
            return null;
        }

        const copyFormData = {
            ...response.data.data,
            buildApplication: {
                ...response.data.data.buildApplication,
                id: applicationId,
                isCopied: true,
            },
            buildIntention: {
                ...response.data.data.buildIntention,
                id: applicationId,
            },
            'applicationId': applicationId,
        };
        await formSave(copyFormData, token, urlPath, source);

        return { path, applicationId };
    } catch (e) {
        // TODO error handling
        console.log(e);
    }
};

const handleSpreadUploadedDocuments = (documents, loadedIntention, setDocuments, setUploadedBytes) => {
    if (!(documents instanceof Array) || !documents?.length) {
        return;
    }

    if (loadedIntention?.documentUploadHere ||
        (loadedIntention?.documentUploadHere === null && 
            loadedIntention?.documentPaperForm === null &&
                loadedIntention?.documentPreviousVersion === null)
    ) {
        spreadUploadedDocuments(documents, setDocuments, undefined, setUploadedBytes);
    } else if (loadedIntention?.documentPreviousVersion) {
        spreadUploadedDocuments(documents, setDocuments, undefined, setUploadedBytes, undefined, 'existing');
    }
};

const canReupload = (errorItem, size, uploadedBytes, setUploadError, uid, setUploads, category) => {
    const reuploadSize = Number(size + uploadedBytes);
    const limit = (5 * 1024 * 1024 * 1024);
    if (errorItem && errorItem.why === 'total_size' && reuploadSize >= limit) {
        return false;
    } else if (errorItem && errorItem.why && errorItem.why !== 'total_size') {
        return false;
    } else if (errorItem && errorItem.why === 'total_size' && reuploadSize < limit) {
        setUploadError(prev => [...prev].filter(el => el.uid !== uid));
        setUploads((prev) => ({
            ...prev,    
            [category]: [...prev[category]].map(el => {
                if (el.uid === uid && el.message !== undefined) {
                    return {...el, message: undefined};       
                } else {
                    return el;
                }
            })
        }));
        return true;
    }
};

const setFiltersForZadosti = ( // :void
    firstRender, // bool
    isReset, // bool
    setStartIndexOdeslane, // fn
    list, // arr
    postBody, // obj
    setFilteredList // fn 
) => {
    try {
        if (!firstRender && isReset) {
            setStartIndexOdeslane(0);
            return;
        }
    
        if (isReset || firstRender || !list.length) {
            return;
        }
    
        let unfiltered = [...list];
        const sortItem = postBody.sorts?.[0]?.field ?? ''; // str
        const isAscending = postBody.sorts?.[0]?.asc ?? false; // bool
        const statusFilter = postBody.filters.find(el => el.field === 'status')?.value ?? ''; // str
        const typeFilter = postBody.filters.find(el => el.field === 'requestType')?.value ?? ''; // str
        const inputFilter = postBody.filters.find(el => el.operation === 'OR')?.value?.[0]?.value.match(/^%%(.+)%%$/)?.[1]?.trim() ?? ''; // str
        
        unfiltered = unfiltered
            .sort((a, b) => a?.[sortItem] < b?.[sortItem] ? (isAscending ? -1 : 1) : (isAscending ? 1 : -1))
            .filter((el) => statusFilter === 'ACTIVE' ? el.status === 'nevyrizen' : statusFilter === 'COMPLETED' ? el.status === 'vyrizen' : true)
            .filter((el) => !typeFilter ? true : el.requestType === typeFilter)
            .filter((el) => !inputFilter ? true : (el.projectName?.toLowerCase().includes(inputFilter?.toLowerCase()) || el.requestType?.toLowerCase().includes(inputFilter?.toLowerCase())) || el.projectId?.toLowerCase().includes(inputFilter?.toLowerCase()));
        
        setStartIndexOdeslane(0);
        setFilteredList([...unfiltered]);
    } catch (error) {
        console.log(error);
    }
};

const checkAnoNeFields = (data, sliceIndex) => {
    function slicedArr(arr) {
        if (!arr.length) return [];

        if (sliceIndex === -1) {
            return arr.slice(-1);
        }

        return arr.slice(sliceIndex, sliceIndex + 1);
    }

    return [...data]?.slice(1).some(arr => 
        slicedArr(arr)?.some(el => 
            el?.trim().toLowerCase() !== 'ano' && el?.trim().toLowerCase() !== 'ne'
        )
    );
};

const getAnoNeValue = (line) => {
    return line?.trim()?.toLowerCase() === 'ano' ? true : line?.trim()?.toLowerCase() === 'ne' ? false : null;
};

function extractYear(projectId = '') {
    const match = projectId.match(/Z\/(\d{4})\//);
    return match ? parseInt(match[1], 10) : null;
}

function extractNumber(projectId = '') {
    const match = projectId.match(/Z\/\d{4}\/(\d+)/);
    return match ? parseInt(match[1], 10) : null;
}

function intentionsSort(a, b) {
    const yearA = extractYear(a?.projectId);
    const yearB = extractYear(b?.projectId);

    if (yearA === yearB) {
        const numA = extractNumber(a?.projectId);
        const numB = extractNumber(b?.projectId);
        return numB - numA;
    }

    return yearB - yearA;
}

export {
    isExceeding,
    convertBytes,
    shouldShowProgress,
    shouldNotFetch,
    getNewAbortController,
    cancelUpload,
    getUrl,
    extractFileName,
    extractExtensionName,
    filterFiles,
    spreadUploadedAttachments,
    spreadUploadedDocuments,
    isNotUploading,
    shouldNotGoToLastPage,
    shouldNotGoToFirstPage,
    shouldNotGoToNextPage,
    shouldNotGoToPreviousPage,
    spreadUploadedModalDocuments,
    getFormType,
    spreadParcelsAndConstructions,
    findChosenIntention,
    shouldNotSave,
    appendNumberToGovLabel,
    updateNumberOnGovLabel,
    setCsvDataAndRequest,
    getPrijemceList,
    getBuildApplicationFormForPrijemce,
    canInitiateSaveForPrijemce,
    canFulFillPrijemceList,
    checkCsvHeaders,
    getTextfromSectionName,
    shouldNotSetAuthority,
    useOnlyRequiredKeys,
    spreadObject,
    enterCsvData,
    removeCsvData,
    shouldNotSaveTable,
    handleAddManuallyIntoTables,
    getLinkforForm18,
    setAttorneyFile,
    setApplicantRepreFile,
    setApplicantsFile,
    setAccomanyingDoc,
    resetApplicant,
    resetAttorney,
    resetForm,
    hasPowerOfAttorneyError,
    checkLimitAndInsertText,
    shouldNotUpdateExceptions,
    filterPruvodniListDocuments,
    filterPruvodniListToDownload,
    canDisableExceptions,
    resetIntentionWithZamerType,
    getFilterBodyBySections,
    handleFilterChange,
    handlePOAFiles,
    handleFiles,
    updateTableValues,
    hasZamerError,
    makeCopyRequest,
    handleSpreadUploadedDocuments,
    canReupload,
    setFiltersForZadosti,
    checkAnoNeFields,
    getAnoNeValue,
    intentionsSort,
};
