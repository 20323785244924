import axios from 'axios';
import urlJoin from 'url-join';
import apiConfig from '../config/api-config.js';

const getFormById = async (
    id, // str
    token,
    source,
    reqType,
    receivedToken = null, // str|null
    pathname, // str
    navigate // fn
) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        cancelToken: source.token ?? null,
    };

    if (receivedToken) {
        config.headers.shared = receivedToken;
    }

    try {
        const firstResponse = await axios.get(
            urlJoin(apiConfig.validateAndPersistBaseUrl, `build-applications/${id}`),
            config
        );

        const requestType = firstResponse.data?.buildApplication?.title;

        if (requestType !== reqType) {
            if (!receivedToken) console.error('incorrect form type and id combination');
            // return null;
        }

        return firstResponse;
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            console.log('Error', error);
        }

        const errorState = { 
            state: { pathname, id } 
        };
        
        if (receivedToken) {
            if (error.response?.status === 401) {
                navigate(`/unauthorized/${receivedToken}`);
            } else {
                navigate('/error', errorState);
            }
        } else {
            navigate('/error', errorState);
        }
    }
};

const formSave = async (data, token, url, source, receivedToken = undefined) => {

    const formType = url.split('/');

    let dataObject;
    if (typeof data === 'string') {
        dataObject = JSON.parse(data);

        if (dataObject?.form) {
            dataObject.form['formType'] = formType[1];
        } else {
            dataObject['form'] = { 'formType': formType[1] };
        }
        data = JSON.stringify(dataObject);
    } else if (data.form) {
        data.form['formType'] = formType[1];
    } else {
        data['form'] = { 'formType': formType[1] };
    }


    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        cancelToken: source ? source.token : null,
    };

    if (receivedToken) {
        config.headers.shared = receivedToken;
    }

    try {
        return await axios.post(
            urlJoin(apiConfig.validateAndPersistBaseUrl, url),
            data,
            config
        );
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            console.log('Error', error);
        }
    }
};

export {
    getFormById,
    formSave,
};