import React, { useEffect, useState } from 'react';
// import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import '../../css/tableView.css';
import { arrowDownDark } from '../../assets';
// import { Link } from 'react-router-dom';
// import { GovButton } from '@gov-design-system-ce/react';

const StavbyTableDisplay = ({ items, setPostBody }) => {
    const [sortKeys, setSortKeys] = useState({
        constructionId: null, // ?bool
        cadastralTerritory: null, // ?bool
        parcelNumber: null, // ?bool
        constructionNumber: null, // ?bool
        flatNumber: null, // ?bool
        projectId: null, // ?bool
        projectName: null, // ?bool
    });

    const handleClick = (field, setSortKeys) => {
        setSortKeys(prev => {
            const ref = {};
            for (const key in prev) {
                if (key === field) {
                    ref[key] = !(prev[key]);
                } else {
                    ref[key] = null;
                }
            }

            return ref;
        });
    };

    useEffect(() => {
        if (Object.values(sortKeys).every(val => val === null)) {
            return;
        }

        const sortKey = Object.keys(sortKeys).find(key => sortKeys[key] !== null);
        setPostBody(prev => {
            const addedSort = {field: sortKey, asc: sortKeys[sortKey]};
            return {...prev, sorts: [...prev.sorts.filter(el => Object.keys(sortKeys).indexOf(el.field ) === -1), {...addedSort}]};
        });
    }, [sortKeys]);

    return (
        <div style={{padding: '0', margin: '0'}}>
            <table className="" /* style={{ width: 'auto'}} */>
                <thead>
                    <tr>
                        <th className='py-2 px-3' style={{cursor: 'pointer'}} onClick={() => handleClick('constructionId', setSortKeys)}><p className='table-head py-2 d-flex'>IČS<img className='ms-2' src={sortKeys.constructionId ? arrowDownDark : arrowDownDark} alt='arrowDownDark' /></p></th>
                        <th className='py-2 px-3' style={{cursor: 'pointer'}} onClick={() => handleClick('cadastralTerritory', setSortKeys)}><p className='table-head py-2 d-flex'>Katastrální území<img className='ms-2' src={sortKeys.cadastralTerritory ? arrowDownDark : arrowDownDark} alt='arrowDownDark' /></p></th>
                        <th className='py-2 px-3' style={{cursor: 'pointer'}} onClick={() => handleClick('parcelNumber', setSortKeys)}><p className='table-head py-2 d-flex'>Parcelní číslo<img className='ms-2' src={sortKeys.parcelNumber ? arrowDownDark : arrowDownDark} alt='arrowDownDark' /></p></th>
                        <th className='py-2 px-3' style={{cursor: 'pointer'}} onClick={() => handleClick('constructionNumber', setSortKeys)}><p className='table-head py-2 d-flex'>Č.p.<img className='ms-2' src={sortKeys.constructionNumber ? arrowDownDark : arrowDownDark} alt='arrowDownDark' /></p></th>
                        <th className='py-2 px-3' style={{cursor: 'pointer'}} onClick={() => handleClick('flatNumber', setSortKeys)}><p className='table-head py-2 d-flex'>Č. bytové jednotky<img className='ms-2' src={sortKeys.flatNumber ? arrowDownDark : arrowDownDark} alt='arrowDownDark' /></p></th>
                        <th className='py-2 px-3' style={{cursor: 'pointer'}} onClick={() => handleClick('projectId', setSortKeys)}><p className='table-head py-2 d-flex'>Číslo záměru<img className='ms-2' src={sortKeys.projectId ? arrowDownDark : arrowDownDark} alt='arrowDownDark' /></p></th>
                        <th className='py-2 px-3' style={{cursor: 'pointer'}} onClick={() => handleClick('projectName', setSortKeys)}><p className='table-head py-2 d-flex'>Záměr<img className='ms-2' src={sortKeys.projectName ? arrowDownDark : arrowDownDark} alt='arrowDownDark' /></p></th>
                        {/* <th></th> */}
                    </tr>
                </thead>
                <tbody>
                    {items.length > 0 && items.map((item, idx) => {
                        return (
                            <tr key={item.id || idx}>
                                <td className='py-2 px-3'><p className='row-number py-2 text-truncate' title={item.constructionId}>{item.constructionId}</p></td>
                                <td className='py-2 px-3'><p className='row-info py-2 text-truncate' title={item.cadastralTerritory}>{item.cadastralTerritory}</p></td>
                                <td className='py-2 px-3'><p className='row-info py-2 text-truncate' title={item.parcelNumber}>{item.parcelNumber}</p></td>
                                <td className='py-2 px-3'><p className='row-info py-2 text-truncate' title={item.constructionNumber}>{item.constructionNumber}</p></td>
                                <td className='py-2 px-3'><p className='row-info py-2 text-truncate' title={item.flatNumber}>{item.flatNumber}</p></td>
                                <td className='py-2 px-3'><p className='row-info py-2 text-truncate' title={item.projectId}>{item.projectId}</p></td>
                                <td className='py-2 px-3'><p className='row-info py-2 text-truncate' title={item.projectName}>{item.projectName}</p></td>
                                {/* <td className='py-2 px-3'>
                                    <Link to='addURL'>
                                        <GovButton className='m-0 p-0 button' type='outlined' variant={'primary'} size='m'>
                                            <Container className='d-flex justify-content-center align-items-center' style={{width: '100px'}}>
                                            Zobrazit detail
                                            </Container>
                                        </GovButton>
                                    </Link>
                                </td>                         */}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

StavbyTableDisplay.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        costructionId: PropTypes.string,
        cadastralTerritory: PropTypes.string,
        parcelNumber: PropTypes.string,
        constructionNumber: PropTypes.string,
        flatNumber: PropTypes.string,
        projectId: PropTypes.string,
        projectName: PropTypes.string,
    })),
    setPostBody: PropTypes.func,
};

export default StavbyTableDisplay;