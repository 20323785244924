import React, { useContext } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { AuthContext } from 'react-oauth2-code-pkce';
import { Navbar, Footer/*, AlertWebUpdate */, AuthError } from '../components/index.js';

const RootLayout = () => {
    const { error } = useContext(AuthContext);
    const location = useLocation();

    // TODO: make more sohpisticated browser check, now it's only checking whether browser is not an Edge
    // let userAgent = navigator.userAgent;
    let pathname = location.pathname;
    const pathMatch = /^\/zadost\/([^:?]*$)/.test(pathname);

    return (
        <>
            <Navbar />
            {/*<ErrorWebUpdate error={error} />*/}
            {/* {userAgent.includes('Edg/') ? <AlertWebUpdate /> : ''} */}
            {!error ?
                <div className='home-page-section'>
                    {/* {userAgent.includes('Edg/') && <div style={{height: '70px'}}></div>} */}
                    <Outlet />
                </div>
                :
                <AuthError 
                    headerText={'Uživatel odhlášen'}
                    secondaryText={'Přístup na požadovanou stránku nebyl povolen, pravděpodobně z důvodu vypršení přihlášení.Prosím zkuste se přihlásit znovu.'}
                    errorNumber={'401'}
                />
            }
            {!error && !pathMatch && <Footer />}
        </>
    );
};

export default RootLayout;