import React, { useState, useContext, useEffect, Fragment, useImperativeHandle } from 'react';
import { Container, Row } from 'react-bootstrap';
import '../../css/zadatelComponent.css';
import PropTypes from 'prop-types';
import { FormInput, StatniPrislusnost, ProjektantDocumentationTable } from '../index';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce';
import { shouldNotSave, useOnlyRequiredKeys, spreadObject, spreadParcelsAndConstructions } from '../../helperFunctions/helpers';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import FormContext from '../../formContexts/FormContext';
import { ACCOMPANYING_PERSON_MODEL } from '../../constants/sharedConstants.js';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import { BASIC_INFO_ENGINEER, BASIC_INFO_PROJEKTANT } from '../../constants/projektantStatesAndEngineerStates.js';
import useFirstRender from '../CustomHooks/useFirstRender.jsx';
import { useLocation, useNavigate } from 'react-router-dom';

const ProjektantComponent18 = ({ 
    progressElements, 
    setProgressElements, 
    urlPath,
    receivedToken,
}) => {
    const {intention, stepValue, validationRef, setStagesArr, id} = useContext(FormContext); 
    const { token } = useContext(AuthContext);
    const firstRender = useFirstRender();
    const [request, setRequest] = useState({
        'applicationId': id,
        accompanyingDocument: {
            mainDesigner: ACCOMPANYING_PERSON_MODEL,
            designers: [],
            engineer: ACCOMPANYING_PERSON_MODEL,
        }
    });
    const prevRequest = usePrevious(request);
    const [designers, setDesigners] = useState({
        designers: [],
    });

    const { pathname } = useLocation();
    const navigate = useNavigate();
    
    useEffect(() => {
        const source = axios.CancelToken.source();
        if (id) {
            (async () => {
                const response = await getFormById(id, token, source, intention, receivedToken, pathname, navigate);

                if ((200 <= response?.status) && (response?.status < 300)) {
                    const accompanyingDocument = response.data?.accompanyingDocument;

                    if (accompanyingDocument) {
                        const struc = request.accompanyingDocument;
                        const filteredDataToSave = useOnlyRequiredKeys(struc, spreadObject(accompanyingDocument));
                        setRequest(state => ({ ...state, accompanyingDocument: {...state.accompanyingDocument, ...filteredDataToSave } }));

                        if (accompanyingDocument.designers?.length > 0) {
                            spreadParcelsAndConstructions(
                                accompanyingDocument, //obj
                                setDesigners, //func
                                designers // obj                
                            );
                        }
                    }
                } else {
                    console.log('ID NOT YET IN DB');
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        } else {
            console.log('NON EXISTENT ID');
        }
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source, receivedToken);
    };


    useEffect(() => {
        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }
        console.log(request);
        saveChanges();
    }, [request.accompanyingDocument]);

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;

            if (!request.accompanyingDocument.mainDesigner.ais.trim()) {
                err = true;
            } else if (
                !request.accompanyingDocument.mainDesigner.firstName ||
                request.accompanyingDocument.mainDesigner.firstName.trim() === '' ||
                !request.accompanyingDocument.mainDesigner.lastName ||
                request.accompanyingDocument.mainDesigner.firstName.trim() === ''
            ) {
                err = true;
            }

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false};
                }
                return stg;
            }));

            return !err;
        }
    }));

    return (
        <>
            <Container fluid>
                <Row className='row-wrapper'>
                    <h2 className='p-0 m-0 main-title'>{`${stepValue}. Projektant`}</h2>
                </Row>
                <Row className='row-wrapper'>
                    <h2 className='p-0 secondary-title'>Hlavní projektant</h2>
                </Row>
                <Row className='row-wrapper'>
                    {
                        BASIC_INFO_PROJEKTANT.map((item, idx) => {
                            if (idx === 4) {
                                return <Fragment key={`${item.id}-${item.refer}`} >
                                    <FormInput {...item} request={request} setRequest={setRequest} />
                                    <StatniPrislusnost request={request} setRequest={setRequest} requestPropToUpdate={'accompanyingDocument'} propToUpdate={'mainDesigner'}/>
                                </Fragment>;
                            } else {
                                return <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />;
                            }
                        })
                    }
                    <ProjektantDocumentationTable
                        progressElements={progressElements}
                        setProgressElements={setProgressElements}
                        urlPath={urlPath}
                        receivedToken={receivedToken}
                        designers={designers.designers}
                    />
                    <Row className='row-wrapper'>
                        <h2 className='p-0 secondary-title'>Autorizovaný zeměměřičský inženýr</h2>
                    </Row>
                    {
                        BASIC_INFO_ENGINEER.map((item, idx) => {
                            if (idx === 4) {
                                return <Fragment key={`${item.id}-${item.refer}`} >
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                    <StatniPrislusnost request={request} setRequest={setRequest} requestPropToUpdate={'accompanyingDocument'} propToUpdate={'engineer'}/>
                                </Fragment>;
                            } else {
                                return <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />;
                            }
                        })
                    }
                </Row>
            </Container>
        </>
    );
};

ProjektantComponent18.propTypes = {
    
    progressElements: PropTypes.arrayOf(Object),
    setProgressElements: PropTypes.func,
    urlPath: PropTypes.string.isRequired,
    foundApplicantIndex: PropTypes.number,
    setFoundApplicantIndex: PropTypes.func,
    stage: PropTypes.number,
    applicantArr: PropTypes.arrayOf(Object),
    setApplicantArr: PropTypes.func,
    receivedToken: PropTypes.string,
};

export default ProjektantComponent18;