import React, { useContext, useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../css/landingPage.css';
import { AuthContext } from 'react-oauth2-code-pkce';
import { Col, Container, Row } from 'react-bootstrap';
import { lewYellow, loader, arrowRight, questionMark } from '../../assets';

const LandingPageLogin = () => {
    const { login } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');

    useEffect(() => {
        if(code !== null) setLoading(true);
    }, [code]);

    const delayedLogin = useCallback(() => {        
        setTimeout(() => {
            try {
                login();
            } catch (error) {
                console.error('Login failed:', error);                
            }
        }, 0);
    }, []);

    return (
        <Container className='py-5 px-4 login-card'>
            <Row className='mb-3'>
                <Col xs={12} className='d-flex flex-column align-items-center justify-content-center'>
                    <p className='login-card-title'>
                        Přihlásit se
                    </p>
                </Col>
            </Row>
            <Row className='border-bottom px-1 py-2 button-wrapper' onClick={delayedLogin}>
                <Col xs={10} className='d-flex align-items-center justify-content-start' >
                    <button className='login-card-button'><img src={lewYellow} alt='lewYellow' className='pe-4' />Identitou občana</button>
                </Col>
                <Col xs={2} className='d-flex align-items-center justify-content-end'>
                    <img src={arrowRight} alt='arrowRight' />
                </Col>
            </Row>
            <Link to={'https://chcidatovku.gov.cz/identita-obcana'}>
                <Row className='px-1 py-2 button-wrapper'>
                    <Col xs={10} className='d-flex align-items-center justify-content-start' >
                        <button className='login-card-button'><img src={questionMark} alt='questionMark' className='pe-4' />Nevím jak se přihlásit</button>
                    </Col>
                    <Col xs={2} className='d-flex align-items-center justify-content-end'>
                        <img src={arrowRight} alt='arrowRight' />
                    </Col>
                </Row>
            </Link>
            {loading && <Row className='fullscreen-loader'>
                <img src={loader} className='login-loader' />
            </Row>}
        </Container>
    );
};

export default LandingPageLogin;
