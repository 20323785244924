import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { NEW_REQUEST_HEADER } from '../../constants/sharedConstants';
import { NewRequestCardHeader } from '../index.js';
import { Link } from 'react-router-dom';

const NejcastejsiZadosti = () => {

    return (
        <Container fluid className='px-2 py-4 px-md-5 px-xl-0 base-width m-auto'>
            <Row className='w-100'>
                <Col xs={12}>
                    <p className='ps-2 ps-md-0 after-login-freq-req-title mb-3'>Nejčastější žádosti</p>
                </Col>
            </Row>
            <Row>
                {NEW_REQUEST_HEADER.map((item, index) => {
                    return (
                        <Col xs={12} md={6} lg={4} key={item.id + item.title + index}
                            className='ps-md-0 px-3 py-1'>
                            {<NewRequestCardHeader item={item} />}
                        </Col>
                    );
                })}
            </Row>
            <Row>
                <Col xs={12} className='d-md-flex justify-content-center p-3'>
                    <Link to='/zadosti/new'>
                        <button
                            className='button-show-request-card'
                        >
                        Všechny žádosti
                        </button>
                    </Link>
                </Col>
            </Row>
        </Container>
    );
};

export default NejcastejsiZadosti;