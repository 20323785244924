import React, { useContext, useState, useEffect, useImperativeHandle } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/prijemci.css';
import useFirstRender from '../CustomHooks/useFirstRender.jsx';
import { AuthContext } from 'react-oauth2-code-pkce';
import axios from 'axios';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import FormContext from '../../formContexts/FormContext';
import { SearchWithDataList } from '../index.js';
import { canFulFillPrijemceList, canInitiateSaveForPrijemce, getPrijemceList, spreadObject } from '../../helperFunctions/helpers';
import { AFFECTED, AUTHORITY_MODEL, BUILDING } from '../../constants/sharedConstants';
import usePrevious from '../CustomHooks/usePrevious';
import { useLocation, useNavigate } from 'react-router-dom';
import useDebounce from '../CustomHooks/useDebounce.jsx';

const PrijemceComponent = ({ urlPath }) => {
    const {intention, stepValue, setStagesArr, stagesArr,validationRef, saveFormRef, id} = useContext(FormContext);
    
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;

    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            buildingAuthority: false,
            affectedOrg: false,
        },
        buildIntention: {
            recipientAuthority: AUTHORITY_MODEL
        }
    });
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [searchRequest, setSearchRequest] = useState(
        {
            title: '',
            typeTitle: null
        }
    );
    const [prijemceList, setPrijemceList] = useState([]);
    const { token } = useContext(AuthContext);
    const prevRequest = usePrevious(request);
    const firstRender = useFirstRender();

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const debouncedSearchTitle = useDebounce(searchRequest.title);

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (id) {
            (async () => {
                const response = await getFormById(id, token, source, intention, null, pathname, navigate);

                if ((200 <= response?.status) && (response?.status < 300)) {
                    const loadedData = response.data?.buildApplicationForms?.[0];

                    if (!loadedData) {
                        return;
                    }

                    if (loadedData?.buildingAuthority === null && loadedData?.affectedOrg === null) {
                        setRequest(state => ({ ...state, form: {...state.form, buildingAuthority: true, affectedOrg: false} }));
                    } else {
                        const intention = response.data?.buildIntention;
                        const recipientAuthority = intention?.recipientAuthority;
                        if (loadedData?.buildingAuthority === true) {
                            setSearchRequest(prev => ({...prev, title: recipientAuthority?.authorityName, typeTitle: BUILDING}));
                        } else if (loadedData?.affectedOrg === true) {
                            setSearchRequest(prev => ({...prev, title: recipientAuthority?.authorityName, typeTitle: AFFECTED}));
                        }
                        setRequest(state => ({ ...state, form: {...state.form,
                            buildingAuthority: loadedData?.buildingAuthority,
                            affectedOrg: loadedData?.affectedOrg,
                        },
                        buildIntention: {...state.buildIntention, ...spreadObject(intention), recipientAuthority: recipientAuthority } }));
                    }
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        }
        else {
            console.log('NON EXISTENT ID');
        }
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (searchRequest.typeTitle === null) {
            return;
        }

        if (!canFulFillPrijemceList(request, firstRender, searchRequest, setPrijemceList, 'recipientAuthority')) {
            return;
        }

        setIsSearchLoading(true);
        getPrijemceList(searchRequest, token, setPrijemceList, setIsSearchLoading, signal);

        return () => {
            controller.abort();
        };
    }, [debouncedSearchTitle]);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        if(!canInitiateSaveForPrijemce(firstRender, request, prevRequest, 'recipientAuthority')) {
            return;
        }

        saveChanges();

        console.log(request);
        
    }, [request]);

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;

            if (!request.buildIntention?.recipientAuthority || request.buildIntention?.recipientAuthority?.authorityName?.trim() === '')
            {
                err = true;
            }
            
            

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, };
                }
                return stg;
            }));

            return !err;
        }
    }));

    const hasError = error && !searchRequest.title.trim();

    return (
        <Container className='py-3' fluid>
            <Row className='mb-5'>
                <p className='p-0 text primary'>{`${stepValue}. Příjemce`}</p>
            </Row>
            {request.form.buildingAuthority && <>
                <Row className='mb-4 mt-5'>
                    <p className='p-0 text section-title' style={{color: hasError && '#C52A3A'}}>Stavební úřad</p>
                </Row>
                <p className='p-0 title' style={{color: hasError && '#C52A3A'}}>Vyhledávání</p>
                <Row className='d-flex align-items-start'>
                    <Col md={6} className='pe-md-4 mb-2 mb-md-0'>
                        <Container fluid>
                            <SearchWithDataList
                                request={request}
                                authorityType={'recipientAuthority'}
                                authorityObj={request.buildIntention.recipientAuthority}
                                setRequest={setRequest}
                                paramFirst={'title'}
                                paramSecond={'typeTitle'}
                                setSearchRequest={setSearchRequest}
                                searchRequest={searchRequest} // obj
                                listData={prijemceList}
                                setListData={setPrijemceList} // arr
                                dynamicVal={BUILDING} // str
                                isSearchLoading={isSearchLoading}
                                setIsSearchLoading={setIsSearchLoading}
                            />
                        </Container>
                    </Col>
                    <Col md={6} className='mt-md-2'>
                        <p className='input-help-text'>Vyhledejte stavební úřad, pro který je žádost určena</p>
                    </Col>
                </Row>
            </>}

            {request.form.affectedOrg
                &&
            <>
                <Row className='mb-4 mt-5'>
                    <p className='p-0 section-title'>Dotčený orgán</p>
                </Row>
                <p className='mb-3 p-0 title' style={{color: hasError && '#C52A3A'}}>Vyhledávání</p>
                <Row className='d-flex align-items-center'>
                    <Col md={6} className='pe-md-4 pb-3 pb-md-0'>
                        <Container fluid>
                            <SearchWithDataList
                                request={request}
                                authorityType={'recipientAuthority'}
                                authorityObj={request.buildIntention.recipientAuthority}
                                setRequest={setRequest}
                                paramFirst={'title'}
                                paramSecond={'typeTitle'}
                                setSearchRequest={setSearchRequest}
                                searchRequest={searchRequest} // obj
                                listData={prijemceList}
                                setListData={setPrijemceList} // arr
                                dynamicVal={AFFECTED} // str
                                isSearchLoading={isSearchLoading}
                                setIsSearchLoading={setIsSearchLoading}
                            />
                        </Container>
                    </Col>
                    <Col md={6} className='d-flex'>
                        <p className='input-help-text'>Vyhledejte dotčený orgán, pro který je žádost určena</p>
                    </Col>
                </Row>
                
            </>}
        </Container>
    );
};

PrijemceComponent.propTypes = {
    urlPath: PropTypes.string.isRequired,
};


export default PrijemceComponent;