import React, { useContext, useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { closeErrorPanelRed, deleteFile, file, iconChange, reloadIcon, uploadedFile } from '../../assets/index.js';
import { Progress } from '../index.js';
import PropTypes from 'prop-types';
import useUpload from '../CustomHooks/useUpload.jsx';
import { cancelUpload, canReupload, convertBytes, getNewAbortController, getTextfromSectionName, shouldNotFetch, shouldShowProgress } from '../../helperFunctions/helpers.js';
import { FORM_18_INTENTION, OTHER_DOCUMENTS, POWER_OF_ATTORNEY_FILE_MODEL, WARRANT } from '../../constants/sharedConstants.js';
import FormContext from '../../formContexts/FormContext.js';
import axios from 'axios';
import urlJoin from 'url-join';
import apiConfig from '../../config/api-config.js';
import { AuthContext } from 'react-oauth2-code-pkce';

const UploadedAttachmentElement = (
    { 
        fileObject, 
        isNotLastLine, 
        category,
        setUploads,
        isNew,
        progressElements,
        setProgressElements,    
        stepsRef,
        setCurrentApplicant,
        applicantArr,
        setFoundApplicantIndex,
        setAttorneyRequest,
        setAttorneyRepreRequest,
        setApplicantRepreRequest,
    }
) => {
    const forCsvImport = category.startsWith('csv_import');
    const { uid, fileName, url, size, blobFile, message, section, subSection, isDeletable } = fileObject;
    const [abortController, setAbortController] = useState(getNewAbortController());
    const abortSignal = abortController.signal;
    const foundFile = [...progressElements].find(el => el.uid === uid);
    const respectiveProgress = foundFile?.progress ?? null;
    const { upload, uploadSuccess, uploadError, uploadProgress, uploadCancelled } = useUpload(
        forCsvImport, // bool
    );
    const { uploadError: uploadErrorsFromContext, setUploadError, setUploadedBytes, intention, uploadedBytes } = useContext(FormContext);
    const { token } = useContext(AuthContext);
    const isUploaded = uploadProgress === null || (uploadProgress === 100 || uploadSuccess);
    const canDeleteFile = Boolean(isDeletable);
    const errorItem = [...uploadErrorsFromContext].find(el => el.uid === uid) ?? null;
    const formatError = errorItem?.why === 'format';
    const isForm18 = intention === FORM_18_INTENTION.title;

    const jumpBackToZadatele = () => {
        if (category !== WARRANT) {
            return;
        }

        stepsRef.current[2].click();
        // TODO find a better solution (maybe return a promise)
        setTimeout(() => {
            const foundZadatelIndex = applicantArr.findIndex(el => el.powerOfAttorneyFile.uid === uid);
            const foundZadatelIndexNew = applicantArr.findIndex(el => el.powerOfAttorneyRepresentative.uid === uid);

            if(foundZadatelIndex >= 0) {
                setFoundApplicantIndex(foundZadatelIndex);
            }

            if(foundZadatelIndexNew >= 0) {
                setFoundApplicantIndex(foundZadatelIndexNew);
            }

            if(foundZadatelIndex === -1 && foundZadatelIndexNew === -1) {
                setFoundApplicantIndex(-1);
            }
        }, [500]);
    };

    useEffect(() => {
        if (progressElements.length === 0 || uploadProgress === null) {
            return;
        }
        
        if (foundFile) {
            const updatedProgressElements = [...progressElements].map(el => {
                if (el.uid === uid) {
                    return {...el, progress: uploadProgress};
                } else {
                    return el;
                }
            });

            setProgressElements(updatedProgressElements);
        }
    }, [uploadProgress]);

    useEffect(() => {
        if (shouldNotFetch(uploadSuccess, uploadCancelled, uploadError, message)) {
            setUploadError(prev => [...prev, {
                where: section,
                why: message,
                uid,
            }]);              
            return;
        }

        if (isNew) {
            (async() => {
                if (forCsvImport) {
                    await upload();
                    return;
                }

                await upload(
                    url,
                    blobFile,
                    abortSignal,
                );
            })();
        }
    }, []);

    useEffect(() => {
        if (uploadSuccess) {
            if (forCsvImport) {
                return;
            }

            setUploads((prev) => ({
                ...prev,    
                [category]: [...prev[category]].map(el => {
                    if (el.uid === uid) {
                        if (el.message === undefined) {
                            // eslint-disable-next-line no-unused-vars
                            const {blobFile, message, ...rest} = el;
                            return {...rest};
                        } else if (el.message) { 
                            return el;
                        }
                    } else {
                        return el;
                    }
                })
            }));

            if (getTextfromSectionName(category)) {
                setUploadedBytes(prev => Number(+prev + +size));
            }

            if (setAttorneyRequest) {
                setAttorneyRequest(prev => ({...prev, form: {...prev.form, attorney: {...prev.form.attorney, powerOfAttorneyFile:  {
                    uid,
                    fileName,
                    url,
                    section: category,
                    subSection,
                    size,
                    personId: prev.form.attorney?.id ?? null,
                    isDeletable,
                }}}}));
            } else if (setAttorneyRepreRequest) {
                setAttorneyRepreRequest(prev => ({...prev, form: {...prev.form, attorney: {...prev.form.attorney, powerOfAttorneyRepresentative:  {
                    uid,
                    fileName,
                    url,
                    section: category,
                    subSection,
                    size,
                    personId: prev.form.attorney?.id ?? null,
                    isDeletable,
                }}}}));
            } else if (setApplicantRepreRequest) {
                setApplicantRepreRequest(prev => ({...prev, form: {...prev.form, applicant: {...prev.form.applicant, powerOfAttorneyRepresentative:  {
                    uid,
                    fileName,
                    url,
                    section: category,
                    subSection,
                    size,
                    personId: prev.form.applicant?.id ?? null,
                    isDeletable,
                }}}}));
            }
            
            return;
        }

        if(uploadCancelled) {
            if (forCsvImport) {
                return;
            }

            setUploads((prev) => ({
                ...prev,    
                [category]: [...prev[category]].filter(el => el.uid !== uid)
            }));

            if (setCurrentApplicant) {
                if (setApplicantRepreRequest) {
                    setCurrentApplicant(prev => ({...prev, powerOfAttorneyRepresentative: POWER_OF_ATTORNEY_FILE_MODEL}));
                }else {
                    setCurrentApplicant(prev => ({...prev, powerOfAttorneyFile: POWER_OF_ATTORNEY_FILE_MODEL}));
                }
            }

            if (setAttorneyRequest) {
                setAttorneyRequest(prev => ({...prev, form: {...prev.form, attorney: {...prev.form.attorney, powerOfAttorneyFile: POWER_OF_ATTORNEY_FILE_MODEL}}}));
            }
            if (setAttorneyRepreRequest) {
                setAttorneyRepreRequest(prev => ({...prev, form: {...prev.form, attorney: {...prev.form.attorney, powerOfAttorneyRepresentative: POWER_OF_ATTORNEY_FILE_MODEL}}}));
            }
            if (!setCurrentApplicant && setApplicantRepreRequest) {
                setApplicantRepreRequest(prev => ({...prev, form: {...prev.form, applicant: {...prev.form.applicant, powerOfAttorneyRepresentative: POWER_OF_ATTORNEY_FILE_MODEL}}}));
            }
            setAbortController(getNewAbortController());
            return;
        }

        if (uploadError) {
            if (forCsvImport) {
                return;
            }

            if (setCurrentApplicant) {
                if (setApplicantRepreRequest) {
                    setCurrentApplicant(prev => ({...prev, powerOfAttorneyRepresentative: POWER_OF_ATTORNEY_FILE_MODEL}));
                }else {
                    setCurrentApplicant(prev => ({...prev, powerOfAttorneyFile: POWER_OF_ATTORNEY_FILE_MODEL}));
                }
            }

            if (setAttorneyRequest) {
                setAttorneyRequest(prev => ({...prev, form: {...prev.form, attorney: {...prev.form.attorney, powerOfAttorneyFile: POWER_OF_ATTORNEY_FILE_MODEL}}}));
            }

            if (setAttorneyRepreRequest) {
                setAttorneyRepreRequest(prev => ({...prev, form: {...prev.form, attorney: {...prev.form.attorney, powerOfAttorneyRepresentative: POWER_OF_ATTORNEY_FILE_MODEL}}}));
            }

            if (!setCurrentApplicant && setApplicantRepreRequest) {
                setApplicantRepreRequest(prev => ({...prev, form: {...prev.form, applicant: {...prev.form.applicant, powerOfAttorneyRepresentative: POWER_OF_ATTORNEY_FILE_MODEL}}}));
            }
            setAbortController(getNewAbortController());
            return;
        }

    }, [uploadSuccess, uploadCancelled, uploadError]);

    const handleRetry = async () => {
        if (!canReupload(errorItem, size, uploadedBytes, setUploadError, uid, setUploads, category)) {
            return;
        }
        await upload(
            url,
            blobFile,
            abortSignal,
        );
    };

    const handleRemove = (id) => {
        setUploads((prev) => ({
            ...prev,    
            [category]: [...prev[category]].filter(el => el.uid !== id)
        }));
        
        if (getTextfromSectionName(category)) {
            setUploadedBytes(prev => Number(+prev - +size));
        }

        if (setCurrentApplicant) {
            if (setApplicantRepreRequest) {
                setCurrentApplicant(prev => ({...prev, powerOfAttorneyRepresentative: POWER_OF_ATTORNEY_FILE_MODEL}));
            }else {
                setCurrentApplicant(prev => ({...prev, powerOfAttorneyFile: POWER_OF_ATTORNEY_FILE_MODEL}));
            }
        }

        if (setAttorneyRequest) {
            setAttorneyRequest(prev => ({...prev, form: {...prev.form, attorney: {...prev.form.attorney, powerOfAttorneyFile: POWER_OF_ATTORNEY_FILE_MODEL}}}));
        }

        if (setAttorneyRepreRequest) {
            setAttorneyRepreRequest(prev => ({...prev, form: {...prev.form, attorney: {...prev.form.attorney, powerOfAttorneyRepresentative: POWER_OF_ATTORNEY_FILE_MODEL}}}));
        }

        if (!setCurrentApplicant && setApplicantRepreRequest) {
            setApplicantRepreRequest(prev => ({...prev, form: {...prev.form, applicant: {...prev.form.applicant, powerOfAttorneyRepresentative: POWER_OF_ATTORNEY_FILE_MODEL}}}));
        }
    }; 

    const downloadPdf = async (uid) => {
        try {
            const source = axios.CancelToken.source();

            const response = await axios.get(
                urlJoin(apiConfig.validateAndPersistBaseUrl, `preview-pdf/attachment/${uid}`),
                {
                    responseType: 'blob',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    cancelToken: source.token,
                });

            if ((200 <= response?.status) && (response?.status < 300)) {
                window.open(URL.createObjectURL(response.data));
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.log('Error', error);
            }
        }
    };

    return (fileName && <Row className={`${(message !== undefined || uploadError) ? '' : 'px-3'}`}>
        <Container 
            className={`d-flex align-items-center justify-content-between py-3 ${isNotLastLine ? 'border-bottom' : ''} file-container ${(message !== undefined || uploadError) ? 'error' : ''}`} fluid>
            <Row>
                <Container
                    className={`d-flex align-items-center justify-content-between file-name ${errorItem ? 'disabled' : ''}`}
                    onClick={() =>{ if(isUploaded && errorItem === null) downloadPdf(uid);}}
                >
                    <img src={uploadedFile} alt={fileName} />
                    <span className={`ps-2 file-description ${uploadError ? 'error' : ''}`}>
                        {`${fileName} - ${convertBytes(size)}`}
                    </span>
                </Container>
            </Row>
            <Row>
                {(message !== undefined) ? 
                    (<Container className={`d-flex align-items-center justify-content-end retry-btn-container ${formatError ? 'disabled' : ''}`} 
                        onClick={() => handleRetry()}>
                        <span className={`${formatError ? 'pe-0' : 'pe-2'} delete-btn-text error`}>{formatError ? 'Nepodporovaný formát souboru, nelze vložit.' : 'Chyba nahrávání, prosím zkuste znovu.'}</span>
                        <img 
                            className={`${formatError ? 'close-error-btn' : ''}`}
                            src={formatError ? closeErrorPanelRed : reloadIcon} 
                            alt={formatError ? 'zavřít' : `nahrát znova ${fileName}`}
                            onClick={() => {
                                if (formatError) {
                                    setUploads((prev) => ({
                                        ...prev,    
                                        [category]: [...prev[category]].filter(el => el.uid !== uid)
                                    })); 
                                    setUploadError(prev => [...prev].filter(el => el.uid !== uid));              
                                } }}
                        />
                    </Container>)
                    : (category === OTHER_DOCUMENTS && subSection?.length > 0) ? 
                        ((<Container className='d-flex align-items-center delete-btn-container' 
                            onClick={() => downloadPdf(uid)}>
                            <Row>
                                <button type='button' className='d-flex justify-content-center align-items-center kontrola-button small'>
                                    <img src={file} alt="file" className='kontrola-button-icon' />
                                    Náhled
                                </button>
                            </Row>
                        </Container>))
                        : (isUploaded)  ?
                            (((canDeleteFile && category !== WARRANT) && 
                                ((!isForm18 && section !== 'cover_letter') || 
                                (isForm18 && canDeleteFile))) || (forCsvImport)
                                ? 
                                (<Container className='d-flex align-items-center delete-btn-container' 
                                    onClick={() => handleRemove(uid)}>
                                    <img src={deleteFile} alt={`smazat ${fileName}`}/>
                                    <span className='ps-2 delete-btn-text'>Smazat</span>
                                </Container>) 
                                : (category === WARRANT) ? 
                                    (<Container className='d-flex align-items-center delete-btn-container' 
                                        onClick={() => jumpBackToZadatele()}>
                                        <img src={iconChange} alt={'Upravit žadatele'}/>
                                        <span className='ps-2 delete-btn-text'>Upravit žadatele</span>
                                    </Container>) 
                                    : '') 
                            : (uploadError) ? 
                                (<Container className='d-flex align-items-center justify-content-end retry-btn-container' 
                                    onClick={() => handleRetry()}>
                                    <span className='pe-2 delete-btn-text error'>Chyba nahrávání, prosím zkuste znovu.</span>
                                    <img src={reloadIcon} alt={`nahrát znova ${fileName}`}/>
                                </Container>) 
                                : (!uploadSuccess && uploadProgress < 100) ? 
                                    (<Container className='d-flex align-items-center cancel-btn-container' 
                                        onClick={() => cancelUpload(abortController)}>
                                        <span className='ps-2 delete-btn-text'>Zrušit</span>
                                    </Container>) 
                                    : ''}
            </Row>
        </Container>
        {shouldShowProgress(uploadSuccess, uploadCancelled, uploadError, uploadProgress) && <Progress progress={respectiveProgress}/>}
    </Row>
    );
};

UploadedAttachmentElement.propTypes = {
    fileObject: PropTypes.shape({
        uid: PropTypes.string,
        blobFile: PropTypes.object,
        fileName: PropTypes.string, 
        url: PropTypes.string,
        size: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        message: PropTypes.string,
        section: PropTypes.string,
        subSection: PropTypes.string,
        isDeletable: PropTypes.bool,
    }),
    isNotLastLine: PropTypes.bool,
    category: PropTypes.string,
    setUploads: PropTypes.func,
    isNew: PropTypes.bool,
    progressElements: PropTypes.arrayOf(Object),
    setProgressElements: PropTypes.func,
    stepsRef: PropTypes.objectOf(Array),
    applicantArr: PropTypes.arrayOf(Object),
    setCurrentApplicant: PropTypes.func,
    setFoundApplicantIndex: PropTypes.func,
    setAttorneyRequest: PropTypes.func,
    setAttorneyRepreRequest: PropTypes.func,
    setApplicantRepreRequest: PropTypes.func,
    receivedToken: PropTypes.string,
};

export default UploadedAttachmentElement;
