import React, { useState, useContext, useEffect, useRef, useImperativeHandle } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import '../../css/zadostPovoleniStavby.css';
import '../../css/zamerComponent.css';
import { errorIcon } from '../../assets/index.js';
import PropTypes from 'prop-types';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import { PozemkyTable, StavbyTable, FormInput, NewConstructionModal, ZamerSelectionControls } from '../../components/index.js';
import { BUILDING_ADDRESS } from '../../constants/form16.js';
import FormContext from '../../formContexts/FormContext.js';
import { handleClose, handleShow } from '../../helperFunctions/modalHelpers';
import { shouldNotSave, spreadParcelsAndConstructions, spreadObject, checkLimitAndInsertText, hasZamerError, } from '../../helperFunctions/helpers.js';
import { dateValidation } from '../../helperFunctions/validationHelpers.js';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import { CharCount } from '../../components/index.js';
import { AUTHORITY_MODEL } from '../../constants/sharedConstants.js';
import { useLocation, useNavigate } from 'react-router-dom';

const buildIntentionModel = {
    title: { value: null, dirty: false },
    address: {
        city: '',
        cityPart: '',
        street: '',
        descNum: '',
        orientNum: '',
        zip: '',
    },
    newBuilding: false,
    buildingTypePurpose: '',
    changeOfFinishedBuilding: false,
    reasonChangeOfBuildingUse: false,
    newBuildingUse: '',
    buildingTemporary: false,
    setOfBuildings: false,
    techOrMarketFacility: false,
    siteFacility: false,
    maintenanceAndModificationPermission: false,
    waterworks: false,
    buildingTemporaryDuration: '',
    parcelChangeSuggestion: '',
    waterworksPurpose: '',
    watercourseName: '',
    watercourseId: '',
    hydrogeologicalAreaName: '',
    hydrogeologicalAreaID: '',
    documentationId: '',
    legalAuthority: '',
    decisionBeenIssued: false,
    administrativeActName: '',
    permissionAuthority: AUTHORITY_MODEL,
    permissionRefNumber: '',
    permissionDated: '',
};

const ZamerComponent16 = ({ progressElements, setProgressElements, urlPath }) => {
    const {intention, stepValue, validationRef, setStagesArr, stagesArr, saveFormRef, id} = useContext(FormContext); 
    const [show, setShow] = useState(false);
    const [ focused, setFocused ] = React.useState({
        nazev: false,
        nazev2: false,
        // date: false,
        ucelVodnihoDila: false,
        nazevVodnihoDila: false,
        idVodnihoDila: false,
        novyZpusob: false,
        dobaTrvani: false,
        navrhUpravy: false
    });
    const [count, setCount] = useState({
        [0]: {count: 0, limit: 1000},
    });
    const [request, setRequest] = useState({
        'applicationId': id,
        form: {
            id: null,
        },
        buildIntention: buildIntentionModel
    });
    const [isIntentionCheckboxDisabled, setIsIntentionCheckboxDisabled] = useState(false);

    const handleFocused = (elementToFocus) => {
        setFocused(prev => ({...prev, [elementToFocus]: true}));
    };

    const buildIntention = request?.buildIntention;

    const [newBuilding, setNewBuilding] = useState({
        isChecked: false,
        select: '',
    });

    const prevRequest = usePrevious(request);
    const { token } = useContext(AuthContext);
    const initialRender = useRef(true);
    const [state, setState] = useState({
        inputValue: '',
        zamerType: 'new',
    });
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const [isDateError, setIsDateError] = useState({
        authorityDate: false,
    });
    const [loading, setLoading] = useState(false);
    const [parcelAndConstructionData, setParcelAndConstructionData] = useState({
        buildConstructions: [],
        buildParcels: [],
    });

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (id) {
            (async () => {
                const response = await getFormById(id, token, source, intention, null, pathname, navigate);
    
                if ((200 <= response?.status) && (response?.status < 300)) {
                    const isCopied = response.data?.buildApplication?.isCopied || false;
                    setIsIntentionCheckboxDisabled(isCopied);
                    spreadParcelsAndConstructions(
                        response.data, //obj
                        setParcelAndConstructionData, //func
                        parcelAndConstructionData // obj                
                    );

                    // do not send id: null if a form is present
                    const formData = response.data?.buildApplicationForms?.[0];
                    if (formData && formData.id) {
                        setRequest(state => ({ ...state, form: {id: formData.id} }));
                    }

                    const intention = response.data?.buildIntention;
                    if (intention) {
                        // eslint-disable-next-line no-unused-vars
                        const { buildConstructions, buildParcels, affectedBuildConstructions, affectedBuildParcels, approvedConstructions, ...rest } = intention;
                        setRequest(state => ({ ...state, buildIntention: spreadObject(rest) }));
                        // TODO reduce number of unnecessary states
                        setNewBuilding(state => ({...state, isChecked: intention.newBuilding}));
                        
                               
                        setState(prevSate => ({ 
                            ...prevSate,
                            inputValue: intention.title.value,
                            zamerType: intention.eszId ? 'existing' : 'new' 
                        }));
                    }
                    setLoading(false);
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        }
        else {
            console.log('NON EXISTENT ID');
            setLoading(false);
        }
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        if (
            (request?.buildIntention?.buildParcels) ||
            (request?.buildIntention?.buildConstructions)
        ) {
            setParcelAndConstructionData(prev => ({
                ...prev,
                buildParcels: [...(request?.buildIntention?.buildParcels ?? [])],
                buildConstructions: [...(request?.buildIntention?.buildConstructions ?? [])],
            }));
            setRequest(prev => ({...prev, buildConstructions: request?.buildIntention?.buildConstructions, buildParcels: request?.buildIntention?.buildParcels}));
        }

        if (initialRender.current) {
            initialRender.current = false;
        } else {
            // avoid multiple save for same object
            if (shouldNotSave(prevRequest.buildIntention, request.buildIntention)) {
                return;
            }

            if (request.buildIntention?.title?.value === null) {
                return;
            }

            saveChanges();
        }
    }, [request.buildIntention]);

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;
            let warning = false;

            if (hasZamerError(buildIntention)) {
                err = true;
            } else if (buildIntention?.newBuilding && !buildIntention?.buildingTypePurpose.trim()) {
                err = true;
            } else if(buildIntention?.buildingTemporary && (!buildIntention?.buildingTemporaryDuration?.trim() || !buildIntention?.parcelChangeSuggestion?.trim()))
            {
                err = true;
            } else if (isDateError.authorityDate) {
                err = true;
            }
            else if(buildIntention?.changeOfFinishedBuilding && !buildIntention?.newBuildingUse?.trim())
            {
                err = true;
            }
            else if(buildIntention?.waterworks && (
                !buildIntention?.waterworksPurpose))
            {
                err = true;
            }

            if (buildIntention?.address?.zip) {
                const cleanedValue = buildIntention?.address?.zip.replace(/\s+/g, '');
                warning = (isNaN(cleanedValue) || cleanedValue.length !== 5);
            }

            

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, warning};
                }
                return stg;
            }));

            return !err;
        }
    }));

    return (
        <>
            {loading ? <div className='loading'></div> :
                <Container fluid>
                    <Row className='row-wrapper'>
                        <h2 className='p-0 m-0 main-title'>{`${stepValue}. Záměr`}</h2>
                    </Row>

                    <ZamerSelectionControls
                        request={request}
                        setRequest={setRequest}
                        state={state}
                        setState={setState}
                        buildIntentionModel={buildIntentionModel}
                        isIntentionCheckboxDisabled={isIntentionCheckboxDisabled}
                    />

                    {/* Místo záměru */}
                    <Row className='row-wrapper border rounded p-4'>
                        <p className='section-title p-0 mb-4'>Místo (nepovinné)</p>
                        <form className='p-0'>

                            {BUILDING_ADDRESS.map(item => (
                                <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                            ))}
                        </form>
                    </Row>

                    {/* Charakteristika záměru */}
                    <Row className='row-wrapper'>
                        <p className='p-0 mb-4 section-title'>Základní charakteristika záměru v případě, že se dodatečné povolení týká stavby</p>
                        {/* <p className='p-0 mb-4 section-description'>v případě, že se žádost o vyjádření týká stavby</p> */}
                        <form className='d-flex flex-column p-0'>
                            <label className='mb-2 id-checkbox-label'>
                                <input type="checkbox" className='id-checkbox-input' checked={newBuilding.isChecked ?? false}
                                    onChange={(e) => { 
                                        setNewBuilding(state => ({ ...state, isChecked: e.target.checked })); 
                                        setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, newBuilding: e.target.checked } })); }} 
                                />
                                Nová stavba
                            </label>
                            {buildIntention?.newBuilding &&
                            <Row className='row-wrapper'>
                                <Col md={6} sm={12}>
                                    <p className='p-0 mt-3 mb-1 new-construction-help-text' style={{color: error && !buildIntention?.buildingTypePurpose.trim() ? '#C52A3A' : ''}}>Druh a účel stavby</p>
                                    <label className='id-checkbox-label'>
                                        <input
                                            type="text"
                                            value={buildIntention?.buildingTypePurpose}
                                            className='new-construction-input'
                                            disabled
                                        />
                                        <button
                                            type='button'
                                            className='d-flex justify-content-center align-items-center new-construction-button'
                                            style={{border: error && !buildIntention?.buildingTypePurpose.trim() ? 'solid 1px #C52A3A' : ''}}
                                            onClick={() => handleShow(setShow)}
                                        >
                                            Vybrat
                                        </button>
                                    </label>
                                </Col>
                                <Col md={6} sm={12} className='d-flex'>
                                    <p className='building-change align-content-end mb-md-2 mt-2 ps-0 ps-md-3'>
                                            V případě souboru staveb uveďte stavbu hlavní
                                    </p>
                                </Col>
                                <NewConstructionModal 
                                    show={show} 
                                    setShow={setShow}
                                    handleClose={handleClose}
                                    setNewBuilding={setNewBuilding} 
                                    setRequest={setRequest}
                                />
                            </Row>}
                            <Row>
                                <Col md={6} sm={12}>
                                    <label className='mb-2 id-checkbox-label'>
                                        <input type="checkbox" className='id-checkbox-input' checked={buildIntention?.changeOfFinishedBuilding}
                                            onChange={(e) => { 
                                                setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, changeOfFinishedBuilding: e.target.checked } })); }} 
                                        />
                                        Změna dokončené stavby (nástavba, přístavba, stavební úprava)
                                    </label>
                                </Col>
                            </Row>

                            <Row className={buildIntention?.changeOfFinishedBuilding ? 'row-wrapper' : ''}>
                                {buildIntention?.changeOfFinishedBuilding && <Row className='d-flex mb-2'>
                                    <Col md={6} sm={12}>
                                        <label className='id-checkbox-label'>
                                            <input type="checkbox" className='id-checkbox-input' checked={buildIntention?.reasonChangeOfBuildingUse}
                                                onChange={(e) => { 
                                                    setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, reasonChangeOfBuildingUse: e.target.checked } })); }} 
                                            />
                                        Navrhuje se z důvodu změny v užívání stavby
                                        </label>
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <p className='building-change my-2 my-md-0 ps-0 ps-md-3'>
                                        Uveďte, zda se nástavba, přístavba nebo stavební úprava  navrhuje z důvodu změny v užívání stavby (změna ve způsobu užívání stavby, změna v provozním zařízení stavby, změna ve způsobu výroby nebo podstatném rozšíření výroby, změna v činnosti, jejíž účinky by mohly ohrozit život nebo veřejné zdraví, život a zdraví zvířat, bezpečnost nebo životní prostředí).
                                        </p>
                                    </Col>                      
                                </Row>}
                                {buildIntention?.changeOfFinishedBuilding && 
                                <Row className='d-flex pb-4'>
                                    <Col xs={12} md={6} className='d-flex flex-column'>
                                        <label className='select-input' style={{color: (!buildIntention?.newBuildingUse?.trim() && (error || focused.novyZpusob)) ? '#C52A3A' : ''}}>
                                            Nový způsob užívání stavby
                                            <input
                                                type='text'
                                                {...(focused.novyZpusob && { focused: 'true' })}
                                                defaultValue={buildIntention.newBuildingUse}
                                                style={{border: (!buildIntention?.newBuildingUse?.trim() && (error || focused.novyZpusob)) ? 'solid 1px #C52A3A' : ''}}
                                                required
                                                onBlur={(e) => {
                                                    setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, newBuildingUse: e.target.value } }));
                                                    handleFocused('novyZpusob');
                                                }}
                                                className='mt-1 id-input2 waterworks bg-white'
                                            />
                                            {(!buildIntention?.newBuildingUse?.trim() && (error || focused.novyZpusob))&&
                                                <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                                    <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                                    Bez vyplnění pole &quot;Nový způsob užívání stavby&quot; není možné pokračovat.
                                                </Container>}
                                        </label>
                                    </Col>
                                </Row>
                                }
                            </Row>

                            <label className='mb-2 id-checkbox-label'>
                                <input type="checkbox" className='id-checkbox-input' checked={Boolean(buildIntention.buildingTemporary)}
                                    onChange={(e) => {  setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, buildingTemporary: e.target.checked } })); }} />
                                Stavba dočasná
                            </label>

                            {buildIntention.buildingTemporary
                                &&
                            <Row className='border p-4 row-wrapper'>
                                <Row className='d-flex pb-4'>
                                    <Col xs={12} md={6} className='d-flex flex-column'>
                                        <label className='select-input' style={{color: (!buildIntention?.buildingTemporaryDuration?.trim() && (error || focused.dobaTrvani)) ? '#C52A3A' : ''}}>
                                            Doba trvání dočasné stavby
                                            <input
                                                type='text'
                                                defaultValue={buildIntention.buildingTemporaryDuration}
                                                style={{border: (!buildIntention?.buildingTemporaryDuration?.trim() && (error || focused.dobaTrvani)) ? 'solid 1px #C52A3A' : ''}}
                                                required
                                                {...(focused.dobaTrvani && { focused: 'true' })}
                                                onBlur={(e) => {
                                                    setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, buildingTemporaryDuration: e.target.value } }));
                                                    handleFocused('dobaTrvani');
                                                }}
                                                className='mt-1 id-input2 waterworks bg-white p-2'
                                            />
                                            {(!buildIntention?.buildingTemporaryDuration?.trim() && (error || focused.dobaTrvani)) &&
                                                <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                                    <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                                    Bez vyplnění pole &quot;Doba trvání dočasné stavby&quot; není možné pokračovat.
                                                </Container>}
                                        </label>
                                    </Col>
                                </Row>
                                <label className='d-flex flex-column p-0 textarea-input row-wrapper' style={{color: (!buildIntention?.parcelChangeSuggestion?.trim() && (error || focused.navrhUpravy)) ? '#C52A3A': ''}}>
                                    Návrh úpravy pozemku po jejím odstranění
                                    <Row className='position-relative textarea-with-count-container'>
                                        <textarea
                                            className='textarea-with-count mt-2 p-2 text-area--validation'
                                            onChange={(e) => { checkLimitAndInsertText(e.target, 0, count, setCount); }}                
                                            rows="5"
                                            required
                                            style={{border:  error && !buildIntention?.parcelChangeSuggestion?.trim() ? 'solid 1px #C52A3A' : ''}}
                                            defaultValue={buildIntention?.parcelChangeSuggestion}
                                            {...(focused.navrhUpravy && { focused: 'true' })}
                                            onBlur={(e) => {
                                                setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, parcelChangeSuggestion: e.target.value } }));
                                                handleFocused('navrhUpravy');
                                            }}
                                        />
                                        <CharCount 
                                            value={buildIntention?.parcelChangeSuggestion} setCount={setCount} propToUpdate={0} count={count[0].count} limit={count[0].limit}
                                        />
                                    </Row>
                                </label>
                            </Row>}

                            <label className='mb-2 id-checkbox-label'>
                                <input type="checkbox" className='id-checkbox-input' checked={buildIntention?.setOfBuildings}
                                    onChange={(e) => { 
                                        //  
                                        setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, setOfBuildings: e.target.checked } })); }} 
                                />
                                Soubor staveb
                            </label>
                            <Col md={6} sm={12}>
                                <label className='mb-2 id-checkbox-label'>
                                    <input type="checkbox" className='id-checkbox-input' checked={buildIntention?.techOrMarketFacility}
                                        onChange={(e) => { 
                                            // 
                                            setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, techOrMarketFacility: e.target.checked } })); }} 
                                    />
                                    Technické zařízení nebo reklamní zařízení
                                </label>
                            </Col>

                            <label className='mb-2 id-checkbox-label'>
                                <input type="checkbox" className='id-checkbox-input' checked={buildIntention?.siteFacility}
                                    onChange={(e) => { 
                                        //  
                                        setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, siteFacility: e.target.checked } })); }} 
                                />
                                Zařízení staveniště
                            </label>

                            <label className='mb-2 id-checkbox-label'>
                                <input type="checkbox" className='id-checkbox-input' checked={buildIntention?.waterworks}
                                    onChange={(e) => { 
                                        //  
                                        setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, waterworks: e.target.checked } })); }} 
                                />
                                Vodní dílo
                            </label>

                            {buildIntention?.waterworks
                                &&
                            <Row className='border p-4 row-wrapper'>
                                <Row className='d-flex pb-3'>
                                    <Col xs={12} md={6} className='d-flex flex-column'>
                                        <label className='select-input' style={{color: (!buildIntention?.waterworksPurpose && (error || focused.ucelVodnihoDila)) ? '#C52A3A' : ''}}>
                                            Účel stavby vodního díla
                                            <select
                                                type='select'
                                                required
                                                value={buildIntention.waterworksPurpose}
                                                style={{border:  (!buildIntention?.waterworksPurpose && (error || focused.ucelVodnihoDila)) ? '#C52A3A solid 1px' : ''}}
                                                onChange={(e) => {  setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, waterworksPurpose: e.target.value } })); }}
                                                className='mt-1 select dropdown-input id-input2' 
                                                onBlur={() => handleFocused('ucelVodnihoDila')}
                                                {...(focused.ucelVodnihoDila && { focused: 'true' })}
                                            >
                                                <option value='' disabled>vyberte</option>
                                                <option value={'01 umožnění odběru povrchové vody pro vodárenské účely'}>01 umožnění odběru povrchové vody pro vodárenské účely</option>
                                                <option value={'02 umožnění odběru povrchové vody pro průmyslové účely'}>02 umožnění odběru povrchové vody pro průmyslové účely</option>
                                                <option value={'03 umožnění odběru povrchové vody pro závlahy'}>03 umožnění odběru povrchové vody pro závlahy</option>
                                                <option value={'04 umožnění odběru povrchové vody pro ostatní účely'}>04 umožnění odběru povrchové vody pro ostatní účely</option>
                                                <option value={'05 podzemní voda'}>05 podzemní voda</option>
                                                <option value={'06 vodní energie, využití energetického potenciálu'}>06 vodní energie, využití energetického potenciálu</option>
                                                <option value={'07 vodní doprava, plavební účely'}>07 vodní doprava, plavební účely</option>
                                                <option value={'08 ochrana před povodněmi a ostatními škodlivými účinky vod'}>08 ochrana před povodněmi a ostatními škodlivými účinky vod</option>
                                                <option value={'09 odvádění odpadních a jiných vod koryty vodních toků'}>09 odvádění odpadních a jiných vod koryty vodních toků</option>
                                                <option value={'10 chov ryb nebo vodní drůbeže, popřípadě jiných vodních živočichů'}>10 chov ryb nebo vodní drůbeže, popřípadě jiných vodních živočichů</option>
                                                <option value={'11 rekreace u vody a jiné účely'}>11 rekreace u vody a jiné účely</option>
                                                <option value={'12 nadlepšování průtoků'}>12 nadlepšování průtoků</option>
                                                <option value={'13 stabilizace toku'}>13 stabilizace toku</option>
                                                <option value={'14 usazovací účinek'}>14 usazovací účinek</option>
                                                <option value={'99 jiné'}>99 jiné</option>
                                            </select>
                                        </label>
                                    </Col>
                                    <Col xs={12} md={6} className='d-flex flex-column'>
                                        <p className='mt-lg-4 mt-sm-2 building-change ps-0 ps-md-3'>
                                        Účel stavby vodního díla podle číselníku Č11 Účel užití
                                        vodního díla uvedeného v příloze č. 4 vyhlášky č.
                                        414/2013 Sb., o rozsahu a způsobu vedení evidence
                                        rozhodnutí, opatření obecné povahy, závazných
                                        stanovisek, souhlasů a ohlášení, k nimž byl dán souhlas
                                        podle vodního zákona, a částí rozhodnutí podle zákona o
                                        integrované prevenci (o vodoprávní evidenci).
                                        </p>
                                    </Col>
                                </Row>
                                <Row className='mb-4'>
                                    <Col xs={12} md={6}>
                                        <label className='select-input'>
                                            Název vodního toku
                                            <input
                                                type='text'
                                                defaultValue={buildIntention.watercourseName}
                                                onBlur={(e) => {
                                                    setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, watercourseName: e.target.value } }));
                                                    handleFocused('nazevVodnihoDila');
                                                }}
                                                {...(focused.nazevVodnihoDila && { focused: 'true' })}
                                                className='mt-1 id-input2 waterworks bg-white p-2'
                                            />
                                        </label>
                                    </Col>
                                </Row>
                                <Row className='d-flex pb-4'>
                                    <Col xs={12} md={6}>
                                        <label className='select-input' >
                                            ID vodního toku
                                            <input
                                                type='text'
                                                defaultValue={buildIntention.watercourseId}
                                                onBlur={(e) => {
                                                    setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, watercourseId: e.target.value } }));
                                                    handleFocused('idVodnihoDila');
                                                }}
                                                {...(focused.idVodnihoDila && { focused: 'true' })}
                                                className='mt-1 id-input2 waterworks bg-white p-2'
                                            />
                                        </label>
                                    </Col>
                                    <Col xs={12} md={6} className='d-flex flex-column'>
                                        <p className='mt-lg-4 mt-sm-2 building-change ps-0 ps-md-3'>
                                            Číselný identifikátor vodního toku podle údajů v evidenci vodních toků (§ 2 vyhlášky č. 252/2013 Sb., o rozsahu údajů v evidencích stavu povrchových a podzemních vod a o způsobu zpracování, ukládání a předávání těchto údajů do informačních systémů veřejné správy).
                                        </p>
                                    </Col>
                                </Row>
                                <Row className='d-flex pb-4'>
                                    <Col xs={12} md={6} className='d-flex flex-column'>
                                        <label className='select-input p-0'>
                                                Název hydrogeologického rajónu
                                            <input type='text' defaultValue={request.buildIntention.hydrogeologicalAreaName}
                                                onBlur={(e) => {setRequest(state => ({...state, buildIntention: {...state.buildIntention, hydrogeologicalAreaName: e.target.value}}));}}
                                                className='mt-1 form-zamer-input waterworks bg-white p-2'
                                            />
                                        </label>
                                    </Col>
                                    <Col md={6}>
                                        <p className='mt-3 mt-md-4 building-change ps-md-3'>
                                            Pouze v případě, že se záměr týká podzemních vod.
                                        </p>
                                    </Col>
                                </Row>
                                <Row className='d-flex pb-4'>
                                    <Col xs={12} md={6} className='d-flex flex-column'>
                                        <label className='select-input'>
                                            ID hydrogeologického rajónu
                                            <input type='text' defaultValue={request.buildIntention.hydrogeologicalAreaID}
                                                onBlur={(e) => {setRequest(state => ({...state, buildIntention: {...state.buildIntention, hydrogeologicalAreaID: e.target.value}}));}}
                                                className='mt-1 form-zamer-input waterworks bg-white p-2'
                                            />
                                        </label>
                                    </Col>
                                    <Col xs={12} md={6} className=''>
                                        <p className='mt-3 mt-md-5 building-change ps-md-3'>
                                        Číselný identifikátor hydrogeologického rajónu podle údajů v evidenci hydrogeologických rajónů (§ 4 vyhlášky č. 252/2013 Sb.).
                                        </p>
                                        <p className='mt-2 building-change ps-md-3'>
                                        Pouze v případě, že se záměr týká podzemních vod.
                                        </p>
                                    </Col>
                                </Row>
                            </Row>}

                            <Row className='d-flex'>
                                <Col md={6} sm={12}>
                                    <label className='mb-2 id-checkbox-label'>
                                        <input type="checkbox" className='id-checkbox-input' checked={buildIntention.maintenanceAndModificationPermission}
                                            onChange={(e) => { setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, maintenanceAndModificationPermission: e.target.checked } })); }} />
                                    Udržovací práce a stavební úpravy, které vyžadují povolení
                                    </label>
                                </Col>
                                <Col md={6} sm={12}>
                                    <p className='building-change ps-0 ps-md-3'>
                                    Příloha č. 2 odst. 2 písm. c) a d) stavebního zákona
                                    </p>
                                </Col>                      
                            </Row>
                        </form>
                    </Row>

                    {/* Součástí záměru je */}
                    <Row className='row-wrapper'>
                        <p className='p-0 mb-4 mt-1 section-title'>Součástí záměru je</p>
                        <Row className='d-flex'>
                            <Col md={6} sm={12}>
                                <label className='mb-2 id-checkbox-label'>
                                    <input type="checkbox" className='id-checkbox-input' checked={buildIntention?.changeOfPurpose}
                                        onChange={(e) => { setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, changeOfPurpose: e.target.checked } })); }} />
                                    změna využití území
                                </label>
                            </Col>
                            <Col md={6} sm={12}>
                                <p className='building-change ps-0 ps-md-3'>
                                Viz § 213 stavebního zákona (např. terénní úprava, změna druhu pozemku nebo způsobu využití pozemku, úprava pozemku, která má vliv na schopnost vsakování vody).
                                </p>
                            </Col>                      
                        </Row>
                        <Row className='d-flex'>
                            <Col md={6} sm={12}>
                                <label className='mb-2 id-checkbox-label'>
                                    <input type="checkbox" className='id-checkbox-input' checked={buildIntention?.splitOrMergeParcels}
                                        onChange={(e) => { setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, splitOrMergeParcels: e.target.checked } })); }} />
                                    dělení nebo scelení pozemků
                                </label>
                            </Col>                  
                        </Row>
                        <Row className='d-flex'>
                            <Col md={6} sm={12}>
                                <label className='mb-2 id-checkbox-label'>
                                    <input type="checkbox" className='id-checkbox-input' checked={buildIntention?.protectZoneDetermination}
                                        onChange={(e) => { setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, protectZoneDetermination: e.target.checked } })); }} />
                                    stanovení ochranného pásma
                                </label>
                            </Col>
                            <Col md={6} sm={12}>
                                <p className='building-change ps-0 ps-md-3'>
                                    Návrh na stanovení ochranného pásma, které se týká blíže neurčeného okruhu osob, nebo ochranného pásma vodního díla je nutné podat samostatně na formuláři, který je obsažen v příloze č. 9 vyhlášky č. 149/2024 Sb..
                                </p>
                            </Col>                      
                        </Row>
                    </Row>

                    <Row className='row-wrapper'>
                        <StavbyTable
                            title={'Stavby'}
                            subtitle={'na kterých se má záměr uskutečnit'}
                            progressElements={progressElements}
                            setProgressElements={setProgressElements}
                            urlPath={urlPath}
                            dataToAdd={parcelAndConstructionData.buildConstructions}
                        />
                        <PozemkyTable
                            subtitle={'na kterých se má záměr uskutečnit'}
                            progressElements={progressElements}
                            setProgressElements={setProgressElements}
                            urlPath={urlPath}
                            dataToAdd={parcelAndConstructionData.buildParcels}
                            setDataToAdd={setParcelAndConstructionData}
                        />
                    </Row>

                    <Row className='mb-4'>
                        <p className='p-0 my-4 section-title'>Vydané rozhodnutí</p>    
                        <label className='id-checkbox-label p-0'>
                            <input 
                                type="checkbox"
                                className='id-checkbox-input' 
                                checked={Boolean(buildIntention?.decisionBeenIssued)}
                                onChange={(e) => {
                                    setRequest(state => ({ ...state, 
                                        buildIntention: { ...state?.buildIntention, 
                                            decisionBeenIssued: e.target.checked, 
                                            ...(!e.target.checked ? {administrativeActName: '',
                                                permissionAuthority: AUTHORITY_MODEL,
                                                permissionRefNumber: '',
                                                permissionDated: '', } : {}
                                            )} 
                                    })); 
                                }} />
                                K záměru již bylo vydáno rozhodnutí v části věci podle § 330 odst. 6 a 8 stavebního zákona
                        </label>
                    </Row>
                    {request.buildIntention.decisionBeenIssued && 
                    <Row className='d-flex flex-column'>
                        <label className='py-2 px-0 label'>
                            <Col md={6} sm={12} /*style={{color: error && !buildIntention?.administrativeActName?.trim() ? '#C52A3A' : ''}}*/>
                                Název správního aktu, kterým byla stavba umístěna
                                <input
                                    type='text'
                                    defaultValue={buildIntention?.administrativeActName}
                                    // style={{border: error && !buildIntention?.administrativeActName?.trim() ? '#C52A3A solid 1px' : ''}}
                                    onBlur={(e) => {setRequest(state => ({ ...state, buildIntention: { ...state?.buildIntention, administrativeActName: e.target.value } }));}}
                                    className='mt-1 form-zamer-input waterworks bg-white p-2'
                                />
                            </Col>
                        </label>
                        <label className='py-2 px-0 label'>
                            <Col md={6} sm={12} /*style={{color: error && !buildIntention?.administrativeActName?.trim() ? '#C52A3A' : ''}}*/>
                                {/* missing in BE */}
                                Označení správního orgánu, který správní akt v části věci vydal
                                {/* TODO check how to get rest of the object */}
                                <input
                                    type='text'
                                    defaultValue={buildIntention?.permissionAuthority?.authorityName}
                                    // style={{border: error && !buildIntention?.recipientAuthority?..trim() ? '#C52A3A solid 1px' : ''}}
                                    onBlur={(e) => {setRequest(state => ({ ...state, buildIntention: { ...state?.buildIntention, permissionAuthority: {...state?.buildIntention?.permissionAuthority, authorityName: e.target.value} } }));}}
                                    className='mt-1 form-zamer-input waterworks bg-white p-2'
                                />
                            </Col>
                        </label>
                        <label className='py-2 px-0 label'>
                            <Col md={6} sm={12}>
                                Číslo Jednací
                                <input 
                                    type='text'
                                    defaultValue={buildIntention?.permissionRefNumber}
                                    // style={{border: error && !buildIntention?.referenceNumber?.trim() ? '#C52A3A solid 1px' : ''}}
                                    onBlur={(e) => {setRequest(state => ({ ...state, buildIntention: { ...state?.buildIntention, permissionRefNumber: e.target.value } }));}}
                                    className='mt-1 form-zamer-input waterworks bg-white p-2'
                                />
                            </Col>
                        </label>
                        <label className='py-2 px-0 label'>
                            <Col md={6} sm={12} className='d-flex flex-column'>
                                Ze dne
                                <input
                                    type='date'
                                    className='id-input'
                                    value={buildIntention?.permissionDated ?? ''}
                                    // style={{border: error && !buildIntention?.intentionDated?.trim() ? '#C52A3A solid 1px' : ''}}
                                    style={{border: isDateError.authorityDate ? '#C52A3A solid 1px' : ''}}
                                    onChange={(e) => setRequest(state => ({ ...state, buildIntention: { ...state?.buildIntention, permissionDated: e.target.value } }))}
                                    onBlur={(e) => {
                                        const validatedDate = dateValidation(e.target.value); 
                                        validatedDate && setRequest(state => ({ ...state, buildIntention: { ...state?.buildIntention, permissionDated: '' } }));
                                        setIsDateError(state => ({...state, authorityDate: validatedDate}));
                                    }}
                                />
                                {isDateError.authorityDate && <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                                    <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                            Nesprávný formát vyplnění, zkuste to znovu.
                                </Container>}
                            </Col>
                        </label>
                    </Row>}
                </Container>}
        </>
    );
};

ZamerComponent16.propTypes = {
    progressElements: PropTypes.arrayOf(Object),
    setProgressElements: PropTypes.func,
    urlPath: PropTypes.string.isRequired,
};

export default ZamerComponent16;
