import axios from 'axios';
import urlJoin from 'url-join';
import apiConfig from '../config/api-config.js';
import { spreadUploadedDocuments, updateTableValues } from '../helperFunctions/helpers.js';
import { STAVBA_MODEL } from '../constants/sharedConstants.js';

export function isRequestOK(status) {
    return 200 <= status < 300;
}

const capitalizeFirstLetter = (string) => {
    const specialSpojky = ['nad', 'pod', 'u']; // TODO check if names can contain more diffent special words
    
    return string
        .trim()
        .split(/\s+/)
        .map(str => {
            if (specialSpojky.indexOf(str.toLowerCase()) > -1) {
                return str.toLowerCase();
            }
            return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        })
        .join(' ');
};

// ZADOSTI SECTION

const createPdf = async (formNumber, id, receivedToken, token) => {
    try {
        const source = axios.CancelToken.source();
        let urlExtension = `preview-pdf/build-application-${formNumber}/${id}`;

        if (receivedToken) {
            urlExtension = `preview-pdf/accompanying/${id}`;
        }

        const response = await axios.get(
            urlJoin(apiConfig.validateAndPersistBaseUrl, urlExtension),
            {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                cancelToken: source.token,
            });
        if ((200 <= response?.status) && (response?.status < 300)) {
            window.open(URL.createObjectURL(response.data));
        }

    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            console.log('Error', error);
        }
    }
};

const fetchZadosti = async (data, token, source) => {
    try {
        return await axios.post(
            urlJoin(apiConfig.getAndQueryBaseUrl, 'requests/browse'),
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                cancelToken: source.token,
            });
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            console.error(error);
        }
    }
};

const fetchZadostiDetail = async (id, token, source) => {
    try {
        return await axios.get(
            urlJoin(apiConfig.getAndQueryBaseUrl, 'requests', encodeURIComponent(id)),
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                cancelToken: source.token,
            });
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            console.error(error);
        }
    }
};

const fetchQR = async (id, token, source) => {
    try {
        return await axios.get(
            urlJoin(apiConfig.validateAndPersistBaseUrl, 'qr', encodeURIComponent(id)),
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                cancelToken: source.token,
            });
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            console.error(error);
        }
    }
};

const fetchDevelopedZadosti = async (page, size, token, source) => {
    try {
        return await axios.get(
            urlJoin(apiConfig.validateAndPersistBaseUrl, 'build-applications') + `/?page=${page}&size=${size}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                cancelToken: source.token,
            });
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            console.error(error);
        }
    }
};

const makeCopyZadostiById = async (id, token, source) => {
    try {
        return await axios.get(
            urlJoin(apiConfig.getAndQueryBaseUrl, 'requests/copy', encodeURIComponent(id)),
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                cancelToken: source.token,
            });
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            console.error(error);
        }
    }
};
// uncommenting not deleting this unused export
// const fetchDocuments = async (bppId, token, source) => {
//     try {
//         return await axios.get(
//             urlJoin(apiConfig.validateAndPersistBaseUrl, `build-intentions/documentations/${bppId}`),
//             {
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${token}`,
//                 },
//                 cancelToken: source.token,
//             });
//     } catch (error) {
//         if (axios.isCancel(error)) {
//             console.log('Request canceled', error.message);
//         } else {
//             console.error(error);
//         }
//     }
// };

const deleteZadosti = async (id, token, source) => {
    try {
        return await axios.delete(
            urlJoin(apiConfig.validateAndPersistBaseUrl, 'build-applications') + `/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                cancelToken: source.token,
            });
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            console.error(error);
        }
    }
};

// RIZENI SECTION

const fetchRizeni = async (data, token, source) => {
    try {
        return await axios.post(
            urlJoin(apiConfig.getAndQueryBaseUrl, 'procedures/browse'),
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                cancelToken: source.token,
            });
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            console.error(error);
        }
    }

};

// ZAMERY SECTION

const fetchZamery = async (data, token, signal) => {
    try {
        const response = await axios.post(
            urlJoin(apiConfig.getAndQueryBaseUrl, 'projects/browse'),
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                signal
            });
        return response;
    } catch (error) {
        if (!signal.aborted) {
            console.error(error);
        }
        return error; 
    }
};

const fetchZamerDetails = async (id, token, signal, setUploadError) => {
    try {
        const response = await axios.get(
            urlJoin(apiConfig.getAndQueryBaseUrl, `projects/${id}`),
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                signal,
            });
        setUploadError(prev => [...prev].filter(el => el.why !== 'api_call'));
        return response;
    } catch (error) {
        if (!signal.aborted) {
            console.error(error);
            setUploadError(prev => { 
                console.log('done');
                return [...prev, {
                    where: 'ZAMER',
                    why: 'api_call',
                    uid: null,
                }];
            });
        }
        return error;
    }
};

const fetchZadostDetails = async (id, token, signal, setUploadError) => {
    try {
        const response = await axios.get(
            urlJoin(apiConfig.getAndQueryBaseUrl, `requests/${id}`),
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                signal,
            });
        setUploadError(prev => [...prev].filter(el => el.why !== 'api_call'));
        return response;
    } catch (error) {
        if (!signal.aborted) {
            console.error(error);
            setUploadError(prev => { 
                console.log('done');
                return [...prev, {
                    where: 'ZAMER',
                    why: 'api_call',
                    uid: null,
                }];
            });
        }
        return error;
    }
};


// STAVBY SECTION 

const fetchStavby = async (data, token, source) => {
    try {
        return await axios.post(
            urlJoin(apiConfig.getAndQueryBaseUrl, 'constructions/browse'),
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                cancelToken: source.token,
            });
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            console.error(error);
        }
    }
};

const getPrijemceDetailWithId = async (id, token, signal) => {
    try {
        const response = await axios.get(
            urlJoin(apiConfig.getAndQueryBaseUrl, 'organizations', encodeURIComponent(id)),
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                signal
            });
        return response;
    } catch (error) {
        if (!signal.aborted) {
            console.log('Error', error);
        }
    }
};

const formatCadastralArea = (obj) => {
    if (obj instanceof Object && !Object.hasOwn(obj, 'city') && Object.hasOwn(obj, 'name')) {
        return {...obj, name: capitalizeFirstLetter(obj.name)};
    } 

    return obj;
};

const handleGetCadastralAreas = async (obj, token, setPozemekState, setLoadings, signal) => {
    try {
        const response = await axios.post(
            urlJoin(apiConfig.getAndQueryBaseUrl, 'parcels/get-cadastral-territories'),
            formatCadastralArea(obj),
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                signal
            });
        if (!signal.aborted && isRequestOK(response.status)) {
            if (!response.data?.data) {
                setPozemekState(prev => ({...prev, cadastralAreaList: []}));
            } else if (Array.isArray(response.data?.data)) {              
                setPozemekState(prev => ({...prev, cadastralAreaList: 
                    [...new Map([...response.data.data]
                        .map(el => [el.code, el])).values()]
                }));
            }
            setLoadings((prevState) => ({
                ...prevState, isCadastralsLoading: false
            }));
        }
        return response;
    } catch (error) {
        if (!signal.aborted) {
            console.log('Error', error);
            setPozemekState(prev => ({ ...prev, cadastralAreaList: []}));
            setLoadings((prevState) => ({
                ...prevState, isCadastralsLoading: false
            }));
        }
    }
};

const resetValues = (setIsReset, setState, key, setRequest, setNewBuilding) => {
    try {
        setIsReset(true);
        setState(prev => ({...prev, [key]: null}));
        setRequest(prev => ({...prev, ...STAVBA_MODEL, uid: prev.uid}));
        setNewBuilding({
            isChecked: false,
            select: '',
        });
    } catch (error) {
        console.log(error);
    }
};

const handleGetParcel = async (obj, token, setPozemekState, setRequest, setLoadings, pozemekState, setParcelNotFound, signal) => {
    try {
        const response = await axios.post(
            urlJoin(apiConfig.getAndQueryBaseUrl, 'parcels/get-parcel'),
            obj,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                signal
            });
        if (!signal.aborted && isRequestOK(response.status)) {
            setPozemekState(prev => ({ ...prev, parcel: response.data.data }));
            if (setRequest) {
                setRequest(prev => ({
                    ...prev, ...response.data.data,
                    cadastralTerritory: response.data.data?.cadastralTerritory ?? pozemekState?.cadastralAreaName,
                    parcelNumber: response.data.data?.parcelNumber ?? pozemekState?.parcelNum
                }));
            }
            if (setParcelNotFound) {
                setParcelNotFound(false);
            }

            setLoadings((prevState) => ({
                ...prevState, isParcelLoading: false
            }));
        }
    } catch (error) {
        if (!signal.aborted) {
            console.log('Error', error);
            setPozemekState(prev => ({ ...prev, parcel: null }));
            setLoadings((prevState) => ({
                ...prevState, isParcelLoading: false
            }));
            if (setParcelNotFound)
                setParcelNotFound(true);
        }
    }
};
// TODO not to break Michal's refactor I temporarily define a second func but ultimately merge these 2 func.
const handleGetParcelInStavba = async (obj, token, setPozemekState, setRequest, setIsReset, isReset = false, itemToUpdate = null, setNewBuilding, setLoadings, signal) => {
    try {
        const response = await axios.post(
            urlJoin(apiConfig.getAndQueryBaseUrl, 'parcels/get-parcel'),
            obj,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                signal,
            });
        if (!signal.aborted && isRequestOK(response.status)) {
            if (response.data.data) {
                setPozemekState(prev => ({ ...prev, parcel: response.data.data }));
                setRequest(prev => ({
                    ...prev, 
                    ...response.data.data, 
                    ...((itemToUpdate && !isReset) && (updateTableValues(itemToUpdate, prev) || {})),
                    constructionId: isReset ? null : (itemToUpdate?.constructionId || prev.constructionId || ''),
                    uid: itemToUpdate?.uid || prev.uid || '',
                }));
            } else {
                resetValues(setIsReset, setPozemekState, 'parcel', setRequest, setNewBuilding);
            }
            setLoadings((prevState) => ({
                ...prevState, isParcelLoading: false
            }));
        }
    } catch (error) {
        if (!signal.aborted) {
            console.log('Error', error);
            resetValues(setIsReset, setPozemekState, 'parcel', setRequest, setNewBuilding);
            setLoadings((prevState) => ({
                ...prevState, isParcelLoading: false
            }));
        }
    }
};

const handleGetConstructionDetails = async (
    obj,
    token,
    setState,
    setRequest, 
    itemToUpdate = null,
    isReset = false,
    setIsReset,
    setNewBuilding,
    setLoadings,
    signal,
) => {
    try {
        const response = await axios.post(
            urlJoin(apiConfig.getAndQueryBaseUrl, 'constructions/get-construction-details'),    
            obj,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                signal,
            }); 
        if (!signal.aborted && isRequestOK(response.status)) {
            if (response.data.data) {
                setState(prev => ({...prev, construction: response.data.data}));
                setRequest(prev => ({
                    ...prev, 
                    ...response.data.data, 
                    ...((itemToUpdate && !isReset) && (updateTableValues(itemToUpdate, prev) || {})),
                    constructionId: isReset ? null : (itemToUpdate?.constructionId || prev.constructionId || ''),
                    uid: itemToUpdate?.uid || prev.uid || '',
                }));
            } else {
                resetValues(setIsReset, setState, 'construction', setRequest, setNewBuilding);
            }
            setLoadings((prevState) => ({
                ...prevState, isCityPartsLoading: false}
            ));
        }
    } catch (error) {
        if (!signal.aborted) {
            console.log('Error', error);
            resetValues(setIsReset, setState, 'construction', setRequest, setNewBuilding);
            setLoadings((prevState) => ({
                ...prevState, isCityPartsLoading: false}
            ));
        }
    }
};

const handleGetCities = async (name, token, setState, setLoadings, signal) => {
    try {
        const response = await axios.get(
            urlJoin(apiConfig.getAndQueryBaseUrl, 'constructions/get-cities'),    
            {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': `Bearer ${token}`,
                },
                signal,
                params: {
                    'city-name': capitalizeFirstLetter(name),
                }
            });
        if (!signal.aborted && isRequestOK(response.status)) {
            if (!response.data?.data) {
                setState(prev => ({...prev,  cityList: [], cityPartList: []}));
            } else if (Array.isArray(response.data?.data)) {
                setState(prev => ({...prev, cityList: 
                    [...new Map([...response.data.data]
                        .map(el => [el.code, el])).values()]
                }));
            }
            setLoadings((prevState) => ({
                ...prevState, isCityLoading: false
            }));
        }
        return response;
    } catch (error) {
        if (!signal.aborted) {
            setState(prev => ({ ...prev, cityList: [], cityPartList: [] }));
            setLoadings((prevState) => ({
                ...prevState, isCityLoading: false
            }));
        }
    }
};

const handleBrowseConstructions = async (id, token, setBrowseState, setLoadings, signal) => {
    try {
        const response = await axios.post(
            urlJoin(apiConfig.getAndQueryBaseUrl, 'constructions/browse'),    
            {
                offset: 0,
                size: 1,
                filters: [
                    {
                        field: 'constructionId',
                        operation: 'EQ',
                        value: id,
                        folding: true
                    }
                ],
                sorts: [],
                attributes: null,
                dirty: false
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                signal
            }); 
        if (!signal.aborted && isRequestOK(response.status)) {
            if (!response.data.data || response.data.data?.length === 0) {
                setBrowseState(prev => ({ ...prev, construction: null}));

            } else {
                setBrowseState(prev => ({...prev, construction: response.data.data[0]}));
            }
            setLoadings((prevState) => ({
                ...prevState, isICSLoading: false
            }));
        }
    } catch (error) {
        if (!signal.aborted) {
            console.log('Error', error);
            setBrowseState(prev => ({ ...prev, construction: null}));
            setLoadings((prevState) => ({
                ...prevState, isICSLoading: false
            }));
        }
    }
};

const handleGetParcelCity = async (name, token, setState, setLoadings, signal) => {
    try {
        const response = await axios.post(
            urlJoin(apiConfig.getAndQueryBaseUrl, 'parcels/get-cities'),
            capitalizeFirstLetter(name),
            {
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': `Bearer ${token}`,
                },
                signal
            });
        if (!signal.aborted && isRequestOK(response.status)) {
            if (!response.data?.data) {
                setState(prev => ({...prev,  cityList: [], cadastralAreaList: []}));
            } else if (Array.isArray(response.data?.data)) {
                setState(prev => ({...prev, cityList: 
                    [...new Map([...response.data.data]
                        .map(el => [el.code, el])).values()]
                }));
            }
            setLoadings((prevState) => ({
                ...prevState, isCityLoading: false
            }));
        }
    } catch (error) {
        if (!signal.aborted) {
            console.log('Error', error);
            setState(prev => ({ ...prev, cityList: [], cadastralAreaList: [] }));
            setLoadings((prevState) => ({
                ...prevState, isCityLoading: false
            }));
        }
    }
};

const handleGetCityParts = async (obj, token, setState, setLoadings, signal) => {
    try {
        const response = await axios.post(
            urlJoin(apiConfig.getAndQueryBaseUrl, 'constructions/get-city-parts'),
            obj,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                signal,
            }); 
        if (!signal.aborted && isRequestOK(response.status)) {
            if (!response.data?.data) {
                setState(prev => ({...prev, cityPartList: []}));
            } else if (Array.isArray(response.data?.data)) {
                setState(prev => ({...prev, cityPartList: 
                    [...new Map([...prev.cityPartList, ...response.data.data]
                        .map(el => [el.code, el])).values()]
                }));
            }
            setLoadings((prevState) => ({
                ...prevState, isCityPartCodeLoading: false
            }));
        }
        return response;
    } catch (error) {
        if (!signal.aborted) {
            setState(prev => ({ ...prev, cityPartList: [] }));
            setLoadings((prevState) => ({
                ...prevState, isCityPartCodeLoading: false
            }));
        }
    }
};

// eslint-disable-next-line no-unused-vars
const fetchExistingDocumentation = async (bppPid, token, setDocuments, setUploadedBytes) => {
    try {
        const source = axios.CancelToken.source();
        const response = await axios.get(
            urlJoin(apiConfig.validateAndPersistBaseUrl, `build-intentions/documentations/${bppPid}`),
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                cancelToken: source.token,
            }); 
        if (200 <= response.status < 300) { 
            const documents = response.data;        
            if (documents instanceof Array && documents.length > 0) {
                spreadUploadedDocuments(documents, setDocuments, undefined, setUploadedBytes, undefined, 'existing');
            }
        }
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            console.log('Error', error);
        }
        // TODO error handling
        spreadUploadedDocuments([], setDocuments, undefined, setUploadedBytes, undefined, 'existing');
    }
};

const deleteExistingZamer = async (id, token) => {
    try {
        const source = axios.CancelToken.source();
        const response = await axios.delete(
            urlJoin(apiConfig.validateAndPersistBaseUrl, `build-intentions/${id}`),
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                cancelToken: source.token,
            }); 
        if (200 <= response.status < 300) { 
            return `${response.data} je smazan`;
        }
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            console.log('Error', error);
        }
        return `${id} je uz smazan nebo nastala chyba`;
    }
};

export {
    fetchZadosti,
    fetchZadostiDetail,
    fetchDevelopedZadosti,
    // fetchDocuments,
    makeCopyZadostiById,
    deleteZadosti,
    fetchRizeni,
    fetchZamery,
    fetchZamerDetails,
    fetchStavby,
    getPrijemceDetailWithId,
    handleGetCadastralAreas,
    handleGetParcel,  
    handleGetConstructionDetails, 
    handleGetCities,
    handleBrowseConstructions,
    handleGetParcelCity,
    handleGetCityParts,
    fetchExistingDocumentation,
    deleteExistingZamer,
    fetchQR,
    handleGetParcelInStavba,
    createPdf,
    fetchZadostDetails
};