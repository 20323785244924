import React from 'react';
import PropTypes from 'prop-types';
import '../../css/tableView.css';
import '../../css/zadatelComponent.css';
import { iconChange, trashbin } from '../../assets';
import { handleUpdate, handleDelete } from '../../helperFunctions/modalHelpers';
import { PLNA_MOC_SECOND } from '../../constants/sharedConstants';
import { Container } from 'react-bootstrap';

const MoreZadatelTable = ({ items, setCurrentApplicant, setZadatelAttachments, setShow, setApplicantArr }) => {
    return (
        <Container className='overflow-x-scroll' fluid>
            {items?.length !== 0 && <table className='border'>
                <thead>
                    <tr>
                        <th className='py-2 px-3 col-2'><p className='table-head--zadatel py-2 d-flex'>Jméno/Název</p></th>
                        <th className='py-2 px-3 col-4'><p className='table-head--zadatel py-2 d-flex'>Adresa</p></th>
                        <th className='py-2 px-3 col-2'><p className='table-head--zadatel py-2 d-flex'>IČO</p></th>
                        <th className='py-2 px-3 col-2'><p className='table-head--zadatel py-2 d-flex'></p></th>
                        <th className='py-2 px-3 col-2'><p className='table-head--zadatel py-2 d-flex'></p></th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item) => (
                        <tr key={item.id}>
                            <td className='py-2 px-3'>
                                <p className='row-info py-2 text-truncate text-wrap' title={item.isPO ? `${item.poName}` : `${item.names} ${item.lastName}`}>
                                    {item.isPO ? item.poName : `${item.names} ${item.lastName}`}
                                </p>
                            </td>
                            <td className='py-2 px-3'><p className='row-info py-2 text-truncate text-wrap' title={`${item.address?.street} ${item.address?.descNum}${item.address?.orientNum ? '/' : ''}${item.address?.orientNum}, ${item.address?.city}`}>{item.address?.street} {item.address?.descNum}{item.address?.orientNum ? '/' : ''}{item.address?.orientNum}, {item.address?.city}</p></td>
                            <td className='py-2 px-3'><p className='row-info py-2 text-truncate text-wrap' title={item.ico}>{item.ico}</p></td>
                            <td className='py-2 px-3'><button type='button' className='zadatel-button zadatel-button--table' onClick={() => handleUpdate(item.id, items, setCurrentApplicant, setShow)}><img src={iconChange} alt='iconChange' className='pe-2' />Upravit</button></td>
                            <td className='py-2 px-3'><button type='button' className='zadatel-button zadatel-button--table zadatel-button--delete' onClick={() => handleDelete(item.id, items, setZadatelAttachments, PLNA_MOC_SECOND, setApplicantArr)}><img src={trashbin} alt='trashbin' className='pe-2' />Smazat</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>}
        </Container>
    );
};

MoreZadatelTable.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        names: PropTypes.string,
        lastName: PropTypes.string,
        address: PropTypes.shape({
            city: PropTypes.string,
            street: PropTypes.string,
            descNum: PropTypes.string,
            orientNum: PropTypes.string,
        }),
        ico: PropTypes.string,
        
    })),
    setCurrentApplicant: PropTypes.func,
    setZadatelAttachments: PropTypes.func,
    setShow: PropTypes.func,
    setApplicantArr: PropTypes.func,
};

export default MoreZadatelTable;