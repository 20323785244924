import React from 'react';
import '../../css/novaZadost.css';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { iconSearch } from '../../assets/index.js';

const NovaZadostFilter = ({ setInputValue }) => {
    return (
        <Container fluid>
            <form action="" className='w-100'>
                <label htmlFor="nova_zadost_filter" className='mb-2 label-zadost'>Filtrovat</label>
                <Container className="position-relative d-flex align-items-center" fluid>
                    <img src={iconSearch} className="position-absolute top-50 start-0 translate-middle-y mx-3" />
                    <input type="text" className='input' placeholder='dle názvu žádosti či jejího obsahu' onChange={(event) => { setInputValue(event.target.value); }} />
                </Container>
            </form>
        </Container>
    );
};

NovaZadostFilter.propTypes = {
    setInputValue: PropTypes.func.isRequired,
};

export default NovaZadostFilter;
